import { logEvent as logEventFirebase, setCurrentScreen } from "firebase/analytics";

import { firebaseAnalytics } from '../firebase/firebase'
import { FBPixel } from './facebookPixel'

export const logEvent = (eventName, params) => {
  // console.log('logEvent', eventName, params)
  params
    ? logWithParams(eventName, params)
    : log(eventName)
}

const log = (eventName) => {
  logEventFirebase(firebaseAnalytics, eventName)
  FBPixel.trackCustom(eventName)
}

const logWithParams = (eventName, params) => {
  logEventFirebase(firebaseAnalytics, eventName, params)
  FBPixel.trackCustom(eventName, params)
}

export const logPageView = (params) => {
  // console.log('logPageView', params)
  const contentName = params.name
  const contentId = params.id

  let fbParams = {
    content_name: contentName
  }

  let gaParams = {
    screen_name: contentName
  }

  if (contentId) {
    fbParams = { ...fbParams, content_ids: [contentId] }
    gaParams = { ...gaParams, content_id: contentId }
  }

  FBPixel.track('PageView', fbParams)

  setCurrentScreen(firebaseAnalytics, window.location.pathname)
  logEventFirebase(firebaseAnalytics, 'screen_view', gaParams)
}
