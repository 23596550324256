import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import sessionListActions from '../../store/redux/SessionRedux'

import AcceptCookies from './AcceptCookies'

export default connect(
  state => ({
    isCookiesAccepted: state.sessionState.isCookiesAccepted
  }),
  dispatch => ({
    setCookiesAcceptedAction: bindActionCreators(sessionListActions.request, dispatch)
  })
)(AcceptCookies)
