import React, { useContext, useState, useEffect } from 'react';
import { Header, Image, Input } from 'semantic-ui-react';



const SelectClothesSizeSlide = (({
  dressSize, shoeSize, topSize, bottomSize,
  setDressSize, setShoeSize, setTopSize, setBottomSize
}, ref) => {


  return (
    <div>
      <div>
        <Header as='h4' inverted>
          What's your <strong>dress size</strong> ?
        </Header>
        <Input
          label="dress size"
          labelPosition='top'
          placeholder='Eg. 7 UK or M'
          value={dressSize}
          onChange={(e, { value }) => setDressSize(value)}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Header as='h4' inverted>
          What <strong>shoe size</strong> do you wear?
        </Header>
        <Input
          label="shoe size"
          placeholder='Eg. 8 UK'
          value={shoeSize}
          onChange={(e, { value }) => setShoeSize(value)}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Header as='h4' inverted>
          What <strong>top size</strong> do you wear?
        </Header>
        <Input
          label="top size"
          placeholder='Eg. 10 UK or XL'
          value={topSize}
          onChange={(e, { value }) => setTopSize(value)}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Header as='h4' inverted>
          What <strong>bottom size</strong> do you wear?
        </Header>
        <Input
          label="bottom size"
          placeholder='Eg. 8 UK'
          value={bottomSize}
          onChange={(e, { value }) => setBottomSize(value?.toUpperCase())}
          autoCapitalize='characters'
        />
      </div>
      <br />
    </div>
  );
});

export default SelectClothesSizeSlide;