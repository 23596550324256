import React, { useContext, useState, useEffect } from 'react';
import {
  Dimmer, Loader, Divider, Button, Message, Container
} from 'semantic-ui-react';
import { getPostRoute } from '../../../constants/routes';

import { AppContext } from '../../../shared/context';
import { getPostUrlToShare } from '../../../shared/utils/generateUrlForShare';
import { getInstagramImageFromPost } from '../../../shared/utils/post';
import AddProductreferralToPostForm from '../../Products/components/AddProductreferralToPost/AddProductreferralToPostForm';


export const renderSave = (onSave, { isSaving, isSuccess, errorMessage }) => {
  return (
    <>
      <Divider />
      <div>
        <Button onClick={onSave} compact primary loading={isSaving}>
          Save
        </Button>
        {isSuccess && <Message success compact size='mini' floated='left'>Changes saved!</Message>}
        {errorMessage && <Message error compact size='mini' floated='left'>{errorMessage}</Message>}
      </div>
    </>
  )
}

export const renderSaving = ({ isSaving }) => {
  return (
    isSaving
      ? <Dimmer active={isSaving}>
        <Loader size='mini'>Saving ...</Loader>
      </Dimmer>
      : <></>
  )
}

const drafProductSelectedStorageKey = "smft_drft_prdct_strg"
const drafProductPublishedStorageKey = "smft_pblshd_prdct_strg"
const drafMediaPostStorageKey = "smft_drft_mdpst_strg"
const drafPostPublishedStorageKey = "smft_pblshd_pst_strg"
const drafProductReferralDraftStorageKey = "smft_drft_prfrl_strg"
const drafProductReferralPublishedStorageKey = "smft_pblshd_prfrl_strg"
const drafProductReferralRerviewDraftStorageKey = "smft_drft_prfrl_review_strg"
const drafProductReferralRerviewPublishedStorageKey = "smft_pblshd_prfrl_review_strg"

const AddPostToProductLandingPage = (({ history, autoLoadUrl }, ref) => {
  const [productSelected, setProductSelected] = useState(null);
  const [productPublished, setProductPublished] = useState(null);

  const [mediaPostSelected, setMediaPostSelected] = useState(null);
  const [productReferralDraft, setProductreferralDraft] = useState(null);

  const [postPublished, setPostPublished] = useState(null);
  const [productreferralPublished, setProductreferralPublished] = useState(null);

  const [productreferralReviewDraft, setProductreferralReviewDraft] = useState(null);
  const [productreferralReviewPublished, setProductreferralReviewPublished] = useState(null);

  const getActiveTabIndex = () => {
    let index = 0
    if (productreferralReviewPublished || productreferralReviewDraft
      || (postPublished && productreferralPublished)) {
      index = 3
    } else if (productReferralDraft || mediaPostSelected || productPublished) {
      index = 2
    } else if (productSelected) {
      index = 1
    }

    return index
  }
  const [activeTabIndex, setActiveTabIndex] = useState(getActiveTabIndex());

  // TODO change this to use context instead of auth
  const { user: userContext, updateUser: updateUserContext, theme } = useContext(AppContext);


  useEffect(() => {
    // console.log("AddPostToProductLandingPage useEffect:");

    // clean up controller
    let isSubscribed = true;

    if (isSubscribed) {
      try {
        setProductSelected(JSON.parse(window.localStorage.
          getItem(drafProductSelectedStorageKey)))

        setProductPublished(JSON.parse(window.localStorage
          .getItem(drafProductPublishedStorageKey)))

        setMediaPostSelected(JSON.parse(window.localStorage
          .getItem(drafMediaPostStorageKey)))

        setProductreferralDraft(JSON.parse(window.localStorage
          .getItem(drafProductReferralDraftStorageKey)))

        setPostPublished(JSON.parse(window.localStorage
          .getItem(drafPostPublishedStorageKey)))

        setProductreferralPublished(JSON.parse(window.localStorage
          .getItem(drafProductReferralPublishedStorageKey)))

        setProductreferralReviewDraft(JSON.parse(window.localStorage
          .getItem(drafProductReferralRerviewDraftStorageKey)))

        setProductreferralReviewPublished(JSON.parse(window.localStorage
          .getItem(drafProductReferralRerviewPublishedStorageKey)))
      } catch (e) {

      }

    }
    // cancel subscription to useEffect
    return () => (isSubscribed = false)
  }, [])

  useEffect(() => {
    setActiveTabIndex(getActiveTabIndex());
  }, [productSelected, productPublished,
    mediaPostSelected, productReferralDraft, postPublished, productreferralPublished,
    productreferralReviewDraft, productreferralReviewPublished])

  const onAddProductToPostDone = () => {
    // console.log("AddPostToProductLandingPage --   onAddProductToPostDone")

    setProductSelected(null)
    window.localStorage.removeItem(drafProductSelectedStorageKey)

    setProductPublished(null)
    window.localStorage.removeItem(drafProductPublishedStorageKey)

    setMediaPostSelected(null)
    window.localStorage.removeItem(drafMediaPostStorageKey)
    setProductreferralDraft(null)
    window.localStorage.removeItem(drafProductReferralDraftStorageKey)
    setPostPublished(null)
    window.localStorage.removeItem(drafPostPublishedStorageKey)
    setProductreferralPublished(null)
    window.localStorage.removeItem(drafProductReferralPublishedStorageKey)

    setProductreferralReviewDraft(null)
    window.localStorage.removeItem(drafProductReferralRerviewDraftStorageKey)
    setProductreferralReviewPublished(null)
    window.localStorage.removeItem(drafProductReferralRerviewPublishedStorageKey)

    setActiveTabIndex(0)

    const postUrl = getPostUrlToShare(postPublished)

    history && history.push(getPostRoute(postUrl))
  }

  const onProductSelectedChange = (product) => {
    // console.log("AddPostToProductLandingPage onProductSelected product:", product)

    setProductSelected(product)
    window.localStorage.setItem(drafProductSelectedStorageKey, JSON.stringify(product))
  }

  const onProductPublished = (product) => {
    // console.log("AddPostToProductLandingPage onProductPublished product:", product)

    setProductSelected(product)
    setProductPublished(product)
    setActiveTabIndex(1)
    window.localStorage.setItem(drafProductPublishedStorageKey, JSON.stringify(product))
  }

  const onMediaPostSelected = (post) => {
    // console.log("AddPostToProductLandingPage onMediaPostSelected post:", post)

    setMediaPostSelected(post)
    window.localStorage.setItem(drafMediaPostStorageKey, JSON.stringify(post))
  }

  const onProductReferralDraftChange = (productReferral) => {
    // console.log("AddPostToProductLandingPage onProductReferralDraftChange productReferral:", productReferral)

    setProductreferralDraft(productReferral)
    window.localStorage.setItem(drafProductReferralDraftStorageKey, JSON.stringify(productReferral))
  }

  const onPostAndProductreferralPublished = ({ post, productreferral }) => {
    // console.log("AddPostToProductLandingPage onPostAndProductreferralPublished post:", post)
    // console.log("AddPostToProductLandingPage onPostAndProductreferralPublished productreferral:", productreferral)

    setPostPublished(post)
    setProductreferralPublished(productreferral)
    setActiveTabIndex(2)
    window.localStorage.setItem(drafPostPublishedStorageKey, JSON.stringify(post))
    window.localStorage.setItem(drafProductReferralPublishedStorageKey, JSON.stringify(productreferral))
  }

  const onProductReferralReviewDraftChange = (productreferral) => {
    // console.log("AddPostToProductLandingPage onProductReferralReviewDraftChange productreferral:", productreferral)

    setProductreferralReviewDraft(productreferral)
    window.localStorage.setItem(drafProductReferralRerviewDraftStorageKey, JSON.stringify(productreferral))
  }

  const onProductReferralReviewPublished = (productreferral) => {
    // console.log("AddPostToProductLandingPage onProductReferralReviewPublished productreferral:", productreferral)

    setProductreferralReviewDraft(productreferral)
    setProductreferralReviewPublished(productreferral)
    window.localStorage.setItem(drafProductReferralRerviewPublishedStorageKey, JSON.stringify(productreferral))
  }

  return (
    <Container>
      <Divider hidden />
      <AddProductreferralToPostForm
        withPostSelector={true}
        initialActiveStepIndex={activeTabIndex}

        productSelected={productSelected}
        productPublished={productPublished}
        mediaPostSelected={mediaPostSelected}
        productReferralDraft={productReferralDraft}
        postPublished={postPublished}
        productreferralPublished={productreferralPublished}
        productreferralReviewDraft={productreferralReviewDraft}
        productreferralReviewPublished={productreferralReviewPublished}

        onProductSelectedChange={onProductSelectedChange}
        onProductPublished={onProductPublished}
        onMediaPostSelected={onMediaPostSelected}
        onProductReferralDraftChange={onProductReferralDraftChange}
        onPostAndProductreferralPublished={onPostAndProductreferralPublished}
        onProductReferralReviewDraftChange={onProductReferralReviewDraftChange}
        onProductReferralReviewPublished={onProductReferralReviewPublished}
        onDone={onAddProductToPostDone}

        autoLoadUrl={autoLoadUrl}
      />
    </Container>
  );
});

export default AddPostToProductLandingPage;