import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import Rehydration from '../../services/rehydration'
import ReduxPersist from './config/reduxPersist'

// import screenTracking from './middlewares/screenTrackingMiddleware'
// import apiMiddleware from './middlewares/apiMiddleware'

// creates the store
export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = []
  const enhancers = []

  /* ------------- Analytics Middleware ------------- */
  // middleware.push(screenTracking)

  /* ------------- API Middleware ------------- */
  // middleware.push(apiMiddleware)

  /* ------------- Saga Middleware ------------- */

  // const sagaMonitor = Config.useReactotron ? console.tron.createSagaMonitor() : null
  const sagaMiddleware = createSagaMiddleware() // { sagaMonitor }
  middleware.push(sagaMiddleware)

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware))

  // // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  // if (Config.useReactotron) {
  //     enhancers.push(console.tron.createEnhancer())
  // }
  const store = createStore(rootReducer, compose(...enhancers))

  // configure persistStore and check reducer version number
  if (ReduxPersist.active) {
    Rehydration.updateReducers(store)
  }

  // kick off root saga
  const sagasManager = sagaMiddleware.run(rootSaga)

  return {
    store,
    sagasManager,
    sagaMiddleware
  }
}
