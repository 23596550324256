import PropTypes from 'prop-types'
import React from 'react'
import {
  Container,
  Header,
  Image,
  Grid, Segment
} from 'semantic-ui-react'

import { Media } from '../../../utils/AppMediaBreakPoints'
import demo from '../../../images/demolaptop.gif'
import InstallAddonButton from '../../../components/InstallAddonButton'

/* eslint-disable react/no-multi-comp */
/* Heads up! Home uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const BrowserDemo = (mobile) => (
  <Container as={Media} greaterThanOrEqual='computer'>
    {/* <Responsive {...Responsive.onlyComputer}> */}
    <Segment
      style={{
        borderRadius: '20px',
        background: '#191919',
        padding: '2em 0em'
      }} inverted
    >
      <Grid centered>
        <Grid.Row style={{ padding: '8px' }}>
          <Header as='h2' inverted>
            An Inclusive Online Shopping Experience
            <Header.Subheader>
              This is your opportunity to share your outfit ideas, must-haves or how you<br /> pair products for shoppers like you and earn money from it!
            </Header.Subheader>
          </Header>
        </Grid.Row>
        <Grid.Row style={{ padding: '8px' }}>
          <InstallAddonButton />
        </Grid.Row>
        <Grid.Row style={{ padding: 0 }}>
          <Image src={demo} style={{ height: '450px', zIndex: 99 }} />
        </Grid.Row>
      </Grid>
      {/*
        === Used to screenrecord the demo gif on top of a laptop
        <Grid centered>
          <div>
            <div style={{ position: 'absolute', zIndex: 9, top: 0, bottom: 0, margin: 'auto' }}>
              <Image src={laptop} style={{ height: '450px' }} />
            </div>
            <Image src={demo} style={{ height: '450px', zIndex: 99 }} />
          </div>
        </Grid> */}
    </Segment>
  </Container>
)

BrowserDemo.propTypes = {
  mobile: PropTypes.bool
}

export default BrowserDemo
