import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  request: ['request'],
  success: ['items'],
  failure: null,
  reset: null
}, { prefix: 'INSTAGRAM_USER_' })

export const InstagramUserDetailTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  items: [],
  fetching: null,
  statusText: null
})

/* ------------- Selectors ------------- */

export const InstagramUserDetailSelectors = {
  selectUser: state => state.items
}

/* ------------- Reducers ------------- */

export const request = (state) => {
  return state.merge({ fetching: true })
}

export const success = (state) => {
  return state.merge({ fetching: false })
}

export const failure = (state) => state.merge({ fetching: false })
export const reset = () => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset
})
