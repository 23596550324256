import { getRemoteConfig, getValue } from 'firebase/remote-config'

const remoteConfig = getRemoteConfig()

export const remoteConfigSmftWebLegacyFeatureEnabled = () =>
  getValue(remoteConfig, 'smft_web_legacy')

export const remoteConfigSmftHomePopularProductsFeatureEnabled = () =>
  getValue(remoteConfig, 'smft_home_popular_products')

export const remoteConfigSmftABHomeRecentUsersEnabled = () =>
  getValue(remoteConfig, 'smft_AB_home_show_recently_joined_users')

export const remoteConfigSmftABHomeLandingHeadlinev1Enabled = () =>
  getValue(remoteConfig, 'smft_landing_headline_v1')
