export const getPathFromRouteAndParam = (route, paramArray) => {
  let routeWithParam = route
  paramArray.map((param) => {
    routeWithParam = routeWithParam.replace(`:${param.key}`, param.value)
    return routeWithParam
  })

  return routeWithParam
}

// not used! check if it's worth keep it
export function compareRoutesWithParam (route, routeConst, param) {
  const regexp = routeConst.replace(/\//g, '\\/').replace(`:${param}`, '.*')
  return route && route.match(regexp)
}

export const getUserRoute = (route, username, id) =>
  getPathFromRouteAndParam(route, [
    { key: 'username', value: username },
    { key: 'id', value: id }
  ])

// export const getProductListFilterRoute = (route, name, id) =>
//     getPathFromRouteAndParam(route, [
//         { key: 'name', value: name },
//         { key: 'id', value: id },
//     ]);

export const replaceSitename = (route, filter) =>
  route.replace(':sitename', `${filter}`)

export const replaceUsername = (route, filter) =>
  route.replace(':username', `${filter}`)

export const replaceUsernameAndBrands = (route, filter) =>
  route.replace(':username', `${filter}`)
    .replace(':brands?', '')

export const replaceUrl = (route, filter) =>
  route.replace(':url', `${filter}`)

export const replaceBrands = (route, filter) =>
  route.replace(':brands?', `${filter}`)
