import firebase from 'firebase/compat/app';
import { useState, useEffect, useContext, createContext } from 'react'
import { getAnalytics } from "firebase/analytics";
import { collection, getFirestore } from "firebase/firestore";
import { getAuth, signOut, sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";

import { logEvent } from '../services/analytics';
import { appContextKey } from '../shared/context';
import { createWebUser, getWebUser, updateWebUser } from '../shared/api/web/users/userWebApi';
import createFirebaseUser from './users/createFirebaseUser';
import getFirebaseUser from './users/getFirebaseUser';
import updateFirebaseUser from './users/updateFirebaseUser';
import { populateFirebaseUserFromWeb, populateWebUserFromFirebase } from '../shared/utils/firebaseUserUtil';
import updateFirebaseCurrentUser from './users/updateFirebaseCurrentUser';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCAFoR5ODtIMRw-HhfsdMtaPOCXaFOWVk0',
  authDomain: 'myyfit-project9873.firebaseapp.com',
  databaseURL: 'https://myyfit-project9873.firebaseio.com',
  projectId: 'myyfit-project9873',
  storageBucket: 'myyfit-project9873.appspot.com',
  messagingSenderId: '34583415616',
  appId: '1:34583415616:web:c6907ed78750053bf248a5',
  measurementId: 'G-BK6WE4R1YK'
}

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://seemyfit.at/welcome',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'at.seemyfit.app'
  },
  android: {
    packageName: 'at.seemyfit.app',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'seemyfit.at'
};

export const myFirebase = firebase.initializeApp(firebaseConfig)
export const firebaseAnalytics = getAnalytics()

// Initialize Cloud Firestore and get a reference to the service
export const FIREBASE_USERS_COLLECTION = 'users';
export const db = getFirestore(myFirebase);
export const usersCollectionRef = collection(db, FIREBASE_USERS_COLLECTION);


export const sendSignInLink = (email, onSuccess, onError, analyticsLabel) =>
  sendSignInLinkToEmail(getAuth(), email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      onSuccess && onSuccess()
      window.localStorage.setItem('emailForSignIn', email);
      logEvent(`${analyticsLabel}-sginInLinkSent`)
    })
    .catch((error) => {
      onError && onError(error)
      logEvent(`${analyticsLabel}:-Code${error && error.code}`)
    })

export const firebaseSignOut = async (auth, history, clearUser) => {
  try {
    await signOut(auth)
    clearUser(null)
    window.localStorage.clear()
    history && history.push("/")
  } catch (e) {
    console.log('signOut error:', e)
  }
}

export const getFirebaseAuth = () => getAuth()


export const AuthContext = createContext({ userPresent: false, user: null })

export const AuthContextProvider = props => {
  const [loading, setUserLoading] = useState(false)
  const [user, setUser] = useState()
  const [error, setError] = useState()

  const auth = getAuth()
  // const [user, loading, error] = useAuthState(auth);

  let [state, changeState] = useState({
    userPresent: false,

    user: null,
    listener: null
  })

  useEffect(() => {
    if (state.listener == null) {
      changeState({
        ...state, listener: auth.onAuthStateChanged((user) => {

          if (user)
            changeState(oldState => ({ ...oldState, userPresent: true, user: user }));
          else
            changeState(oldState => ({ ...oldState, userPresent: true, user: null }));
        }, setError)
      });
    }

    // setUserLoading(true)
    // const unsubscribe = onAuthStateChanged(auth,
    //   (user) => {
    //     setUserLoading(false)
    //     setUser(user)
    //     console.log("firebase.js - setUserLoading false, setUser:", user)
    //   }, setError)
    return () => {
      if (state.listener)
        state.listener()
    }
  }, [])
  return <AuthContext.Provider value={state} {...props} />
}

export const useFirebaseAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth && auth.user != null }
}

export const connectUserByEmailLink = async ({ auth, onSuccess, onFailure, updateUserContext }) => {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  let email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation');
  }
  if (!email) {
    // console.log("connectUserByEmailLink missing email error.")
    return
  }
  // The client SDK will parse the code from the link for you.
  await signInWithEmailLink(auth, email, window.location.href)
    .then(async (result) => {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser

      // console.log("====signInWithEmailLink result:", result);

      const user = result && result.user
      const uid = user && user.uid
      if (uid) {
        // console.log("====signInWithEmailLink uid:", uid);
        let fbuser = await getFirebaseUser(uid);
        // console.log("====signInWithEmailLink get firebaseUser:", fbuser)
        const isNewUser = result && result.additionalUserInfo && result.additionalUserInfo.isNewUser

        if (isNewUser || !fbuser) {
          // If new user -> create firebaseUser
          fbuser = await createFirebaseUser(uid);
          // console.log("====signInWithEmailLink new firebaseUser created:", fbuser)
        }

        updateUserContext({ uid, ...fbuser })
      }

      onSuccess && onSuccess()
    })
    .catch((error) => {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      onFailure && onFailure(error)
    });
}

export const linkIGInFirebaseAndWebUser = async ({
  auth,
  userContext,
  updateUserContext,
  code,
  getInstagramUserByToken,
  getInstagramAvatarByUsername,
  onFailure,
  onSuccess
}) => {
  console.log("linkIGInFirebaseAndWebUser called code:", code)
  const payload = { ig_code: code }

  console.log("linkIGInFirebaseAndWebUser called userContext:", userContext)

  // Create or update web user to get instagram token
  let webResponse = null
  let webUserId = userContext && userContext.webUser && userContext.webUser.id
  let isWebUserLinkedToFirebaseUser = !!webUserId

  if (isWebUserLinkedToFirebaseUser) {
    const webRequest = {
      id: webUserId,
      ...payload
    }
    webResponse = await updateWebUser(webRequest)
    console.log("linkIGInFirebaseAndWebUser updateWebUser:", webResponse)
  } else {
    const webRequest = {
      ...payload
    }
    webResponse = await createWebUser(webRequest)
    webUserId = webResponse && webResponse.id
    console.log("linkIGInFirebaseAndWebUser createWebUser:", webResponse)
  }

  if (webResponse) {
    const accessToken = webResponse.ig_access_token
    const accessTokenExpiresIn = webResponse.ig_access_token_expires_in
    const uid = userContext && userContext.uid
    if (!accessToken) {
      onFailure && onFailure()
    }
    else if (accessToken) {
      // console.log("linkIGInFirebaseAndWebUser accessToken:", accessToken)
      // Use accessToken to get username and instagram id
      const userIdResponse = await getInstagramUserByToken(accessToken)
      console.log("linkIGInFirebaseAndWebUser userIdResponse:", userIdResponse)
      const username = userIdResponse && userIdResponse.username
      if (!!username) {
        // Use username to get profile picture
        const image = await getInstagramAvatarByUsername(username)

        // Update web profile with username, profile picture, and firebaseUser
        const firebaseUserToWebFlatProperties = populateWebUserFromFirebase(userContext)
        // console.log("linkIGInFirebaseAndWebUser  firebaseUserToWebFlatProperties:", firebaseUserToWebFlatProperties)
        const webRequest = {
          id: webUserId,
          username,
          ...image && { instagram_avatar: image },
          ig_access_token: '', // removed token from web and keep it in Firebase
          mrglgcy: true,
          ...firebaseUserToWebFlatProperties
        }
        // console.log("linkIGInFirebaseAndWebUser updateWebUser webRequest:", webRequest)
        webResponse = await updateWebUser(webRequest)
        // console.log("linkIGInFirebaseAndWebUser updateWebUser webResponse:", webResponse)
        if (webResponse) {
          const userMergedWithLegacy = webResponse.ig_access_token == "merged"
          if (userMergedWithLegacy) {
            // if new user merged with legacy one, use the legacy id as webUserId
            webResponse.id = webResponse.ig_access_token_expires_in
            webResponse.instagram_username = username
          }
        }
        // Update FirebaseUser with username, profile picture and webUser
        try {
          if (uid) {
            const webFlatProperties = populateFirebaseUserFromWeb(webResponse || {})
            const updatedUserPayload = {
              username,
              igBasicApi: {
                access_token: accessToken,
                expires_in: accessTokenExpiresIn,
                ...userIdResponse
              },
              photoURL: webResponse.instagram_avatar,
              ...webFlatProperties,
            }
            const firebaseResult = await updateFirebaseUser(uid, updatedUserPayload)
            // console.log("linkIGInFirebaseAndWebUser updateFirebaseUser firebaseResult:", firebaseResult)

            updateUserContext({ uid, ...updatedUserPayload, avatar: webResponse.instagram_avatar })
          }

          try {
            await updateFirebaseCurrentUser(auth.currentUser, {
              photoURL: webResponse.instagram_avatar,
              displayName: username
            })
            onSuccess && onSuccess()
          } catch (e) {
            onFailure && onFailure(e)
            // console.log("linkIGInFirebaseAndWebUser updateFirebaseCurrentUser error:", e)
          }
        } catch (e) {
          onFailure && onFailure(e)
          console.error(e);
        }
      }
    }
  }
}


        // const webUser = fbuser && fbuser.webUser
        // console.log("====linkIGInFirebaseAndWebUser webUser:", webUser);
        // if (webUser) {
        //   // else if web user -> create webUser and link both
        //   const webUserId = webUser.id
        //   console.log("====linkFirebaseAndWebUser webUserId:", webUserId);
        //   const webRequest = { id: webUserId }
        //   const webResult = await getWebUser(webRequest);
        //   const webFlatProperties = populateFirebaseUserFromWeb(webResult || {})
        //   const updatedUser = {
        //     ...webFlatProperties,
        //   }
        //   const firebaseResult = await updateFirebaseUser(fbuser.uid, updatedUser)
        //   return firebaseResult
        // }
