import React, { Component } from 'react'

import {
  Grid,
  Image,
  Button
} from 'semantic-ui-react'

import asoslogo from '../../../../images/asoslogo.png'
import boohoologo from '../../../../images/boohoologo.png'
import vanslogo from '../../../../images/vanslogo.png'
import zaralogo from '../../../../images/zaralogo.png'
import sheinlogo from '../../../../images/sheinlogo.png'
import guccilogo from '../../../../images/guccilogo.png'
import diorlogo from '../../../../images/diorlogo.png'
import hermeslogo from '../../../../images/hermeslogo.png'
// import chanellogo from '../../../../images/chanellogo.png'
import aritzialogo from '../../../../images/aritzialogo.png'

export const FILTERS = [
  { name: 'gucci', image: guccilogo },
  { name: 'dior', image: diorlogo },
  { name: 'hermes', image: hermeslogo },
  //   { name: 'chanel', image: chanellogo },
  { name: 'aritzia', image: aritzialogo },
  { name: 'asos', image: asoslogo },
  { name: 'boohoo', image: boohoologo },
  { name: 'zara', image: zaralogo },
  { name: 'shein', image: sheinlogo },
  { name: 'vans', image: vanslogo }
  // { name: 'dc', image: dclogo },
]

class ProductFilters extends Component {
  setSitenameFilter (newSitename) {
    this.props.onProductFilterChange && this.props.onProductFilterChange(newSitename)
  }

  renderFilter (filter, index) {
    const { siteName } = this.props
    return (
      <Grid.Column mobile={6} tablet={2} computer={2} key={index}>
        <Button
          onClick={() => this.setSitenameFilter(filter.name)}
          active={siteName === filter.name}
          fluid
        >
          <Image src={filter.image} size='tiny' rounded />
        </Button>
      </Grid.Column>
    )
  }

  render () {
    return (
      <Grid centered columns='equal' className='filters'>
        {FILTERS.map((f, index) => this.renderFilter(f, index))}
      </Grid>
    )
  }
}
export default ProductFilters
