import React, { useContext } from 'react';
import {
    Button, Icon, Divider
} from 'semantic-ui-react';
import { ADDPRODUCT } from '../../../constants/routes';
import { logEvent } from '../../../services/analytics';
import { AppContext } from '../../../shared/context';


const ProductreferralTopButtons = (({
    post,
    onClick
}, ref) => {
    const { user: userContext } = useContext(AppContext)
    const postOwner = post && post.user
    const isCurrentUserPost = postOwner && (userContext.uid === postOwner.firebase_user_id)
    // console.log("ProductreferralTopButtons userContext.uid", userContext.uid)
    // console.log("ProductreferralTopButtons post", post)
    // console.log("ProductreferralTopButtons isCurrentUserPost", isCurrentUserPost)

    return isCurrentUserPost ? (
        <center>
            <Button compact primary icon labelPosition='right'
                onClick={onClick}
            >
                Tag another product
                <Icon name='plus' />
            </Button>
            <br />
            <small>Tag another product to this post</small>
            <Divider clearing />
        </center>
    ) : <></>
});

export default ProductreferralTopButtons;