import React, { Component } from 'react'
import {
  Segment, Grid, Header, Button, Icon, Dimmer, Loader, Divider, Container
} from 'semantic-ui-react'

import LookCardWithProductName from '../../Posts/components/LookCardWithProductName'
import { logEvent } from '../../../services/analytics'
import { getPostsListFilterRoute, ROOT } from '../../../constants/routes'

export const RELATED_POSTS_COUNT = 4

class RelatedPosts extends Component {
  componentDidMount() {
    const { siteName } = this.props
    this.loadPosts(siteName)
  }

  loadPosts(siteName) {
    this.props.getPostsAction({
      filter: {
        ...(siteName && { dn: siteName })
      },
      page: {
        size: RELATED_POSTS_COUNT
      }
    })
  }

  onSeeMoreClicked(siteName) {
    this.props.history.push({
      pathname: siteName ? getPostsListFilterRoute(siteName) : ROOT
    })
    logEvent(`relatedSeeMore${siteName}`)
  }

  renderPost(post) {
    return (
      <Grid.Column
        key={post.id} id={post.id}
        style={{ padding: '1px', overflow: 'hidden' }}
        mobile={8} tablet={8} computer={4}
      >
        <LookCardWithProductName
          post={post}
          analytisEventName='relatedProductCardImageClicked'
        />
      </Grid.Column>
    )
  }

  render() {
    const { posts, isLoading, siteName } = this.props
    const postsSharedCount = posts && posts.length

    return (
      <Container className='no-horizontal-margin'>
        {/* <Divider hidden /> */}
        <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
          {!isLoading && postsSharedCount > 0 && (
            <Segment basic textAlign='center'>
              <Header as='h1' inverted>
                Similar Styles
                <Header.Subheader>{siteName && `from ${siteName}`}</Header.Subheader>
              </Header>
            </Segment>
          )}
          <Grid centered>
            <Dimmer active={isLoading}>
              <Loader size='mini'>Loading Similar Styles...</Loader>
            </Dimmer>
            {posts && posts.length > 0 && posts.map((p) => this.renderPost(p))}
          </Grid>

          {!isLoading && posts && posts.length > 0 && (
            <Segment basic clearing>
              <Button
                floated='right'
                onClick={() => this.onSeeMoreClicked(siteName)}
                color='blue'
              >{`More ${siteName || ''} Looks`}
                <Icon name='right arrow' />
              </Button>
            </Segment>
          )}
        </Segment>
        <Divider hidden />
      </Container>
    )
  }
}

export default RelatedPosts
