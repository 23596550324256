import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import { AppContext } from '../../../shared/context';
import { getMatchColorByPercent, getTotalScoreMatchPercent } from '../../../shared/utils/styleScore';


const UserOverallMatchString = (({
    profile
}, ref) => {
    const { user: userContext } = useContext(AppContext)
    const matchPercent = getTotalScoreMatchPercent(userContext, profile)
    const matchPercentStr = `${matchPercent}% match`
    // console.log("renderPostOwner matchPercentStr", matchPercentStr)
    const percentMatch = getTotalScoreMatchPercent(userContext, profile)
    const overAllMatchColor = getMatchColorByPercent(percentMatch)

    return <>
        <Icon name='bullseye' style={{ paddingRight: 0, marginRight: 0, color: `${overAllMatchColor}` }} />
        {matchPercent > 0 ? matchPercentStr : ''}
    </>
});

export default UserOverallMatchString;