import React from 'react'
import {
  Placeholder,
  Segment
} from 'semantic-ui-react'

const PlaceholderCard = () => (
  <Segment inverted>
    <Placeholder inverted>
      <Placeholder.Image style={{ height: 300 }} />
      <Placeholder.Header image />
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
    <br />
  </Segment>
)

export default PlaceholderCard
