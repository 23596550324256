import React, { useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

import { firebaseSignOut } from '../../../../firebase/firebase';
import { AppContext } from '../../../../shared/context';


const LogoutHyperLink = (({
  history
}, ref) => {
  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth);
  const { clearUser } = useContext(AppContext);


  const logout = () => {
    firebaseSignOut(auth, history, clearUser)
  }

  return (
    <div>
      <br />
      {`Not ${user && user.email}? `}
      <a href="#" onClick={logout} style={{ color: "rgba(54, 48, 40, 0.95)" }}>logout</a>
    </div>

  );
});

export default LogoutHyperLink;