import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'

// import { sessionExpired } from './LoginSagas'

export function* getProducts({ api, Actions }, { request }) {
  const { filter, page } = request

  if (page && page.number > 1) {
    yield put(Actions.nextPageRequest())
  }

  const include = 'productreferrals.post.user'
  const sort = '-updated_at'

  const config = {
    params: {
      include,
      sort,
      ...(filter && { filter: { ...filter } }),
      ...(page && { page: { ...page } })
    }
  }

  try {
    const response = yield call(api.getProducts, config)
    if (response) {
      const itemsPage = path(['data'], response)

      // const itemsPage = data.filter(p => p.url && p.image_url && p.name && p.posts &&
      //   p.posts.data && p.posts.data.length > 0)

      const paging = path(['meta', 'page'], response)
      const lastPageNumber = paging && paging['last-page']
      const total = paging && paging.total
      // do data conversion here if needed
      yield put(Actions.success(itemsPage, lastPageNumber, total))
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }

    yield put(Actions.failure())
  }
}
