import React, { Component } from 'react'
import {
  Image, Icon, Header
} from 'semantic-ui-react'
import { logEvent } from '../../../services/analytics'
import { getUsernameRoute } from '../../../constants/routes'
import { getPostsFromResponse } from '../../../shared/utils/post'
import { getInfluencerStatusFromUser, influencersStatus, isVerifiedAccount } from '../../../shared/utils/influencerStatus'
import verificationBadgeImage from '../../../images/instagram-verification-badge.png'


export const renderUserAvatarWithFallback = (user, avatarFailedToLoad, onError) => {
  const status = getInfluencerStatusFromUser(user)
  const isMega = influencersStatus.Mega === status
  const isMacro = influencersStatus.Macro === status
  const borderColor = isMega ? 'red' : isMacro ? 'orange' : 'rgba(255, 255, 255, 0.5)'

  if (!avatarFailedToLoad) {
    return (
      <Image
        src={user.instagram_avatar} circular
        // style={{ border: `0.1em solid ${borderColor}` }}
        className='story-circle'
        onError={() => onError && onError()}
        id={user.id}
        size='mini'
      />
    )
  } else {
    return (
      <Icon name='user' circular inverted
        style={{ fontSize: '1.4em' }}
      />
    )
  }
}

export const renderUsernameVerifiedBadge = (user) => {
  return (
    <Header.Content
      style={{
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        flexWrap: 'nowrap',
      }}
    >@{user.instagram_username}{
        (isVerifiedAccount(user)) &&
        (
          // <Icon name='check circle' color='blue' style={{
          //   fontSize: '0.8em',
          //   display: 'inline',
          //   paddingLeft: '2px'
          // }} />
          <Image src={verificationBadgeImage} centered size='mini' style={{
            // fontSize: '0.8em',
            width: '18px',
            height: '18px',
            display: 'inline',
            // paddingLeft: '2px'
          }} />
        )
      }</Header.Content >
  )
}

class UserCard extends Component {
  state = {
    avatarFailedToLoad: false
  }

  render() {
    const { user, analytisEventName } = this.props
    const posts = (getPostsFromResponse(user)) || []
    const lastPost = posts[posts.length - 1] || {}
    // let looksShared = formatNumber(posts.length) || 0
    // let followersCount = formatNumber(user.ig_followed_by_count) || '--' // not used

    const website = user.website
    let websitename = website && website.replace('https://', '').replace('http://', '').replace('www.', '').replace(/\/$/, '')
    if (!websitename) {
      websitename = website
    }

    const url = getUsernameRoute(user.instagram_username)

    // not used
    // const status = getInfluencerStatusFromUser(user)
    // const subStatus = getUserInfluencerLabel(user)
    // const isMega = influencersStatus.Mega === status
    // const isMacro = influencersStatus.Macro === status

    return (
      <>
        <a href={url} id={user.id}>
          <div
            onClick={() => logEvent(analytisEventName)}
            style={{
              backgroundImage: `url(${lastPost.url})`,
              backgroundSize: 'cover',
              height: '120px',
              backgroundPosition: 'center'
            }}
          />
          {
            (user.instagram_avatar) && (
              <div style={{
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                background: 'rgba(0, 0, 0, 0.6)',
                margin: 'auto',
                textAlign: 'center'
              }}
              >
                <div style={{ padding: '14px' }} >
                  <Header as='h3' textAlign='center' icon inverted>
                    {renderUserAvatarWithFallback(user, this.state.avatarFailedToLoad, () => this.setState({ avatarFailedToLoad: true }))}
                    {renderUsernameVerifiedBadge(user)}
                    <Header.Subheader>{user.name}</Header.Subheader>
                    {/* <Divider />
                    <Header.Subheader>
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          width: '100%',
                          left: 0,
                          right: 0,
                          margin: 'auto',
                          padding: '10px',
                          background: 'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.55),rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))',
                          paddingTop: '35px'
                        }}>
                        <Statistic.Group
                          size='mini'
                          style={{ margin: 'auto !important', fontSize: '0.9em' }}
                          inverted
                          {...(isMega ? { color: 'red' } : {})}
                          {...(isMacro ? { color: 'orange' } : {})}
                          widths='two'
                        >
                          <Statistic style={{ fontSize: '0.9em' }}>
                            <Statistic.Value>{followersCount}</Statistic.Value>
                            <Statistic.Label>FOLLOWERS</Statistic.Label>
                          </Statistic>
                          <Statistic style={{ fontSize: '0.9em' }}>
                            <Statistic.Value style={{ fontSize: '0.9em' }}>{status}</Statistic.Value>
                            <Statistic.Label>{subStatus}</Statistic.Label>
                          </Statistic>
                        </Statistic.Group>
                      </div>
                    </Header.Subheader> */}
                  </Header>
                </div>
              </div>
            )
          }
        </a>
      </>
    )
  }
}

export default UserCard
