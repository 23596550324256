import React, { useContext } from 'react';
import { Container, Divider, Grid, Header, Label, Icon } from 'semantic-ui-react';
import PromocodeLabel from '../../../layouts/components/PromocodeLabel';
import { AppContext } from '../../../shared/context';
import { getMatchColorByPercent, getOverAllMatchCopy, getTotalScoreMatchPercent } from '../../../shared/utils/styleScore';
import { Media } from '../../../utils/AppMediaBreakPoints';
import { renderUserAvatarWithFallback, renderUsernameVerifiedBadge } from './UserCard';
import UserMatchLabels from './UserMatchLabels';


const UserHeaderComponent = (({
    profile,
    username,
    isLoadingInstagram,
    isLoadingProfile,
    followersCount,
    looksShared,
    totalProductViews,
    website, websitename, promocode, lastPost,
    isMega, isMacro, subStatus
}, ref) => {
    const { user: userContext } = useContext(AppContext)

    const overAllMatchCopyText = getOverAllMatchCopy(userContext, profile)
    const percentMatch = getTotalScoreMatchPercent(userContext, profile)
    const overAllMatchColor = getMatchColorByPercent(percentMatch)

    return (
        <Container
            // as={Media} greaterThanOrEqual='tablet'
            style={{ padding: '0px' }}
        >
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <Header inverted as='h1'>
                    {profile && (
                        renderUserAvatarWithFallback(profile, false, () => { })
                    )}
                    <Header.Content style={{ textAlign: 'left' }}>
                        {profile && renderUsernameVerifiedBadge(profile)}
                        <Header.Subheader>{isLoadingProfile ? '...' : profile && profile.name}</Header.Subheader>
                    </Header.Content>
                </Header>
                {(website || promocode) && (
                    <div style={{ paddingBottom: '2em' }}>
                        {website && (
                            <a href={website} target='_blank' rel='noopener noreferrer'>
                                {websitename}{' '}
                                {promocode && (
                                    <PromocodeLabel
                                        size='tiny'
                                        color='black'
                                        basic
                                        promocode={promocode}
                                        analytisEventName='userfeedPromoCodeCopied'
                                    />
                                )}
                            </a>
                        )}
                    </div>
                )}
                <Grid centered>
                    <Grid.Row>
                        <Header as='h4' >
                            <p style={{ maxWidth: '350px' }}><Icon name="bullseye" style={{ color: `${overAllMatchColor}` }}></Icon>{overAllMatchCopyText}</p>
                            <Header.Subheader>
                                <UserMatchLabels profile={profile} />
                            </Header.Subheader>
                        </Header>
                    </Grid.Row>
                </Grid>
                {/* {profile && this.renderSocialMedia(profile)} */}
            </div>
        </Container>
    )
});

export default UserHeaderComponent;