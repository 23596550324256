import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { getPostsFromResponse } from '../../../shared/utils/post'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  request: ['request'],
  success: ['user'],
  failure: null,
  reset: null,
  filter: ['activeFilters'],
  updateUser: ['user']
}, { prefix: 'USER_' })

export const UserDetailTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: {},
  allItems: [],
  fetching: null,
  statusText: null
})

/* ------------- Selectors ------------- */

export const UserDetailSelectors = {
  selectUser: state => state.user
}

/* ------------- Reducers ------------- */

export const request = (state) => {
  return state.merge({ fetching: true })
}

export const success = (state, { user }) => {
  const data = (getPostsFromResponse(user)) || []
  return state.merge({ fetching: false, user, allItems: data })
}

export const failure = (state) => state.merge({ fetching: false })
export const reset = () => INITIAL_STATE

export const filter = (state, { activeFilters }) => {
  let user = state.user
  const posts = state.allItems
  if (activeFilters && activeFilters.length > 0) {
    // console.log('filter activeFilters:', activeFilters)
    // console.log('filter posts:', posts)
    posts.filter((post) => {
      // console.log('post:', post)
      const productreferralsArr = post.productreferrals && post.productreferrals
      if (productreferralsArr && productreferralsArr.length > 0) {
        // console.log('productreferralsArr:', productreferralsArr)
        if (productreferralsArr.filter(pr => activeFilters.some(filter => pr.product && pr.product.url.includes(filter)))) {
          return true
        }

        return false
      }
    })

    // posts = posts && posts.filter(p =>
    //   p.productreferrals && p.productreferrals.length > 0 &&
    //   p.productreferrals.filter(pr => activeFilters.some(filter => pr.product && pr.product.url.includes(filter)))
    // )
    // console.log('filter results:', posts)
  }

  // console.log('filter user:', user)
  user = user.merge({ posts })
  return state.merge({ user })
}

export const updateUser = (state, { user }) => {
  const posts = state.user.posts
  let updatedUser = state.user.merge({ ...user })
  updatedUser = updatedUser.merge({ posts })
  return state.merge({ fetching: false, user: updatedUser })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
  [Types.FILTER]: filter,
  [Types.UPDATE_USER]: updateUser
})
