import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import Mergers from 'seamless-immutable-mergers'

import history from '../../../services/history'
// import { getFilterFromParams, FILTERS } from '../Utils/Filters'
import { filterFilesByDomainName } from '../../../shared/utils/product'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  request: ['request'],
  success: ['itemsPage', 'lastPageNumber', 'totalItems'],
  failure: null,
  reset: null,
  nextPageRequest: null,
  filterItems: ['filterParams'],
  rehydrationCompleted: ['rehydrationCompleted']
}, { prefix: 'PRODUCTS_' })

export const ProductsListTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  cachedItems: [],
  items: [],
  filterLoaded: null,
  fetching: null,
  statusText: null,
  isNextPageLoading: false,
  pageNumberLoaded: 1,
  lastPageReached: false,
  totalItems: 0
})

/* ------------- Selectors ------------- */

export const PostsListSelectors = {
  selectItems: state => state.items
}

/* ------------- Reducers ------------- */

export const request = (state, { request }) => {
  return requestAndPrefilterItems(state, request)
}
export const nextPageRequest = (state) => state.merge({ isNextPageLoading: true })

export const success = (state, { itemsPage, lastPageNumber, totalItems }) => {
  const mergeConfig = {
    merger: Mergers.updatingByIdArrayMerger,
    mergerObjectIdentifier: 'id'
  }
  const filter = state.filterLoaded
  const isRandomOrder = filter && filter.rn
  let cacheInvalid = (totalItems !== state.totalItems) || isRandomOrder
  const lastUpdatedAtCached = state.cachedItems && state.cachedItems.length > 0
    && state.cachedItems[0] && state.cachedItems[0].number_requests
  const lastUpdatedAtFetched = itemsPage && itemsPage.length > 0
    && itemsPage[0] && itemsPage[0].number_requests
  if (!state.isNextPageLoading && !state.fetching &&
    lastUpdatedAtCached !== lastUpdatedAtFetched) { // this takes into account only if the last item date change, but not the rest
    cacheInvalid = true
  }

  const pageNumberLoaded = cacheInvalid ? 1 : state.pageNumberLoaded
  const lastPageReached = pageNumberLoaded >= lastPageNumber

  const newStateCachedItems =
    cacheInvalid
      ? state.merge({ cachedItems: itemsPage })
      : state.merge({ cachedItems: itemsPage }, mergeConfig)
  const cachedItems = newStateCachedItems.cachedItems

  const newStateItems =
    cacheInvalid
      ? state.merge({ items: itemsPage })
      : state.merge({ items: itemsPage }, mergeConfig)
  const items = newStateItems.items

  return state.merge({
    fetching: false,
    isNextPageLoading: false,
    items,
    cachedItems,
    lastPageReached,
    totalItems,
    pageNumberLoaded
  })
}

export const failure = (state) => state.merge({ fetching: false, isNextPageLoading: false })
export const reset = () => INITIAL_STATE

export const requestAndPrefilterItems = (state, request) => {
  if (!request) {
    return state
  }

  const { filter, page } = request
  const pageNumber = page && page.number

  let newState = state
  let newFiltering = false

  if (filter) {
    let items = state.cachedItems
    const isRandomOrder = filter.rn

    if (state.filterLoaded) {
      newFiltering = (filter.dn !== state.filterLoaded.dn) || isRandomOrder
    } else {
      newFiltering = true
    }

    if (newFiltering) {
      const domainName = filter && filter.dn
      if (domainName) {
        items = filterFilesByDomainName(items, domainName)
      }
      newState = newState.merge({ lastPageReached: false, fetching: true })
    }

    newState = newState.merge({ items })
  }
  return newState.merge({ pageNumberLoaded: pageNumber, filterLoaded: filter })
}

export const rehydrationCompleted = (state) => {
  // temporary work aournd, when the page is loaded before the rehyration it replace the filter
  // with the previous one which consider the loading of the next page as an initial one
  const location = history && history.location
  const path = location && location.pathname
  const siteName = path && path.split('/').pop()

  if (state.pageNumberLoaded > 1) {
    return state.merge({
      filterLoaded: { dn: siteName }
    })
  } else {
    return state
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
  [Types.NEXT_PAGE_REQUEST]: nextPageRequest,
  [Types.FILTER_ITEMS]: requestAndPrefilterItems,
  [Types.REHYDRATION_COMPLETED]: rehydrationCompleted
})
