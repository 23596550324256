import React, { Component } from 'react'
import {
  Container,
  Grid,
  Segment,
  Divider,
  Header
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet'

import Footer from '../../../layouts/components/Footer'
import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'

// import PlaceHolderSquareCard from '../components/PlaceHolderSquareCard'
// import ProductCard from '../components/ProductCard'
// import { logPageView } from '../../../services/analytics'
import { ADDPRODUCT, getAddProductPageRoute, TAGPRODUCTPREFIX } from '../../../constants/routes'
// import { DOMAINNAME_WITH_HTTPS } from '../../../shared/constants/apiroutes'
// import { getFullUrlFromRoute, getProductsFullUrlWithBrand } from '../../../shared/utils/generateFullUrl'
// import AddIGPostToProductContainer from '../../Posts/AddPost/AddIGPostToProductContainer'
import AddPostToProductLandingPage from '../../Posts/AddPost/AddPostToProductLandingPage'

class AddProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      autoLoadUrl: null,
      showLoadNext: false,
      isInstalled: true
    }
  }

  componentDidMount() {
    const { pathname } = this.props.location

    let url = pathname && pathname.replace(`${TAGPRODUCTPREFIX}/`, '')
      .replace(`${TAGPRODUCTPREFIX}`, '')


    if (url) {
      let isUrlChanged = false
      if (!url.includes('https://')) { // for pinterest
        url = url.replace('https:/', 'https://')
        isUrlChanged = true
      }

      if (url.includes('https://www.seemyfit.at/')) {
        url = url.replace('https://www.seemyfit.at/', '')
        isUrlChanged = true
      }

      if (url.includes('https://seemyfit.at/')) {
        url = url.replace('https://seemyfit.at/', '')
        isUrlChanged = true
      }

      // if (isUrlChanged) {
      //   // this.props.history.push({
      //   //   pathname: getAddProductPageRoute(url)
      //   // })

      //   url = getAddProductPageRoute(url)
      // }

      const autoLoadUrl = url

      this.setState({ autoLoadUrl })
    }
  }

  render() {
    const title = "Instagram's Inspiring Looks 📸!"
    const { autoLoadUrl, step } = this.state

    // const { productStep1, productStep2, postStep3 } = this.props remove

    // Step1 title
    const titleAddProductUrl = 'Find the product'
    const subtitleAddProductUrl = () => (
      <><span role='img' aria-label='Note'>⚠️</span> Use the final product url, you can link your commisionable urls later</>
    )
    // Step2 title
    const titleSaveProductUrl = 'Product Information'
    const subtitleSaveProductUrl = () => (
      <>Verify product detail and price</>
      // <>Search with product url to check if it's already on <b>seemyfit.at</b></>
    )
    // Step3 title
    const titleAddPost = 'Product Look IRL'
    const subtitleAddPost = () => (
      <>Select an Instagram post with the product your are sharing</>
    )
    // Step4 title
    const titleReviewProduct = 'Product Tagged!'
    const subtitleReviewProduct = () => (
      <>Last question, how did you find the fit of this product?</>
    )

    let headerTitle = titleAddProductUrl
    let headerSubtitlte = subtitleAddProductUrl
    if (step === 2) {
      headerTitle = titleSaveProductUrl
      headerSubtitlte = subtitleSaveProductUrl
    } else if (step === 3) {
      headerTitle = titleAddPost
      headerSubtitlte = subtitleAddPost
    } else if (step === 4) {
      headerTitle = titleReviewProduct
      headerSubtitlte = subtitleReviewProduct
    }

    return (
      <ResponsiveContainer history={this.props.history} location={this.props.location}>
        <Helmet title={title} />
        <AddPostToProductLandingPage history={this.props.history}
          autoLoadUrl={autoLoadUrl} />
        {/*  <Container className='no-horizontal-margin'>
          <Divider hidden />
          <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
            <Segment basic textAlign='center'>
              <Header as='h1' inverted>
                {headerTitle}
                <Header.Subheader>
                  {headerSubtitlte()}
                </Header.Subheader>
              </Header>
            </Segment> */}
        {/* <Grid centered doubling>
              <Grid.Column mobile={16} tablet={6} computer={10}>
                <AddIGPostToProductContainer
                  autoLoadUrl={autoLoadUrl}
                  stepChanged={(step) => this.setState({ step })}
                />
              </Grid.Column>
            </Grid> 
          </Segment>
        </Container>*/}
        <Divider hidden />
        <Footer history={this.props.history} />
      </ResponsiveContainer>
    )
  }
}
export default AddProduct
