import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import postDetailActions from '../../../store/redux/posts/PostDetailRedux'
import productDetailActions from '../../../store/redux/products/ProductDetailRedux'
import getPostStep1Actions from '../../../store/redux/posts/update/GetPostStep1Redux'
import updatePostStep2Actions from '../../../store/redux/posts/update/UpdatePostStep2Redux'
import addPostStep3Actions from '../../../store/redux/posts/add/AddPostStep3Redux'

import PostDetailPage from './PostDetailPage'

export default connect(
  state => ({
    // currentUser: state.login.currentUser,
    posts: state.postDetailState.items,
    isLoading: state.postDetailState.fetching,
    statusText: state.postDetailState.statusText
  }),
  dispatch => ({
    getPostAction: bindActionCreators(postDetailActions.request, dispatch),
    getProductAction: bindActionCreators(productDetailActions.request, dispatch),
    getPostStep1Action: bindActionCreators(getPostStep1Actions.request, dispatch),
    updatePostStep2: bindActionCreators(updatePostStep2Actions.request, dispatch),
    addPostStep2: bindActionCreators(addPostStep3Actions.request, dispatch)
  })
)(PostDetailPage)
