import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Divider, Message, Image } from 'semantic-ui-react'


import { getAuth } from "firebase/auth";

import { extensionInstalled } from '../../utils/chromeExtension'
import Footer from '../../layouts/components/Footer'
import ResponsiveContainer from '../../layouts/components/Responsive/ResponsiveContainer'
import HomePageHeading from './components/HomePageHeading'
import InstallAppHero from './components/InstallAppHero'
import InstallAppSubscribeHero from './components/InstallAppSubscribeHero'
import BrowserDemo from './components/BrowserDemo'
import { DOMAINNAME_WITH_HTTPS } from '../../shared/constants/apiroutes'
import { getBrandNameFromSiteName } from '../../shared/utils/product'
import AcceptCookiesContainer from '../../components/AcceptCookies/AcceptCookiesContainer'
import HomeFAQ from './components/HomeFAQ'
import HomeReviews from './components/HomeReviews'
import LastPostsContainer from '../Posts/LastPosts/LastPostsContainer'
import LastProductsContainer from '../Products/LastProducts/LastProductsContainer'
import { Media } from '../../utils/AppMediaBreakPoints'
import AppDeeplinkImg from '../../images/AppDeeplinkImg.png'
import { getLandingHeadlineV1Expr } from '../../services/landingHeadlineExperiment'
import PersonalizedHomePage from './PersonalizedHomePage';
import HomePageSignInComponent from './HomePageSignInComponent';
import EmailLoginForm from '../../components/EmailLoginForm';
import HomepageHeading from './components/HomePageHeading';

class HomePage extends Component {
  state = {
    isInstalled: true,
    siteName: null,
    firebaseLink: null
  }

  componentDidMount() {
    let self = this
    window.onload = function () {
      self.setState({ isInstalled: extensionInstalled() })
    }

    const { history } = this.props
    const location = this.props.location || (history && history.location)
    const path = location && location.pathname
    const search = location && location.search
    const siteName = path && path.split('/').pop()

    if (search && search.startsWith('?link=')) {
      this.setState({ firebaseLink: search.replace('?link=', '') })
    }

    const brand = getBrandNameFromSiteName(siteName)

    this.setState({ landingHeadlineV1Exp: getLandingHeadlineV1Expr() })

    this.setSitenameFilter(brand)
  }

  setSitenameFilter(newSitename) {
    let siteName = newSitename
    if (this.state.siteName && this.state.siteName.includes(newSitename)) {
      siteName = null
    }

    this.setState({ siteName })
  }

  renderSignInOnMobile() {
    return (
      <Container as={Media} at='moble'>
        <Message error>
          Please open the link received by email on your mobile to sign-in into the app. <br /><br />
          To open the link on seemyfit mobile App, scroll to the top of the page and tap the <strong>blue Open</strong> in the <strong>banner</strong> as shown below.
        </Message>
        <Image
          src={AppDeeplinkImg} centered
          style={{ verticalAlign: 'middle' }}
        />
      </Container>
    )
  }

  render() {
    const { isInstalled, siteName, firebaseLink, landingHeadlineV1Exp } = this.state
    let title = "Fashion-lovers community of all shapes and sizes 📸!"
    if (siteName) {
      title = `${siteName} See my fit 📸!`
    }

    const showAppHero = !siteName

    return (
      <ResponsiveContainer
        headerHero={HomePageHeading}
        withHero={false}
        isInstalled={isInstalled}
        history={this.props.history}
        location={this.props.location}
        auth={getAuth()}
      >
        <Helmet title={title} >
          <meta property='og:image' content={`${DOMAINNAME_WITH_HTTPS}/seemyfit-og-img.png`} />
          {firebaseLink && <meta name="apple-itunes-app" content={`app-id=id1510249148, app-argument=${firebaseLink}`} />}
        </Helmet>


        {/* <SearchBarContainer history={this.props.history} siteName={siteName} /> */}
        <Container>
          {/* {!!firebaseLink && this.renderSignInOnMobile()} */}
          {/* <SignUpBanner
            callToActionButton={<EmailLoginForm auth={getAuth()} btnLabelText="Join" />}
            auth={getAuth()} /> */}

          <PersonalizedHomePage history={this.props.history} isInstalled={isInstalled} siteName={siteName} />
          {/* <LastPostsContainer history={this.props.history} siteName={siteName} /> */}

          {!isInstalled && <Divider hidden />}
          {!isInstalled && <BrowserDemo history={this.props.history} siteName={siteName} />}

          <Divider />

          {showAppHero && <Divider hidden />}
          {showAppHero && <InstallAppSubscribeHero
            history={this.props.history} siteName={siteName}
            appInstallCampaign='w_home'
            headline={"Weekly Highlights Newsletter"}
            description={"Hand-pick your favourite creators to get their weekly highlights in your inbox and keep discovering new inspirations for your wardrobe!"}
            // ctaText={"Download the app"}
            analyticsLabel={'HomePage_InstallAppSubscribeHero_1'}
          />}

          {showAppHero && <Divider hidden />}
          {showAppHero && <InstallAppHero history={this.props.history} siteName={siteName}
            appInstallCampaign={`'w_home_exp_${landingHeadlineV1Exp?.id}`}
            headline={landingHeadlineV1Exp?.payload?.headline}
            description={landingHeadlineV1Exp?.payload?.description}
            ctaText={landingHeadlineV1Exp?.payload?.ctaText}
            analyticsLabel={'HomePage_InstallAppHero_1'}
          />}


          {/* <LastProductsContainer history={this.props.history} siteName={siteName} /> */}




          {!showAppHero && <InstallAppHero history={this.props.history} siteName={siteName}
            appInstallCampaign={`'w_home_exp_${landingHeadlineV1Exp?.id}`}
            headline={landingHeadlineV1Exp?.payload?.headline}
            description={landingHeadlineV1Exp?.payload?.description}
            ctaText={landingHeadlineV1Exp?.payload?.ctaText}
            analyticsLabel={'HomePage_InstallAppHero_2'}
          />}

          {showAppHero && <HomeReviews history={this.props.history} siteName={siteName} />}
          {showAppHero && <HomeFAQ history={this.props.history} siteName={siteName} />}

          <Divider hidden />
          <AcceptCookiesContainer />

          {/* <InstallMobileAppBannerContainer
            history={this.props.history}
            siteName={siteName}
            title='Seemyfit App!'
            subtitle="We asked you what you wanted most. Now we're giving it to you."
            analyticsLabel='AppInviteFromHomePage'
          /> */}
        </Container>
        {/* {!isInstalled && <TryItNowDesktopOnly siteName={siteName} />} */}

        <Divider hidden />
        {/* <LastUsersContainer history={this.props.history} siteName={siteName} /> */}
        {/* <FeaturedPostsContainer history={this.props.history} siteName={siteName} /> */}
        <Footer history={this.props.history} />
      </ResponsiveContainer>
    )
  }
}

export default HomePage
