import React from 'react'
import { Container } from 'semantic-ui-react'

import { Media } from '../utils/AppMediaBreakPoints'
import TryItNow from './TryItNow'

const TryItNowDesktopOnly = () => (
  <Container as={Media} greaterThanOrEqual='computer'>
    <TryItNow />
  </Container>
)

export default TryItNowDesktopOnly
