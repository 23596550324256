import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  request: ['request'],
  success: ['data'],
  failure: null,
  reset: null
}, { prefix: 'ADD_POST_S3_' })

export const AddPostStep3Types = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  statusText: null
})

/* ------------- Reducers ------------- */

export const request = (state) => {
  // load the product from the posts list by id
  return state.merge({ fetching: true, data: null })
}

export const success = (state, { data }) => {
  return state.merge({ fetching: false, data })
}

export const failure = (state) => state.merge({ fetching: false, data: null })
export const reset = () => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset
})
