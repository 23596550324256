import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'
import { getPostAttributes } from './PostAttributes'

// import { sessionExpired } from './LoginSagas'

export function* addPost({ api, Actions }, { request }) {
  const {
    url,
    urlOriginalPost,
    commissionLink,
    promocode,
    igPost,
    user,
    productreferral,
    productId,
    onSuccess
  } = request
  // const include = 'user,product'

  let errorMessage = ''
  if (!user) {
    errorMessage = 'User not found! Please try again!'
  }

  if (errorMessage !== '') {
    yield put(Actions.failure(errorMessage))
    return
  }

  let product = null
  if (productId) {
    product = {
      id: productId,
      type: 'products'
    }
  }

  const config = {
    ...getPostAttributes(url, urlOriginalPost, commissionLink, promocode, igPost),
    ...(product && { product }),
    user: {
      ...user,
      type: 'users'
    },
    productreferral: {
      ...productreferral,
      type: 'productreferrals'
    }
    // --
    // params: { include } use this later
  }

  try {
    const response = yield call(api.addPost, config)

    // FOR DEBUG
    // const response = {
    //   data:
    //   {
    //     created_at: '2020-06-21T03:49:07+00:00',
    //     id: '100',
    //     links: { self: 'http://127.0.0.1:8000/api/v1/posts/100' },
    //     product: {},
    //     type: 'posts',
    //     updated_at: '2020-06-21T03:49:07+00:00',
    //     url: 'https://myyfit.s3.fr-par.scw.cloud/d751f193-634b-4e75-93ed-334be358595b.jpg',
    //     url_original_post: 'https://www.instagram.com/p/CANJthSFctp/',
    //     user: {}
    //   }
    // }
    if (response) {
      const data = path(['data'], response)
      // do data conversion here if needed
      yield put(Actions.success(data))
      if (onSuccess) {
        yield put(onSuccess(data))
      }
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }
    yield put(Actions.failure())
  }
}

// const request = { url }
//     const user = yield call(getIGPostAndUpdateUser, { api, Actions }, { request })
//     console.log('user', user)

//     if (!productId) {
//       const productConfig = {
//         filter: {
//           url
//         }
//       }
//       const product = yield call(api.getProducts, productConfig)
//       console.log('product', product)
//       productId = product.id
//     }

//     console.log('productId', productId)
