import React, { useState } from 'react'
import {
  Form, Message, Header, Icon, Button, Loader
} from 'semantic-ui-react'
import SelectorButtons from './SelectorButtons'

// TODO refactor with mobile
const transformFitChallengesArray = (fitChalengesArray) => {
  return {
    shouldersFit: fitChalengesArray?.[0],
    sleeve: fitChalengesArray?.[1],
    arms: fitChalengesArray?.[2],
    trouser: fitChalengesArray?.[3]
  }
}

const FitChallengesSelector = ({ initialValues, onChange }) => {
  const fitChallengesInitialValues = transformFitChallengesArray(initialValues)

  // console.log("fitChallengesInitialValues", fitChallengesInitialValues)

  let defaultSelectedShouldersIndex, defaultSelectedSleeveIndex, defaultSelectedArmsIndex, defaultSelectedTrouserIndex = 0
  if (fitChallengesInitialValues) {
    defaultSelectedShouldersIndex = fitChallengesInitialValues.shouldersFit
    if (defaultSelectedShouldersIndex !== 0 && !defaultSelectedShouldersIndex) {
      defaultSelectedShouldersIndex = 1 // only if it's not 0 from user input
    }

    defaultSelectedSleeveIndex = fitChallengesInitialValues.sleeve
    if (defaultSelectedSleeveIndex !== 0 && !defaultSelectedSleeveIndex) {
      defaultSelectedSleeveIndex = 1 // only if it's not 0 from user input
    }

    defaultSelectedArmsIndex = fitChallengesInitialValues.arms
    if (defaultSelectedArmsIndex !== 0 && !defaultSelectedArmsIndex) {
      defaultSelectedArmsIndex = 1 // only if it's not 0 from user input
    }

    defaultSelectedTrouserIndex = fitChallengesInitialValues.trouser
    if (defaultSelectedTrouserIndex !== 0 && !defaultSelectedTrouserIndex) {
      defaultSelectedTrouserIndex = 1 // only if it's not 0 from user input
    }
  }

  const [selectedShouldersIndex, setSlectedShouldersIndex] = useState(defaultSelectedShouldersIndex);
  const [selectedSleeveIndex, setSlectedSleeveIndex] = useState(defaultSelectedSleeveIndex);
  const [selectedArmsIndex, setSlectedArmsIndex] = useState(defaultSelectedArmsIndex);
  const [selectedTrouserIndex, setSlectedTrouserIndex] = useState(defaultSelectedTrouserIndex);

  const onPropertyChange = (selectedShouldersIndex, selectedSleeveIndex, selectedArmsIndex, selectedTrouserIndex) => {
    console.log('onPropertyChange:', [
      selectedShouldersIndex,
      selectedSleeveIndex,
      selectedArmsIndex,
      selectedTrouserIndex
    ])
    onChange([
      selectedShouldersIndex,
      selectedSleeveIndex,
      selectedArmsIndex,
      selectedTrouserIndex
    ]);
  }

  const renderSegmentedControl = (title, selectedIndexDefault, values, onChange) => {
    return (
      <>
        <Header as='h4' inverted>{title}</Header>
        <SelectorButtons
          firstLabel={values[0]}
          secondLabel={values[1]}
          thirdLabel={values[2]}
          selectedIndexDefault={selectedIndexDefault}
          onSelected={onChange}
        />
      </>
    )
  }

  return (
    <div>
      {renderSegmentedControl('Shoulders', selectedShouldersIndex, ['Too tight', 'None', 'Too Loose'],
        (selectedSegmentIndex) => {
          setSlectedShouldersIndex(selectedSegmentIndex)
          onPropertyChange(
            selectedSegmentIndex,
            selectedSleeveIndex,
            selectedArmsIndex,
            selectedTrouserIndex)
        })}
      {renderSegmentedControl('Sleeve Length', selectedSleeveIndex, ['Too tight', 'None', 'Too Loose'],
        (selectedSegmentIndex) => {
          setSlectedSleeveIndex(selectedSegmentIndex)
          onPropertyChange(
            selectedShouldersIndex,
            selectedSegmentIndex,
            selectedArmsIndex,
            selectedTrouserIndex)
        })}
      {renderSegmentedControl('Arms', selectedArmsIndex, ['Too tight', 'None', 'Too Loose'],
        (selectedSegmentIndex) => {
          setSlectedArmsIndex(selectedSegmentIndex)
          onPropertyChange(
            selectedShouldersIndex,
            selectedSleeveIndex,
            selectedSegmentIndex,
            selectedTrouserIndex)
        })}
      {renderSegmentedControl('Trouser Length', selectedTrouserIndex, ['Too tight', 'None', 'Too Loose'],
        (selectedSegmentIndex) => {
          setSlectedTrouserIndex(selectedSegmentIndex)
          onPropertyChange(
            selectedShouldersIndex,
            selectedSleeveIndex,
            selectedArmsIndex,
            selectedSegmentIndex)
        })}
    </div>
  )
}

export default FitChallengesSelector
