import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db, FIREBASE_USERS_COLLECTION } from '../firebase';

export default async (uid) => {
    // console.log("set firebase user with uid: ", uid)
    const docRef = doc(db, FIREBASE_USERS_COLLECTION, uid);
    await setDoc(docRef,
        {
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
        }
        , { merge: true }
    );
}
