import { MaterialColors } from "../theme"

export const getOverAllMatchCopy = (userContext, profile) => {
  const username = profile && (profile.username || profile.instagram_username)
  const topRightBannerProfileNotFound = `Profile Not Found!`
  const topRightBannerMessageNoFirebaseProfile = `${username}'s profile is not completed yet`
  const topRightBannerMessageFirebaseIncompleteProfile = `${username} didn't complete fit and style preferences yet`
  const topRightBannerMessageFirebaseIncompleteMyProfile = `Complete your fit and style preferences to see how you match with @${username} `

  const isLegacyProfile = profile && !profile.firebase_user_id
  const isFitAndStyleCompleted = userContext.fitChallenges && userContext.fitChallenges.length >= 0
    && userContext.styles && userContext.styles.length >= 0
    && userContext.bodyProperties

  let topRightBannerMessage = ''

  // console.log("getOverAllMatchCopy ------ userContext", userContext)
  // console.log("getOverAllMatchCopy ------ profile", profile)

  const styleScore = getStyleMatchScore(userContext, profile)
  const sizeScore = getSizeMatchScore(userContext, profile)

  if (!userContext || !isFitAndStyleCompleted) {
    topRightBannerMessage = topRightBannerMessageFirebaseIncompleteMyProfile
  } else if (isLegacyProfile) {
    topRightBannerMessage = topRightBannerMessageNoFirebaseProfile
  } else if (styleScore === 0 && sizeScore === 0) {
    topRightBannerMessage = topRightBannerMessageFirebaseIncompleteProfile
  } else {
    const percentMatch = getTotalScoreMatchPercent(userContext, profile)
    const messageOverallScore = `${percentMatch}% Overall match`

    topRightBannerMessage = messageOverallScore
  }

  return topRightBannerMessage
}

export const getMatchColorByPercent = (percent) => {
  let ringColor
  if (percent >= 98) {
    ringColor = MaterialColors.red[900]
  } else if (percent >= 95) {
    ringColor = MaterialColors.red[700]
  } else if (percent >= 95) {
    ringColor = MaterialColors.red[600]
  } else if (percent >= 92) {
    ringColor = MaterialColors.red[500]
  } else if (percent >= 90) {
    ringColor = MaterialColors.red[400]
  } else if (percent >= 85) {
    ringColor = MaterialColors.orange[900]
  } else if (percent >= 75) {
    ringColor = MaterialColors.orange[700]
  } else if (percent >= 65) {
    ringColor = MaterialColors.orange[500]
  } else if (percent >= 55) {
    ringColor = MaterialColors.orange[300]
  } else if (percent >= 45) {
    ringColor = MaterialColors.orange[200]
  } else if (percent >= 35) {
    ringColor = MaterialColors.orange[100]
  } else if (percent >= 25) {
    ringColor = MaterialColors.grey[300]
  } else if (percent >= 15) {
    ringColor = MaterialColors.grey[200]
  } else
    ringColor = MaterialColors.grey[100]

  return ringColor
}

export const getPercentFromScore = (score) => {
  const percent = (score / 10) * 100
  return percent < 5 ? 0 : percent
}

export const getTotalScoreMatchPercent = (user, profile) => {
  const totalScore = getTotalStyleScore(user, profile)
  return getPercentFromScore(totalScore)
}

export const getTotalStyleScore = (user, profile) => {
  return (getStyleMatchScore(user, profile) + getSizeMatchScore(user, profile)) / 2
}

export const getStyleMatchScore = (user, profile) => {
  let score = 0

  if (!user || !profile) {
    return score
  }

  // console.log("getStyleMatchScore user:", user)
  // console.log("getStyleMatchScore profile:", profile)

  // console.log("getStyleMatchScore user.styles:", user.styles)
  // console.log("getStyleMatchScore profile.styles:", profile.styles)
  score += getStylesScore(user.styles, profile.styles)
  // console.log("getStyleMatchScore styles score:", score)

  // console.log("getStyleMatchScore user.influencedBy:", user.influencedBy)
  // console.log("getStyleMatchScore profile.influencedBy:", profile.influencedBy)
  score += getInfluencedByByScore(user.influencedBy, profile.influencedBy)
  // console.log("getStyleMatchScore influencedBy score:", score)

  return score > 10 ? 10 : score
}

export const getSizeMatchScore = (user, profile) => {
  let score = 0

  if (!user || !profile) {
    return score
  }

  // console.log("getSizeMatchScore user:", user)
  // console.log("getSizeMatchScore profile:", profile)

  // console.log("getSizeMatchScore user.bodyProperties:", user.bodyProperties)
  // console.log("getSizeMatchScore profile.bodyProperties:", profile.bodyProperties)
  score += getBodyPropertiesScore(user.bodyProperties, profile.bodyProperties)

  // console.log("getSizeMatchScore user.fitChallenges:", user.fitChallenges)
  // console.log("getSizeMatchScore profile.fitChallenges:", profile.fitChallenges)
  score += getFitChallengesScore(user.fitChallenges, profile.fitChallenges)

  // console.log("getSizeMatchScore user.dressSize:", user.dressSize)
  // console.log("getSizeMatchScore profile.dressSize:", profile.dressSize)
  // console.log("getSizeMatchScore dressSize score:", score)

  // console.log("getSizeMatchScore user.shopSize:", user.shopSize)
  // console.log("getSizeMatchScore profile.shopSize:", profile.shopSize)
  // console.log("getSizeMatchScore shopSize score:", score)

  // console.log("getSizeMatchScore user.topSize:", user.topSize)
  // console.log("getSizeMatchScore profile.topSize:", profile.topSize)
  // console.log("getSizeMatchScore topSize score:", score)

  // console.log("getSizeMatchScore user.bottomSize:", user.bottomSize)
  // console.log("getSizeMatchScore profile.bottomSize:", profile.bottomSize)
  // console.log("getSizeMatchScore bottomSize score:", score)

  return score > 10 ? 10 : score
}

export const getBrandsMatchScore = (user, profile) => {
  let score = 0

  if (!user || !profile) {
    return score
  }

  // console.log("getBrandsMatchScore user:", user)
  // console.log("getBrandsMatchScore profile:", profile)

  // console.log("getBrandsMatchScore user.brands:", user.brands)
  // console.log("getBrandsMatchScore profile.brands:", profile.brands)
  score += getBrandsScore(user?.brands, profile?.brands)

  return score > 10 ? 10 : score
}

const getBodyPropertiesScore = (myBodyProperties, profileBodyProperties) => {
  let score = 0

  if (!myBodyProperties || !profileBodyProperties) {
    return score
  }

  if (myBodyProperties.heightType === profileBodyProperties.heightType) {
    score += 3
  } else if (myBodyProperties.heightType < profileBodyProperties.heightType) {
    score += 0.25
  }

  if (myBodyProperties.widthType === profileBodyProperties.widthType) {
    score += 3
  } else if (myBodyProperties.widthType < profileBodyProperties.widthType) {
    score += 0.25
  }

  score = score < 1 ? 1 : score > 7 ? 7 : score // min 1, max 7
  // console.log("getSizeMatchScore bodyProperties score:", score)
  return score
}

const getFitChallengesScore = (myFitChallenges, profileFitChallenges) => {
  let score = 0

  if (!myFitChallenges || !profileFitChallenges) {
    return score
  }

  let count = 0
  if (myFitChallenges.length > 0) {
    myFitChallenges.map((f, i) => {
      count += (f === profileFitChallenges[i]) ? 1 : 0
      // console.log("getSizeMatchScore map count", count)
    })
  }

  score = count

  score = score < 1 ? 1 : score > 7 ? 7 : score
  // console.log("getSizeMatchScore fitChallenges score:", score)
  return score
}

const getStylesScore = (myStyle, profileStyle) => {
  let score = 0

  if (!myStyle || !profileStyle) {
    return score
  }

  const similarCount = compareArrayInclude(myStyle, profileStyle)

  const total = myStyle.length + profileStyle.length
  score += Math.round((similarCount / total) * 10)

  return score > 7.5 ? 7.5 : score
}

const getInfluencedByByScore = (myInfluencedBy, profileInfluencedBy) => {
  let score = 0

  if (!myInfluencedBy || !profileInfluencedBy) {
    return score
  }

  const similarCount = compareArrayInclude(myInfluencedBy, profileInfluencedBy)

  const total = myInfluencedBy.length + profileInfluencedBy.length
  score += Math.round((similarCount / total) * 10)

  return score > 7.5 ? 7.5 : score
}

const getBrandsScore = (myBrands, profileBrands) => {
  let score = 0

  if (!myBrands || !profileBrands) {
    return score
  }

  const similarCount = compareArrayInclude(myBrands, profileBrands)

  const total = myBrands.length + profileBrands.length
  score += Math.round((similarCount / total) * 10)

  return score > 7.5 ? 7.5 : score
}

const compareArrayInclude = (a1, a2) => a1.reduce((a, c) => a + a2.includes(c), 0);
const compareArrayEqual = (a1, a2) => a1.map((a, ind) => a === a2?.[ind]);


