import React, { useContext, useState, useEffect } from 'react';
import {
    Button, Grid, Accordion, Image, Segment, Message, Divider, Label, Icon, Step, Card
} from 'semantic-ui-react';
import copy from 'copy-to-clipboard'

import { logEvent } from '../../../../services/analytics';
import { addProductreferral, updateProductreferral } from '../../../../shared/api/web/productreferrals/productreferralsWebApi';
import { AppContext } from '../../../../shared/context';
import { getPostUrlToShare } from '../../../../shared/utils/generateUrlForShare';
import ProductSelectorByUrl from '../ProductSelectorByUrl';
import ProductreferralInputFields from './ProductreferralInputFields';
import ProductreferralInputReviewFields from './ProductreferralInputReviewFields';
import IgMediaSelector from '../../../../components/IgMediaSelector';
import { addPost } from '../../../../shared/api/web/posts/postWebApi';


const AddProductreferralToPostForm = (({
    post,
    withPostSelector,
    initialActiveStepIndex,
    productSelected: initialProductSelected,
    productPublished: initialProductPublished,
    mediaPostSelected: initialMediaPostSelected,
    productReferralDraft: initialProductReferralDraft,
    postPublished: initialPostPublished,
    productreferralPublished: initialProductReferralPublished,
    productreferralReviewDraft: initialProductReferralReviewDraft,
    productreferralReviewPublished: initialProductReferralReviewPublished,
    onProductSelectedChange,
    onProductPublished,
    onMediaPostSelected,
    onProductReferralDraftChange,
    onPostAndProductreferralPublished,
    onProductReferralReviewDraftChange,
    onProductReferralReviewPublished,
    onDone,
    autoLoadUrl
}, ref) => {
    const { user: userContext } = useContext(AppContext)

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const [statusText, setStatusText] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [productSelected, setProductSelected] = useState(initialProductSelected);
    const [productPublished, setProductPublished] = useState(initialProductPublished);

    const [mediaPostSelected, setMediaPostSelected] = useState(initialMediaPostSelected);
    const [productReferralDraft, setProductreferralDraft] = useState(initialProductReferralDraft);

    const [postPublished, setPostPublished] = useState(initialPostPublished);
    const [productreferralPublished, setProductreferralPublished] = useState(initialProductReferralPublished);

    const [productreferralReviewDraft, setProductreferralReviewDraft] = useState(initialProductReferralReviewDraft);
    const [productreferralReviewPublished, setProductreferralReviewPublished] = useState(initialProductReferralReviewPublished);

    const [activeAccordionIndex, setActiveAccordionIndex] = useState(mediaPostSelected ? 1 : 0);

    const [activeStepIndex, setActiveStepIndex] = useState(initialActiveStepIndex);

    const prDraft = productReferralDraft
    const [promocode, setPromocode] = useState(prDraft && prDraft.promocode);
    const [commissionLink, setCommissionLink] = useState(prDraft && prDraft.commissionLink);
    const [productSize, setProductSize] = useState(prDraft && prDraft.productSize);
    const [productColor, setProductColor] = useState(prDraft && prDraft.productColor);

    const prReviewDraft = productreferralReviewDraft
    const [productTrueFit, setProductTrueFit] = useState((prReviewDraft && prReviewDraft.productTrueFit) || 5);
    const [forSale, setForSale] = useState(prReviewDraft && prReviewDraft.forSale);
    const [salePrice, setSalePrice] = useState(prReviewDraft && prReviewDraft.salePrice);
    const [saleCurrency, setSaleCurrency] = useState((prReviewDraft && prReviewDraft.saleCurrency) || 'USD');
    const [saleCondition, setSaleCondition] = useState(prReviewDraft && prReviewDraft.saleCondition);
    const [saleDescription, setSaleDescription] = useState(prReviewDraft && prReviewDraft.saleDescription);
    const [saleEmail, setSaleEmail] = useState(prReviewDraft && prReviewDraft.saleEmail);
    const [saleIGUsername, setSaleIGUsername] = useState(
        (prReviewDraft && prReviewDraft.saleIGUsername) || (postPublished && postPublished.ig_media_owner_username));
    const [productRating, setProductRating] = useState((prReviewDraft && prReviewDraft.productRating) || 5);
    const [productCommentReview, setProductCommentReview] =
        useState(prReviewDraft && prReviewDraft.productCommentReview);


    useEffect(() => {
        onProductReferralDraftChange
            && onProductReferralDraftChange({ promocode, commissionLink, productSize, productColor })
    }, [promocode, commissionLink, productSize, productColor])


    useEffect(() => {
        onProductReferralDraftChange
            && onProductReferralReviewDraftChange({
                productTrueFit, forSale, salePrice, saleCurrency, saleCondition, saleDescription,
                saleEmail, saleIGUsername, productRating, productCommentReview
            })
    }, [productTrueFit, forSale, salePrice, saleCurrency, saleCondition, saleDescription,
        saleEmail, saleIGUsername, productRating, productCommentReview])

    const onProductSelectedChangeCallBack = (product) => {
        // console.log("onProductSelectedChangeCallBack product:", product)
        setProductSelected(product)
        setActiveStepIndex(1)
        onProductSelectedChange && onProductSelectedChange(product)
    }

    const onProductPublishedCallBack = (product) => {
        // console.log("onProductPublishedCallBack product:", product)
        setProductSelected(product)
        setProductPublished(product)
        setActiveStepIndex(2)
        onProductPublished && onProductPublished(product)
    }

    const onMediaPostSelectedCallBack = (post) => {
        // console.log('AddProductreferralToPostForm onMediaPostSelectedCallBack post', post)
        if (post) {
            setMediaPostSelected(post)
            setActiveAccordionIndex(1) // open use affialite accordion
            onMediaPostSelected && onMediaPostSelected(post)
        }
    }

    const onPostAndProductreferralPublishedCallBack = ({ post, productreferral }) => {
        // console.log('AddProductreferralToPostForm onPostAndProductreferralPublishedCallBack post', post)
        // console.log('AddProductreferralToPostForm onPostAndProductreferralPublishedCallBack productreferral', productreferral)
        setPostPublished(post)
        setProductreferralPublished(productreferral)
        setActiveStepIndex(3)
        onPostAndProductreferralPublished && onPostAndProductreferralPublished({ post, productreferral })
    }

    const onProductReferralReviewPublishedCallBack = (productreferral) => {
        // console.log('AddProductreferralToPostForm onProductReferralReviewPublishedCallBack post', post)
        setProductreferralReviewPublished(productreferral)
        setActiveStepIndex(4)
        onProductReferralReviewPublished && onProductReferralReviewPublished(productreferral)
    }

    const onSubmitProductreferral = async () => {
        if (withPostSelector) {
            await addPostSubmit(
                productPublished, mediaPostSelected,
                onPostAndProductreferralPublishedCallBack)
        }
        else {
            const onProductreferralPublishedSuccess = (productreferral) => {
                setProductreferralPublished(productreferral)
                setActiveStepIndex(3)
            }
            await addProductreferralSubmit(productPublished, post, onProductreferralPublishedSuccess)
        }
    }

    const onSubmitProductreferralReview = async () => {
        await addReviewSubmit(onProductReferralReviewPublishedCallBack)
    }


    const onDoneClick = async () => {
        setIsLoading(false);
        setIsSuccess(null);
        setStatusText(null);
        setErrorMessage(null);

        setProductSelected(null)
        setProductPublished(null)

        setMediaPostSelected(null)
        setProductreferralDraft(null)
        setPostPublished(null)
        setProductreferralPublished(null)

        setProductreferralReviewDraft(null)
        setProductreferralReviewPublished(null)

        setPromocode();
        setCommissionLink();
        setProductSize();
        setProductColor();
        setProductTrueFit(5);
        setForSale();
        setSalePrice();
        setSaleCurrency('USD');
        setSaleCondition();
        setSaleDescription();
        setSaleEmail();
        setSaleIGUsername((postPublished && postPublished.ig_media_owner_username));
        setProductRating(5);
        setProductCommentReview();

        setActiveStepIndex(0)
        onDone()
    }

    const addPostSubmit = async (product, mediaPost, onSuccess) => {
        const productId = product && product.id

        if (mediaPost) {
            let webUserId = userContext && userContext.webUser && userContext.webUser.id
            // const owner = {
            //     // username: mediaPost.original && mediaPost.original.username,
            //     id: webUserId
            // }

            const isVideo = mediaPost.original.media_type === "VIDEO"
            const igPost = {
                post_video_thumbnail_src: mediaPost.original.thumbnail_url,
                post_video_url: isVideo ? mediaPost.mediaUrl : null,
                post_caption: mediaPost.original.caption,
                post_is_video: isVideo,
                ig_media_owner_username: mediaPost.original.username,
                ig_media_timestamp: mediaPost.original.timestamp,
                ig_media_id: mediaPost.original.id,
                ig_media_type: mediaPost.original.media_type,
            }

            const requestToAddPostStep2 = {
                urlOriginalPost: mediaPost.url,
                url: mediaPost.mediaUrl,
                commissionLink,
                promocode,
                igPost,
                productId,
                userId: webUserId,
                // productreferral, this is done separatly
            }

            setIsLoading(true)
            setIsSuccess(false)
            setErrorMessage("")

            try {
                const postResponse = await addPost(requestToAddPostStep2)
                if (postResponse && product) {
                    const productreferralsResponse = await addProductreferralSubmit(product, postResponse,
                        (productreferral) => onSuccess && onSuccess({ post: postResponse, productreferral })
                    )
                    setIsLoading(false)
                    setIsSuccess(true)
                }
            } catch (e) {
                setIsLoading(false)
                setIsSuccess(false)
                setErrorMessage("Ops, we couldn't save your post, please try again or reach out to us.")
                console.log("SelectPostTab addPostSubmit error:", e)
                logEvent("selectPostTabAddPostSubmitFailed")
            }
        }
    }

    const addProductreferralSubmit = async (product, post, onSuccess) => {
        const productId = product && product.id
        const postId = post && post.id

        const productreferral = {
            commissionLink,
            promocode,
            size: productSize,
            color: productColor,
            product_id: productId,
            file_id: postId
        }


        if (post) {
            setIsLoading(true)
            setIsSuccess(false)
            setErrorMessage("")

            try {
                const producrreferralRequest = {
                    ...productreferral,
                }
                const productreferralsResponse = await addProductreferral(producrreferralRequest)
                // TODO add productreferral here to get its id for late updates
                setIsLoading(false)
                setIsSuccess(true)
                onSuccess && onSuccess(productreferralsResponse)
            } catch (e) {
                setIsLoading(false)
                setIsSuccess(false)
                setErrorMessage("Ops, we couldn't save your post, please try again or reach out to us.")
                console.log("SelectPostTab addPostSubmit error:", e)
                logEvent("AddProductreferralToPostAddPRSubmitFailed")
            }
        }
    }

    const addReviewSubmit = async () => {
        const username = saleIGUsername && saleIGUsername.trim('').replace('@', '')
        const productreferralId = productreferralPublished && productreferralPublished.id

        const requestStep4 = {
            id: productreferralId,
            productRating,
            productTrueFit,
            forSale: forSale ? 1 : 0,
            saleCondition,
            salePrice,
            saleCurrency,
            saleDescription,
            saleEmail,
            saleIGUsername: username,
            productCommentReview: productCommentReview
        }
        setIsLoading(true)
        setIsSuccess(false)
        setErrorMessage("")

        try {
            const response = await updateProductreferral(requestStep4)
            setIsLoading(false)
            setIsSuccess(true)
            onProductReferralReviewPublishedCallBack && onProductReferralReviewPublishedCallBack(response)
        } catch (e) {
            setIsLoading(false)
            setIsSuccess(false)
            setErrorMessage("Ops, we couldn't save your post, please try again or reach out to us.")
            console.log("addReviewSubmit addPostSubmit")
            logEvent("AddProductreferralToPostFormAddReviewSubmitFailed")
        }
    }



    const renderPostThumb = (product) => {
        const imgLabel = promocode && { as: 'span', color: 'red', corner: 'right', icon: 'tags', size: 'mini' }
        let imageUrl
        if (mediaPostSelected) {
            imageUrl = mediaPostSelected.mediaUrl
        } else {
            imageUrl = post && post.url
        }

        return (
            <div>
                {imageUrl ? (<Image size='medium' bordered rounded
                    src={imageUrl}
                    style={{ display: 'flex', alignItems: 'center' }}
                // onError={() => { setMissingImage(true) }}
                />) : (<div style={{
                    border: "1px solid black",
                    height: "180px", width: "180px", background: "#eee"
                }}></div>)}
                <div style={{
                    position: 'absolute',
                    top: '8px',
                    left: '5px'
                }}>
                    {product && (<div>
                        <Image key={product.id} id={product.id}
                            src={product.image_url} size='mini' rounded bordered
                            {...(imgLabel ? { label: imgLabel } : {})}
                            style={{
                                width: '5em',
                                borderRadius: '4px',
                                border: '1px solid #FFF',
                                marginBottom: '1px'
                            }} />
                        {productSize && (
                            <div style={{ position: 'relative', bottom: '22px', left: '2px', height: '0' }}>
                                <Label size='mini' color={productColor || 'grey'}>{productSize}</Label>
                            </div>)}
                    </div>)
                    }
                </div>
            </div>
        )
    }

    const renderBottomButtons = (analyticsLabelPrefix, goNext, nextLabel = 'Next') => {
        const onClick = (callback, analyticsLabel) => {
            logEvent(analyticsLabel)
            callback()
        }
        return (
            <>
                <Divider clearing inverted />
                <Segment basic clearing>
                    {errorMessage && <Message error compact size='mini' floated='left'>{errorMessage}</Message>}
                    {/* <Button
                        type='button'
                        content='Back'
                        onClick={() => onClick(goBack, `${analyticsLabelPrefix}BackClicked`)}
                        secondary
                        floated='left'
                    /> */}
                    <Button
                        type='submit'
                        content={nextLabel}
                        onClick={() => onClick(goNext, `${analyticsLabelPrefix}NextClicked`)}
                        color='blue'
                        floated='right'
                        loading={isLoading}
                    />
                </Segment>
            </>
        )
    }

    const renderProductSelector = (autoLoadUrl) => {
        return (
            <Message color='black'>
                <ProductSelectorByUrl
                    inputLabel={'Search by the product url you would like to tag on the post below'}
                    compact={!withPostSelector}
                    onProductSelectedChange={onProductSelectedChangeCallBack}
                    onProductPublished={onProductPublishedCallBack}
                    productSelected={productSelected}
                    productPublished={productPublished}
                    autoLoadUrl={autoLoadUrl}
                />
            </Message>
        )
    }

    const renderProductFormWithIGSelector = () => {
        return (
            <Accordion inverted>
                <Accordion.Title
                    active={activeAccordionIndex === 0}
                    index={0}
                    onClick={(e, { index }) => setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index)}
                    inverted
                    content={<Label size={'large'}
                        content={"1 - Select an Instagram post"} />}
                >
                </Accordion.Title>
                <Accordion.Content active={activeAccordionIndex === 0} inverted>
                    <IgMediaSelector onMediaSelected={onMediaPostSelectedCallBack} />
                </Accordion.Content>

                <Accordion.Title
                    active={activeAccordionIndex === 1}
                    index={1}
                    onClick={(e, { index }) => setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index)}
                    inverted
                    content={<Label size={'large'}
                        content={"2 - Use your affiliates link or promo code"} />}
                >
                </Accordion.Title>
                <Accordion.Content active={activeAccordionIndex === 1} inverted
                    style={{ paddingLeft: "40px" }}>
                    <ProductreferralInputFields
                        isLoading={isLoading}
                        promocode={promocode}
                        commissionLink={commissionLink}
                        productColor={productColor}
                        productSize={productSize}
                        setPromocode={setPromocode}
                        setCommissionLink={setCommissionLink}
                        setProductSize={setProductSize}
                        setProductColor={setProductColor}
                    />
                </Accordion.Content>
            </Accordion>
        )
    }

    const renderProductreferralForm = (product) => {
        return (<Message color='black'>
            <Grid columns={4} relaxed centered>
                <Grid.Column width={withPostSelector ? 4 : 3} >
                    {product && renderPostThumb(product)}
                </Grid.Column>

                <Grid.Column width={withPostSelector ? 12 : 10} >
                    {withPostSelector
                        ? renderProductFormWithIGSelector()
                        : <ProductreferralInputFields
                            isLoading={isLoading}
                            promocode={promocode}
                            commissionLink={commissionLink}
                            productColor={productColor}
                            productSize={productSize}
                            setPromocode={setPromocode}
                            setCommissionLink={setCommissionLink}
                            setProductSize={setProductSize}
                            setProductColor={setProductColor}
                        />
                    }
                </Grid.Column>
            </Grid>
            {renderBottomButtons(
                // goBackToProduct,
                "addproductreferralToPostStep2",
                onSubmitProductreferral
            )}
        </Message>
        )
    }

    const renderAddReview = (product) => {
        return (
            <Message color='black'>
                <Grid columns={4} relaxed centered>
                    <Grid.Column width={withPostSelector ? 4 : 3} >
                        {product && renderPostThumb(product)}
                    </Grid.Column>

                    <Grid.Column width={withPostSelector ? 12 : 10} >
                        <ProductreferralInputReviewFields
                            productTrueFit={productTrueFit}
                            forSale={forSale}
                            saleCondition={saleCondition}
                            salePrice={salePrice}
                            saleCurrency={saleCurrency}
                            saleDescription={saleDescription}
                            saleEmail={saleEmail}
                            saleIGUsername={saleIGUsername}
                            productRating={productRating}
                            productCommentReview={productCommentReview}
                            setProductTrueFit={setProductTrueFit}
                            setForSale={setForSale}
                            setSalePrice={setSalePrice}
                            setSaleCurrency={setSaleCurrency}
                            setSaleCondition={setSaleCondition}
                            setSaleDescription={setSaleDescription}
                            setSaleEmail={setSaleEmail}
                            setSaleIGUsername={setSaleIGUsername}
                            setProductRating={setProductRating}
                            setProductCommentReview={setProductCommentReview}
                            productName={product && product.name}
                        />
                    </Grid.Column>
                </Grid>

                {renderBottomButtons(
                    // goBackToPost,
                    "addproductreferralToPostStep3",
                    onSubmitProductreferralReview
                )}
            </Message>
        )
    }

    const renderShare = (product) => {
        const onPromocodeCopied = (url) => {
            copy(url, {
                message: "Couldn't auto-copy. Please select and copy"
            })
            logEvent(`AddProductreferralToPostFormShareUrlCopied`)
        }
        const urlToShare = getPostUrlToShare(postPublished)
        return (
            <Message color='black'>
                <Grid columns={4} relaxed centered>
                    <Grid.Column width={withPostSelector ? 4 : 3} >
                        {product && renderPostThumb(product)}
                    </Grid.Column>

                    <Grid.Column width={withPostSelector ? 12 : 10} >
                        <Message success onClick={() => onPromocodeCopied(urlToShare)}>
                            <Message.Header>Thanks for sharing your review of {product && product.name}</Message.Header>
                            Use this url to share your review of {product && product.name}
                            <Label
                                color={"blue"}

                            >
                                <Icon name='tags' />
                                {urlToShare}
                                <Label.Detail><Icon name='copy' /></Label.Detail>
                            </Label>
                        </Message>

                    </Grid.Column>
                </Grid>

                {renderBottomButtons(
                    // goBackToPost,
                    "addproductreferralToPostStep3",
                    onDoneClick,
                    "Done"
                )}
            </Message>
        )
    }

    let content
    if (activeStepIndex === 4) {
        content = renderShare(productPublished)
    } else if (activeStepIndex === 3) {
        content = renderAddReview(productPublished)
    } else if (activeStepIndex === 2) {
        content = renderProductreferralForm(productPublished)
    } else {
        content = renderProductSelector(autoLoadUrl)
    }

    const bgStepColor = (index) => (activeStepIndex === index ? 'black' : '#1b1c1d')
    const productUsed = productPublished || productSelected

    const mediaImgUrl = (mediaPostSelected && mediaPostSelected.mediaUrl) || (post && post.url)

    return <>
        <Step.Group size='mini' widths={3} attached='top'

        >
            <Step active={activeStepIndex === 0 || activeStepIndex === 1} completed={activeStepIndex > 1}
            // style={{ backgroundColor: bgStepColor(0), color: 'white' }}
            >
                {
                    activeStepIndex >= 0 && !!productUsed ? (
                        <Image size='mini' bordered rounded src={productUsed.image_url}
                            style={{ marginRight: "4px", maxWidth: '25px' }} />
                    )
                        : (<Icon name='shopping bag' color='grey' />)
                }
                <Step.Content>
                    <Step.Title>Find the product
                        {activeStepIndex > 1 && <Icon name='check' color='green' />}
                    </Step.Title>
                    <Step.Description>
                        {
                            productUsed
                                ? productUsed.name
                                : 'Enter the product url'
                        }</Step.Description>
                </Step.Content>
            </Step>

            <Step active={activeStepIndex === 2} completed={activeStepIndex > 2}
            // style={{ backgroundColor: bgStepColor(1), color: 'white' }}
            >
                {
                    activeStepIndex >= 0 && !!mediaImgUrl ? (
                        <Image size='mini' bordered rounded src={mediaImgUrl}
                            style={{ marginRight: "4px", maxWidth: '45px' }} />
                    )
                        : (<Icon name='instagram' color='grey' />)
                }
                <Step.Content>
                    <Step.Title>
                        {withPostSelector
                            ? "Add your IRL Product Look"
                            : "Add Affiliates link"}
                        {activeStepIndex > 2 && <Icon name='check' color='green' />}
                    </Step.Title>
                    <Step.Description>
                        {withPostSelector
                            ? "Select an Instagram post with the product your are sharing"
                            : "Use your affiliates link or promo code"}
                    </Step.Description>
                </Step.Content>
            </Step>

            <Step active={activeStepIndex === 3} completed={activeStepIndex > 3}
            // style={{ backgroundColor: bgStepColor(2), color: 'white' }}
            >
                <Icon name='star outline' color='grey' />
                <Step.Content>
                    <Step.Title>Add your review
                    </Step.Title>
                    <Step.Description >{
                        productUsed
                            ? `Overall what do you think of ${productUsed.name}`
                            : 'Add your True-to-fit review'
                    }</Step.Description>
                </Step.Content>
            </Step>
        </Step.Group>
        <Segment inverted attached style={{ borderColor: '#1b1c1d' }}>
            {content}
        </Segment>
    </>
});

export default AddProductreferralToPostForm;