import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import API from '../../shared/services/webApi'
// import FixtureAPI from '../Services/FixtureApi'
// import DebugConfig from '../Config/DebugConfig'

/* ------------- Types ------------- */

import { StartupTypes } from '../redux/StartupRedux'
import PostsListActions, { PostsListTypes } from '../redux/posts/PostsListRedux'
import PostDetailActions, { PostDetailTypes } from '../redux/posts/PostDetailRedux'

import GetProductStep1Actions, { GetProductStep1Types } from '../redux/posts/add/GetProductStep1Redux'
import GetOwnerStep2Actions, { GetOwnerStep2Types } from '../redux/posts/add/GetOwnerStep2Redux'
import AddPostStep3Actions, { AddPostStep3Types } from '../redux/posts/add/AddPostStep3Redux'
import GetPostStep1Actions, { GetPostStep1Types } from '../redux/posts/update/GetPostStep1Redux'
import UpdatePostStep2Actions, { UpdatePostStep2Types } from '../redux/posts/update/UpdatePostStep2Redux'

import ProductsListActions, { ProductsListTypes } from '../redux/products/ProductsListRedux'
import ProductDetailActions, { ProductDetailTypes } from '../redux/products/ProductDetailRedux'
import UpdateProductActions, { UpdateProductTypes } from '../redux/products/UpdateProductRedux'
import ProductRelatedPostsActions, { ProductRelatedPostsTypes } from '../redux/products/ProductRelatedPostsRedux'

import UsersListActions, { UsersListTypes } from '../redux/users/UsersListRedux'
import UserDetailActions, { UserDetailTypes } from '../redux/users/UserDetailRedux'
import InstagramUseDetailrActions, { InstagramUserDetailTypes } from '../redux/users/InstagramUserDetailRedux'
import UpdateUserActions, { UpdateUserTypes } from '../redux/users/UpdateUserRedux'

/* ------------- Sagas ------------- */

import { startup } from './StartupSagas'

import { getItems } from './posts/PostsListSaga'
import { getPost } from './posts/PostDetailSaga'
import { addPost } from './posts/AddPostSaga'
import { getIGPost } from './posts/GetIGPostSaga'
import { updatePost } from './posts/UpdatePostSaga'

import { getProducts } from './products/ProductsListSaga'
import { getProduct } from './products/ProductDetailSaga'
import { updateProduct } from './products/UpdateProductSaga'

import { getUsers } from './users/UsersListSaga'
import { getUser } from './users/UserDetailSaga'
import { updateUser } from './users/UpdateUserSaga'
import { getIGUser } from './users/InstagramUserDetailSaga'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
// const api = DebugConfig.useFixtures ? FixtureAPI : API.create()
const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup, { api }),

    // // some sagas receive extra parameters in addition to an action
    // takeLatest(GithubTypes.USER_REQUEST, getUserAvatar, api),

    // Get List of Posts
    takeLatest(PostsListTypes.REQUEST, getItems,
      { api, Actions: PostsListActions }),

    // Get a post
    takeLatest(PostDetailTypes.REQUEST, getPost,
      {
        api,
        Actions: PostDetailActions
      }),

    // [-> Adding a post
    // first get product from the url post
    takeLatest(GetProductStep1Types.REQUEST, getProduct,
      {
        api,
        Actions: GetProductStep1Actions,
        // withoutInclude: true,
        withOpenGraph: true
      }),
    // then get the owner
    takeLatest(GetOwnerStep2Types.REQUEST, getIGPost,
      {
        api,
        Actions: GetOwnerStep2Actions
      }),
    // finally add the post
    takeLatest(AddPostStep3Types.REQUEST, addPost,
      {
        api,
        Actions: AddPostStep3Actions
      }),
    // ->]

    // [-> Update a post
    // first get product from the url post
    takeLatest(GetPostStep1Types.REQUEST, getIGPost,
      {
        api,
        Actions: GetPostStep1Actions
      }),
    // finally add the post
    takeLatest(UpdatePostStep2Types.REQUEST, updatePost,
      {
        api,
        Actions: UpdatePostStep2Actions
      }),
    // ->]

    // Get a product
    takeLatest(ProductDetailTypes.REQUEST, getProduct,
      {
        api,
        Actions: ProductDetailActions
      }),

    // Get List of Products
    takeLatest(ProductsListTypes.REQUEST, getProducts,
      { api, Actions: ProductsListActions }),

    // Update a product
    takeEvery(UpdateProductTypes.REQUEST, updateProduct,
      {
        api,
        Actions: UpdateProductActions,
        withoutInclude: true
      }),

    // Get related posts
    takeLatest(ProductRelatedPostsTypes.REQUEST, getItems,
      {
        api,
        Actions: ProductRelatedPostsActions
      }),

    // Get List of Users
    takeLatest(UsersListTypes.REQUEST, getUsers,
      { api, Actions: UsersListActions }),

    // [-> Get User
    // first get user and request his IG profile
    takeLatest(UserDetailTypes.REQUEST, getUser,
      {
        api,
        Actions: UserDetailActions,
        onSuccessAction: InstagramUseDetailrActions.request
      }),
    // second, get IG profile and request update user
    takeLatest(InstagramUserDetailTypes.REQUEST, getIGUser,
      {
        api,
        Actions: InstagramUseDetailrActions,
        onSuccessAction: UpdateUserActions.request
      }),
    // third, update the user and update the store
    takeEvery(UpdateUserTypes.REQUEST, updateUser,
      {
        api,
        Actions: UpdateUserActions,
        onSuccessAction: UserDetailActions.updateUser
      })
    // ->]
  ])
}
