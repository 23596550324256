import { put } from 'redux-saga/effects'

import AppStateActions from '../redux/AppStateRedux'
import PostsListActions from '../redux/posts/PostsListRedux'

// import { LoginSelectors } from '../Redux/LoginRedux'
// import { userConnected, userDisconnected } from './LoginSagas'

// export const selectIsLoggedIn = (state) => LoginSelectors.isLoggedIn(state.login)

// process STARTUP actions
export function * startup ({ api }) {
  yield put(AppStateActions.setRehydrationComplete())

  yield put(PostsListActions.rehydrationCompleted())
  // const isLoggedIn = yield select(selectIsLoggedIn)
  // if (isLoggedIn) {
  //     yield userConnected(api)
  // } else {
  //     yield userDisconnected(api)
  // }
}
