import React from 'react'
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon
} from 'react-share'

const shareTextMessage = (name, brandName, url) => `Check out ${brandName} ${name} looks 👉 ${url} #${brandName} #seemyfit`
const shareTextMessageFacebook = (name, brandName, url) => `Check out ${brandName} ${name} looks on ${url} #${brandName} #seemyfit`
const shareTextMessageTwitter = (name, brandName) => `Check out ${brandName} ${name} looks 👉 `

const ShareButtons = ({ url, name, media, brandName }) => (
  <>{' '}
    <PinterestShareButton
      url={url}
      media={media}
      description={shareTextMessage(name, brandName, url)}
    >
      <PinterestIcon size={32} round />
    </PinterestShareButton>{' '}
    <TwitterShareButton
      url={url}
      title={shareTextMessageTwitter(name, brandName)}
      via='seemyfit'
      hashtags={['seemyfit', `${brandName}`]}
    >
      <TwitterIcon size={32} round />
    </TwitterShareButton>{' '}
    <FacebookShareButton
      url={url}
      quote={shareTextMessageFacebook(name, brandName, url)}
      hashtag={`#${brandName} #seemyfit`}
    >
      <FacebookIcon size={32} round />
    </FacebookShareButton>
  </>
)

export default ShareButtons
