import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Sidebar, Menu, Header, Icon, Container, Button } from 'semantic-ui-react'
import { Link } from "react-router-dom";

import {
    ROOT,
    PRODUCTS_LIST, POSTS_LIST,
    getBrandsFilterRoute, getPostsListFilterRoute, TAGPRODUCTPREFIX
} from '../../../constants/routes';
import { logEvent } from '../../../services/analytics';
import { Media } from '../../../utils/AppMediaBreakPoints'
import appstoreimg from '../../../images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'
import ShowMenuButtonsForAuthenticated from './ShowMenuButtonsForAuthenticated';
import EmailLoginForm from '../../../components/EmailLoginForm';
import { getAuth } from 'firebase/auth';
import HomepageHeading from '../../../routes/Home/components/HomePageHeading';

export default class MobileMenu extends Component {
    state = { sidebarOpened: false }

    handleSidebarHide = () => this.setState({ sidebarOpened: false })

    handleToggle = () => this.setState({ sidebarOpened: true })

    render() {
        const { brandName, history, auth } = this.props

        const { sidebarOpened } = this.state

        const location = (history && history.location)
        const path = location && location.pathname

        const isNotAddProductPage = path && path.startsWith(TAGPRODUCTPREFIX)

        return (
            <>
                <Container as={Media} lessThan='computer' style={{
                    marginRight: '0px !important',
                    marginLeft: '0px !important',
                    paddingRight: '0px !important',
                    paddingLeft: '0px !important',
                }}>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                    >
                        <Menu.Item as='span'>
                            <Link
                                to={ROOT}
                                onClick={() => {
                                    // Force a refresh
                                    window.location.href = ROOT
                                    logEvent('mobileHeaderHomeSidebarClicked')
                                }}
                            >
                                <Header as='h4' inverted>
                                    <Icon name='home' />
                                    <Header.Content>
                                        Home
                                        <Header.Subheader>#seemyfit</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Link>
                        </Menu.Item>
                        <ShowMenuButtonsForAuthenticated auth={auth}
                            menuButtons={
                                <>
                                    <Menu.Item as='span'>
                                        <Link
                                            to={brandName ? getPostsListFilterRoute(brandName) : POSTS_LIST}
                                            onClick={() => logEvent('mobileSidebarLooksClicked')}
                                        >
                                            <Header as='h4' inverted>
                                                <Icon name='hashtag' />
                                                <Header.Content>
                                                    Looks
                                                    {brandName && <Header.Subheader>{brandName}</Header.Subheader>}
                                                </Header.Content>
                                            </Header>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item as='span'>
                                        <Link
                                            to={brandName ? getBrandsFilterRoute(brandName) : PRODUCTS_LIST}
                                            onClick={() => logEvent('mobileSidebarProductsClicked')}
                                        >
                                            <Header as='h4' inverted>
                                                <Icon name='shopping bag' />
                                                <Header.Content>
                                                    Products
                                                    {brandName && <Header.Subheader>{brandName}</Header.Subheader>}
                                                </Header.Content>
                                            </Header>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        {!isNotAddProductPage && (
                                            <Link to={TAGPRODUCTPREFIX}>
                                                <Button
                                                    color="orange" compact
                                                    // size='large'
                                                    href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    icon labelPosition='right'
                                                    style={{ marginRight: '10px' }}
                                                    onClick={() => logEvent('desktopHeaderInstallAddonClicked')}
                                                >
                                                    Add Product Review
                                                    <Icon name='shopping bag' />
                                                </Button>
                                            </Link>
                                        )}
                                    </Menu.Item>
                                </>}
                        />
                        {/* <Menu.Item as='span'>
                        <Link
                            to={USERS_LIST}
                            onClick={() => logEvent('mobileSidebarProfilesClicked')}
                        >
                            <Header as='h4'>
                                <Icon name='instagram' />
                                <Header.Content>Profiles</Header.Content>
                            </Header>
                        </Link>
                    </Menu.Item> */}
                        <Menu.Item as='span'>
                            <Link
                                to={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=w_mobile_sidemenu${brandName ? `_${brandName}` : ''}&mt=8`}
                                onClick={() => logEvent('MobileSidebarInstalliOSAppClicked')}
                                target='_blank' rel='noopener noreferrer'
                            >
                                <Header as='h4'>
                                    <Header.Content><img src={appstoreimg} alt='Seemyfit app' /></Header.Content>
                                </Header>
                            </Link>
                        </Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Menu icon inverted size='large'>
                            <Menu.Item
                                name='bars'
                                onClick={() => {
                                    this.setState({ sidebarOpened: true })
                                    logEvent('mobileMenuIconSidebarClicked')
                                }}
                            >
                                <Icon name='bars' />
                            </Menu.Item>
                            <Menu.Menu position='right'>
                                <Menu.Item>
                                    <EmailLoginForm auth={getAuth()} />
                                </Menu.Item>
                            </Menu.Menu>
                            {/* <a
                            href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=MobileHeaderBar&mt=8`}
                            onClick={() => logEvent(`InstallMobileAppBannerMobileHeaderBariOSAppClicked`)}
                            target='_blank' rel='noopener noreferrer'
                            style={{ position: 'fixed', right: '0' }}
                        >
                            <img src={appstoreimg} alt='Seemyfit app' />
                        </a> */}
                        </Menu>

                    </Sidebar.Pusher>

                    <HomepageHeading history={history} mobile={true} />
                </Container>
            </>
        )
    }
}

MobileMenu.propTypes = {
    withHero: PropTypes.bool,
}