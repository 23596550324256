import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import usersListActions from '../../../store/redux/users/UsersListRedux'

import UsersList from './UsersList'

export default connect(
  (state, ownProps) => ({
    history: ownProps.history,
    // currentUser: state.login.currentUser,
    items: state.usersState.items.asMutable(),
    filterLoaded: state.usersState.filterLoaded,
    pageNumberLoaded: state.usersState.pageNumberLoaded,
    isLoading: state.usersState.fetching,
    isNextPageLoading: state.usersState.isNextPageLoading,
    lastPageReached: state.usersState.lastPageReached,
    statusText: state.usersState.statusText
  }),
  dispatch => ({
    getUsersAction: bindActionCreators(usersListActions.request, dispatch)
  })
)(UsersList)
