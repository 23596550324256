import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  request: ['request'],
  success: ['items'],
  failure: null,
  reset: null
}, { prefix: 'PRODUCT_' })

export const ProductDetailTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  items: null,
  fetching: null,
  statusText: null
})

/* ------------- Selectors ------------- */

export const ProductDetailSelectors = {
  selectProduct: state => state.items
}

/* ------------- Reducers ------------- */

export const request = (state) => {
  // load the product from the posts list by id
  return state.merge({ fetching: true, items: null })
}

export const success = (state, { items }) => {
  return state.merge({ fetching: false, items })
}

export const failure = (state) => state.merge({ fetching: false })
export const reset = () => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset
})
