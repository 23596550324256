import React from 'react'
import {
  Container,
  Header,
  Segment,
  Divider
} from 'semantic-ui-react'

import ResponsiveContainer from '../../layouts/components/Responsive/ResponsiveContainer'
import Footer from '../../layouts/components/FooterDesktop'

const GDPRPage = (props) => (
  <ResponsiveContainer history={props.history} location={props.location}>
    <Divider hidden />
    <Container text>
      <Segment
        style={{
          borderRadius: '20px'
        }} inverted padded
      >
        <Header as='h3' style={{ fontSize: '2em' }}>
          Data Deletion Policy
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Please send us a request email directly to seemyfit@outlook.com with your userid and we will send you a confirmation once we handle your data deletion request.
        </p>
      </Segment>
    </Container>
    <Divider hidden />
    <Footer />
  </ResponsiveContainer>
)

export default GDPRPage
