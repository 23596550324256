import React from 'react'
import {
  Image,
  Header,
  Grid,
  Segment,
  Icon
} from 'semantic-ui-react'

const HomeReviews = ({ siteName }) => (
  <Segment vertical>
    <Grid celled='internally' columns='equal' stackable inverted>
      <Grid.Row textAlign='center'>
        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
          <Header as='h3' style={{ fontSize: '2em' }}>
            <Icon name='quote left' />I don’t need to browse forever to find pieces I love, it’s for sure easier to look for ideas from creators like me!
          </Header>
          <Header as='h5'><Image avatar src='https://myyfit.s3.fr-par.scw.cloud/users/729/72276506_985571878448907_3717886668246614016_n.jpg' /><b>Sofya</b> - Fashion Desginer</Header>
        </Grid.Column>
        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
          <Header as='h3' style={{ fontSize: '1.69em' }}><Icon name='quote left' />I like seeing how other people are doing it! outfits they wear, colours they mix, accessories they pair with their outfits and so on..</Header>
          <Header as='h5'><Image avatar src='https://myyfit.s3.fr-par.scw.cloud/users/1628/144894955_852851025292505_6964096225766308492_n.jpg' /><b>Anna</b> - Seemyfit user</Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

export default HomeReviews
