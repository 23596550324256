import { put } from 'redux-saga/effects'
import axios from 'axios'

// import { sessionExpired } from './LoginSagas'
import { loadIGPostByUrl } from '../../../shared/services/instagramApi'

export function* getIGPost({ api, Actions }, { request }) {
  const { url, onSuccess } = request
  let igPostUrlWithoutParams = url
  if (igPostUrlWithoutParams && igPostUrlWithoutParams.includes('?')) {
    igPostUrlWithoutParams = igPostUrlWithoutParams.split('?')[0]
  }
  // console.log("=====getIGPost", igPostUrlWithoutParams)

  try {
    const response = yield fetchInstagramPhotos(igPostUrlWithoutParams)
    // console.log("=====getIGPost response", response)

    /// new for debug 
    /**
     * 
     * {
      "success": true,
      "data": {
        "taken_at": 1654531268,
        "pk": 2854737643463525000,
        "id": "2854737643463525091_25025320",
        "device_timestamp": 1654531102300697,
        "media_type": 1,
        "code": "CeeEML9lELj",
        "client_cache_key": "Mjg1NDczNzY0MzQ2MzUyNTA5MQ==.2",
        "filter_type": 0,
        "is_unified_video": false,
        "should_request_ads": false,
        "original_media_has_visual_reply_media": false,
        "caption_is_edited": false,
        "like_and_view_counts_disabled": false,
        "commerciality_status": "not_commercial",
        "is_paid_partnership": false,
        "is_visual_reply_commenter_notice_enabled": false,
        "clips_tab_pinned_user_ids": [],
        "has_delayed_metadata": false,
        "location": {
          "pk": 212950988,
          "short_name": "Brooklyn",
          "facebook_places_id": 112111905481230,
          "external_source": "facebook_places",
          "name": "Brooklyn, New York",
          "address": "",
          "city": "",
          "has_viewer_saved": false,
          "lng": -73.95,
          "lat": 40.65,
          "is_eligible_for_guides": true
        },
        "lat": 40.65,
        "lng": -73.95,
        "comment_likes_enabled": true,
        "comment_threading_enabled": true,
        "max_num_visible_preview_comments": 2,
        "has_more_comments": true,
        "preview_comments": [],
        "comment_count": 11426,
        "photo_of_you": false,
        "usertags": {
          "in": [
            {
              "user": {
                "pk": 205859113,
                "username": "alexacorbisiero",
                "is_verified": false,
                "profile_pic_id": "2968849245391178448_205859113",
                "profile_pic_url": "https://scontent-ams4-1.cdninstagram.com/v/t51.2885-19/315099289_144122411704491_9194392421258594395_n.jpg?stp=dst-jpg_s150x150&_nc_ht=scontent-ams4-1.cdninstagram.com&_nc_cat=103&_nc_ohc=rFmuCvZZWNsAX_chRrz&edm=ALQROFkBAAAA&ccb=7-5&oh=00_AfCAlkEkC0otE4qsFEKU56SiWLgENaAJTYEZESd36HcZXw&oe=63AC5467&_nc_sid=30a2ef",
                "pk_id": "205859113",
                "full_name": "Alexa ୨୧⋆｡˚",
                "is_private": false
              },
              "position": [
                0.4666666667,
                0.7743589744
              ],
              "start_time_in_video_in_sec": null,
              "duration_in_video_in_sec": null
            },
            {
              "user": {
                "pk": 46206981764,
                "username": "strawbi3",
                "is_verified": false,
                "profile_pic_id": "2751473614433871247_46206981764",
                "profile_pic_url": "https://scontent-ams4-1.cdninstagram.com/v/t51.2885-19/271819713_797701864373306_3664530729214066215_n.jpg?stp=dst-jpg_s150x150&_nc_ht=scontent-ams4-1.cdninstagram.com&_nc_cat=102&_nc_ohc=eORXLWH2rJYAX_kJYaA&edm=ALQROFkBAAAA&ccb=7-5&oh=00_AfAW8B2dq6P-IxJrUIC321rbWtcEvvWCqX8lVZU1PzHc3Q&oe=63AD32A0&_nc_sid=30a2ef",
                "pk_id": "46206981764",
                "full_name": "STRAWBi3🍓",
                "is_private": false
              },
              "position": [
                0.46752135450000004,
                0.6017093756
              ],
              "start_time_in_video_in_sec": null,
              "duration_in_video_in_sec": null
            }
          ]
        },
        "is_organic_product_tagging_eligible": false,
        "can_see_insights_as_brand": false,
        "user": {
          "pk": 25025320,
          "username": "instagram",
          "is_verified": true,
          "friendship_status": {
            "following": false,
            "outgoing_request": false,
            "is_bestie": false,
            "is_restricted": false,
            "is_feed_favorite": false
          },
          "profile_pic_id": "2839516949842903169_25025320",
          "profile_pic_url": "https://scontent-ams2-1.cdninstagram.com/v/t51.2885-19/281440578_1088265838702675_6233856337905829714_n.jpg?stp=dst-jpg_s150x150&_nc_ht=scontent-ams2-1.cdninstagram.com&_nc_cat=1&_nc_ohc=HXGEp3Vn-UAAX_MDLWV&edm=ALQROFkBAAAA&ccb=7-5&oh=00_AfA-RT7ttC_UfvA4Uy-uWP0Xe_6CBszcJuG263aABzwBHw&oe=63AC1758&_nc_sid=30a2ef",
          "pk_id": "25025320",
          "full_name": "Instagram",
          "is_private": false,
          "account_badges": [],
          "has_anonymous_profile_picture": false,
          "show_account_transparency_details": true,
          "fan_club_info": {
            "fan_club_id": null,
            "fan_club_name": null,
            "is_fan_club_referral_eligible": null,
            "fan_consideration_page_revamp_eligiblity": null,
            "is_fan_club_gifting_eligible": null
          },
          "transparency_product_enabled": false,
          "latest_reel_media": 0,
          "is_favorite": false,
          "is_unpublished": false
        },
        "can_viewer_reshare": true,
        "like_count": 452480,
        "has_liked": false,
        "top_likers": [],
        "facepile_top_likers": [],
        "image_versions2": {
          "candidates": [
            {
              "width": 828,
              "height": 929,
              "url": "https://scontent-ams4-1.cdninstagram.com/v/t51.2885-15/285896651_741841410334825_324475216850331915_n.jpg?stp=dst-jpg_e35_p828x828&_nc_ht=scontent-ams4-1.cdninstagram.com&_nc_cat=101&_nc_ohc=mMcJzCjm67cAX_zoFwJ&edm=ALQROFkBAAAA&ccb=7-5&ig_cache_key=Mjg1NDczNzY0MzQ2MzUyNTA5MQ%3D%3D.2-ccb7-5&oh=00_AfAMUwPzjJwoYs7_wRrVnQAd4g0zS2JFCmQzHab90mrXuA&oe=63ACFE6D&_nc_sid=30a2ef",
              "scans_profile": "e35"
            },
            {
              "width": 320,
              "height": 359,
              "url": "https://scontent-ams4-1.cdninstagram.com/v/t51.2885-15/285896651_741841410334825_324475216850331915_n.jpg?stp=dst-jpg_e35_p320x320&_nc_ht=scontent-ams4-1.cdninstagram.com&_nc_cat=101&_nc_ohc=mMcJzCjm67cAX_zoFwJ&edm=ALQROFkBAAAA&ccb=7-5&ig_cache_key=Mjg1NDczNzY0MzQ2MzUyNTA5MQ%3D%3D.2-ccb7-5&oh=00_AfDIPTgP3a1GZUa6JEi1eBgnFZ7Wa5pOuCs6wyltLQOczw&oe=63ACFE6D&_nc_sid=30a2ef",
              "scans_profile": "e35"
            }
          ]
        },
        "original_width": 1212,
        "original_height": 1360,
        "caption": {
          "pk": 17914219259402944,
          "user_id": 25025320,
          "text": "“My work celebrates my inner child, vintage styles and slow fashion.” —Alexa Corbisiero (@alexacorbisiero) 🍓\n\n“What I love most about fashion is that it can be whatever you want it to be. It is an amazing way for people to express themselves.You don’t have to follow any rules or have expensive materials. I’ve always been into DIY fashion, styling outfits and crafting, but I only started crocheting recently.\n\nCrochet cannot be done by a machine. Every crochet piece you’ve seen has been done by someone’s hands. It takes passion, patience and delicacy. I design all the pieces I make. I’ve tried making some pieces by following patterns, but I find it easier to freehand and love figuring things out through trial and error. It is satisfying to create something from nothing but an idea and a drawing.\n\nMy approach for my work is to reference and reinterpret styles from my childhood that a younger me would’ve wished she could’ve worn. My fashion and art are playful and explorative. They’re ever-changing as I grow, learn and develop my personal style.”\n\nPhoto by @strawbi3",
          "type": 1,
          "created_at": 1654531268,
          "created_at_utc": 1654531268,
          "content_type": "comment",
          "status": "Active",
          "bit_flags": 0,
          "did_report_as_spam": false,
          "share_enabled": false,
          "user": {
            "pk": 25025320,
            "username": "instagram",
            "is_verified": true,
            "profile_pic_id": "2839516949842903169_25025320",
            "profile_pic_url": "https://scontent-ams2-1.cdninstagram.com/v/t51.2885-19/281440578_1088265838702675_6233856337905829714_n.jpg?stp=dst-jpg_s150x150&_nc_ht=scontent-ams2-1.cdninstagram.com&_nc_cat=1&_nc_ohc=HXGEp3Vn-UAAX_MDLWV&edm=ALQROFkBAAAA&ccb=7-5&oh=00_AfA-RT7ttC_UfvA4Uy-uWP0Xe_6CBszcJuG263aABzwBHw&oe=63AC1758&_nc_sid=30a2ef",
            "pk_id": "25025320",
            "full_name": "Instagram",
            "is_private": false
          },
          "is_covered": false,
          "is_ranked_comment": false,
          "media_id": 2854737643463525000,
          "private_reply_status": 0
        },
        "comment_inform_treatment": {
          "should_have_inform_treatment": false,
          "text": "",
          "url": null,
          "action_type": null
        },
        "sharing_friction_info": {
          "should_have_sharing_friction": false,
          "bloks_app_url": null,
          "sharing_friction_payload": null
        },
        "can_viewer_save": true,
        "is_in_profile_grid": false,
        "profile_grid_control_enabled": false,
        "organic_tracking_token": "eyJ2ZXJzaW9uIjo1LCJwYXlsb2FkIjp7ImlzX2FuYWx5dGljc190cmFja2VkIjp0cnVlLCJ1dWlkIjoiMjUyNDYyNjI1N2UyNDg4NGE3ZTk0M2NkOTU3ZTc3OGQyODU0NzM3NjQzNDYzNTI1MDkxIiwic2VydmVyX3Rva2VuIjoiMTY3MTk0ODM5NzUwOHwyODU0NzM3NjQzNDYzNTI1MDkxfDU3MjIxNzMxNTM0fGM3NGI1YThmMTA1ZTI4YmFkOTdkMzNjOTYyMjI5Y2NiMjBiZDkzZjEzYTJjYWI0ZjhlZmFmM2VmMDUzOTllNjgifSwic2lnbmF0dXJlIjoiIn0=",
        "has_shared_to_fb": 0,
        "product_type": "feed",
        "deleted_reason": 0,
        "integrity_review_decision": "pending",
        "commerce_integrity_review_decision": null,
        "music_metadata": {
          "music_canonical_id": "0",
          "audio_type": null,
          "music_info": null,
          "original_sound_info": null,
          "pinned_media_ids": null
        },
        "is_artist_pick": false,
        "can_view_more_preview_comments": false,
        "inline_composer_display_condition": "impression_trigger",
        "inline_composer_imp_trigger_time": 5,
        "hide_view_all_comment_entrypoint": false
      }
    }
     * */


    // old FOR DEBUG
    // const response = {
    //   graphql: {
    //     shortcode_media: {
    //       owner: {
    //         username: 'ouadieo',
    //         id: '123',
    //         profile_pic_url: 'https://scontent-sea1-1.cdninstagram.com/v/t51.2885-19/s320x320/103111198_935628063578853_713361901729302940_n.jpg?_nc_ht=scontent-sea1-1.cdninstagram.com&_nc_ohc=OdlPLijbqlcAX8dr8DK&oh=a6d0dd0960585ff1721511bfb2c5fb5a&oe=5F1676F5',
    //         edge_followed_by: {
    //           count: 33
    //         },
    //         is_verified: 0
    //       },
    //       display_url: 'https://scontent-sea1-1.cdninstagram.com/v/t51.2885-15/fr/e15/p1080x1080/96841983_173930973935370_3209719557070439684_n.jpg?_nc_ht=scontent-sea1-1.cdninstagram.com&_nc_cat=106&_nc_ohc=tMVvdEB2_-MAX8XJuTl&oh=8b6b60612acfd95b20f12a7d0233a8cb&oe=5EF39EC8'
    //     }
    //   }
    // }

    if (response) {
      const igPost = (response.graphql && response.graphql.shortcode_media) || {}
      const igUser = igPost && igPost.owner
      const followedBy = igUser && igUser.edge_followed_by && igUser.edge_followed_by.count

      const igPostFormatted = {
        igPostImgUrl: igPost && igPost.display_url,
        post_caption: getCaption(igPost),
        post_is_video: igPost && igPost.is_video,
        post_has_audio: igPost && igPost.has_audio,
        post_video_view_count: igPost && igPost.video_view_count,
        post_video_url: igPost && igPost.video_url,
        post_product_type: igPost && igPost.product_type,
        post_video_duration: igPost && igPost.video_duration,
        post_video_thumbnail_src: igPost && igPost.thumbnail_src,
        owner: {
          instagram_username: igUser && igUser.username,
          instagram_avatar: igPost && igPost.owner && igPost.owner.profile_pic_url,
          ig_followed_by_count: followedBy,
          ig_user_id: igUser && igUser.id,
          ig_is_verified: igUser && igUser.is_verified ? 1 : 0
        }
      }

      // do data conversion here if needed
      yield put(Actions.success(igPostFormatted))
      if (onSuccess) {
        yield put(onSuccess(igPostFormatted))
      }
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }

    yield put(Actions.failure())
  }
}

const fetchInstagramPhotos = async (accountUrl) => {
  const instagramRegExp = new RegExp(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/)

  const instance = axios.create({
    baseURL: accountUrl,
    withCredentials: false,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
  });

  const response = await instance.get(accountUrl)
  const json = JSON.parse(response.data.match(instagramRegExp)[1])
  // console.log("=====json", json)
  const edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(0, 8)
  const photos = edges.map(({ node }) => {
    return {
      url: `https://www.instagram.com/p/${node.shortcode}/`,
      thumbnailUrl: node.thumbnail_src,
      displayUrl: node.display_url,
      caption: node.edge_media_to_caption.edges[0].node.text
    }
  })
  return photos
}

function getCaption(igPost) {
  const edges = igPost && igPost.edge_media_to_caption && igPost.edge_media_to_caption.edges
  const item = edges && edges[0]
  const node = item && item.node
  return node && node.text
}

// TODO
// function getVideo (igPost) {
//   const edges = igPost && igPost.edge_media_to_caption && igPost.edge_media_to_caption.edges
//   const item = edges && edges[0]
//   const node = item && item.node
//   return node && node.text
// }
