import { DOMAINNAME_WITH_HTTPS } from '../constants/apiroutes'

export const clientId = '227687775154933'
export const clientSecret = '7e2c7e5e459d7c2fffd8fc8c803891a3'
export const redirectUri = `${DOMAINNAME_WITH_HTTPS}/ig/auth`
export const IG_MEDIA_LIMIT = 100

export const getIgUser = async (accessToken) => {
  try {
    const fields = 'id,username,account_type,media_count'
    const edges = 'media'
    const response = await fetch(`https://graph.instagram.com/me?fields=${fields}&edges=${edges}&access_token=${accessToken}`, {
      method: 'get'
    })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}

export const getLongToken = async (accessToken) => {
  const urlbase = 'https://graph.instagram.com'
  try {
    const response = await fetch(
      `${urlbase}/access_token?grant_type=ig_exchange_token&client_secret=${clientSecret}&access_token=${accessToken}`, {
      method: 'get'
    })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}

export const getShortAccessToken = async (code) => {
  const data = new FormData()
  data.append('client_id', clientId)
  data.append('client_secret', clientSecret)
  data.append('grant_type', 'authorization_code')
  data.append('redirect_uri', redirectUri)
  data.append('code', code)

  try {
    const response = await fetch('https://api.instagram.com/oauth/access_token', {
      method: 'post',
      body: data
    })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}

// not used
export const getIgUserProfile = async (accessToken, userId) => {
  try {
    // "https://graph.facebook.com/v3.2/17841405309211844?fields=business_discovery.username(bluebottle){followers_count,media_count}&access_token={access-token}"
    const response = await fetch(`https://graph.facebook.com/v3.2/${userId}?fields=business_discovery.username(bluebottle){followers_count,media_count}&access_token=${accessToken}`, {
      method: 'get'
    })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}

export const getIgUserMedia = async (accessToken, nextPage) => {
  try {
    const fields = 'id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username'
    const next = nextPage ? `&next=${nextPage}` : ''
    const url = `https://graph.instagram.com/me/media?fields=${fields}&access_token=${accessToken}&limit=${IG_MEDIA_LIMIT}${next}`
    // console.log("getIgUserMedia url:", url)
    const response = await fetch(url, {
      method: 'get'
    })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}

export const getIgUserImageProfile = async (accessToken, userId) => {
  try {
    const response = await fetch(`https://graph.facebook.com/v3.2/${userId}/picture&access_token=${accessToken}`, {
      method: 'get'
    })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}
