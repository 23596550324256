import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import relatedPostsActions, { ProductRelatedPostsSelectors } from '../../../store/redux/products/ProductRelatedPostsRedux'

import RelatedPosts from './RelatedPosts'

export default connect(
  (state, ownProps) => ({
    product: ownProps.product,
    // currentUser: state.login.currentUser,
    posts: ProductRelatedPostsSelectors.selectRelatedPosts(state.relatedPostsState).asMutable(),
    siteName: state.relatedPostsState.siteName,
    isLoading: state.relatedPostsState.fetching,
    statusText: state.relatedPostsState.statusText
  }),
  dispatch => ({
    getPostsAction: bindActionCreators(relatedPostsActions.request, dispatch)
  })
)(RelatedPosts)
