import { path } from 'ramda'
import { serialise } from 'kitsu-core'

import API from '../../../services/webApi'
import { transformUser } from "../../transformers/userTransformer";
import { loadUrlMetaData } from '../../../services/opengraphApi';

const api = API.create()

export const getProduct = async (request, { withoutInclude, withOpenGraph }) => {

    const { filter } = request
    const include = 'productreferrals.post.user,productreferrals.post.productreferrals.product'

    const config = {
        params: {
            ...(!withoutInclude && { include }),
            ...(filter && { filter: { ...filter } })
        }
    }
    const response = await api.getProducts(config)
    if (response) {
        const data = path(['data'], response)
        let productData = data
        if (productData && productData.length > 0) {
            productData = productData[0]
            // if(product.length > 1){
            // if the url returns more than one product
            // show the other below the main one
            // and on tap show the relationship looks
            // }
        }

        if (withOpenGraph && filter && filter.url
            && (!productData.name || !productData.image_url)) {
            const openGraphMetadata = await loadUrlMetaData(filter.url)
            const hybridGraph = openGraphMetadata && openGraphMetadata.hybridGraph
            let productName = hybridGraph && hybridGraph.title
            if (productName && productName.includes('|')) {
                productName = productName.split('|')[0]
            }
            const productImage = hybridGraph && hybridGraph.image
            productData.name = productName
            productData.image_url = productImage
        }

        return productData
    }
    return response
};

export const updateProduct = async (request, { withoutInclude }) => {
    //   const { filter } = request
    const include = 'posts.user'
    const config = {
        params: {
            ...(!withoutInclude && { include })
        },
        ...request
    }

    const response = await api.updateProduct(config)
    if (response) {
        const data = path(['data'], response)
        // do data conversion here if needed
        return data
    }
    return response
}

// export default function useUserMutation() {
//     return useMutation((request) => updateUser(request));
// }