import React from 'react'
import {
  Image, Grid, Header, Segment, Container, Divider
} from 'semantic-ui-react'
import { logEvent } from '../services/analytics'
import herophoto1 from '../images/herophoto1.jpg'
import InstallAddonButton from './InstallAddonButton'
import bgTryitNow from '../images/bg-tryitnow.jpeg'

const TryItNow = () => (
  <Container>
    <Divider hidden />
    <Segment
      style={{
        backgroundImage: `url(${bgTryitNow})`,
        backgroundSize: 'cover'
      }} inverted
    >
      <Segment
        style={{
          margin: '15px 60px',
          background: 'rgba(27, 28, 29, 0.95)',
          borderRadius: '20px',
          padding: '30px'
        }} inverted
      >
        <Grid centered>
          <Grid.Row>
            <Header
              as='h3'
              style={{
                fontSize: '1.7em',
                fontWeight: 'normal'
              }}
            >Use <b>seemyfit</b> browser extension while shopping your favorite stores to automatically<br />
              find influencers wearing different sizes of the specific item you are browsing.<br />
              {/* and help you <b className='underlined'> Find Clothes That Fit! </b> */}
            </Header>
          </Grid.Row>
          <Grid.Row>
            <InstallAddonButton />
          </Grid.Row>
        </Grid>
        <Grid container stackable verticalAlign='middle' centered>
          <Grid.Row>
            <Grid.Column floated='right' largeScreen={8}>
              <Image bordered rounded size='large' src={herophoto1} />
            </Grid.Column>
            <Grid.Column largeScreen={8}>
              <Header as='h2' style={{ fontSize: '2em' }}>
                Try it now!
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                <b>1 -</b>{' Add '}
                <a
                  href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk'
                  target='_blank' rel='noopener noreferrer' className='yellow'
                  onClick={() => logEvent('tryItInstallAddonClicked')}
                >
                  seemyfit add-on
                </a> to your chome<br />
                <br />
                <b>2 -</b> {'Visit this '}
                <a
                  href='https://www.urbanoutfitters.com/shop/nirvana-unplugged-oversized-tee'
                  target='_blank' rel='noopener noreferrer' className='yellow'
                  onClick={() => logEvent('tryItVisitLinkClicked')}
                >
                  Nirvana Unplugged T-Shirt Dress
                </a><br /><br />
                <b>3 -</b> Click <b>"See my Fit"</b> button and that's it!<br /><br />
                {/* <b>Now pause for a moment <br />
                                and appreciate how awesome is this! <span role='img' aria-label='Namaste'>🙏</span>
                  </b><br />
                            We show you Creators photos of the same clothes in the same page to help you get an idea of how the outfit looks IRL and help you find what's fit your taste! */}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment>
    <Divider hidden />
  </Container>
)

export default TryItNow
