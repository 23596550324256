import React, { Component } from 'react'
import {
  Icon,
  Button,
  Divider
} from 'semantic-ui-react'

import { getPopularDomainNamesFromPosts } from '../../../shared/utils/product'

class UserBrandsFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      statusText: null,
      isSuccess: null
    }
  }

  setFilter (brand) {
    let activeFilters = this.props.activeFilters || []

    if (activeFilters.includes(brand)) {
      activeFilters = activeFilters.filter(item => item !== brand)
    } else {
      activeFilters.push(brand)
    }

    this.props.onBrandFilterChange && this.props.onBrandFilterChange(activeFilters)
    this.refs.containerRef.scrollLeft = 0
  }

  renderBrand (brand, isActive, withTags) {
    return (
      <Button
        key={brand}
        onClick={() => this.setFilter(brand)}
        as='a' compact size='small'
        color={isActive ? 'blue' : 'black'}
        active={isActive}
        style={{
          border: '1px solid #000'
        }}
      >
        {withTags ? '#' : ''}{brand}
        {isActive && (
          <Icon name='close' style={{ paddingLeft: '10px' }} />
        )}
      </Button>
    )
  }

  render () {
    const { allPosts, brandsList, withTags } = this.props

    const activeFilters = this.props.activeFilters || []

    const popularBrands = brandsList || getPopularDomainNamesFromPosts(allPosts)

    if (activeFilters.length === 0 && (!popularBrands || popularBrands.length < 2)) {
      return <Divider hidden />
    }

    const brands = popularBrands && popularBrands.filter(b => !activeFilters.includes(b))
    const classNameContainer = !brandsList ? 'horizontal-scroll-container' : ''
    const className = !brandsList ? 'horizontal-scroll-content' : ''

    return (
      <div className={classNameContainer}>
        <div className={className} ref='containerRef'>
          {activeFilters && activeFilters.map(b => this.renderBrand(b, true, withTags))}
          {brands && brands.map(b => this.renderBrand(b, false, withTags))}
        </div>
        {/* <Divider hidden /> */}
      </div>
    )
  }
}

export default UserBrandsFilter
