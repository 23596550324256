import React, { Component } from 'react'
import {
  Segment, Grid, Header, Button, Icon, Dimmer, Loader, Divider, Container,
  Image
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import InstagramTextLogo from '../../../images/Instagram_logo_white.svg'
import LookCardWithProductName from '../../Posts/components/LookCardWithProductName'
import { logEvent } from '../../../services/analytics'
import { POSTS_LIST, getPostsListFilterRoute } from '../../../constants/routes'

export const ITEMS_TO_SHOW_COUNT = 4

class LastPosts extends Component {
  componentDidMount() {
    const { siteName } = this.props
    this.setSitenameFilter(siteName)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteName !== this.props.siteName) {
      this.setSitenameFilter(this.props.siteName)
    }
  }

  setSitenameFilter(siteName) {
    this.loadItems(1, siteName)
  }

  loadItems(pageNumber, siteName) {
    this.props.getPostsAction({
      filter: {
        ...(siteName && { dn: siteName }),
        rn: true,
        wrp: true // get only posts with product referral
      },
      page: {
        number: pageNumber,
        size: 20
      }
    })
  }

  renderItem(post) {
    return (
      <Grid.Column
        key={post.id} id={post.id}
        style={{ padding: '1px', overflow: 'hidden' }}
        mobile={16} tablet={8} computer={4}
      >
        <LookCardWithProductName
          key={post.id} id={post.id}
          post={post}
          analytisEventName='lastPostCardImageClicked'
        />
      </Grid.Column>
    )
  }

  renderLoading(isLoading) {
    return (
      <Dimmer active={isLoading}>
        <Loader size='mini'>Loading ...</Loader>
      </Dimmer>
    )
  }

  render() {
    const { items, isLoading, siteName } = this.props

    const itemsCount = siteName ? ITEMS_TO_SHOW_COUNT * 2 : ITEMS_TO_SHOW_COUNT

    return (
      <Container className='no-horizontal-margin'>
        <Divider hidden />
        <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
          <Segment basic textAlign='center'>
            <Header as='h1' inverted>
              Trending Looks {siteName && `From ${siteName}`}
              <Header.Subheader>
                Recent <b>{siteName && `${siteName} `}</b> looks shared on<Image src={InstagramTextLogo} size='tiny' spaced alt='Instagram' />with <b>#seemyfit</b>
              </Header.Subheader>
            </Header>
          </Segment>

          {this.renderLoading(isLoading)}

          <Grid centered>
            {items && items.slice(0, itemsCount).map((item) => this.renderItem(item))}
          </Grid>

          {!isLoading && items && items.length > 0 && (
            <Segment clearing basic>
              <Link to={siteName ? getPostsListFilterRoute(siteName) : POSTS_LIST}>
                <Button
                  as='a' floated='right'
                  onClick={() => logEvent('seeAllProductsClicked')}

                  color='blue'
                >{`More ${siteName ? `${siteName} Looks` : ''}`}
                  <Icon name='right arrow' />
                </Button>
              </Link>
            </Segment>
          )}
        </Segment>
      </Container>
    )
  }
}

export default LastPosts
