
import { DOMAINNAME_WITH_HTTPS } from '../constants/apiroutes'
import { removeIGDomainName } from './instagramUrl'

// ==> PRODUCTS
export const getProductUrlToShare = (product) => {
  return product && (`${DOMAINNAME_WITH_HTTPS}/${product.url}`)
}

// ==> Posts
export const getPostUrlToShare = (post) => {
  const pid = post && removeIGDomainName(post.url_original_post)
  return pid && (`${DOMAINNAME_WITH_HTTPS}/${pid}`)
}

// ==> Users
export const getUsernameUrlToShare = (instagram_username) => {
  return (`${DOMAINNAME_WITH_HTTPS}/${instagram_username ? `@${instagram_username}` : ''}`)
}
