import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from './Home'
import Privacy from './Privacy'
import GDPR from './GDPR'
import FAQPage from './FAQ'
import DownloadAppPage from './DownloadApp'
import AddImage from './Images/AddImage'
import ProductDetail from './Products/ProductDetail'
import Admin from './Admin'
import UserPage from './User/UserPage'
import PostDetailPage from './Posts/PostDetail'
import IgAuth from './IgAuth'
import SignUp from './SignUp'
import UserProfilePage from './User/UserProfile'
import UsersList from './User/UserList'
import ProductsList from './Products/ProductsList'
import AddProduct from './Products/AddProduct'
import PostsList from './Posts/PostsList'

import {
  ROOT, BRANDSFILTERLIST, DETAIL_PAGE,
  TERMSOFUSE, PRIVACY_PATH_LEGACY
} from '../constants/routes'
import isValidHttpUrl from '../shared/utils/url'
import { isInstagramUrl, isPartialInstagramPost } from '../shared/utils/instagramUrl'
import { AuthContextProvider, getFirebaseAuth, useFirebaseAuthState } from '../firebase/firebase'
import { Loader } from 'semantic-ui-react'

export default function Routes() {
  return (
    <AuthContextProvider>
      <Switch>
        <Route path={ROOT} exact component={Home.component} />
        <Route path={BRANDSFILTERLIST} component={Home.component} />

        <Route path={GDPR.path} component={GDPR.component} />
        <Route path={Privacy.path} component={Privacy.component} />
        <Route path={PRIVACY_PATH_LEGACY} component={Privacy.component} />
        <Route path={TERMSOFUSE} component={Privacy.component} />
        <Route path={FAQPage.path} component={FAQPage.component} />
        <Route path={DownloadAppPage.path} component={DownloadAppPage.component} />


        {/* old without auth <Route path={UserProfilePage.path} component={UserProfilePage.component} /> */}
        {/* <Route exact path={UserProfilePage.path}
        render={(props) => withAuthProtection(props, auth, UserProfilePage.component)} /> */}
        {/* not working <AuthenticatedRoute path={UserProfilePage.path} component={UserProfilePage.component} /> */}

        <AuthenticatedRoute path={UserProfilePage.path} exact>
          <UserProfilePage.component />
        </AuthenticatedRoute>
        <Route path={DownloadAppPage.path} component={DownloadAppPage.component} />
        <Route path={SignUp.path} component={SignUp.component} />
        <Route path={IgAuth.path} component={IgAuth.component} />
        <Route path={Admin.path} component={Admin.component} />
        {/* <Route path={AddImage.path} component={AddImage.component} /> */}

        <Route path={PostsList.path} component={PostsList.component} />
        <Route path={UsersList.path} component={UsersList.component} />
        {/* Legacy route */}
        <Route path='/members' component={UsersList.component} />

        <Route path={AddProduct.path} component={AddProduct.component} />
        <Route path={ProductsList.path} component={ProductsList.component} />

        <Route path={UserPage.path} component={UserPage.component} />

        <Route path={DETAIL_PAGE} component={DetailPageWrapperComponent} />

        {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
        <Route component={Home.component} />
      </Switch>
    </AuthContextProvider>
  )
}

const DetailPageWrapperComponent = (props) => {
  const path = props && props.location && props.location.pathname
  const param = path && path.substr(path.indexOf('/') + 1)

  const isUrl = isValidHttpUrl(param)

  if (isUrl) {
    // true if /{param} contains instagram url
    const isIgUrl = isInstagramUrl(param)

    if (isIgUrl) {
      return <PostDetailPage.component {...props} />
    } else {
      return <ProductDetail.component {...props} />
    }
  }

  const isPartialIgUrl = isPartialInstagramPost(param)
  if (isPartialIgUrl) {
    return <PostDetailPage.component {...props} />
  }

  return <UserPage.component {...props} />
}

const defaultRedirectForProtectedRoutes = SignUp.path
// const withAuthProtection = (props, auth, WrappedComponent, redirectPath = defaultRedirectForProtectedRoutes) => {


//   // console.log("withAuthProtection props", props)
//   // console.log("withAuthProtection user", user)
//   // console.log("withAuthProtection auth.currentUser", auth.currentUser)
//   // console.log("withAuthProtection auth.authStateSubscription", auth.authStateSubscription)
//   const { history } = props
//   if (!user && !loading) {
//     // no auth at the beginning of the app, redirect them to 
//     return history.push(redirectPath)
//   }
//   return <WrappedComponent {...props} />
// }

const RenderLoading = () => {
  return <Loader active />
}

const AuthenticatedRoute = ({ ...props }) => {
  const { isAuthenticated, loading, userPresent } = useFirebaseAuthState()
  console.log(`AuthenticatedRoute: ${isAuthenticated}`)
  console.log(`userPresent: ${userPresent}`)
  return loading || !userPresent ? RenderLoading() : (
    <Route
      {...props}>
      {isAuthenticated ? props.children : <Redirect to={defaultRedirectForProtectedRoutes} />}
    </Route>


    // OLD   not working
    // <Route
    //   {...props}
    //   render={routeProps => {
    //     (isAuthenticated) ? <C {...routeProps} /> : <Redirect to={defaultRedirectForProtectedRoutes} />
    //   }
    //   }
    // />
  )
}

// const withAuthProtection2 = redirectPath => WrappedComponent => {
//   class WithAuthProtection extends React.Component {
//     componentDidMount() {
//       // use history from parent.
//       const { history } = this.props;
//       if (!firebase.auth().currentUser) {
//         // no auth at the beginning of the app, redirect them to login.
//         return history.push(redirectPath)
//       }
//     }
//     componentWillReceiveProps(nextProps) {
//       const { me, history } = this.props;
//       const { me: nextMe } = nextProps;
//       if (me && !nextMe) {
//         // this case is a must,
//         // if user stay at auth route while they signing out
//         // we must take them to login again immediately.
//         history.push(redirectPath)
//       }
//     }
//     render() {
//       const { me } = this.props;
//       if (!me) {
//         // don't render anything if no auth
//         return null
//       }
//       return <WrappedComponent {...this.props} />
//     }
//   }

//   return WithAuthProtection
// }
