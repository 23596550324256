import { doc, getDoc } from 'firebase/firestore';
import { db, FIREBASE_USERS_COLLECTION } from '../firebase';

export default async (uid) => {
    // console.log("get firebase user with uid: ", uid)
    const docRef = doc(db, FIREBASE_USERS_COLLECTION, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap && docSnap.exists && docSnap.exists()) {
        return docSnap.data()
    }
    return null
}