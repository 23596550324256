export const influencersStatus = {
  Nano: 'nano',
  Micro: 'micro',
  Macro: 'macro',
  Mega: 'mega'
}

export function getInfluencerStatusFromUser (user) {
  if (!user) return null

  let status = null
  const followers = user.ig_followed_by_count

  if (followers < 5000) {
    status = influencersStatus.Nano
  } else if (followers < 100000) {
    status = influencersStatus.Micro
  } else if (followers < 1000000) {
    status = influencersStatus.Macro
  } else {
    status = influencersStatus.Mega
  }
  return status
}

export function isBussinessAccount (user) {
  return user && (user.ig_is_business_account === '1' || user.ig_is_business_account === 1)
}

export function isVerifiedAccount (user) {
  return user && (user.ig_is_verified === '1' || user.ig_is_verified === 1)
}

export function getUserInfluencerLabel (user) {
  return !isBussinessAccount(user) ? 'Influencer' : isVerifiedAccount(user) ? 'Brand' : 'Business'
}
