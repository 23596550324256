import React, { useEffect, useContext, useState } from 'react';
import { Grid, Image, Segment, Form, Button, Divider, Icon, Header } from 'semantic-ui-react';
import { logEvent } from '../../../services/analytics';
import { getProduct, updateProduct } from '../../../shared/api/web/products/productWebApi';

import { AppContext } from '../../../shared/context';
import PriceCurrencyInput from '../../Posts/AddPost/PriceCurrencyInput';

const removeSeemyfitFromProductUrl = (productUrl) => {
    let url = productUrl
    if (url && url.includes('https://www.seemyfit.at/')) {
        url = url.replace('https://www.seemyfit.at/', '')
    }

    if (url && url.includes('https://seemyfit.at/')) {
        url = url.replace('https://seemyfit.at/', '')
    }
    return url
}

const ProductSelectorByUrl = (({
    inputLabel,
    compact,
    productSelected: initialProductSelected,
    productPublished: initialProductPublished,
    onProductSelectedChange,
    onProductPublished,
    autoLoadUrl
}, ref) => {
    const [isStep1Loading, setIsStep1Loading] = useState(false);
    const [isStep2Loading, setIsStep2Loading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const [statusText, setStatusText] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [productSelected, setProductSelected] = useState(initialProductSelected);
    const [productPublished, setProductPublished] = useState(initialProductPublished);
    const [missingImage, setMissingImage] = useState(false);

    const [productName, setProductName] = useState('');
    const [productImage, setProductImage] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productCurrency, setProductCurrency] = useState('USD');
    const [productType, setProductType] = useState();
    const [productCategory, setProductCategory] = useState();

    const [productUrl, setProductUrl] = useState(removeSeemyfitFromProductUrl(autoLoadUrl))

    useEffect(() => {
        let isSubscribed = true
        const autoLoadProduct = async () => {
            if (autoLoadUrl) {
                await onLoadProductByUrlFromAutoLoad(autoLoadUrl)
            }
        }

        autoLoadProduct()
        // cancel subscription to useEffect
        return () => (isSubscribed = false)

    }, [autoLoadUrl])

    // useEffect(() => {
    //     console.log("ProductSelectorByUrl useEffect of productSelected:", productSelected);

    //     if (productSelected) {
    //         setProductName(productSelected.name)
    //         setProductImage(productSelected.image_url)
    //         setProductPrice(productSelected.price)
    //         setProductCurrency(productSelected.currency)
    //         setProductType(productSelected.productType)
    //         setProductCategory(productSelected.productCategory)
    //     }

    // }, [productSelected])

    const onLoadProductByUrlFromAutoLoad = async (url) => {
        setProductUrl(url)
        setIsStep1Loading(true)
        setIsSuccess(false)
        setErrorMessage("")
        setProductSelected(null)
        setProductPublished(null)

        const request = {
            filter: {
                url: removeSeemyfitFromProductUrl(url)
            },
        }
        try {
            const response = await getProduct(request, { withOpenGraph: true })
            setProductSelected(response)
            setProductPublished(response)
            setIsStep1Loading(false)
            setIsSuccess(true)
            onProductSelectedChange && onProductSelectedChange(response)
            onProductPublished && onProductPublished(response)
        } catch (e) {
            setIsStep1Loading(false)
            setIsSuccess(false)
            setErrorMessage("Ops we couldn't find your product, please try again or reach out to us.")
            console.log("ProductSelectorByUrl onLoadProductByUrlFromAutoLoad productUrl:", productUrl)
            logEvent("onLoadProductByUrlFromAutoLoadSubmitFailed")
        }
    };

    const onLoadProductByUrlStep1Submit = async () => {
        setIsStep1Loading(true)
        setIsSuccess(false)
        setErrorMessage("")
        setProductSelected(null)
        setProductPublished(null)

        const request = {
            filter: {
                url: removeSeemyfitFromProductUrl(productUrl)
            },
        }
        try {
            const response = await getProduct(request, { withOpenGraph: true })
            setProductSelected(response)
            setIsStep1Loading(false)
            setIsSuccess(true)
            onProductSelectedChange && onProductSelectedChange(response)
        } catch (e) {
            setIsStep1Loading(false)
            setIsSuccess(false)
            setErrorMessage("Ops we couldn't find your product, please try again or reach out to us.")
            console.log("ProductSelectorByUrl onLoadProductByUrlSubmit productUrl:", productUrl)
            logEvent("onLoadProductByUrlStep1SubmitFailed")
        }
    };

    const onAddProductDetailStep2Submit = async () => {
        setIsStep2Loading(true)
        setIsSuccess(null)
        setErrorMessage("")

        let productToUpdate = productSelected

        if (productToUpdate) {
            productToUpdate = {
                name: productName,
                image_url: productImage,
                price: productPrice,
                currency: productCurrency,
                productType: productType && productType.key,
                productCategory: productCategory && productCategory.key,
                ...productToUpdate
            }

            const isProductChanged = (productSelected.name !== productToUpdate.name) ||
                (productSelected.image_url !== productToUpdate.image_url) ||
                (productSelected.price !== productToUpdate.price) ||
                (productSelected.currency !== productToUpdate.currency) ||
                (productSelected.productType !== productToUpdate.productType) ||
                (productSelected.productCategory !== productToUpdate.productCategory)

            if (isProductChanged) {
                const request = {
                    id: productToUpdate.id,
                    name: productToUpdate.name,
                    image_url: productToUpdate.image_url,
                    price: productToUpdate.price,
                    currency: productToUpdate.currency,
                    productType: productToUpdate.productType,
                    productCategory: productToUpdate.productCategory,
                }

                try {
                    const response = await updateProduct(request, { withoutInclude: true })
                    setProductSelected(response)
                    setProductPublished(response)
                    setIsStep2Loading(false)
                    setIsSuccess(true)
                    onProductPublished && onProductPublished(response)
                } catch (e) {
                    setIsStep2Loading(false)
                    setIsSuccess(false)
                    setErrorMessage("Ops we couldn't find your product, please try again or reach out to us.")
                    console.log("ProductSelectorByUrl onLoadProductByUrlSubmit e:", e)
                    logEvent("onLoadProductByUrlStep1SubmitFailed")
                }
            }
            else {
                setIsStep2Loading(false)
                setIsSuccess(true)
                onProductPublished && onProductPublished(productSelected)
            }
        }
    };


    const renderProductSummaryConfirmationStep2Form = () => {
        const product = productSelected
        return (
            <Form
                success={isSuccess}
                error={isSuccess === false}
                warning={statusText === null}
                onSubmit={onAddProductDetailStep2Submit}
                loading={isStep2Loading}
                inverted
            >
                {statusText && this.renderMessage()}
                {product && renderSummaryProduct(product)}

                <Segment basic clearing>
                    <Button
                        type='submit'
                        content='Next'
                        onClick={() => logEvent(`ProductSelectorByUrlStep2Submit`)}
                        color='blue'
                        floated='right'
                    />
                </Segment>
            </Form>)
    }

    const renderProductUrlStep1Input = () => {
        return (
            <Form
                success={isSuccess}
                error={isSuccess === false}
                warning={statusText === null}
                onSubmit={onLoadProductByUrlStep1Submit}
                loading={isStep1Loading}
                inverted
            >
                {statusText && this.renderMessage()}
                <Form.Input
                    label={inputLabel || 'Product URL'}
                    placeholder='eg. https://www.aritzia.com/en/product/crochet-v-neck-sweater/76464.html'
                    name='productUrl'
                    value={productUrl}
                    onChange={(e, { value }) => setProductUrl(value)}
                    loading={isStep1Loading}
                    required
                    action='Search'
                />
                {!productUrl && (
                    <Header.Subheader>
                        <span role='img' aria-label='Note'>⚠️</span> Use the final product url, you can link your commisionable urls right after
                    </Header.Subheader>
                )}
                <Divider hidden />
            </Form>
        )
    }

    const renderSummaryProduct = (product) => {

        const productNameField = productName || (product && product.name)
        const productImageField = productImage || (product && product.image_url)

        const productPriceField = productPrice || (product && product.price)
        const productCurrencyField = productCurrency || (product && product.currency)
        // const productTypeField = productType || (product && product.productType)
        // const productCategoryField = productCategory || (product && product.productCategory)

        // const { missingImage } = this.state
        // const productFoundMissingImage = product
        //     && (missingImage || (!product.image_url || product.image_url === ''))


        const productUrl = product && product.url

        return (
            <Segment inverted>
                <Grid columns={2} relaxed centered>
                    <Grid.Column width={compact ? 2 : 4} style={{ padding: 0 }}>
                        <Image size='medium' bordered rounded
                            src={productImageField}
                            style={{ display: 'flex', alignItems: 'center' }}
                            onError={() => { setMissingImage(true) }}
                        />
                    </Grid.Column>
                    <Grid.Column width={compact ? 8 : 10} style={{ paddingTop: 0 }}>
                        <Header>Verify product detail and price</Header>
                        <Form.Input
                            label='Product Name'
                            placeholder='eg. White Embroidered Cherry Crop Top'
                            name='productName'
                            value={productNameField}
                            onChange={(e, { value }) => setProductName(value)}
                            loading={isStep2Loading}
                            required
                            fluid
                            size='mini'
                            style={{ maxWidth: "300px" }}
                        />
                        {!productPriceField && (
                            <small style={{ fontSize: "9px" }}>Get the price from the <a href={productUrl} target='_blank' rel='noopener noreferrer' onClick={() => logEvent('productLinkGetPriceMessageClicked')}>product page here <Icon name='external alternate'></Icon></a></small>)
                        }
                        <PriceCurrencyInput
                            onPriceChange={(price) => setProductPrice(price)}
                            onCurrencyChange={(currency) => setProductCurrency(currency)}
                            priceFieldName='productPrice'
                            currencyFieldName='productCurrency'
                            productPrice={productPriceField}
                            productCurrency={productCurrencyField}
                        />
                        {/* Removed from now
                    <Header as='h5' inverted>
                        <Header.Subheader>Add product type and category to help online shoppers easily find the product</Header.Subheader>
                    </Header>
                    <Form.Group columns={2}>
                        {this.renderDropdown('productType', 'Product Type', producTypeOptions, productTypeField)}
                        {this.renderDropdown('productCategory', 'Category', producTypeOptions, productCategoryField)}
                    </Form.Group> */}
                    </Grid.Column>
                </Grid>
            </Segment >
        )
    }

    const productFoundStep2Reached = !!productSelected

    return (
        <div>
            {renderProductUrlStep1Input()}
            {productFoundStep2Reached && renderProductSummaryConfirmationStep2Form()}
        </div>
    );
});

export default ProductSelectorByUrl;


// Product categories
// import AccessoriesImg from '../../../images/productIcons/belt.svg'
// import ActivewearImg from '../../../images/productIcons/men-tracksuit.svg'
// import BagImg from '../../../images/productIcons/bag.svg'
// import BeachwearImg from '../../../images/productIcons/bikini.svg'
// import CoatsImg from '../../../images/productIcons/women-coat.svg'
// import DressesImg from '../../../images/productIcons/women-drees.svg'
// import HosieryImg from '../../../images/productIcons/long-socks.svg'
// import JacketsImg from '../../../images/productIcons/bag.svg'
// import JeansImg from '../../../images/productIcons/bag.svg'
// import JewelryImg from '../../../images/productIcons/bag.svg'
// import JumpsuitsImg from '../../../images/productIcons/belt.svg'
// import KnitwearImg from '../../../images/productIcons/belt.svg'
// import LifestyleImg from '../../../images/productIcons/belt.svg'
// import LoungewearImg from '../../../images/productIcons/belt.svg'
// import PantsImg from '../../../images/productIcons/belt.svg'
// import ShoesImg from '../../../images/productIcons/belt.svg'
// import ShortsImg from '../../../images/productIcons/belt.svg'
// import SkitsImg from '../../../images/productIcons/belt.svg'
// import TopsImg from '../../../images/productIcons/belt.svg'

// const producTypeOptions = [
//     { key: 1, text: 'Accessories', image: { avatar: true, src: AccessoriesImg } },
//     { key: 2, text: 'Activewear', image: { avatar: true, src: ActivewearImg } },
//     { key: 3, text: 'Bags', image: { avatar: true, src: BagImg } },
//     { key: 4, text: 'Beachwear', image: { avatar: true, src: BeachwearImg } },
//     { key: 5, text: 'Coats', image: { avatar: true, src: CoatsImg } },
//     { key: 6, text: 'Dresses', image: { avatar: true, src: DressesImg } },
//     { key: 7, text: 'Hosiery', image: { avatar: true, src: HosieryImg } },
//     { key: 8, text: 'Jackets', image: { avatar: true, src: JacketsImg } },
//     { key: 9, text: 'Jeans', image: { avatar: true, src: JeansImg } },
//     { key: 10, text: 'Jewelry', image: { avatar: true, src: JewelryImg } },
//     { key: 11, text: 'Jumpsuits', image: { avatar: true, src: JumpsuitsImg } },
//     { key: 12, text: 'Knitwear', image: { avatar: true, src: KnitwearImg } },
//     { key: 13, text: 'Lifestyle', image: { avatar: true, src: LifestyleImg } },
//     { key: 14, text: 'Loungewear', image: { avatar: true, src: LoungewearImg } },
//     { key: 15, text: 'Pants', image: { avatar: true, src: PantsImg } },
//     { key: 16, text: 'Shoes', image: { avatar: true, src: ShoesImg } },
//     { key: 17, text: 'Shorts', image: { avatar: true, src: ShortsImg } },
//     { key: 18, text: 'Skits', image: { avatar: true, src: SkitsImg } },
//     { key: 19, text: 'Tops', image: { avatar: true, src: TopsImg } },
// ]