export function getPostAttributes(url, urlOriginalPost, commissionLink, promocode, igPost) {
  return {
    url,
    url_original_post: urlOriginalPost,
    commission_link: commissionLink,
    promocode: promocode,
    post_caption: igPost.post_caption,
    post_is_video: igPost.post_is_video,
    post_has_audio: igPost.post_has_audio,
    post_video_view_count: igPost.post_video_view_count,
    post_video_url: igPost.post_video_url,
    post_product_type: igPost.post_product_type,
    post_video_duration: igPost.post_video_duration,
    post_video_thumbnail_src: igPost.post_video_thumbnail_src,
    ig_media_owner_username: igPost.ig_media_owner_username,
    ig_media_timestamp: igPost.ig_media_timestamp,
    ig_media_id: igPost.ig_media_id,
    ig_media_type: igPost.ig_media_type,
  }
}
