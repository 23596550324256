import { path } from 'ramda'
import { serialise } from 'kitsu-core'
import { getIgUserMedia } from '../../services/instagramBasicDisplayApi';


export const getIGMedia = async (request) => {
    // console.log("getIGMedia getIGMedia request:", request)
    let { code, shortToken, next } = request
    // TODO
    // if (!shortToken) {
    //   const tokenResponse = await getShortAccessToken(code)
    //   if (tokenResponse) {
    //     shortToken = tokenResponse.access_token
    //   }
    // }
    const userIdResponse = await getIgUserMedia(shortToken, next)
    if (userIdResponse) {
        // TODO update the token and save it
        //          const longTokenResponse = await getLongToken(shortToken)
        // console.log("useIgMedia userIdResponse", userIdResponse)
        // navigate(Routes.MyProfileScreen)
        // const userProfileMedia = await getIgUserMedia(shortToken)
        // console.log("userProfileMedia", userProfileMedia)
        // const userProfile = await getIgUserImageProfile(shortToken, userIdResponse.id)
        // console.log("userProfile", userProfile)
        // await this.updateToken(userIdResponse, longTokenResponse)
        // this.props.history.push({ pathname: getUsernameRoute(userIdResponse.username) })
    }

    return {
        ...userIdResponse,
        shortToken
    }
};