import React, { useState } from 'react'
import {
  Form, Message, Header, Icon, Button, Loader
} from 'semantic-ui-react'
import SelectorButtons from './SelectorButtons'


const BodySizeSelector = ({ widthLabel, heightLabel, onHeightSelected, onWidthSelected,
  selectedWidthDefault, selectedHeightDefault }) => {
  return (
    <div>
      <Header as='h4' inverted>{widthLabel}</Header>
      <SelectorButtons
        firstLabel='Fit'
        secondLabel='Straight'
        thirdLabel='Some Curves'
        selectedIndexDefault={selectedWidthDefault}
        onSelected={onWidthSelected}
      />
      <Header as='h4' inverted>{heightLabel}</Header>
      <SelectorButtons
        firstLabel='Petite'
        secondLabel='Average'
        thirdLabel='Tall'
        selectedIndexDefault={selectedHeightDefault}
        onSelected={onHeightSelected}
      />
    </div>
  )
}

export default BodySizeSelector
