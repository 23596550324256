import React, { useContext, useState, useEffect } from 'react';
import {
    Form, Dropdown, Header, Icon, Divider, Checkbox, Input, TextArea
} from 'semantic-ui-react';

import { AppContext } from '../../../../shared/context';
import { saleConditionsOptions } from '../../../../shared/utils/productSaleCondition';
import PriceCurrencyInput from '../../../Posts/AddPost/PriceCurrencyInput';
import ProductRating from '../../../Posts/AddPost/ProductRating';
import ProductTrueFit from '../../../Posts/AddPost/ProductTrueFit';

const ProductreferralInputReviewFields = (({
    productTrueFit,
    forSale,
    saleCondition,
    salePrice,
    saleCurrency,
    saleDescription,
    saleEmail,
    saleIGUsername,
    productRating,
    productCommentReview,
    setProductTrueFit,
    setForSale,
    setSalePrice,
    setSaleCurrency,
    setSaleCondition,
    setSaleDescription,
    setSaleEmail,
    setSaleIGUsername,
    setProductRating,
    setProductCommentReview,
    productName
}, ref) => {
    return (
        <Form inverted>
            <Form.Field>
                <ProductRating
                    onRate={(rating) => setProductRating(rating)}
                />
            </Form.Field>
            <Form.Field>
                <Header as='h5' inverted>
                    <Header.Subheader>and how would your rate the product true fit?</Header.Subheader>
                </Header>
                <ProductTrueFit
                    value={productTrueFit}
                    onChange={(value) => setProductTrueFit(value)}
                />
            </Form.Field>
            <Form.Field style={{ marginTop: 10 }}>
                <br />
                <TextArea
                    placeholder={`What did you like about ${productName}?`}
                    name='productCommentReview'
                    value={productCommentReview}
                    onChange={(e, { value }) => setProductCommentReview(value)}
                    maxLength="1000"
                />
            </Form.Field>
            <Divider hidden></Divider>
            <Divider hidden></Divider>
            <Form.Field>
                <Checkbox
                    label='I have the same for sale'
                    name='forSale'
                    onChange={() => setForSale(!forSale)}
                    checked={forSale} />
            </Form.Field>
            {forSale && (
                <>
                    <Form.Group>
                        <Form.Field style={{ marginTop: 10, marginBottom: 0 }}>
                            <Dropdown
                                name='saleCondition'
                                placeholder={saleCondition || 'Product Condition'}
                                defaultValue={saleCondition}
                                options={saleConditionsOptions}
                                onChange={(e, { value }) => setSaleCondition(value)}
                                size='mini' compact
                                button
                            />
                        </Form.Field>
                        <Form.Field style={{ marginTop: 10 }}>
                            <PriceCurrencyInput
                                onPriceChange={(price) => setSalePrice(price)}
                                onCurrencyChange={(currency) => setSaleCurrency(currency)}
                                priceFieldName='salePrice'
                                currencyFieldName='saleCurrency'
                                productPrice={salePrice}
                                productCurrency={saleCurrency}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Field style={{ marginTop: 10, marginBottom: 0 }}>
                        <Header as='h5' inverted>
                            <Header.Subheader>Interested shoppers can reach out to you either by email or Instagram Messenger </Header.Subheader>
                        </Header>
                    </Form.Field>
                    <Form.Group>
                        <Form.Field style={{ marginTop: 10 }}>
                            <Input iconPosition='left' placeholder='Email' size='mini'
                                value={saleEmail}
                                name='saleEmail'
                                onChange={(e, { value }) => setSaleEmail(value)}
                            >
                                <Icon name='at' />
                                <input />
                            </Input>
                        </Form.Field>
                        <Form.Field style={{ marginTop: 10 }}>
                            <Input iconPosition='left' placeholder='Instagram username' size='mini'
                                value={saleIGUsername}
                                name='saleIGUsername'
                                onChange={(e, { value }) => setSaleIGUsername(value)}
                            >
                                <Icon name='instagram' />
                                <input />
                            </Input>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field style={{ marginTop: 10 }}>
                        <TextArea
                            placeholder='Anything you want to add?'
                            name='saleDescription'
                            value={saleDescription}
                            onChange={(e, { value }) => setSaleDescription(value)}
                            maxLength="1000"
                        />
                    </Form.Field>
                </>
            )}
        </Form>
    )
})

export default ProductreferralInputReviewFields;