import React, { useContext, useState } from 'react';
import { Header, Input } from 'semantic-ui-react';

import { AppContext } from '../../../../shared/context';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';


const SelectSocialProfilesTab = (({
}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [tiktok, setTiktok] = useState((userContext && userContext.tiktok) || '');
  const [youtube, setYoutube] = useState((userContext && userContext.youtube) || '');
  const [pinterest, setPinterest] = useState((userContext && userContext.pinterest) || '');
  const [twitter, setTwitter] = useState((userContext && userContext.twitter) || '');
  const [snap, setSnap] = useState((userContext && userContext.snap) || '');
  const [facebook, setFacebook] = useState((userContext && userContext.facebook) || '');

  const onSave = async () => {
    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = {
      tiktok,
      youtube,
      pinterest,
      facebook,
      twitter,
      snap
    }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };

  return (
    <div>
      {renderSaving({ isSaving })}
      <div>
        <Header as='h4' inverted>
          Promote your other accounts
        </Header>
        <Input
          label="Tiktok"
          placeholder='Eg. @seemyfit'
          value={tiktok}
          onChange={(e, { value }) => setTiktok(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Input
          label="Youtube"
          placeholder='Eg. https://www.youtube.com/@seemyfit'
          value={youtube}
          onChange={(e, { value }) => setYoutube(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Input
          label="Twitter"
          placeholder='Eg. @seemyfit'
          value={twitter}
          onChange={(e, { value }) => setTwitter(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Input
          label="Pinterest"
          placeholder='Eg. https://www.pinterest.ca/seemyfit'
          value={pinterest}
          onChange={(e, { value }) => setPinterest(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Input
          label="Snap"
          placeholder='Eg. @seemyfit'
          value={snap}
          onChange={(e, { value }) => setSnap(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Input
          label="Facebook"
          placeholder='Eg. @seemyfit'
          value={facebook}
          onChange={(e, { value }) => setFacebook(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      {renderSave(onSave, { isSuccess, errorMessage })}
    </div>
  );
});

export default SelectSocialProfilesTab;