
import React from 'react'
import {
  Grid, Header
} from 'semantic-ui-react'
import { Slider } from 'react-semantic-ui-range'

const ProductTrueFit = (props) => (
  <Grid >
    <Grid.Row style={{ paddingBottom: 0 }}>
      <Grid.Column width={16}>
        <Slider
          inverted discrete color={'blue'}
          value={props.productTrueFit}
          style={{ trackFill: { backgroundColor: '#fff' } }}
          settings={{
            start: 5,
            min: 0,
            max: 10,
            step: 1,
            onChange: props.onChange
          }}
          {...(props.readOnly ? { disabled: true } : {})}
        />
      </Grid.Column>
    </Grid.Row>
    {
      !props.compact && (
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width={3} textAlign='left'>
            <Header as='h6'>Too Small</Header>
          </Grid.Column>
          <Grid.Column width={3} textAlign='center'>
            <Header as='h6'>Bit Small</Header>
          </Grid.Column>
          <Grid.Column width={4} textAlign='center'>
            <Header as='h6'>True To size</Header>
          </Grid.Column>
          <Grid.Column width={3} textAlign='right'>
            <Header as='h6'>Bit Big</Header>
          </Grid.Column>
          <Grid.Column width={3} textAlign='right'>
            <Header as='h6'>Too Big</Header>
          </Grid.Column>
        </Grid.Row>
      )
    }
    {
      props.compact && (
        <Grid.Row style={{ padding: 0, margin: 0 }}>
          <Grid.Column width={5} textAlign='left'>
            <Header as='h6'>Too Small</Header>
          </Grid.Column>
          <Grid.Column width={6} textAlign='center'>
            <Header as='h6'>True To size</Header>
          </Grid.Column>
          <Grid.Column width={5} textAlign='right'>
            <Header as='h6'>Too Big</Header>
          </Grid.Column>
        </Grid.Row>
      )
    }
  </Grid >
)

export default ProductTrueFit
