// TODO this is a new block moved to shared package; use this in the app too
export const populateFirebaseUserFromWeb = (userWebData) => (userWebData && {
    webUser: {
        instagram_avatar: userWebData.instagram_avatar,
        ig_follow_count: userWebData.ig_follow_count,
        id: userWebData.id,
        ig_is_verified: userWebData.ig_is_verified,
        InfluencerSubStatus: userWebData.InfluencerSubStatus,
        isMacro: userWebData.isMacro,
        ig_account_type: userWebData.ig_account_type,
        ig_user_id: userWebData.ig_user_id,
        name: userWebData.id,
        ig_is_business_account: userWebData.ig_is_business_account,
        InfluencerStatus: userWebData.InfluencerStatus,
        followersCount: userWebData.followersCount,
        ig_followed_by_count: userWebData.ig_followed_by_count,
        looksShared: userWebData.looksShared,
        isMega: userWebData.isMega,
        brandsCount: userWebData.brandsCount,
        brands: userWebData.brands,
        instagram_username: userWebData.instagram_username
    }
})

export const populateWebUserFromFirebase = (firebaseUse) => (firebaseUse && {
    firebase_user_id: firebaseUse.uid,
    tip_urls: firebaseUse.tipUrls,
    styles: firebaseUse.styles,
    influenced_by: firebaseUse.influencedBy,
    tiktok: firebaseUse.tiktok,
    youtube: firebaseUse.youtube,
    pinterest: firebaseUse.pinterest,
    facebook: firebaseUse.facebook,
    twitter: firebaseUse.twitter,
    snap: firebaseUse.snap,
    dress_size: firebaseUse.dressSize,
    shoe_size: firebaseUse.shoeSize,
    top_size: firebaseUse.topSize,
    bottom_size: firebaseUse.bottomSize,
    promocode: firebaseUse.promocode,
    website: firebaseUse.website,
    fit_challenges: firebaseUse.fitChallenges,
    city_location: firebaseUse.cityLocation,
    body_properties: firebaseUse.bodyProperties
})


