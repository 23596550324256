import { put } from 'redux-saga/effects'

// import { sessionExpired } from './LoginSagas'
import { loadIGFeedBy } from '../../../shared/services/instagramApi'
import { getSiteNameFromUrl } from '../../../shared/utils/product'

export function * getIGUser ({ api, Actions, onSuccessAction }, { request }) {
  const { username } = request
  try {
    const response = yield loadIGFeedBy(username)
    // DEBUG const response = {}
    if (response) {
      const igUser = (response && response.graphql && response.graphql.user)
      // const igUser = {
      //     username: 'test 3',
      //     profile_pic_url_hd: 'igUser.profile_pic_url_hd',
      //     external_url: 'igUser.profile_pic_url_hd',
      //     full_name: 'test 3',
      //     is_business_account: 1,
      //     is_verified: 1,
      //     edge_followed_by: { count: 12 },
      //     edge_follow: { count: 13 },
      // }

      if (igUser) {
        let data = igUser && {
          id: request.user.id,
          instagram_username: igUser.username,
          instagram_avatar: igUser.profile_pic_url_hd,
          name: igUser.full_name,
          ig_followed_by_count: igUser.edge_followed_by && igUser.edge_followed_by.count,
          ig_follow_count: igUser.edge_follow && igUser.edge_follow.count
        }

        if (igUser.hasOwnProperty('is_business_account')) {
          data = {
            ...data,
            ig_is_business_account: igUser.is_business_account ? 1 : 0
          }
        }

        if (igUser.hasOwnProperty('is_verified')) {
          data = {
            ...data,
            ig_is_verified: igUser.is_verified ? 1 : 0
          }
        }

        if (igUser.external_url && !igUser.external_url.includes('https://seemyfit.at/')) {
          let website = igUser.external_url
          if (website && website.length > 255) {
            website = getSiteNameFromUrl(igUser.external_url)
          }
          data = { ...data, website }
        }

        if (onSuccessAction) {
          yield put(onSuccessAction(data))
        }
      }

      // do data conversion here if needed
      yield put(Actions.success(request))
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }

    yield put(Actions.failure())
  }
}
