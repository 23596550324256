import React, { useContext, useState } from 'react';
import { Header, Input } from 'semantic-ui-react';

import { AppContext } from '../../../../shared/context';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';


const SelectTipJarTab = (({
}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const tipUrls = (userContext && userContext.tipUrls) || []
  const defaultTip1 = tipUrls[0]
  const defaultTip2 = tipUrls[1]
  const defaultTip3 = tipUrls[2]
  const [tipUrl1, setTipUrl1] = useState(defaultTip1 || '');
  const [tipUrl2, setTipUrl2] = useState(defaultTip2 || '');
  const [tipUrl3, setTipUrl3] = useState(defaultTip3 || '');

  const onSave = async () => {
    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = {
      tipUrls: [tipUrl1, tipUrl2, tipUrl3]
    }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };

  const renderUrlInputWithLabel = (label, placeholder, value, setValue) => {
    return (
      <div>
        <Input
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={(e, { value }) => setValue(value)}
          maxLength={200}
        />
      </div>
    )
  }

  return (
    <div>
      {renderSaving({ isSaving })}
      <Header as='h4' inverted>
        Add urls where fans can send you tips e.g. Paypal, Cash App, Vennmo
      </Header>
      {renderUrlInputWithLabel("Paypal", 'Eg. https://paypal.me/username', tipUrl1, setTipUrl1)}
      <br />
      {renderUrlInputWithLabel("Cash Tag", 'Eg. https://cash.app/$username', tipUrl2, setTipUrl2)}
      <br />
      {renderUrlInputWithLabel("Venmo", 'Eg. https://venmo.com/username', tipUrl3, setTipUrl3)}

      {renderSave(onSave, { isSuccess, errorMessage })}
    </div>
  );
});

export default SelectTipJarTab;