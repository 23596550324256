import React, { Component } from 'react'
import {
  Image, Divider, Header, Grid, Label, Segment, Button, Icon
} from 'semantic-ui-react'

import { getSiteNameFromUrl } from '../../../shared/utils/product'
import { getUserFromResponse, getProductreferralsFromResponse, getPostFromResponse } from '../../../shared/utils/post'
import { logEvent } from '../../../services/analytics'
import { renderUserAvatarWithFallback } from '../../User/components/UserCard'

class ProductCard extends Component {
  state = {
    avatarFailedToLoad: false
  }

  renderThumblail(productreferral, index) {
    const post = productreferral && getPostFromResponse(productreferral)
    const user = getUserFromResponse(post)

    const promocode = post && post.promocode
    const imgLabel = promocode && { as: 'span', color: 'red', corner: 'right', icon: 'tags', size: 'mini' }
    return (
      <div style={{ float: 'left', margin: '10px' }} key={index}>
        <Image src={post.url} size='tiny' rounded key={index} label={imgLabel} />

        {user && (
          <div style={{
            position: 'relative',
            top: '0px',
            right: '8px',
            marginTop: '-30px'
          }}
          >
            <Header as='h3'>
              {user && renderUserAvatarWithFallback(user, this.state.avatarFailedToLoad, () => this.setState({ avatarFailedToLoad: true }))}
            </Header>
          </div>
        )}

      </div>
    )
  }

  renderPrice(product, siteName, analyticsVendorLinkEventName, isSoldOut) {
    return (
      <div style={{ height: '15px' }}>
        {(product.price || isSoldOut) && (
          <div style={{ float: 'left' }}>
            <Header sub inverted red>{isSoldOut ? 'SOLD OUT' : 'Price'}</Header>
            {product.price && <span>{product.price} {product.currency}</span>}
          </div>
        )}
        <Button
          as='a'
          href={`${product.url}`}
          floated='right'
          target='_blank' rel='noopener noreferrer'
          onClick={() => logEvent(analyticsVendorLinkEventName)}
          icon labelPosition='right'
          compact
          primary
          size='mini'
        ><Icon name='external alternate' />Find it on {siteName}
        </Button>
      </div>
    )
  }

  render() {
    const { product, withoutLooks, analyticsVendorLinkEventName } = this.props
    const productreferrals = getProductreferralsFromResponse(product) || []

    const siteName = getSiteNameFromUrl(product.url)
    const isSoldOut = (product && product.productAvailability) === 'SoldOut'

    return (
      <div style={{ minHeight: '100px' }}>
        <a href={`/${product.url}`}>
          <Grid.Column id={product.id} key={product.id} style={{}}>
            <Segment style={{
              padding: '0px',
              margin: '2px',
              minHeight: '100px',
              maxHeight: '430px',
              overflow: 'hidden',
              border: '2px solid #000',
              borderRadius: '20px',
            }} inverted id={product.id}>
              <div className='ui image'>
                <img src={product.image_url} alt={product.name} />
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: -1,
                  width: '100%',
                  left: 0,
                  right: 0,
                  margin: 'auto',
                  padding: '10px',
                  background: 'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.55),rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))',
                }}>
                {analyticsVendorLinkEventName && this.renderPrice(product, siteName, analyticsVendorLinkEventName, isSoldOut)}
                <div className="ui center aligned" style={{ marginBottom: '10px' }}>
                  {/* <Image.Group size='tiny'> */}
                  {!withoutLooks && productreferrals && productreferrals.length > 0 &&
                    productreferrals.slice(0, 3).map((p, index) => this.renderThumblail(p, index))}
                  {/* </Image.Group> */}
                </div>
                <Divider clearing />
                <div style={{ padding: '5px' }}>
                  <Header as='h4' style={{ color: 'rgb(18, 18, 18)' }}> {product.name}</Header>
                </div>
              </div>
              <Label color='black' attached='top right' style={{ background: '#000' }}>
                {siteName}
              </Label>
              {isSoldOut &&
                <Label color='red' attached='top left' style={{ background: '#000' }}>
                  SOLD OUT
                </Label>}
            </Segment>
          </Grid.Column>
        </a>
      </div>
    )
  }
}

export default ProductCard
