import React, { Component } from 'react'
import {
  Label,
  Image,
  Icon,
  Header
} from 'semantic-ui-react'
import { logEvent } from '../../../services/analytics'
import { getSiteNameFromUrl } from '../../../shared/utils/product'

import PlayIcon from '../../../images/InstagramPlay.svg'
import { getProductFromResponse, getProductreferralsFromResponse, getUserFromResponse } from '../../../shared/utils/post'
import { renderUserAvatarWithFallback, renderUsernameVerifiedBadge } from '../../User/components/UserCard'
import { removeIGDomainName } from '../../../shared/utils/instagramUrl'

class LookCardWithProductName extends Component {
  state = {
    avatarFailedToLoad: false,
    fluid: true
  }

  renderAvatar(user) {
    return (
      <Header as='h5' textAlign='center' icon inverted>
        {user && renderUserAvatarWithFallback(user, this.state.avatarFailedToLoad, () => this.setState({ avatarFailedToLoad: true }))}
        {user && renderUsernameVerifiedBadge(user)}
      </Header>
    )
  }

  renderProducts(post) {
    const productreferrals = getProductreferralsFromResponse(post) || []

    const productReferral1 = productreferrals && productreferrals[0]
    const productReferral2 = productreferrals && productreferrals[1]
    const productReferral3 = productreferrals && productreferrals[2]

    const product1 = productReferral1 && getProductFromResponse(productReferral1)
    const product2 = productReferral2 && getProductFromResponse(productReferral2)
    const product3 = productReferral3 && getProductFromResponse(productReferral3)

    const siteName = product1 && getSiteNameFromUrl(product1.url)
    const siteName2 = product2 && getSiteNameFromUrl(product2.url)
    const siteName3 = product3 && getSiteNameFromUrl(product3.url)

    return (
      <>
        <div style={{
          position: 'absolute',
          top: '8px',
          right: '5px',
          textAlign: 'right'
        }}
        >
          {siteName && this.renderProductSiteName(siteName, productReferral1)}
          {product2 && siteName2 !== siteName && this.renderProductSiteName(siteName2, productReferral2)}
          {product3 && siteName3 !== siteName2 && this.renderProductSiteName(siteName3, productReferral3)}
        </div>
        <div style={{
          position: 'absolute',
          top: '8px',
          left: '5px'
        }}>
          {productreferrals && productreferrals.length > 0 &&
            productreferrals.slice(0, 3).map(pr => this.renderProductImage(pr))}
        </div>
      </>
    )
  }

  renderProductSiteName(siteName, productReferral) {
    const promocode = productReferral && productReferral.promocode
    return (
      <div>
        <Label.Group size='mini'>
          <Label color='black'>{siteName}
            {promocode && (
              <Label.Detail color='red' style={{ color: 'red' }}>
                <Icon name='tags' /> {promocode}
              </Label.Detail>)}
          </Label>
        </Label.Group>
      </div>
    )
  }

  renderProductImage(productreferral) {
    const product = getProductFromResponse(productreferral) || {}
    const promocode = productreferral.promocode
    const size = productreferral.size
    const color = productreferral.color
    const imgLabel = promocode && { as: 'span', color: 'red', corner: 'right', icon: 'tags', size: 'mini' }

    let dataAttrs = {
      'data-pr-id': productreferral && productreferral.id,
      'data-product-id': product && product.id
    };
    return (
      <div>
        <Image key={product.id} id={product.id} {...dataAttrs}
          src={product.image_url} size='mini' rounded bordered
          label={imgLabel}
          style={{
            width: '5em',
            borderRadius: '4px',
            border: '1px solid #FFF',
            marginBottom: '1px'
          }}
        />
        {size && (<div style={{ position: 'relative', bottom: '22px', left: '2px', height: '0' }}>
          <Label size='mini' color={color || 'grey'}>{size}</Label>
        </div>)
        }
      </div>
    )
  }

  render() {
    const { post, withoutAvatar, analytisEventName, fluid } = this.props
    const user = getUserFromResponse(post) || this.props.user || {}

    const postUrl = post && removeIGDomainName(post.url_original_post)
    const url = postUrl || post.id
    // const moreProductsCount = (post3 ? 2 : post2 ? 1 : null)

    return (
      <a href={`/${url}`}>
        <div
          onClick={() => logEvent(analytisEventName)}
          size='small'
          style={{
            backgroundImage: `url(${post.url})`,
            backgroundSize: 'cover',
            height: fluid ? '100%' : '250px',
            backgroundPosition: 'center'
          }}
        />
        {post && this.renderProducts(post)}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            left: 0,
            right: 0,
            margin: 'auto',
            padding: '0px',
            background: 'linear-gradient(rgba(0, 0, 0, 0),  rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.8))',
          }}>
          {(!withoutAvatar && user.instagram_avatar) && this.renderAvatar(user)}
          {/* 
          Product Name
          <Divider clearing />
          {product && (
            <div style={{ padding: '5px' }}>
              <Header as='h4' style={{ color: 'rgb(18, 18, 18)' }}>{product.name} {moreProductsCount && `+${moreProductsCount}`}</Header>
            </div>
          )} */}
        </div>

        {post && post.post_is_video === '1' && (
          <div
            onClick={() => logEvent(analytisEventName)}
            className='post-play-icon'
          >
            <Image src={PlayIcon} size='tiny' spaced alt='Play Instagram Video' />
          </div>
        )}
      </a>
    )
  }
}

export default LookCardWithProductName
