export function getUsernameFromInstagramUrl (url) {
  return url && url.replace('https://www.instagram.com/', '')
    .replace('https://instagram.com/', '')
    .replace('https://instagr.am/', '')
    .replace(/\/$/, '')
}

export function isInstagramUrl (url) {
  return url && (url.indexOf('https://www.instagram.com') !== -1 ||
    url.indexOf('https://instagram.com') !== -1 ||
    url.indexOf('https://instagr.am') !== -1 ||
    url.indexOf('https:/www.instagram.com') !== -1 || // used in pinterest
    url.indexOf('https:/instagram.com') !== -1) // used in pinterest
}

export function isPartialInstagramPost (url) {
  return (url.indexOf('p/') !== -1) || (url.indexOf('reel/') !== -1) || (url.indexOf('tv/') !== -1)
}

export function removeIGDomainName (url) {
  return url && url
    .replace('https://www.instagram.com/', '').replace('https://instagram.com/', '')
    .replace('https://www.instagram.com', '').replace('https://instagr.am', '')
    .replace('https:/www.instagram.com', '').replace('https:/instagram.com', '')
}
