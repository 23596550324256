import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import productsListActions from '../../../store/redux/products/ProductsListRedux'

import ProductsList from './ProductsList'

export default connect(
  (state, ownProps) => ({
    history: ownProps.history,
    // currentUser: state.login.currentUser,
    items: state.productsState.items.asMutable(),
    filterLoaded: state.productsState.filterLoaded,
    pageNumberLoaded: state.productsState.pageNumberLoaded,
    isLoading: state.productsState.fetching,
    isNextPageLoading: state.productsState.isNextPageLoading,
    lastPageReached: state.productsState.lastPageReached,
    statusText: state.productsState.statusText
  }),
  dispatch => ({
    getProductsAction: bindActionCreators(productsListActions.request, dispatch)
  })
)(ProductsList)
