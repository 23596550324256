import React, { Component } from 'react'
import {
  Image, Divider, Header, Grid, Icon, Button, Segment, Container, Label,
  Popup, Message, List, Accordion
} from 'semantic-ui-react'

import { logEvent } from '../../../services/analytics'
import { renderUserAvatarWithFallback, renderUsernameVerifiedBadge } from '../../User/components/UserCard'
import { getPostRoute, getUsernameRoute } from '../../../constants/routes'
import { getPostsFromResponse, getProductreferralsFromResponse, getUserFromResponse } from '../../../shared/utils/post'
import UserOverallMatchString from './UserOverallMatchString'
import ProductreferralHorizontalCard from './ProductreferralHorizontalCard'
import AddProductreferralToPostForm from './AddProductreferralToPost/AddProductreferralToPostForm'
import ProductreferralTopButtons from './ProductreferralTopButtons'
import { getPostUrlToShare } from '../../../shared/utils/generateUrlForShare'

class SelectedPost extends Component {
  state = {
    showAddProductToPostBlock: false,
  }

  renderProductReferral(productreferral) {
    return <ProductreferralHorizontalCard
      key={productreferral && productreferral.id}
      productreferral={productreferral} post={this.props.post}
    />
  }

  renderSelectedPostImageOrVideo(post, isVideo) {
    if (isVideo) {
      return (
        <video
          muted
          controls
          autoPlay
          playsInline
          poster={post.url}
          preload='none'
          type='video/mp4'
          src={post.post_video_url}
          loop
          style={{ width: '100%' }}
        />
      )
    } else {
      return (
        <Image wrapped size='big' src={post.url} centered />
      )
    }
  }

  // renderHeader(user, post) {
  //   const userProfilUrl = getUsernameRoute(user.instagram_username)
  //   const followersCount = (user && formatNumber(user.ig_followed_by_count)) || '--'
  //   const status = getInfluencerStatusFromUser(user)
  //   // const subStatus = getUserInfluencerLabel(user)
  //   const isMega = influencersStatus.Mega === status
  //   const isMacro = influencersStatus.Macro === status
  //   return (
  //     <div
  //       style={{
  //         position: 'absolute',
  //         top: 0,
  //         width: '100%',
  //         left: 0,
  //         background: 'linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.60),rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0))'
  //       }}
  //     >
  //       <div style={{ paddingTop: '15px', paddingLeft: '20px', paddingBottom: '25px' }}>
  //         <a
  //           href={userProfilUrl}
  //           onClick={() => logEvent('detailPageProductCardUsernameClicked')}
  //         >
  //           <Header as='h3' textAlign='left' inverted>
  //             {renderUserAvatarWithFallback(user, false, () => { })}
  //             <Header.Content>
  //               {renderUsernameVerifiedBadge(user)}
  //               <Header.Subheader>
  //                 <Header
  //                   as='h6'
  //                   style={{ textTransform: 'uppercase' }}
  //                   {...(isMega ? { color: 'red' } : {})}
  //                   {...(isMacro ? { color: 'orange' } : {})}
  //                 >{`${followersCount} `}
  //                   <span style={{ color: 'rgba(255, 255, 255, 0.8)' }}>FOLLOWERS</span>
  //                   {/* <span style={{ color: 'rgba(255, 255, 255, 0.9)' }}> • </span>
  //                   {`${status} `}
  //                   <span style={{ color: 'rgba(255, 255, 255, 0.8)' }}>{subStatus}</span> */}
  //                 </Header>
  //               </Header.Subheader>
  //             </Header.Content>
  //           </Header>
  //         </a>
  //       </div>
  //       {/*
  //       IG post of the logo
  //       {post.url_original_post && (
  //         <div style={{ position: 'absolute', top: '20px', right: '5px' }}>
  //           <div style={{ marginBottom: '15px' }}>
  //             <a
  //               href={post.url_original_post} target='_blank' rel='noopener noreferrer'
  //             >
  //               <Icon name='instagram' size='large' color='instagram' />
  //             </a>
  //           </div>
  //         </div>
  //       )} */}
  //     </div>
  //   )
  // }

  // renderFocusText(label, icon, position) {
  //   const pos = position || 'bottom right'
  //   return (
  //     <div style={{ zIndex: 10, textAlign: 'center' }} className='centered-floating'>
  //       <Label color='black' attached={pos} size='medium'>
  //         <Icon name={icon} />
  //         {label}
  //       </Label>
  //     </div>
  //   )
  // }

  // renderEndOfList(post, product) {
  //   const brandName = product && getBrandNameFromUrl(product.url)
  //   const url = getBrandFullUrlFromProduct(product)
  //   return (
  //     <Segment
  //       key={post.id} id={post.id}
  //       style={{
  //         padding: '1px',
  //         overflow: 'hidden'
  //       }}
  //     >
  //       {this.renderFocusText(`More ${brandName} Looks`, 'caret right', 'top right')}
  //       <a href={`${url}`} onClick={() => logEvent('endOfListClicked')}>
  //         <div
  //           style={{
  //             backgroundImage: `url(${post.url})`,
  //             backgroundSize: 'cover',
  //             height: '170px',
  //             backgroundPosition: 'center',
  //             cursor: 'pointer'
  //           }}
  //         />
  //       </a>
  //     </Segment>
  //   )
  // }

  renderPostOwner(user, post) {
    const userProfilUrl = getUsernameRoute(user.instagram_username)

    let userPosts = user && getPostsFromResponse(user)
    if (!userPosts || userPosts.length === 0) {
      userPosts = [post]
    }
    console.log("userPosts", userPosts)
    const looksCount = (userPosts && userPosts.length) || 0
    return (
      <div
        key={user.id} id={user.id}
        style={{
          padding: '0px',
          overflow: 'hidden'
        }}
      >
        <div>
          <a
            href={userProfilUrl}
            onClick={() => logEvent('postDetailUsernameClicked')}
          >
            <Header as='h3' textAlign='left' inverted>
              {renderUserAvatarWithFallback(user, false, () => { })}
              <Header.Content>
                {renderUsernameVerifiedBadge(user)}
                <Header.Subheader>
                  <Header
                    as='h6'
                    style={{ textTransform: 'uppercase' }}
                  >{`${looksCount ? `${looksCount}+` : '0'} `}
                    <span style={{ color: 'rgba(255, 255, 255, 0.8)' }}>LOOKS</span>
                    <span style={{ color: 'rgba(255, 255, 255, 0.9)' }}> • </span>
                    {/* <span style={{ color: 'rgba(255, 255, 255, 0.8)' }}>{subStatus}</span> */}
                    <span style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                      <UserOverallMatchString profile={user} /></span>
                  </Header>
                </Header.Subheader>
              </Header.Content>
            </Header>
          </a>
        </div>
        {/* <Grid container stackable verticalAlign='middle' centered columns='4' equals> */}
        {/* <Grid.Row> */}
        <div style={{
          borderRadius: '8px',
          overflow: 'hidden',
          width: '100%',
          padding: 0,
          marginTop: '8px'
        }}
        >
          <a
            href={userProfilUrl}
            onClick={() => logEvent('postDetailUserPostsThumbsClicked')}
          >
            {userPosts && userPosts.filter(p => p.url).slice(0, 3).map(post => (
              <div
                // onClick={() => this.onPostSelected(post)}
                key={post.id}
                style={{
                  backgroundImage: `url(${post.url})`,
                  backgroundSize: 'cover',
                  height: '120px',
                  width: '32.9%',
                  backgroundPosition: 'center',
                  cursor: 'pointer',
                  float: 'left',
                  padding: 0,
                  marginRight: '0.5px',
                  marginLeft: '0.5px'
                }}
              />
            )
            )}
          </a>
        </div>
        {/* <UserMatchBadge profile={user} /> */}
      </div>
    )
  }

  // renderNextPost(post, product) {
  //   return (
  //     <Segment
  //       key={post.id} id={post.id}
  //       style={{
  //         padding: '1px',
  //         overflow: 'hidden'
  //       }}
  //     >
  //       {this.renderFocusText('Next', 'caret right')}
  //       {this.renderPostImage(post, product)}
  //     </Segment>
  //   )
  // }

  // renderPost(post, product, selectedPost) {
  //   let isSelectedPost = false
  //   if (selectedPost) {
  //     isSelectedPost = selectedPost.id === post.id
  //   }

  //   return (
  //     <Grid.Column
  //       key={post.id} id={post.id}
  //       style={{
  //         overflow: 'hidden',
  //         padding: '1px'
  //       }}
  //       mobile={8} tablet={8} computer={4}
  //     >
  //       {isSelectedPost && this.renderFocusText('Selected', 'caret up')}
  //       <div style={{ opacity: isSelectedPost ? '0.2' : 1 }}>
  //         {this.renderPostImage(post, product)}
  //       </div>
  //     </Grid.Column>
  //   )
  // }

  // renderPosts(productObj, selectedPost) {
  //   const product = productObj && productObj.product
  //   let posts = getPostsFromResponse(product)

  //   posts = posts && posts.sort((a, b) => b.id - a.id)
  //   return (
  //     <div key={product.id}>
  //       <Grid style={{ padding: '0px' }} centered>
  //         {posts && posts.filter(p => p && p.url_original_post !== selectedPost.url_original_post)
  //           .map((i) => this.renderPost(i, product, selectedPost))}
  //       </Grid>
  //     </div>
  //   )
  // }

  // renderPostsList(post, user, productreferrals, isLoading) {
  //   const product1 = productreferrals[0] && productreferrals[0].product
  //   const product2 = productreferrals[1] && productreferrals[1].product
  //   const product3 = productreferrals[2] && productreferrals[2].product

  //   return (
  //     <div>
  //       {product1 && this.renderPosts(product1, post)}
  //       {product2 && this.renderPosts(product2, post)}
  //       {product3 && this.renderPosts(product3, post)}
  //     </div>
  //   )
  // }

  renderSideContent(productreferrals, user) {
    // const posts = getPostsFromResponse(product1)
    // const selectedPostIndex = posts && posts.findIndex(p => p.id === post.id)
    // const nextPostIndex = selectedPostIndex + 1
    // const nextPost = posts && posts[nextPostIndex]
    // let endListPost = null
    // if (!nextPost) {
    //   endListPost = posts && posts[selectedPostIndex - 1]
    // }
    const { post } = this.props
    return (
      <Segment inverted>
        {!this.state.showAddProductToPostBlock &&
          <ProductreferralTopButtons
            post={post}
            onClick={() => this.setState({ showAddProductToPostBlock: true })}
          />}
        {productreferrals && productreferrals.length > 0 && productreferrals.map(productReferral => this.renderProductReferral(productReferral))}
        {user && this.renderPostOwner(user, post)}
      </Segment>
    )
  }
  /* {endListPost && this.renderEndOfList(endListPost, product1)} */
  /* {nextPost && this.renderNextPost(nextPost, product1)} */

  onDone() {
    const { history, post, onProductreferralAdded } = this.props
    this.setState({ showAddProductToPostBlock: false })
    const postUrl = getPostUrlToShare(post)

    onProductreferralAdded && onProductreferralAdded()
    history && history.push(getPostRoute(postUrl))
  }

  render() {
    const { post } = this.props
    const user = getUserFromResponse(post)
    // const postCaption = post && post.post_caption
    const isVideo = post && post.post_is_video === '1'

    const productReferrals = getProductreferralsFromResponse(post) || []
    // const withMoreLooks = productReferrals && productReferrals.some(pr => {
    //   const posts = getPostsFromResponse(pr.product)
    //   return posts && posts.length > 1 // won't count the selected one
    // })

    const withMoreLooks = false
    return (
      <div style={{ margin: 'auto', maxWidth: '900px' }}>
        {this.state.showAddProductToPostBlock &&
          <AddProductreferralToPostForm
            post={post}
            onDone={() => this.onDone()}
          />}
        <Segment style={{ borderRadius: '20px', overflow: 'hidden', padding: '0px' }} inverted id={post && post.id}>
          <Grid stackable centered columns='equal'>
            <Grid.Row>
              <Grid.Column style={{ padding: '0' }} width={10}>
                {this.renderSelectedPostImageOrVideo(post, isVideo)}
                {/* {user && this.renderHeader(user, post)} */}
              </Grid.Column>
              <Grid.Column width={6}>
                {this.renderSideContent(productReferrals, user)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {withMoreLooks && (
          <Container>
            {/* <div style={{ float: 'left' }}>
            <Icon name='chevron left' size='huge' inverted />
          </div>
          <div style={{ float: 'right' }}>
            <Icon name='chevron right' size='huge' inverted />
          </div>
          */}
            <Divider horizontal inverted>Same Product</Divider>
            <Segment style={{ borderRadius: '20px', overflow: 'hidden', padding: '0px' }} inverted>
              {/*
              TODO
               {this.renderPostsList(post, user, products, isLoading)}
               */}
            </Segment>
          </Container>)}

      </div>
    )
  }
}

export default SelectedPost

/* {!isVideo && ( // TODO this is to remove caption for now
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      left: 0,
                      right: 0,
                      margin: 'auto',
                      background: 'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.55),rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))'
                    }}
                  >
                    <Divider clearing />
                    <div style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
                      <Header
                        as='h5' style={{
                          color: 'rgb(18, 18, 18)',
                          maxHeight: '105px',
                          display: 'block',
                          WebkitLineClamp: '3',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                        textAlign='center'
                      >{postCaption}
                      </Header>
                    </div>
                  </div>)} */
