import React, { Component } from 'react'
import { Container, Segment, Message, Divider, Dimmer, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import UserFeed from '../components/UserFeed'
import {
    getUserBrandFilterRoute, getUsernameRoute, POSTS_LIST, PRODUCTS_LIST, FAQ, DOWNLOAD_APP,
    ROOT
} from '../../../constants/routes'
import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'
import RelatedPostsContainer from '../../Posts/RelatedPosts/RelatedPostsContainer'
import { logPageView } from '../../../services/analytics'
import { getPostsFromResponse } from '../../../shared/utils/post'
import { getPopularDomainNameFromPosts } from '../../../shared/utils/product'
import { RELATED_POSTS_COUNT } from '../../Posts/RelatedPosts/RelatedPosts'
import { DOMAINNAME_WITH_HTTPS } from '../../../shared/constants/apiroutes'
import { getUserFullUrlFromUsername } from '../../../shared/utils/generateFullUrl'
import Footer from '../../../layouts/components/Footer'

class UserPage extends Component {
    state = {
        username: null,
        activeFilters: []
    }

    componentDidMount() {
        const urlParam = this.props.match.params.url
        let username = this.getUsernameFromUrl()
        let activeFilters = this.getBrandsFilter()

        if (!username && urlParam) {
            username = urlParam
            const filtersString = activeFilters && activeFilters.join(';')
            this.props.history.push({
                pathname:
                    filtersString ? getUserBrandFilterRoute(username, filtersString)
                        : getUsernameRoute(username)
            })
            return
        }

        if (`/${username}` === FAQ) {
            this.props.history.push({ pathname: FAQ })
            return
        }

        if (`/${username}` === DOWNLOAD_APP) {
            this.props.history.push({ pathname: DOWNLOAD_APP })
            return
        }

        if (username === "chrome:") {
            this.props.history.push({ pathname: ROOT })
            return
        }

        this.loadUser()
        logPageView({ name: `UserPage_${username}` })
    }

    onBrandFilterChange(activeFilters) {
        const username = this.getUsernameFromUrl()
        const filtersString = activeFilters.join(';')

        this.props.history.push({
            pathname: activeFilters.length > 0 ? getUserBrandFilterRoute(username, filtersString)
                : getUsernameRoute(username)
        })

        this.setState({ activeFilters, username });

        this.props.filterUserPostsAction(activeFilters)
    }

    onImagedAdded() {
        this.loadUser()
    }

    getUsernameFromUrl() {
        const atUsername = this.props.match.params.username
        let username = atUsername && atUsername.replace('@', '')
        if (username && username.includes('?')) {
            username = username.split('?')[0]
        }
        return username
    }

    getBrandsFilter() {
        const brands = this.props.match.params.brands || this.props.match.params.filter
        const filters = brands && brands.replace('profile', '') && brands.split(";")
        return filters
    }

    loadUser = async () => {
        const username = this.getUsernameFromUrl()
        const activeFilters = this.getBrandsFilter()

        this.setState({ activeFilters, username })
        this.getUserProfile(username)
    }

    onProfileReady(user) {
        if (user && !user.instagram_avatar) {
            this.setState({ noUserFound: true })
        }
        else {
            this.setState({ noUserFound: false })
        }
        this.setStructuredData(user)
        const posts = getPostsFromResponse(user)
        const siteName = getPopularDomainNameFromPosts(posts)
        siteName && this.props.getRelatedPostsAction({
            filter: {
                ...(siteName && { dn: siteName }),
                wrp: true // used to get only looks with productreferrals
            },
            page: {
                size: RELATED_POSTS_COUNT
            }
        })
    }

    getUserProfile(username) {
        this.props.getUserAction({
            filter: {
                u: username,
                uwpr: true, // used to get only looks with productreferrals
            },
            updateInstagramUser: false, // TODO update this based on time
            onSuccess: (user) => this.onProfileReady(user) // TODO reenable this
        })
    }

    setStructuredData(user) {
        if (!user) {
            return
        }

        const breadcrumbList = this.getBreadcrumbStructuredData(user)
        const structuredData = [
            breadcrumbList,
        ];

        const ldjsonDocumentId = "googleJobNetworkScript"
        const element = document.getElementById(ldjsonDocumentId)
        if (element) {
            element.remove();
        }

        const googleJobNetworkScript = document.createElement("script")
        googleJobNetworkScript.type = "application/ld+json"
        googleJobNetworkScript.id = ldjsonDocumentId
        googleJobNetworkScript.innerHTML = JSON.stringify(structuredData)
        document.head.appendChild(googleJobNetworkScript)
    }

    getBreadcrumbStructuredData(user) {
        if (!user) return

        const itemListElement = [{
            "@type": "ListItem",
            "position": 1,
            "name": "seemyfit.at",
            "item": DOMAINNAME_WITH_HTTPS
        }]

        user && itemListElement.push({
            "@type": "ListItem",
            "position": 2,
            "name": user.instagram_username,
            "item": getUserFullUrlFromUsername(user.instagram_username)
        })

        return {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": itemListElement
        }
    }

    render404() {
        const { username } = this.state
        return (
            <Segment basic textAlign='center'>
                <Message warning={true} compact>
                    Sorry we couldn't find any profile for <b>{username}</b> 😐 <br /><br />
                    If this is your account, please connect your account on the mobile app to complete your profile<br />
                    and if you have any questions, please reach out to us on IG @seemyfit<br />
                    <br />
                    Quick links <Link to={POSTS_LIST}>Looks</Link> - <Link to={PRODUCTS_LIST}>Products</Link> <br />
                </Message>
                <Divider hidden />
            </Segment>
        )
    }

    renderLoading(isLoading) {
        return (
            <Dimmer active={isLoading}>
                <Loader size='mini'>Loading ...</Loader>
            </Dimmer>
        )
    }

    render() {
        const { username, activeFilters, noUserFound } = this.state

        const { isLoadingInstagram, isLoadingProfile } = this.props
        let { user, allPosts } = this.props
        const posts = getPostsFromResponse(user)

        return (
            <ResponsiveContainer
                isInstalled
                history={this.props.history}
                location={this.props.location}
            >
                <Container className='no-horizontal-margin'>
                    <div style={{
                        maxWidth: '1127px',
                        margin: 'auto'
                    }}
                    >
                        {isLoadingProfile && this.renderLoading(isLoadingProfile)}
                        {!noUserFound && <UserFeed
                            urlPathUsername={username}
                            isLoadingInstagram={isLoadingInstagram}
                            isLoadingProfile={isLoadingProfile}
                            user={user}
                            allPosts={allPosts}
                            activeFilters={activeFilters}
                            isFullPage={this.props.isFullPage}
                            imagedAdded={() => this.onImagedAdded()}
                            onBrandFilterChange={(activeFilters) => this.onBrandFilterChange(activeFilters)}
                        />}

                        {noUserFound && this.render404()}
                        <Divider hidden />
                        {!noUserFound && <RelatedPostsContainer posts={posts} history={this.props.history} />}
                    </div>
                    {/* <TryBioLink history={this.props.history} /> */}
                </Container>
                <Footer history={this.props.history} />
            </ResponsiveContainer>
        )
    }

}
export default UserPage