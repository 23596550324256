import React, { Component } from 'react'
import { Segment, Header, Container, Form } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { ADDIMAGE } from '../../constants/routes'
import ResponsiveContainer from '../../layouts/components/Responsive/ResponsiveContainer'

class AdminPage extends Component {
    state = {
        isAdmin: false
    }

    showPasswordForm() {
        return (
            <Segment>
                <Header>Please login</Header>
                <Form>
                    <Form.Input label='Enter Password' type='password' />
                </Form>
            </Segment>
        )
    }

    renderContent() {
        const { isAdmin } = this.state
        if (!isAdmin) {
            return this.showPasswordForm()
        }
        return (
            <>
                <Segment>
                    {/* <Header>Shortcuts</Header>
                    <Link to={ADDIMAGE} className='yellow'>Share your fit</Link> <br />
                </Segment>
                <Segment>
                    <Header>Import bulk dry-run</Header>
                    <Header as='h4'>format {"{url_original_post': 'INSTA_POST_URL', 'prodcut_url': 'PRODUCT_URL'}"}</Header>
                    <p>first run : document.querySelectorAll('.ls-photo.js-click-image').forEach(a => console.log(a.href))</p>
                    <p>on https://likeshop.me/nastygal</p>
                    <Form>
                        <Form.TextArea label='Bulk data' placeholder='Tell us more about you...' />
                    </Form>
                </Segment>
                <Segment>
                    <Header>Import bulk dry-run</Header>
                    <Header as='h4'>format {"{url_original_post': 'INSTA_POST_URL', 'prodcut_url': 'PRODUCT_URL'}"}</Header>
                    <Form>
                        <Form.TextArea label='Bulk data' placeholder='Tell us more about you...' />
                    </Form> */}
                </Segment>
            </>
        )
    }

    render() {
        return (
            <ResponsiveContainer history={this.props.history} location={this.props.location}>
                <Container>
                    {this.renderContent()}
                </Container>
            </ResponsiveContainer>
        )
    }
}

export default AdminPage
