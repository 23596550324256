import React, { useContext } from 'react';
import { Label, Icon } from 'semantic-ui-react';
import { AppContext } from '../../../shared/context';
import { getStyleMatchScore, getSizeMatchScore, getMatchColorByPercent, getTotalScoreMatchPercent, getPercentFromScore } from '../../../shared/utils/styleScore';


const UserMatchLabels = (({
    profile
}, ref) => {
    const { user: userContext } = useContext(AppContext)

    const styleScore = getStyleMatchScore(userContext, profile)
    const sizeScore = getSizeMatchScore(userContext, profile)

    const styleMatchColor = getMatchColorByPercent(getPercentFromScore(styleScore))
    const sizeMatchColor = getMatchColorByPercent(getPercentFromScore(sizeScore))
    // const brandsScore = getBrandsMatchScore(user, webUser) TODO: add-brand-match

    return (
        <>
            <Label icon color={"black"} size={"tiny"} >
                <Icon name="bullseye" style={{ color: `${styleMatchColor}` }}></Icon>
                Style
                <Label.Detail style={{ color: `${styleMatchColor}` }}>{styleScore}</Label.Detail>
            </Label>
            <Label color={"black"} icon size={"tiny"}>
                <Icon name="bullseye" style={{ color: `${sizeMatchColor}` }}></Icon>
                Fit
                <Label.Detail style={{ color: `${sizeMatchColor}` }}>{sizeScore}</Label.Detail>
            </Label>
            {/* <Label as='a' color='yellow' image size={"tiny"}>
                Brand
                <Label.Detail>6.7</Label.Detail>
            </Label> */}
        </>
    )
});

export default UserMatchLabels;