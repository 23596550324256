import { connect } from 'react-redux'

import InstallMobileAppBanner from './InstallMobileAppBanner'

export default connect(
  state => ({
  }),
  dispatch => ({
  })
)(InstallMobileAppBanner)
