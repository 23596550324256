import { path } from 'ramda'
import { serialise } from 'kitsu-core'

import API from '../../../services/webApi'
import { transformUser } from "../../transformers/userTransformer";

const api = API.create()

export const updateWebUser = async (request) => {
    // console.log("userWebApi updateWebUser")

    // const include = 'posts.productreferrals.product' // posts.user is not working with the deserialize lib
    const config = {
        ...request
    }
    // console.log("userWebApi updateWebUser serialise", serialise('users', config, 'patch'))
    const response = await api.updateUser(config)
    if (response) {
        const user = path(['data'], response)
        // console.log("userWebApi updateWebUser user:", user)
        return transformUser(user)
    }
};

export const getWebUser = async (request) => {
    // console.log("userWebApi getWebUser")

    // const include = 'posts.productreferrals.product' // posts.user is not working with the deserialize lib
    const config = {
        ...request
    }
    // console.log("userWebApi getWebUser serialise", serialise('users', config, 'get'))
    const response = await api.getUser(config)
    if (response) {
        const user = path(['data'], response)
        // console.log("userWebApi getWebUser user:", user)
        return transformUser(user)
    }
};

export const createWebUser = async (request) => {
    // console.log("userWebApi createWebUser")

    // const include = 'posts.productreferrals.product' // posts.user is not working with the deserialize lib
    const config = {
        ...request
    }
    const response = await api.addUser(config)
    if (response) {
        const user = path(['data'], response)
        // console.log("userWebApi createWebUser user:", user)
        return transformUser(user)
    }
};

// export default function useUserMutation() {
//     return useMutation((request) => updateUser(request));
// }