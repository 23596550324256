import React from 'react'

import appherosubscribe from '../../../images/appherosubscribe.png'
import DownloadAppBannerLeftImg from '../../../components/InstallMobileAppBanner/DownloadAppBannerLeftImg'

const InstallAppSubscribeHero = ({ siteName, headline, description, ctaText, appInstallCampaign, analyticsLabel }) => (
  <DownloadAppBannerLeftImg
    headline={headline}
    description={description}
    ctaText={ctaText}
    heroImg={appherosubscribe}
    siteName={siteName}
    appInstallCampaign={appInstallCampaign}
    analyticsLabel={analyticsLabel}
  />)

// <Segment
//   style={{
//     borderRadius: '20px',
//     // padding: '0',
//     backgroundImage: `url(${appherosubscribe})`,
//     backgroundSize: 'contain',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'top left'
//   }} inverted
// >
//   <Grid container stackable verticalAlign='middle' centered>
//     <Grid.Row>
//       <Grid.Column floated='left' largeScreen={6} style={{ height: '300px' }}>

//       </Grid.Column>
//       <Grid.Column largeScreen={10}>
//         <Header as='h2'>
//           Weekly Highlights Newsletter
//         </Header>
//         <p style={{ fontSize: '1.33em' }}>
//           Hand-pick your favourite creators to get their weekly highlights and find new inspirations for your wardrobe!
//         </p>
//         <p style={{ fontSize: '1.33em' }}>
//           {/* Share your look and express yourself. Get weekly hightlights from your favorite creators */}
//         </p>
//         <Header>
//           Available on <span role='img' aria-label='Red Apple' style={{ marginRight: '2px' }}>🍎 </span>{' & '}<span role='img' aria-label='Robot' style={{ marginRight: '2px' }}>🤖</span><small>(coming soon)</small>. Try it out now <span role='img' aria-label='Pointing Down'>👇</span>
//         </Header>
//         <a
//           href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=${appInstallCampaign}${siteName ? `_${siteName}` : ''}&mt=8`}
//           onClick={() => logEvent('InstallAppSubscribeHeroiOSAppClicked')}
//           target='_blank' rel='noopener noreferrer' >
//           <img src={appstoreimg} alt='Seemyfit app' />
//         </a>
//         {/* <Subscribe siteName={siteName} /> */}
//       </Grid.Column>
//     </Grid.Row>
//   </Grid>
// </Segment>
// )

export default InstallAppSubscribeHero
