import React, { Component } from 'react'
import {
  Image, Header, Label, Icon
} from 'semantic-ui-react'
import { logEvent } from '../../../services/analytics'

import PlayIcon from '../../../images/InstagramPlay.svg'
import { getUserFromResponse, getProductFromResponse } from '../../../shared/utils/post'
import { renderUserAvatarWithFallback } from '../../User/components/UserCard'
import { getSaleConditionStringFromKey } from '../../../shared/utils/productSaleCondition'
import { removeIGDomainName } from '../../../shared/utils/instagramUrl'
import ProductRating from '../AddPost/ProductRating'
import ProductTrueFit from '../AddPost/ProductTrueFit'

class BigPostCard extends Component {
  state = {
    avatarFailedToLoad: false
  }

  renderProductImage(productreferral) {
    const product = getProductFromResponse(productreferral) || {}
    const promocode = productreferral.promocode
    const size = productreferral.size
    const color = productreferral.color
    const imgLabel = promocode && { as: 'span', color: 'red', corner: 'right', icon: 'tags', size: 'mini' }

    let dataAttrs = {
      'data-pr-id': productreferral && productreferral.id,
      'data-product-id': product && product.id
    };

    if (!product || !product.image_url) {
      return <div></div>
    }
    return (
      <div id={product.id}>
        <Image key={product.id} id={product.id} {...dataAttrs}
          src={product.image_url} size='mini' rounded bordered
          label={imgLabel}
          style={{
            width: '5em',
            borderRadius: '4px',
            border: '1px solid #FFF',
            marginBottom: '1px'
          }}
        />
        {size && (<div style={{ position: 'relative', bottom: '22px', left: '2px', height: '0' }}>
          <Label size='mini' color={color || 'grey'}>{size}</Label>
        </div>)
        }
      </div>
    )
  }

  render() {
    const { post, product, analytisClicked, productreferral } = this.props
    const user = (post && getUserFromResponse(post)) || {}

    const productValue = (post && getProductFromResponse(post)) || product || {}
    const url = (post && removeIGDomainName(post.url_original_post)) || productValue.url

    const { size, color, forSale, saleCondition, productTrueFit, productRating, promocode: promo } = productreferral
    const promocode = promo || (post && post.promocode) // todo remove later this is used for old promocodes


    return (
      <a href={`/${url}`}
        onClick={() => logEvent(analytisClicked)}
      >
        <div
          // onClick={() => this.onPostSelected(post)}
          style={{
            backgroundImage: `url(${post && post.url})`,
            backgroundSize: 'cover',
            height: '380px',
            backgroundPosition: 'center',
            cursor: 'pointer'
          }}
        />
        <div style={{
          position: 'absolute',
          bottom: '30px',
          // left: '0',
          right: '5px',
          margin: 'auto',
          textAlign: 'left',
          zIndex: '99'
        }}
        >
          {
            productreferral && productreferral.post && productreferral.post.productreferrals
            && productreferral.post.productreferrals.length > 0 && productreferral.post.productreferrals
              .slice(0, 6).map(pr => this.renderProductImage(pr))
          }
        </div>

        <div style={{ position: 'absolute', top: '5px', right: '5px', textAlign: 'right' }}>
          <div>
            {promocode && (
              <Label color='red' size='mini' >
                <Icon name='tags' />Promo Code:
                <Label.Detail>{promocode}</Label.Detail>
              </Label>
            )}
            {forSale === 1 && (<Label color='red' size='mini' >
              For SALE
              <Label.Detail>{getSaleConditionStringFromKey(saleCondition)}</Label.Detail>
            </Label>)}

            {size && (<Label size='mini' color={color}>
              {size}
              {color && <Label.Detail >{color.toUpperCase()}</Label.Detail>}
            </Label>)}
            {productRating && <ProductRating rating={productRating} readOnly size='tiny' />}

          </div>
        </div>

        <div style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
          background: 'linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.35),rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))',
          margin: 'auto',
          textAlign: 'left'
        }}
        >
          <div style={{ position: 'absolute', bottom: '15px', right: '5px', textAlign: 'right' }}>
            {productTrueFit && <ProductTrueFit value={productTrueFit} readOnly compact />}
          </div>

          <div style={{ bottom: '8px', left: '5px', paddingLeft: '8px', position: "relative" }}>
            {/* <Header as='h5' textAlign='left' icon inverted> */}
            {user && renderUserAvatarWithFallback(user, this.state.avatarFailedToLoad, () => this.setState({ avatarFailedToLoad: true }))}
            {/* {user && renderUsernameVerifiedBadge(user)} */}
            {/* </Header> */}
          </div>
        </div>
        {
          post && post.post_is_video === '1' && (
            <div
              className='post-play-icon'
            >
              <Image src={PlayIcon} size='tiny' spaced alt='Play Instagram Video' />
            </div>
          )
        }
      </a >
    )
  }
}

export default BigPostCard
