import React, { useContext, useState } from 'react';
import { Header, Image, Icon, Divider, Button, Message, Accordion, Label, Container } from 'semantic-ui-react';
import copy from 'copy-to-clipboard'

import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom'

import { AppContext } from '../../../../shared/context';
import { INSTAGRAM_API } from '../../../../shared/constants/instagramApi';
import { getIgProfilePicByUsername } from '../../../../shared/services/instagramRapidApi';
import { getUsernameUrlToShare } from '../../../../shared/utils/generateUrlForShare';
import { updateWebUser } from '../../../../shared/api/web/users/userWebApi';
import updateFirebaseCurrentUser from '../../../../firebase/users/updateFirebaseCurrentUser';
import updateFirebaseUser from '../../../../firebase/users/updateFirebaseUser';
import { logEvent } from '../../../../services/analytics';
import { ADDPRODUCT, getAddProductPageRoute, TAGPRODUCTPREFIX } from '../../../../constants/routes';

const SelectGeneralTab = (({ history, isInstalled }, ref) => {
  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth);
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext);

  const username = userContext && userContext.username
  const emailStepCompleted = !!(user && user.emailVerified)
  const instagramUsernameAdded = !!username
  const instagramAvatarAdded = !!((user && user.photoURL) || (userContext && userContext.photoURL))
  const instagramStepCompleted = instagramUsernameAdded && instagramAvatarAdded
  const bodyShapeFitChallengeStepCompleted = !!(userContext.bodyProperties && userContext.fitChallenges)
  const sizesStylesStepCompleted = !!(userContext.dressSize && userContext.shoeSize && userContext.topSize && userContext.bottomSize &&
    userContext.styles && userContext.influencedBy)
  const promocodesSocialStepCompleted = !!((userContext.promocode || userContext.website)
    && (userContext.tiktok || userContext.youtube || userContext.pinterest || userContext.twitter
      || userContext.snap || userContext.facebook))

  const stepsStage1Completed = emailStepCompleted && instagramStepCompleted

  const stepsStage2Completed = bodyShapeFitChallengeStepCompleted &&
    sizesStylesStepCompleted && promocodesSocialStepCompleted

  const profileAllStepsCompleted = stepsStage1Completed && stepsStage2Completed

  const inslallBrowserAddonStepCompleted = isInstalled
  const addALookStepCompleted = false
  const sendInvitesStepCompleted = false

  const defaultActiveIndex = profileAllStepsCompleted ? 2 : (stepsStage1Completed ? 1 : 0)

  const [trackerActiveIndex, setTrackerActiveIndex] = useState(defaultActiveIndex);

  const getIgUrl = () => {
    // const scope = isMediaPermission ? 'user_media,user_profile' : 'user_profile'
    const scope = 'user_media,user_profile'
    const igurl = `https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_API.clientId}&redirect_uri=${INSTAGRAM_API.redirectUri}&scope=${scope}&response_type=code`

    return igurl
  }

  const getIGProfilePic = async (username) => {
    const response = await getIgProfilePicByUsername(username)

    if (!response) {
      return
    }

    const image = response.data
    if (image) {
      const webUser = userContext && userContext.webUser
      if (webUser && webUser.id) {
        const webRequest = {
          id: webUser.id,
          instagram_avatar: image
        }

        let webResult = null
        try {
          webResult = await updateWebUser(webRequest)
        } catch (e) {
          webResult = null
        }

        if (webResult) {
          const updatePayload = { avatar: webResult.instagram_avatar, photoURL: webResult.instagram_avatar }
          updateUserContext(updatePayload)
          try {
            await updateFirebaseCurrentUser(auth.currentUser, {
              photoURL: webResult.instagram_avatar,
              displayName: username
            })
          } catch (e) {
            console.log("getIGProfilePic updateFirebaseCurrentUser error:", e)
          }

          try {
            await updateFirebaseUser(user && user.uid, {
              photoURL: webResult.instagram_avatar,
            })
          } catch (e) {
            console.log("getIGProfilePic updateFirebaseUser error:", e)
          }
          setTrackerActiveIndex(2)
        }
      }
    }
  }


  const renderProfileCompletion = () => {

    const onProfileCopied = (url, analyticsEventName) => {
      copy(url, {
        message: "Couldn't auto-copy. Please select and copy"
      })
      logEvent(analyticsEventName)
    }


    const emailStep = {
      isCompleted: emailStepCompleted,
      completedTitle: `Email verified. ${user && user.email}`,
      incompletedTitle: (<><strong>Check your inbox</strong> and tap the link sent to {user && user.email}.</>)
    }


    const instagramStep = {
      isCompleted: instagramStepCompleted,
      completedTitle: "Username and profile picture added",
      incompletedTitle: instagramAvatarAdded
        ? (<>{username ? `@${username} connected! now ` : ''}<a onClick={() => getIGProfilePic(username)} target="_blank">refresh</a> your Instagram Profile Picture</>)
        : (<><a href={getIgUrl()} target="_blank">Connect</a> your instagram to use your <strong>username</strong>, <strong>profile picture</strong> and import your posts.</>)
    }

    const bodyShapeFitChallengeStep = {
      isCompleted: bodyShapeFitChallengeStepCompleted,
      completedTitle: "Body shape and fit challenges added",
      incompletedTitle: (<>Select your <strong>body shape</strong> and <strong>fit challenges</strong></>)
    }

    const sizesStylesStep = {
      isCompleted: sizesStylesStepCompleted,
      completedTitle: "Sizes and styles added",
      incompletedTitle: (<>Add your <strong>sizes</strong> and <strong>styles</strong></>)
    }

    const promocodesSocialStep = {
      isCompleted: promocodesSocialStepCompleted,
      completedTitle: "Promo codes, website and social profiles added",
      incompletedTitle: (<>Promote your <strong>promo codes</strong>, website and your other <strong>social profiles</strong></>)
    }

    // START USING SEEMYFIT SECTION

    const inslallBrowserAddonStep = {
      isCompleted: inslallBrowserAddonStepCompleted,
      completedTitle: `Seemyfit add-on added`,
      incompletedTitle: (<><strong>Add <a href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk'
        target='_blank'
        rel='noopener noreferrer'> #seemyfit to chrome</a></strong> to see seemyfit posts on online product pages</>)
    }


    const addALookStep = {
      isCompleted: addALookStepCompleted,
      completedTitle: `Looks added`,
      incompletedTitle: (<><Link to={TAGPRODUCTPREFIX} as='a' onClick={() => logEvent('addlooksStepProfileUrlClicked')}>Share you looks</Link> and tag products</>)
    }

    const generateUrlForShare = username && getUsernameUrlToShare(username)
    const sendInvitesStepStep = {
      isCompleted: sendInvitesStepCompleted,
      completedTitle: `Invites sent!`,
      incompletedTitle: (<><strong>Share</strong> your profile and <strong>invite</strong> other members
        <Label
          color='grey' size='medium' basic
          onClick={() => onProfileCopied(generateUrlForShare, "inviteStepProfileUrlCopied")}
        >
          {generateUrlForShare}
          <Label.Detail><Icon name='copy' /></Label.Detail>
        </Label></>)
    }

    const renderStep = ({ isCompleted, completedTitle, incompletedTitle }) => {
      return isCompleted
        ? (<Message.Content><Icon name='check' color='green' size="small" /> {completedTitle}</Message.Content>)
        : (<Message.Content><Icon name='circle outline' size="small" /> {incompletedTitle}</Message.Content>)
    }

    const renderAccordionBlock = ({ completed, accordionIndex, stepsChildren,
      titleForCompleted, titleForIncompleted }) => {
      const handleClick = (index) => {
        setTrackerActiveIndex(trackerActiveIndex === index ? -1 : index)
      }
      const labelProps = {}
      if (completed) {
        labelProps.icon = 'check'
      } else {
        labelProps.color = 'blue'
        labelProps.icon = 'circle outline'
      }
      return (
        <>
          <Accordion.Title
            active={trackerActiveIndex === accordionIndex}
            index={accordionIndex}
            onClick={(e, { index }) => handleClick(index)}
            content={<Label {...labelProps} size={'large'}
              content={completed ? titleForCompleted : titleForIncompleted} />}
          >
          </Accordion.Title>
          <Accordion.Content active={trackerActiveIndex === accordionIndex}>
            <Message color='black'>
              {stepsChildren}
            </Message>
          </Accordion.Content>
        </>
      )
    }

    const renderProfileTrackerSteps = () => {
      return (
        <Accordion inverted defaultActiveIndex={defaultActiveIndex}>
          {renderAccordionBlock({
            completed: stepsStage1Completed, accordionIndex: 0,
            titleForCompleted: '1 - Email and Instagram Verified',
            titleForIncompleted: "1 - Complete your profile",
            stepsChildren: (
              <>
                {renderStep(emailStep)}
                {renderStep(instagramStep)}
                {!username ? renderConnectYourInstagram() : renderInstagramConnected()}
              </>
            )
          })}
          {renderAccordionBlock({
            completed: stepsStage2Completed, accordionIndex: 1,
            titleForCompleted: '2 - Size & Style Preferences Added',
            titleForIncompleted: "Add Size and Style Preferences",
            stepsChildren: (
              <>
                {renderStep(bodyShapeFitChallengeStep)}
                {renderStep(sizesStylesStep)}
                {renderStep(promocodesSocialStep)}
              </>
            )
          })}
          {profileAllStepsCompleted && renderAccordionBlock({
            completed: false, accordionIndex: 2,
            titleForCompleted: "3 - Start using seemyfit",
            titleForIncompleted: "3 - Start using seemyfit",
            stepsChildren: (
              <>
                {renderStep(inslallBrowserAddonStep)}
                {renderStep(addALookStep)}
                {renderStep(sendInvitesStepStep)}
              </>
            )
          })}
        </Accordion >
      )
    }

    return renderProfileTrackerSteps()
  }

  const renderConnectYourInstagram = () => {
    return (
      <Message floating warning>
        <Icon name='instagram' size='large' />
        <a href={getIgUrl()} target="_blank">Connect your instagram</a> to use your username and profile picture.
      </Message>
    )
  }

  const renderInstagramConnected = () => {
    const avatarUrl = (user && user.photoURL) || (userContext && userContext.photoURL)
    return (
      <Message compact positive>
        <Message.Header>
          {/* <Icon name='instagram' size='large' /> */}
          <Image src={avatarUrl} avatar />{username}
          {!avatarUrl &&
            <Button icon labelPosition='right' compact
              onClick={() => getIGProfilePic(username)}
            >Add Instagram profile picture <Icon name="instagram" />
            </Button>}
        </Message.Header>
        {!!avatarUrl && (
          <Message.Content>
            Keep your profile picture up to date. {" "}
            <Button icon labelPosition='right' compact basic
              onClick={() => getIGProfilePic(username)}>
              Refresh your profile picture <Icon name="refresh" />
            </Button>
          </Message.Content>
        )}
      </Message>
    )
  }

  const renderBottomActions = () => {
    const generatePublicUrlForShare = username && getUsernameUrlToShare(username)
    return (
      <div style={{ textAlign: 'right' }}>
        <Divider />
        <Button icon labelPosition='right' compact secondary
          href={generatePublicUrlForShare}
          target='_blank' rel='noopener noreferrer'
          onClick={() => logEvent("generalTabPublicProfileClicked${username}`")}
        >See public profile @{username} <Icon name="external alternate" />
        </Button>
      </div>
    )
  }

  return (
    <div>
      {renderProfileCompletion()}

      {!!username && renderBottomActions()}
      {/* {renderSave(onSave, { isSuccess, errorMessage })} */}
    </div>
  );
});

export default SelectGeneralTab;