import React, { useContext } from 'react';

import { getAuth } from 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { AppContext } from '../../../shared/context';

const ShowMenuButtonsForAuthenticated = (({
    menuButtons,
    showOnlyIfProfileCompleted
}, ref) => {
    const auth = getAuth()
    const [user] = useAuthState(auth);

    // TODO: get and show product added to subscription list count
    const { user: userContext } = useContext(AppContext);

    const showContent = showOnlyIfProfileCompleted ? (!!user && userContext && userContext.username)
        : !!user
    return showContent ? (menuButtons)
        : <></>
});

export default ShowMenuButtonsForAuthenticated;