import React, { useState } from 'react'
import {
  Form, Message, Header, Icon, Button, Loader
} from 'semantic-ui-react'


const SelectorButtons = ({ firstLabel, secondLabel, thirdLabel, selectedIndexDefault, onSelected }) => {
  const [selectedIndex, setSelectedIndexDefault] = useState(selectedIndexDefault || 0)

  const onChange = (index) => {
    setSelectedIndexDefault(index)
    onSelected && onSelected(index)
  }

  const fistLabelProps = selectedIndex === 0 ? {
    color: 'blue'
  } : {
    color: 'black', basic: true, inverted: true
  }

  const secondLabelProps = selectedIndex === 1 ? {
    color: 'blue'
  } : {
    color: 'black', basic: true, inverted: true
  }

  const thirdLabelProps = selectedIndex === 2 ? {
    color: 'blue'
  } : {
    color: 'black', basic: true, inverted: true
  }

  return (
    <Button.Group>
      <Button {...fistLabelProps} onClick={() => onChange(0)} >{firstLabel}</Button>
      <Button {...secondLabelProps} onClick={() => onChange(1)}>{secondLabel}</Button>
      {thirdLabel && <Button {...thirdLabelProps} onClick={() => onChange(2)}>{thirdLabel}</Button>}
    </Button.Group >
  )
}

export default SelectorButtons
