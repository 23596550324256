import React, { useContext, useState } from 'react';

import { AppContext } from '../../../../shared/context';
import SelectClothesSizeSlide from '../../../../components/SelectClothesSizeSlide';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';
import { Header, Icon } from 'semantic-ui-react';

const SelectSizesTab = (({
}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [dressSize, setDressSize] = useState((userContext && userContext.dressSize) || '');
  const [shoeSize, setShoeSize] = useState((userContext && userContext.shoeSize) || '');
  const [topSize, setTopSize] = useState((userContext && userContext.topSize) || '');
  const [bottomSize, setBottomSize] = useState((userContext && userContext.bottomSize) || '');

  const onSave = async () => {
    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = {
      dressSize,
      shoeSize,
      topSize,
      bottomSize
    }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };


  return (
    <div>
      {renderSaving({ isSaving })}
      <Header as='h5'>
        <Icon name='lock' />
        <Header.Content>Only Visible to you.</Header.Content>
      </Header>
      <SelectClothesSizeSlide
        dressSize={dressSize}
        shoeSize={shoeSize}
        topSize={topSize}
        bottomSize={bottomSize}
        setDressSize={setDressSize}
        setShoeSize={setShoeSize}
        setTopSize={setTopSize}
        setBottomSize={setBottomSize}
      />
      {renderSave(onSave, { isSuccess, errorMessage })}
    </div>
  );
});

export default SelectSizesTab;