
import React from 'react'
import { Rating } from 'semantic-ui-react'

const ProductRating = (props) => (
  <Rating
    maxRating={5}
    onRate={(e, { rating }) => props.onRate(rating)}
    icon='star'
    size={props.size ? props.size : 'huge'}
    {...(props.rating ? { rating: props.rating } : { defaultRating: 5 })}
    {...(props.readOnly ? { disabled: true } : {})}
  />
)

export default ProductRating
