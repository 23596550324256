export function intlFormat (num) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10)
}

export function formatNumber (num) {
  if (num >= 1000000) { return intlFormat(num / 1000000) + 'm' }
  if (num >= 10000) { return intlFormat(num / 1000) + 'k' } // only applies for 10k+
  return intlFormat(num)
}

export function unformatNumber (numString) {
  if (!numString || !(typeof numString === 'string' || numString instanceof String)) return numString
  const numStringLowerCase = numString.toLowerCase().replace(',', '')

  let numInt = parseInt(numStringLowerCase)

  if (numStringLowerCase.endsWith('k')) {
    const num = numStringLowerCase.replace('k', '')
    numInt = parseFloat(num) * 1000
  }

  if (numStringLowerCase.endsWith('m')) {
    const num = numStringLowerCase.replace('m', '')
    numInt = parseFloat(num) * 1000000
  }

  return numInt.toString()
}
