import React, { Component } from 'react'
import {
  Segment, Grid, Button, Icon, Dimmer, Loader, Container, Header, Divider
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { logEvent } from '../../../services/analytics'
import { PRODUCTS_LIST, getProductListFilterRoute } from '../../../constants/routes'
import ProductCard from '../components/ProductCard'

export const ITEMS_TO_SHOW_COUNT = 3

class LastProducts extends Component {
  componentDidMount() {
    const { siteName } = this.props
    this.setSitenameFilter(siteName)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteName !== this.props.siteName) {
      this.setSitenameFilter(this.props.siteName)
    }
  }

  setSitenameFilter(siteName) {
    this.loadItems(1, siteName)
  }

  loadItems(pageNumber, siteName) {
    let filter = {
      ws: true,
      rn: true
    }

    if (siteName) {
      filter = {
        ...filter,
        dn: siteName
      }
    }

    this.props.getProductsAction({
      filter,
      page: {
        number: pageNumber,
        size: 30
      }
    })
  }

  renderItem(item) {
    return (
      <Grid.Column
        key={item.id} id={item.id}
        style={{ padding: '1px', overflow: 'hidden' }}
        mobile={16} tablet={8} computer={5}
      >
        <ProductCard
          product={item}
          analytisEventName='postListProductCardImageClicked'
        />
      </Grid.Column>
    )
  }

  renderLoading(isLoading) {
    return (
      <Dimmer active={isLoading}>
        <Loader size='mini'>Loading ...</Loader>
      </Dimmer>
    )
  }

  render() {
    const { items, isLoading, siteName } = this.props

    const itemsCount = siteName ? ITEMS_TO_SHOW_COUNT * 2 : ITEMS_TO_SHOW_COUNT

    return (
      <Container className='no-horizontal-margin'>
        <Divider hidden />
        <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
          <Segment basic textAlign='center'>
            <Header as='h1' inverted>
              Trending{siteName && ` ${siteName}`} Products
              <Header.Subheader>
                Recent <b>{siteName && `${siteName} `}</b> products viewed on <b>seemyfit.at</b> profiles
              </Header.Subheader>
            </Header>
          </Segment>

          {this.renderLoading(isLoading)}

          <Grid centered>
            {items && items.slice(0, itemsCount).map((item) => this.renderItem(item))}
          </Grid>

          {!isLoading && items && items.length > 0 && (
            <Segment clearing basic>
              <Link to={siteName ? getProductListFilterRoute(siteName) : PRODUCTS_LIST}>
                <Button
                  as='a' floated='right'
                  onClick={() => logEvent('seeAllProductsClicked')}
                  color='blue'
                >{`More ${siteName ? `${siteName} Products` : ''}`}
                  <Icon name='right arrow' />
                </Button>
              </Link>
            </Segment>
          )}

          {/*
          ADD Product removed
           <Divider horizontal inverted>Tag Products</Divider>
          <Divider hidden />
          {this.renderTagProduct()}
          <Divider hidden clearing /> */}
        </Segment>
        <Divider hidden />
      </Container>
    )
  }

  renderTagProduct() {
    return (
      <Grid centered doubling>
        <Grid.Column mobile={16} tablet={8} computer={6}>
          {/* <AddIGPostToProductContainer /> */}
        </Grid.Column>
      </Grid>
    )
  }
}

export default LastProducts
