import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import productsListActions from '../../../store/redux/products/ProductsListRedux'

import AddProduct from './AddProduct'

export default connect(
  (state, ownProps) => ({
    history: ownProps.history,

    isLoadingGetProductStep1: state.addPostGetProductState.fetching,
    statusTextGetProductStep1: state.addPostGetProductState.statusText,
    productStep1: ownProps.product || state.addPostGetProductState.data,
    productPage: ownProps.productPage,

    isLoadingGetOwnerStep2: state.addPostGetOwnerStep2State.fetching,
    statusTextGetOwnerStep2: state.addPostGetOwnerStep2State.statusText,
    ownerStep2: state.addPostGetOwnerStep2State.data,

    isLoadingAddPostStep3: state.addPostStep3State.fetching,
    statusTextAddPostStep3: state.addPostStep3State.statusText,
    postStep3: state.addPostStep3State.data,

    // currentUser: state.login.currentUser,
    items: state.productsState.items.asMutable(),
    filterLoaded: state.productsState.filterLoaded,
    pageNumberLoaded: state.productsState.pageNumberLoaded,
    isLoading: state.productsState.fetching,
    isNextPageLoading: state.productsState.isNextPageLoading,
    lastPageReached: state.productsState.lastPageReached,
    statusText: state.productsState.statusText
  }),
  dispatch => ({
    getProductsAction: bindActionCreators(productsListActions.request, dispatch)
  })
)(AddProduct)
