import React from 'react'

import herophoto2 from '../../../images/apphero.png'
import DownloadAppBannerRightImg from '../../../components/InstallMobileAppBanner/DownloadAppBannerRightImg'

const InstallAppHero = ({ siteName, headline, description, ctaText, appInstallCampaign, analyticsLabel }) => (
  <DownloadAppBannerRightImg
    headline={headline}
    description={description}
    ctaText={ctaText}
    heroImg={herophoto2}
    siteName={siteName}
    appInstallCampaign={appInstallCampaign}
    analyticsLabel={analyticsLabel}
  />)

// <Segment
//   style={{
//     borderRadius: '20px',
//     padding: '25px 10px',
//     backgroundImage: `url(${herophoto2})`,
//     backgroundSize: 'contain',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'bottom right'
//   }} inverted
// >

//   <Grid container stackable verticalAlign='middle' centered>
//     <Grid.Row>
//       <Grid.Column largeScreen={10}>
//         <Header as='h2'>{headline}</Header>
//         <p style={{ fontSize: '1.33em' }}>{description}</p>
//         {/* <Subscribe siteName={siteName} /> */}
//         <Header>
//           {/* Available on <span role='img' aria-label='Red Apple' style={{ marginRight: '2px' }}>🍎 </span>{' & '}<span role='img' aria-label='Robot' style={{ marginRight: '2px' }}>🤖</span><small>(coming soon)</small>. */}
//           {ctaText} <span role='img' aria-label='Pointing Down'>👇</span>
//         </Header>
//         <a
//           href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=${appInstallCampaign}${siteName ? `_${siteName}` : ''}&mt=8`}
//           onClick={() => logEvent('InstallAppHeroiOSAppClicked')}
//           target='_blank' rel='noopener noreferrer'
//         >
//           <img src={appstoreimg} alt='Seemyfit app' />
//         </a>
//       </Grid.Column>
//       <Grid.Column floated='left' largeScreen={6} style={{ height: '300px' }}>
//       </Grid.Column>
//     </Grid.Row>
//   </Grid>
//   <Container as={Media} greaterThanOrEqual='computer'>
//     <a
//       href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=${appInstallCampaign}_qr${siteName ? `_${siteName}` : ''}&mt=8`}
//       onClick={() => logEvent('InstallAppHeroiOSAppQRClicked')}
//       target='_blank' rel='noopener noreferrer'
//       style={{ position: 'absolute', right: 0, top: '-25px' }}
//     >
//       <Image size='small' src={appqrcodeimg} floated='right' circular />
//     </a>
//   </Container>
//   {/* // style={{ position: 'absolute', bottom: 0, marginBottom: '-58px' }} /> */}
// </Segment>
// )

export default InstallAppHero
