import { path } from 'ramda'
import { serialise } from 'kitsu-core'

import API from '../../../services/webApi'
import { getPostAttributes } from '../../../../store/sagas/posts/PostAttributes';

const api = API.create()

export const addPost = async (request) => {
    const {
        url,
        urlOriginalPost,
        commissionLink,
        promocode,
        igPost,
        userId,
        productreferral,
        productId,
    } = request
    // const include = 'user,product'

    let product = null
    if (productId) {
        product = {
            id: productId,
            type: 'products'
        }
    }

    const config = {
        ...getPostAttributes(url, urlOriginalPost, commissionLink, promocode, igPost),
        ...(product && { product }),
        user: {
            id: userId,
            type: 'users'
        },
        ...(productreferral && {
            productreferral: {
                ...productreferral,
                type: 'productreferrals'
            }
        }),

        // --
        // params: { include } use this later
    }
    // console.log("---config", config)

    const response = await api.addPost(config)
    // console.log("---response", response)

    // FOR DEBUG
    // const response = {
    //   data:
    //   {
    //     created_at: '2020-06-21T03:49:07+00:00',
    //     id: '100',
    //     links: { self: 'http://127.0.0.1:8000/api/v1/posts/100' },
    //     product: {},
    //     type: 'posts',
    //     updated_at: '2020-06-21T03:49:07+00:00',
    //     url: 'https://myyfit.s3.fr-par.scw.cloud/d751f193-634b-4e75-93ed-334be358595b.jpg',
    //     url_original_post: 'https://www.instagram.com/p/CANJthSFctp/',
    //     user: {}
    //   }
    // }
    if (response) {
        const data = path(['data'], response)
        // do data conversion here if needed
        return data
    }
    return response
}

// export default function useUserMutation() {
//     return useMutation((request) => updateUser(request));
// }