import { getAuth } from 'firebase/auth';
import React, { useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import {
    Button, Icon, Divider, Segment, Header
} from 'semantic-ui-react';
import { ADDPRODUCT, getAddProductPageRoute, TAGPRODUCTPREFIX } from '../../../constants/routes';
import { logEvent } from '../../../services/analytics';
import { AppContext } from '../../../shared/context';


const AddLooksToProductButton = (({
    product,
    title,
    subtitle,
    callToActionLabel
}, ref) => {
    const { user: userContext } = useContext(AppContext)
    const auth = getAuth()
    const [user, loading, error] = useAuthState(auth);

    const productUrl = product && product.url
    const productId = product && product.id
    const url = productUrl ? getAddProductPageRoute(productUrl) : TAGPRODUCTPREFIX

    const titleText = title
    const subtitleText = subtitle
    const callToActionText = callToActionLabel || "Review products"
    return (
        user ? (<Segment inverted>
            <center>

                <Header as='h2'>{titleText}
                    {subtitleText && (
                        <Header.Subheader>
                            <Header as='h3'>{subtitleText}</Header>
                        </Header.Subheader>
                    )}
                </Header>

                <Link to={`${url}`}>
                    <Button
                        as='a' icon labelPosition='right'
                        onClick={() => logEvent(`addLooksToProductClicked${productId}`)}
                        color='orange'
                    >{callToActionText}
                        <Icon name='plus' />
                    </Button>
                </Link>
            </center>
        </Segment>) : <></>
    )
});

export default AddLooksToProductButton;