import React, { useContext, useEffect } from 'react';
import {
    Container
} from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, isSignInWithEmailLink } from "firebase/auth";

import { AppContext } from '../../shared/context';
import { connectUserByEmailLink } from '../../firebase/firebase';
import { logEvent } from '../../services/analytics';
import { ROOT } from '../../constants/routes';
import SignUpBanner from '../../components/SignUpBanner';
import EmailLoginForm from '../../components/EmailLoginForm';

const HomePageSignInComponent = (({ history, isInstalled, mobile }, ref) => {

    const auth = getAuth()
    const [user, loading, error] = useAuthState(auth);
    const { user: userContext, updateUser: updateUserContext, theme } = useContext(AppContext);

    useEffect(() => {
        // console.log("---------------HomePageSignInComponent useEffect");

        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, window.location.href)) {
            connectUserByEmailLink({
                auth,
                updateUserContext,
                onSuccess: () => {
                    // console.log("homePage connectUserByEmailLink onSuccess")
                    history.push({
                        pathname: ROOT
                    })
                },
                onFailure: (error) => {
                    // console.log("homePage connectUserByEmailLink onSuccess", error)
                    logEvent('homePageConnectUserByEmailLinkFailed')
                }
            })
        }

    }, [])


    // console.log("HomePageSignInComponent user:", user)
    // console.log("HomePageSignInComponent userContext:", userContext)
    // console.log("HomePageSignInComponent get user with ID:", user && user.uid)

    // TODO change to show Complete profile button

    const mobileStyle = mobile && {
        margin: '0px !important',
        marginRight: '0px !important',
        marginLeft: '0px !important',
        padding: '0px !important',
    }
    return (
        <Container style={mobileStyle} className='ui home-hero-mobile'>
            <SignUpBanner
                callToActionButton={<EmailLoginForm auth={getAuth()} btnLabelText="Join" />}
                auth={getAuth()}
                mobile />
        </Container>
    );
});

export default HomePageSignInComponent;