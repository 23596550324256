
import React, { Component } from 'react'
import {
  Form, Input
} from 'semantic-ui-react'

import { logEvent } from '../../services/analytics'
import { getUsernameRoute } from '../../constants/routes'

class TryUsername extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: ''
    }
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit() {
    logEvent(`footerTryUsername:${this.state.username}`)
    const pathname = getUsernameRoute(this.state.username)
    this.props.history.push({ pathname })
  }

  render() {
    const { inverted } = this.props
    const label = {
      content: 'seemyfit.at/'
    }
    if (!inverted) {
      label.color = 'blue'
    }

    return (
      <Form onSubmit={() => this.handleSubmit()} inverted>
        <Form.Field >
          <Input
            label={label}
            placeholder='Instagram Username'
            action={{
              color: 'blue',
              icon: 'check'
            }}
            name='username'
            value={this.state.username}
            onChange={this.handleChange}
            required
          />
        </Form.Field>
      </Form>
    )
  }
}

export default TryUsername
