import { DOMAINNAME_WITH_HTTPS } from '../constants/apiroutes'
import {
  getProductListFilterRoute, getUsernameRoute,
  getPostsListFilterRoute, getBrandsFilterRoute, getUserBrandFilterRoute
} from '../../constants/routes'
import { getSiteNameFromUrl } from './product'

// ==> PRODUCTS

export const getProductsFullUrlWithBrand = (brandName) => {
  const path = getProductListFilterRoute(brandName)
  return getFullUrlFromRoute(path)
}

// ==> Brand
export const getBrandFullUrlFromProduct = (product) => {
  const siteName = product && getSiteNameFromUrl(product.url)
  const siteNameRelativePath = getBrandsFilterRoute(siteName)
  return siteNameRelativePath && (`${DOMAINNAME_WITH_HTTPS}${siteNameRelativePath}`)
}

// ==> Posts
export const getLooksFullUrlWithBrand = (brandName) => {
  const path = getPostsListFilterRoute(brandName)
  return getFullUrlFromRoute(path)
}

// ==> USER
export const getUserFullUrlFromUsername = (username) => {
  return username && (`${DOMAINNAME_WITH_HTTPS}${getUsernameRoute(username)}`)
}

export const getUserBrandFilterFullUrl = (username, filter) => {
  return username && (`${DOMAINNAME_WITH_HTTPS}${getUserBrandFilterRoute(username, filter)}`)
}

// ==> All
export const getFullUrlFromRoute = (path) => {
  return path && (`${DOMAINNAME_WITH_HTTPS}${path}`)
}
