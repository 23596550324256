import urlUtil from 'url'

export default function isValidHttpUrl (string) {
  let url

  try {
    url = urlUtil.parse(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
