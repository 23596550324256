import React from 'react'
import {
  Header,
  Segment,
  Container, Divider, Label, Icon
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import copy from 'copy-to-clipboard'

import { logEvent } from '../../../services/analytics'

const renderWebsiteClickToCopy = (url) => {
  const onPromocodeCopied = (url, analyticsEventName) => {
    copy(url, {
      message: "Couldn't auto-copy. Please select and copy"
    })
    logEvent(analyticsEventName)
  }

  return (
    <Label
      color='grey' size='medium' basic
      onClick={() => onPromocodeCopied(url)}
    >
      <Icon name='linkify' />
      {url}
      <Label.Detail><Icon name='copy' />copy</Label.Detail>
    </Label>
  )
}

const showQuestion = (question, anwser) => (
  <>
    <Header as='h4' style={{ fontSize: '2em' }}>
      {question}
    </Header>
    <Header as='h5' style={{ marginTop: 0, fontSize: '1.33em', fontWeight: 200, whiteSpace: 'pre-line' }}>
      {anwser}
    </Header>
  </>
)
const HomeFAQ = ({ siteName }) => (
  <Segment vertical>
    <Container text>
      <Divider
        horizontal
        inverted
        style={{ textTransform: 'uppercase' }}
      >
        Frequently Asked Questions
      </Divider>

      {showQuestion('What is seemyfit?', `Seemyfit is foremost a space for fashion-lovers who embrace diversity, inclusivity and creativity, a space that celebrate you as you are. 

Seemyfit browser extension caters to just that, with one click on any online store you can see how the outfit you are about to buy look IRL on diverse body types, how people style it with other brands and believe me, this will make your relationship with your closet less stressful!`)}

      {showQuestion('How does it works?', `On your desktop or mobile, when you install seemyfit browser extension, it'll automatically show you influencers wearing different sizes of the specific item you are browsing, with True-to-size reviews. 
      You can use seemyfit.at website or mobile app to share your looks.`)}

      <Divider hidden />
      {showQuestion('How can I share my look on seemyfit?', `To share a look with our community, simply choose one of your Instagram posts and tag the URLs of the products you're wearing. You can include any affiliate links or promo codes you'd like to promote. Your look will then be featured on the same product page of the online store, as well as on our newsletter, mobile app, and website. 
      
      By sharing your looks, you can make a positive impact, contribute to a more inclusive shopping experience, and increase the reach of your affiliate links.`)}

      <Divider hidden />
      {showQuestion('What is seemyfit newsletter?', 'Seemyfit is a weekly newsletter showcasing the best outfit ideas of the week. You can hand-pick your favourite influencers from the mobile app to get their weekly highlights and must-haves right into your inbox!')}

      <Divider hidden />
      {showQuestion('Can I use my Instagram username?', 'Yes, once you link your Instagram account, your Instagram username will be used as your seemyfit username. At anytime, you can refresh your Instagram account to account for any username or profile picture changes.')}

      <Divider hidden />
      {showQuestion('How seemyfit helps brands and retailers?', `Seemyfit helps online stores and retailers to enable inclusive experiences for their online customers.
Adding seemyfit photos of an online product into its photo gallery strengthens the shopping experience even further by providing customers with an overview of how the apparel they are purchasing will fit on them, by seeing it worn by people with similar body properties. 

This combination of IRL fit and visual comprehension means that customers are far more likely to order the right fit, leaving them with positive feelings towards the retailers, fostering loyalty, and preemptively reducing returns.
`)}
      <Divider hidden />
      {showQuestion('How seemyfit helps online shoppers?', `Saves time and money: By allowing shoppers to see how products look in real life before they make a purchase, seemyfit helps them make more informed decisions and avoid buying products that don't fit or look the way they expected. This can save shoppers time and money by reducing the need to return items or buy multiple sizes to find the right fit.
    
    Increases confidence: Seeing product photos in real life and reading reviews from other users can help shoppers feel more confident in their purchase decisions. Knowing that a product looks good on someone with a similar body type or style can give shoppers peace of mind and help them feel more comfortable buying online.
    
    Improve your shopping experience and feel more confident in your purchases. Seemyfit is designed to make online shopping more inclusive and accessible for everyone.
`)}

      <Divider hidden />
      {showQuestion('Which affiliate links are supported?', 'All commissionable links are supported. Including REWARDSTYLE, AWIN, AMAZON ASSOCIATES, AVANTLINK, CJ, SHAREASALE, SHOPSTYLE COLLECTIVE and others.')}



      <Divider hidden />
      {showQuestion("What's Now?", 'Thanks to all of you who provided feedback and helped us improve and beta test seemyfit app. We want to hear from you! Please let us know what you like, need improvement and desire to see ahead within Seemyfit.')}

      <Header as='h5' style={{ marginTop: 0 }}>
        <p style={{ fontSize: '1.33em', fontWeight: 400, marginTop: 0 }}>
          You can reach out to us directly on <Link to='https://www.instagram.com/seemyfit/' target='_blank' className='white' style={{ textDecoration: 'underline' }} onClick={() => logEvent('FAQContactUsIGClicked')}>Instagram @seemyfit</Link>
          {' '}or <Link to='https://m.me/seemyfit' target='_blank' className='white' style={{ textDecoration: 'underline' }} onClick={() => logEvent('FAQContactUsEmailClicked')}>seemyfit@outlook.com</Link>.
        </p>
      </Header>
      <Header as='h5' style={{ marginTop: 0, fontSize: '1.33em', fontWeight: 200, whiteSpace: 'pre-line' }}>
        You can also share the website with friends and colleagues who might be interested in online clothing inclusivity and positive impact, by sharing the link below:
      </Header>
      {renderWebsiteClickToCopy('https://seemyfit.at/')}

    </Container>
  </Segment>
)

export default HomeFAQ
