import React, { useContext, useState } from 'react';
import { Header, Input } from 'semantic-ui-react';

import { AppContext } from '../../../../shared/context';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';


const SelectCityTab = (({
}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [cityLocation, setCityLocation] = useState((userContext && userContext.cityLocation) || '');

  const onSave = async () => {
    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = { cityLocation }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };

  return (
    <div>
      {renderSaving({ isSaving })}
      <Header as='h4' inverted>
        Add your city to filter by nearby listings
      </Header>
      <Input
        placeholder='Eg. London, UK'
        value={cityLocation}
        onChange={(e, { value }) => setCityLocation(value)}
        maxLength={200}
        autoCapitalize='characters'
      />


      {renderSave(onSave, { isSuccess, errorMessage })}
    </div>
  );
});

export default SelectCityTab;