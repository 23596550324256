import PropTypes from 'prop-types'
import React from 'react'

import { mediaStyles, MediaContextProvider } from '../../../utils/AppMediaBreakPoints'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const isNoheaderPage = (location) =>
  location && location.search && location.search.indexOf('smftnoheader') !== -1

const ResponsiveContainer = ({ children, headerHero, withHero, isInstalled, history, brandName, location, auth }) => (
  <div>
    <style>{mediaStyles}</style>
    <MediaContextProvider>
      {!isNoheaderPage(location) && (
        <>
          <DesktopMenu
            headerHero={headerHero}
            withHero={withHero}
            isInstalled={isInstalled}
            history={history}
            brandName={brandName}
            auth={auth}
          />
          <MobileMenu headerHero={headerHero} brandName={brandName} auth={auth} />
        </>)}
      {children}
    </MediaContextProvider>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node
}

export default ResponsiveContainer
