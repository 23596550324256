import React from 'react'
import { Provider } from 'react-redux'

import App from './App'

function AppContainer (props) {
  return (
    <Provider store={props.store}>
      <App />
    </Provider>
  )
}

export default AppContainer
