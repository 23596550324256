import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, FIREBASE_USERS_COLLECTION } from '../firebase';

export default async (uid, userProperties) => {
    // console.log("update firebase user with uid: ", uid)
    const userDoc = doc(db, FIREBASE_USERS_COLLECTION, uid);
    const userRef = await updateDoc(userDoc, {
        ...userProperties,
        updatedAt: serverTimestamp()
    })
    return userRef
}