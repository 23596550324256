// import config from 'config'; // TODO

const devMode = false

export const DOMAINNAME_WITH_HTTPS = 'https://seemyfit.at'

const API_LOCAL_URL = 'http://127.0.0.1:8000/api/v1/'
const API_PROD_URL = 'https://api.seemyfit.at/api/v1/'
export const API_BASE_URL = devMode ? API_LOCAL_URL : API_PROD_URL

// export const API_URL = config.apiBaseUrl;

export const PRODUCTS = 'products'
export const POSTS = 'posts'
export const USERS = 'users'
export const PRODUCTREFERRALS = 'productreferrals'

export const API = {
  ROOT: API_BASE_URL,
  PRODUCTS,
  POSTS,
  USERS,
  PRODUCTREFERRALS
}
