export const saleConditionsOptions = [
  { key: 'NWT', text: 'New With Tags', value: 'NWT' },
  { key: 'EUC', text: 'Excellent Used Condition', value: 'EUC' },
  { key: 'GUC', text: 'Good Used Condition', value: 'GUC' },
  { key: 'VUC', text: 'Very Used Condition', value: 'VUC' }
]
export function getSaleConditionFromKey(key) {
  return saleConditionsOptions.filter(sc => sc.key == key)
}

export function getSaleConditionStringFromKey(key) {
  const condition = getSaleConditionFromKey(key)
  return (condition && condition.length >= 1 && condition[0].text) || '--'
}
