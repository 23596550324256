import PropTypes from 'prop-types'
import React, { Component, useState, useContext } from 'react'
import {
    Container,
    Menu,
    Segment,
    Visibility,
    Header,
    Icon,
    Image,
    Button
} from 'semantic-ui-react'
import { Link } from "react-router-dom";

import AppIconLogo from '../../../images/appIconLogo.png'
import { logEvent } from '../../../services/analytics'
import {
    ROOT,
    PRODUCTS_LIST, POSTS_LIST,
    getBrandsFilterRoute, getPostsListFilterRoute, ADDPRODUCT, getAddProductPageRoute, TAGPRODUCTPREFIX, EDITPROFILE
} from '../../../constants/routes'
import { Media } from '../../../utils/AppMediaBreakPoints'

import EmailLoginForm from '../../../components/EmailLoginForm';
import { getAuth } from "firebase/auth";
import ShowMenuButtonsForAuthenticated from './ShowMenuButtonsForAuthenticated';
import HomepageHeading from '../../../routes/Home/components/HomePageHeading';



/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
// export default class DesktopMenu extends Component {
const DesktopMenu = ({ withHero, isInstalled, brandName, history, auth }) => {

    const [fixed, setFixed] = useState(false)
    const hideFixedMenu = () => setFixed(false)
    const showFixedMenu = () => setFixed(true)

    const location = (history && history.location)
    const path = location && location.pathname
    let isAddProductPage, isHomePage, isEditProfilePage
    if (path) {
        isAddProductPage = path.startsWith(TAGPRODUCTPREFIX)
        isHomePage = path.startsWith(ROOT)
        isEditProfilePage = path.startsWith(EDITPROFILE)
    }

    console.log("history", history)

    return (
        <Visibility
            once={false}
            onBottomPassed={showFixedMenu}
            onBottomPassedReverse={hideFixedMenu}
            as={Media} greaterThanOrEqual='computer'
        >
            <Segment
                style={{ minHeight: withHero ? 700 : 'auto', padding: '1em 0.4em 0em 1em', verticalAlign: 'middle' }}
                vertical
                className='home-hero-segment'
            >
                <Menu
                    fixed={fixed ? 'top' : null}
                    inverted={!fixed}
                    pointing={!fixed}
                    secondary={!fixed}
                    size='large'
                    style={{ height: '60px', padding: '10px', verticalAlign: 'middle', }}
                >
                    <Container style={{
                        verticalAlign: 'middle',
                        padding: '4px',
                        // marginBottom: '4px'
                    }}>
                        <Menu.Item as='span' style={{
                            verticalAlign: 'middle',
                            padding: '4px',
                            // marginBottom: '4px'
                        }}>
                            <Link
                                to={ROOT}
                                onClick={() => {
                                    // Force a refresh
                                    window.location.href = ROOT
                                    logEvent('desktopHeaderHomeClicked')
                                }}
                            >
                                <Header as='h5' inverted style={{ width: '240px', verticalAlign: 'middle' }}>
                                    <Image
                                        src={AppIconLogo} size='small' centered floated='left'
                                        style={{
                                            width: 'auto', height: '50px', verticalAlign: 'middle',
                                            paddingRight: '10px', margin: 0
                                        }}
                                    />
                                    <Header.Subheader style={{ width: '230px', paddingTop: '10px' }}>
                                        Fashion-lovers community <br />of all shapes and sizes
                                    </Header.Subheader>
                                </Header>
                            </Link>
                            {/* {isInstalled && (<Label pointing='left' color='yellow'>Tag @seemyfit and get featured on online stores and IG!</Label>)} */}
                        </Menu.Item>

                        {/* <PersonalizedNavMenuButtons brandName={brandName} auth={auth} /> */}
                        <ShowMenuButtonsForAuthenticated auth={auth}
                            menuButtons={
                                <>
                                    <Menu.Item as={Link}
                                        to={brandName ? getPostsListFilterRoute(brandName) : POSTS_LIST}
                                        onClick={() => logEvent('desktopHeaderLooksClicked')}
                                    >
                                        <Header as='h4' inverted>
                                            <Icon name='hashtag' />
                                            <Header.Content>
                                                Looks
                                                {brandName && <Header.Subheader>{brandName}</Header.Subheader>}
                                            </Header.Content>
                                        </Header>
                                    </Menu.Item>
                                    <Menu.Item as={Link}
                                        to={brandName ? getBrandsFilterRoute(brandName) : PRODUCTS_LIST}
                                        onClick={() => logEvent('desktopHeaderProductsClicked')}
                                    >
                                        <Header as='h4' inverted>
                                            <Icon name='shopping bag' />
                                            <Header.Content>
                                                Products
                                                {brandName && <Header.Subheader>{brandName}</Header.Subheader>}
                                            </Header.Content>
                                        </Header>
                                    </Menu.Item>
                                </>
                            }
                        />
                        {/* <Menu.Item as='a'
                                href={USERS_LIST}
                                onClick={() => logEvent('desktopHeaderProfilesClicked')}>
                                <Header as='h4'>
                                    <Icon name='instagram' />
                                    <Header.Content>Profiles</Header.Content>
                                </Header>
                            </Menu.Item> */}
                        <Menu.Menu position='right'>

                            {/* {!isInstalled && <InstallAddonButton style={{ marginTop: '10px' }} />} */}
                            {/* {isInstalled && <a
                                    href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=dektopMenu_qr${brandName ? `_${brandName}` : ''}&mt=8`}
                                    onClick={() => logEvent('dektopMenuQRClicked')}
                                    target='_blank' rel='noopener noreferrer'
                                // style={{ position: 'absolute', right: 0, top: '-60px' }}
                                >
                                    <img src={appqrcodeimg}
                                        style={{ height: '55px', borderRadius: '10px' }}
                                    />
                                </a>} */}

                            {!isAddProductPage && (<ShowMenuButtonsForAuthenticated auth={auth}
                                showOnlyIfProfileCompleted={true}
                                menuButtons={
                                    <Menu.Item>

                                        <Link to={TAGPRODUCTPREFIX}>
                                            <Button
                                                color="orange" compact
                                                // size='large'
                                                href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                icon labelPosition='right'
                                                style={{ marginRight: '10px' }}
                                                onClick={() => logEvent('desktopHeaderInstallAddonClicked')}
                                            >
                                                Add Product Review
                                                <Icon name='shopping bag' />
                                            </Button>
                                        </Link>

                                    </Menu.Item>
                                }
                            />)}

                            <ShowMenuButtonsForAuthenticated auth={auth}
                                showOnlyIfProfileCompleted={isEditProfilePage}
                                menuButtons={
                                    <Menu.Item>
                                        <EmailLoginForm auth={getAuth()} />
                                    </Menu.Item>
                                }
                            />

                        </Menu.Menu>
                    </Container>
                </Menu>
                {isHomePage && <HomepageHeading isInstalled={isInstalled} history={history} />}
                {/* {withHero && headerHero(false)} */}
            </Segment>
            {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> */}
        </Visibility>
    )
}

export default DesktopMenu