import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
  Container, Divider, Message, Image,
} from 'semantic-ui-react'

import { extensionInstalled } from '../../utils/chromeExtension'
import Footer from '../../layouts/components/Footer'
import ResponsiveContainer from '../../layouts/components/Responsive/ResponsiveContainer'
import HomePageHeading from '../Home/components/HomePageHeading'
import { DOMAINNAME_WITH_HTTPS } from '../../shared/constants/apiroutes'
import AcceptCookiesContainer from '../../components/AcceptCookies/AcceptCookiesContainer'
import { Media } from '../../utils/AppMediaBreakPoints'
import AppDeeplinkImg from '../../images/AppDeeplinkImg.png'

class SignUpPage extends Component {
  state = {
    isInstalled: true,
    siteName: null,
    firebaseLink: null
  }

  componentDidMount() {
    let self = this
    window.onload = function () {
      self.setState({ isInstalled: extensionInstalled() })
    }
  }

  setSitenameFilter(newSitename) {
    let siteName = newSitename
    if (this.state.siteName && this.state.siteName.includes(newSitename)) {
      siteName = null
    }

    this.setState({ siteName })
  }

  renderSignInOnMobile() {
    return (
      <Container as={Media} at='mobile'>
        <Message error>
          Please open the link received by email on your mobile to sign-in into the app. <br /><br />
          To open the link on seemyfit mobile App, scroll to the top of the page and tap the <strong>blue Open</strong> in the <strong>banner</strong> as shown below.
        </Message>
        <Image
          src={AppDeeplinkImg} centered
          style={{ verticalAlign: 'middle' }}
        />
      </Container>
    )
  }


  render() {
    const { isInstalled, siteName, firebaseLink, landingHeadlineV1Exp } = this.state
    let title = "Fashion-lovers community of all shapes and sizes 📸!"
    if (siteName) {
      title = `${siteName} See my fit 📸!`
    }


    return (
      <ResponsiveContainer
        headerHero={HomePageHeading}
        withHero={false}
        isInstalled={isInstalled}
        history={this.props.history}
        location={this.props.location}
      >
        <Helmet title={title} >
          <meta property='og:image' content={`${DOMAINNAME_WITH_HTTPS}/seemyfit-og-img.png`} />
          {firebaseLink && <meta name="apple-itunes-app" content={`app-id=id1510249148, app-argument=${firebaseLink}`} />}
        </Helmet>

        <Container>
          {!!firebaseLink && this.renderSignInOnMobile()}
          {/* <SignUpBanner
            callToActionButton={<EmailLoginForm auth={getAuth()} btnLabelText="Join" />}
            auth={getAuth()} /> */}

          <Divider hidden />
          <AcceptCookiesContainer />

        </Container>

        <Divider hidden />
        <Footer history={this.props.history} />
      </ResponsiveContainer>
    )
  }
}

export default SignUpPage
