import React, { Component } from 'react'

import { Placeholder, Grid } from 'semantic-ui-react'

class PlaceHolderSquareCard extends Component {
  render () {
    return (
      <Grid.Column
        style={{ padding: '1px' }}
        mobile={8} tablet={8} computer={4}
      >
        <Placeholder style={{ height: '180px' }} inverted>
          <Placeholder.Image />
          <div style={{
            position: 'absolute',
            bottom: '8px',
            left: '5px'
          }}
          >
            <div style={{
              width: '2.2em',
              height: '2.2em',
              backgroundColor: '#000',
              borderRadius: '2.2em'
            }}
            />
          </div>
        </Placeholder>
      </Grid.Column>
    )
  }
}
export default PlaceHolderSquareCard
