import React, { Component } from 'react'
import {
  Container,
  Grid,
  Visibility,
  Segment,
  Button,
  Icon,
  Divider,
  Header,
  Loader
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet'

import Footer from '../../../layouts/components/Footer'
import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'

import PlaceHolderSquareCard from '../components/PlaceHolderSquareCard'
import ProductCard from '../components/ProductCard'
import { logEvent, logPageView } from '../../../services/analytics'
import { PRODUCTS_LIST, getProductListFilterRoute } from '../../../constants/routes'
import { DOMAINNAME_WITH_HTTPS } from '../../../shared/constants/apiroutes'
import { getFullUrlFromRoute, getProductsFullUrlWithBrand } from '../../../shared/utils/generateFullUrl'
import InstallMobileAppBannerContainer from '../../../components/InstallMobileAppBanner/InstallMobileAppBannerContainer'
import { extensionInstalled } from '../../../utils/chromeExtension'
import UserBrandsFilter from '../../User/components/UserBrandsFilter'
import { Media } from '../../../utils/AppMediaBreakPoints'
import AddLooksToProductButton from '../components/AddLooksToProductButton'

export const getTrendingBrands = () => {
  const trendingBrands = ['zara', 'hm', 'nastygal', 'aritzia', 'urbanoutfitters', 'chanel'].sort(() => 0.5 - Math.random()).slice(0, 5)
  const segendaryBrands = ['princesspolly', 'etsy', 'prettylittlething', 'storets',
    'revolve', 'nike', 'shop437', 'mejuri', 'luxyhair', 'agolde', 'asos', 'boohoo'].sort(() => 0.5 - Math.random()).slice(0, 1)

  trendingBrands.push(segendaryBrands.pop())
  return trendingBrands
}



class ProductsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      siteName: null,
      showLoadNext: false,
      isInstalled: true,
      activeFilters: []
    }
  }

  componentDidMount() {
    const initialRouteName = PRODUCTS_LIST
    const { history } = this.props
    const location = this.props.location || (history && history.location)
    const path = location && location.pathname
    const siteName = path && path.replace(initialRouteName, '') && path.split('/').pop()

    this.setSitenameFilter(siteName)
    logPageView({ name: 'UsersList' })

    const self = this
    window.onload = function () {
      self.setState({ isInstalled: extensionInstalled() })
    }
  }

  setStructuredData(siteName) {
    const initialRouteName = PRODUCTS_LIST

    const brandName = siteName
    const itemListElement = [{
      '@type': 'ListItem',
      position: 1,
      name: 'seemyfit.at',
      item: DOMAINNAME_WITH_HTTPS
    }, {
      '@type': 'ListItem',
      position: 2,
      name: `seemyfit.at${initialRouteName}`,
      item: getFullUrlFromRoute(initialRouteName)
    }]

    if (brandName) {
      itemListElement.push({
        '@type': 'ListItem',
        position: 3,
        name: `seemyfit.at${initialRouteName}/${brandName}`,
        item: getProductsFullUrlWithBrand(brandName)
      })
    }

    const breadcrumbList = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: itemListElement
    }
    const structuredData = [breadcrumbList]
    const ldjsonDocumentId = 'googleJobNetworkScript'
    const element = document.getElementById(ldjsonDocumentId)
    if (element) {
      element.remove()
    }

    const googleJobNetworkScript = document.createElement('script')
    googleJobNetworkScript.type = 'application/ld+json'
    googleJobNetworkScript.id = ldjsonDocumentId
    googleJobNetworkScript.innerHTML = JSON.stringify(structuredData)
    document.head.appendChild(googleJobNetworkScript)
  }

  setSitenameFilter(newSitename) {
    let siteName = newSitename
    if (this.state.siteName && this.state.siteName.includes(newSitename)) {
      siteName = null
    }

    const { filterLoaded, pageNumberLoaded } = this.props
    let pageNumber = pageNumberLoaded

    if (filterLoaded) {
      if (filterLoaded.dn !== siteName) {
        pageNumber = 1
      }
    }

    this.setState({ siteName })
    this.setState({ activeFilters: siteName ? [siteName] : [] })
    this.setStructuredData(siteName)
    this.loadItems(pageNumber, siteName)
  }

  loadItems(pageNumber, siteName) {
    let filter = {
      // ws: true legacy to remove
      wr: true
    }

    if (siteName) {
      filter = {
        ...filter,
        dn: siteName
      }
    }

    this.props.getProductsAction({
      filter,
      page: {
        number: pageNumber,
        size: 30
      }
    })
  }

  onLoadNextPressed() {
    const pageNumber = this.props.pageNumberLoaded + 1
    this.loadItems(pageNumber)
  }

  onBrandFilterChange(activeFilters) {
    const lastClickedTag = activeFilters[activeFilters.length - 1] || '' // todo enable filering by more

    this.props.history.push({
      pathname: lastClickedTag ? getProductListFilterRoute(lastClickedTag) : PRODUCTS_LIST
    })
    logEvent(`productsListTagsFilter${lastClickedTag}`)

    this.setState({ activeFilters: lastClickedTag && [lastClickedTag] })

    this.setSitenameFilter(lastClickedTag)
  }

  renderPlaceholders() {
    return (
      <>
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
      </>
    )
  }

  renderItem(item) {
    // const siteName = getSiteNameFromUrl(file.url);
    // const formattedDate = moment(file.created_at).format("MMM Do YYYY")
    return (
      <Grid.Column
        key={item.id}
        id={item.id}
        style={{ padding: '0px', overflow: 'hidden' }}
        mobile={16} tablet={8} computer={5}
        className='no-padding'
      >
        <ProductCard
          key={item.id}
          product={item}
          analytisEventName='postListProductCardImageClicked'
        />
      </Grid.Column>
    )
  }

  renderFilters() {

    // return (
    //   <UserFilters
    //     siteName={this.state.siteName}
    //     onProductFilterChange={(newSitename) => this.onProductFilterChange(newSitename)}
    //   />
    // )
  }

  renderLoadMoreLooks(siteName) {
    const { lastPageReached, isNextPageLoading, activeFilters, isLoading } = this.props
    const trendingBrands = getTrendingBrands()

    return (<Segment basic clearing>
      {!lastPageReached && <Button
        floated='right' loading={isNextPageLoading}
        onClick={() => this.onLoadNextPressed()}
      >{`More ${siteName || ''} `}
        <Icon name='down arrow' />
      </Button>}
      {(!isLoading && !isNextPageLoading) && <UserBrandsFilter
        brandsList={trendingBrands}
        activeFilters={activeFilters}
        withTags
        onBrandFilterChange={(activeFilters) => {
          setTimeout(() => document && document.body && document.body.scrollTo({ top: 0, behavior: 'smooth' }), 500)
          this.onBrandFilterChange(activeFilters)
        }}
      />}
    </Segment>)
  }

  renderList() {
    const { items, isLoading } = this.props
    return (
      <Visibility onUpdate={this.handleVisibilityUpdate}>
        {/* {this.renderFilters()} */}
        <Grid centered stackable>
          {
            isLoading
              ? this.renderPlaceholders()
              : (items && items.map((p) => this.renderItem(p)))
          }
        </Grid>
      </Visibility>
    )
  }

  render() {
    const title = 'Fashion-lovers community of all shapes and sizes 📸!'
    const { siteName, isInstalled, activeFilters } = this.state
    const { isNextPageLoading, isLoading } = this.props

    const trendingBrands = getTrendingBrands()

    return (
      <ResponsiveContainer
        history={this.props.history}
        isInstalled={isInstalled}
        location={this.props.location}
      >
        <Helmet title={title} />
        <Container className='no-horizontal-margin'>
          <Divider hidden />
          <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
            <Segment basic textAlign='center'>
              <Header as='h1' inverted>
                Recently Viewed Products
                <Header.Subheader>
                  {(isNextPageLoading || isLoading) && <Loader active inline size='mini' />}
                  Recent <b>{siteName && `${siteName} `}</b> products viewed on <b>seemyfit.at</b>
                </Header.Subheader>
              </Header>
              <UserBrandsFilter
                brandsList={trendingBrands}
                activeFilters={activeFilters}
                withTags
                onBrandFilterChange={(activeFilters) => this.onBrandFilterChange(activeFilters)}
              />
            </Segment>
            {this.renderList()}
            {this.renderLoadMoreLooks(siteName)}
          </Segment>
          <Container as={Media} greaterThanOrEqual='computer'>
            {/* <InstallMobileAppBannerContainer
              history={this.props.history}
              siteName={siteName}
              title='Seemyfit - A space that celebrates you, as you are!'
              subtitle={`Get seemyfit app and discover how shoppers like you style their ${siteName ? `${siteName} ` : ''}outfits`}
              analyticsLabel='AppInviteFromProductList'
              appInstallCampaign='w_products_page'
            /> */}
            <AddLooksToProductButton
              title={`Share your ${siteName ? `${siteName}` : 'Style'}`}

            />
          </Container>
        </Container>
        <Divider hidden />
        <Footer history={this.props.history} />
      </ResponsiveContainer>
    )
  }
}
export default ProductsList
