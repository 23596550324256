export const getInstagramImageFromPost = (post) => {
  let instaImg = null

  if (!post) {
    return null
  }

  if (!post.url_original_post) {
    return post.url
  }

  // remove ? params
  const postUrl = post.url_original_post
  if (postUrl.indexOf('?') > -1) {
    instaImg = postUrl.substr(0, postUrl.indexOf('?'))
  }

  // remove trailling slashes
  instaImg = instaImg && instaImg.endsWith('/')
    ? instaImg.slice(0, -1) : instaImg

  // return image or post url
  return `${instaImg}/media/?size=l`
}

export const getUserFromResponse = (response) => {
  return response && response.user && response.user
}

export const getProductFromResponse = (response) => {
  return response && response.product && response.product
}

export const getPostsFromResponse = (response) => {
  return response && response.posts && response.posts
}

export const getPostFromResponse = (response) => {
  return response && response.post && response.post
}

export const getProductsFromPostsResponse = (posts) => {
  return posts && (posts.map
    ? posts.map(p => getProdctsFromPostResponse(p))
    : posts[0])
}

export const getProdctsFromPostResponse = (post) => {
  return post && post.product && post.product
}

export const getProductreferralsFromResponse = (response) => {
  return response && response.productreferrals && response.productreferrals
}
