import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'

import { loadUrlMetaData } from '../../../shared/services/opengraphApi'

// import { sessionExpired } from './LoginSagas'

export function* getProduct({ api, Actions, onSuccessAction, withoutInclude, withOpenGraph }, { request }) {
  const { filter, onSuccess } = request
  const include = 'productreferrals.post.user,productreferrals.post.productreferrals.product'

  const config = {
    params: {
      ...(!withoutInclude && { include }),
      ...(filter && { filter: { ...filter } })
    }
  }

  try {
    const response = yield call(api.getProducts, config)
    if (response) {
      const data = path(['data'], response)
      let productData = data
      if (productData && productData.length > 0) {
        productData = productData[0]
        // if(product.length > 1){
        // if the url returns more than one product
        // show the other below the main one
        // and on tap show the relationship looks
        // }
      }

      if (withOpenGraph && filter && (!productData.name || !productData.image_url)) {
        const openGraphMetadata = yield call(loadUrlMetaData, filter.url)
        const hybridGraph = openGraphMetadata && openGraphMetadata.hybridGraph
        let productName = hybridGraph && hybridGraph.title
        if (productName && productName.includes('|')) {
          productName = productName.split('|')[0]
        }
        const productImage = hybridGraph && hybridGraph.image
        productData.name = productName
        productData.image_url = productImage
      }

      // do data conversion here if needed
      yield put(Actions.success(productData))

      if (onSuccessAction) {
        yield put(onSuccessAction(productData))
      }

      if (onSuccess) {
        yield put(onSuccess(productData))
      }
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }
    // console.log('saga error', error)

    yield put(Actions.failure())
  }
}
