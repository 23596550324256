import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
  Divider
} from 'semantic-ui-react'

import { extensionInstalled } from '../../../utils/chromeExtension'
import Footer from '../../../layouts/components/Footer'
import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'
import HomePageHeading from '../../Home/components/HomePageHeading'
import { DOMAINNAME_WITH_HTTPS } from '../../../shared/constants/apiroutes'
import UserProfileTabs from './components/UserProfileTabs';

class UserProfilePage extends Component {
  state = {
    isInstalled: true,
    siteName: null,
    firebaseLink: null
  }

  componentDidMount() {
    let self = this
    window.onload = function () {
      self.setState({ isInstalled: extensionInstalled() })
    }
  }

  render() {
    const { isInstalled, siteName, firebaseLink, landingHeadlineV1Exp } = this.state
    let title = "Fashion-lovers community of all shapes and sizes 📸!"
    if (siteName) {
      title = `${siteName} See my fit 📸!`
    }

    return (
      <ResponsiveContainer
        headerHero={HomePageHeading}
        withHero={false}
        isInstalled={isInstalled}
        history={this.props.history}
        location={this.props.location}
      >
        <Helmet title={title} >
          <meta property='og:image' content={`${DOMAINNAME_WITH_HTTPS}/seemyfit-og-img.png`} />
          {firebaseLink && <meta name="apple-itunes-app" content={`app-id=id1510249148, app-argument=${firebaseLink}`} />}
        </Helmet>

        {/* <SearchBarContainer history={this.props.history} siteName={siteName} /> */}
        <UserProfileTabs history={this.props.history} />

        <Divider hidden />

        <Footer history={this.props.history} />
      </ResponsiveContainer>
    )
  }
}

export default UserProfilePage
