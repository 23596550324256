import React from 'react'
import { Router } from 'react-router-dom'

import './App.css'
import history from './services/history'
import Routes from './routes'
import { AppContext, AppContextProvider } from './shared/context';


import './firebase/firebase'
import './services/facebookPixel'

function App() {
  return (
    <AppContextProvider>
      <Router history={history}>
        <Routes />
      </Router>
    </AppContextProvider>
  )
}

export default App
