import React, { useContext, useState, useEffect } from 'react';
import { Header, Icon, Input, Label, Message, Segment } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

import { AppContext } from '../../../../shared/context';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';
import BodySizeSelector from '../../../../components/BodySizeSelector';
import SelectorButtons from '../../../../components/SelectorButtons';

// TODO refactor
const heightMeasurementValues = ['CM', 'FT']

const SelectBodyShapeTab = (({
}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let defaultWidthType = 0, defaultHeightType = 0
  if (userContext && userContext.bodyProperties) {
    defaultWidthType = userContext.bodyProperties.widthType
    if (defaultWidthType !== 0 && !defaultWidthType) {
      defaultWidthType = 1 // only if it's not 0 from user input
    }

    defaultHeightType = userContext.bodyProperties.heightType
    if (defaultHeightType !== 0 && !defaultHeightType) {
      defaultHeightType = 1 // only if it's not 0 from user input
    }
  }

  const [measurementId, setMeasurementId] = useState((userContext && userContext.bodyProperties && userContext.bodyProperties.measurementId) || 0);
  const [heightSize, setHeightSize] = useState((userContext && userContext.bodyProperties && userContext.bodyProperties.heightSize) || 0);
  const [waistSize, setWaistSize] = useState((userContext && userContext.bodyProperties && userContext.bodyProperties.waistSize) || 0);
  const [widthType, setWidthType] = useState(defaultWidthType)
  const [heightType, setHeightType] = useState(defaultHeightType)

  const onSave = async () => {
    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = { bodyProperties: { widthType, heightType, heightSize, waistSize, measurementId } }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };

  return (
    <div>
      {renderSaving({ isSaving })}
      <Header as='h5'>
        <Icon name='lock' />
        <Header.Content>Only Visible to you.</Header.Content>
      </Header>
      {/* <Label>
        <Icon name='lock' /> Only Visible to you.
      </Label> */}
      <br />
      <BodySizeSelector
        widthLabel="What's your body shape?"
        heightLabel='How tall are you?'
        onWidthSelected={setWidthType}
        onHeightSelected={setHeightType}
        selectedWidthDefault={widthType}
        selectedHeightDefault={heightType}
      />
      <br /><br />
      <Header as='h4' inverted>
        {"Measurement Type? "}
        <SelectorButtons
          firstLabel='CM'
          secondLabel='FT'
          selectedIndexDefault={(userContext && userContext.bodyProperties && userContext.bodyProperties.measurementId) || 0}
          onSelected={setMeasurementId}
        />
      </Header>
      <br />
      <div>
        <Header as='h4' inverted>
          What's your height? <strong>{heightSize > 0 ? heightSize : ''} {heightMeasurementValues[measurementId]}</strong>
        </Header>
        <Input
          label={`height (${heightMeasurementValues[measurementId]})`}
          placeholder={`Eg. ${measurementId === 0 ? '130' : '50'}`}
          value={heightSize > 0 ? heightSize : ''}
          onChange={(e, { value }) => setHeightSize(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      <br />

      <div>
        <Header as='h4' inverted>
          What's your Waist size? <small>{waistSize > 0 ? waistSize : ''} {heightMeasurementValues[measurementId]}</small>
        </Header>
        <Input
          label={`dress size (${heightMeasurementValues[measurementId]})`}
          placeholder={`Eg. ${measurementId === 0 ? '74' : '29'}`}
          value={waistSize > 0 ? waistSize : ''}
          onChange={(e, { value }) => setWaistSize(value)}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      {renderSave(onSave, { isSaving, isSuccess, errorMessage })}
    </div>
  );
});

export default SelectBodyShapeTab;