// import config from 'config'; // TODO

import { DOMAINNAME_WITH_HTTPS } from './apiroutes'

const clientId = '227687775154933'
const clientSecret = '7e2c7e5e459d7c2fffd8fc8c803891a3'
const redirectPath = 'ig/auth'
const redirectUri = `${DOMAINNAME_WITH_HTTPS}/${redirectPath}`

export const INSTAGRAM_API = {
  clientId,
  clientSecret,
  redirectUri,
  redirectPath
}
