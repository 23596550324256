import { replaceUsername, replaceSitename, replaceUrl, replaceBrands, replaceUsernameAndBrands } from '../utils/routes'

//
export const ROOT = '/'
export const FAQ = '/How-to-see-online-clothes-on-different-body-types'
export const DOWNLOAD_APP = '/how-to-use-see-my-fit'
export const PRIVACY = '/pages/privacy'
export const PRIVACY_PATH_LEGACY = '/privacy'
export const TERMSOFUSE = '/pages/termsofuse'
export const GDPR = '/ig/ddr/'
export const ADMIN = '/admn'
export const IG_AUTH = '/ig/auth/'

export const DETAIL_PAGE = `${ROOT}:url/:filter?`
export const getDetailPageRoute = (url) => `${ROOT}${url}`

// login sign-up
export const LOGIN = '/login'
export const SIGNUP = '/join-seemyfit'
export const REQUESTPASSWORD = '/requestpassword'
export const EDITPROFILE = '/profile'

// products

// posts
const POSTS = 'looks'
export const POSTS_LIST = `/${POSTS}`
export const POSTSFILTERLIST = `${ROOT}${POSTS}/:sitename`
export const getPostsListFilterRoute = (filter) => replaceSitename(POSTSFILTERLIST, filter)

export const POST_DETAIL = `${ROOT}:url`
export const getPostRoute = (filter) => replaceUrl(POST_DETAIL, filter)

// users
export const USERS_LIST = '/profiles'
export const USERPROFILE = `${ROOT}@:username/:brands?`
export const getUsernameRoute = (filter) => replaceUsernameAndBrands(USERPROFILE, filter)
export const getUserBrandFilterRoute = (username, filter) => replaceBrands(replaceUsername(USERPROFILE, username), filter)

// products
const PRODUCTS = 'products'
export const PRODUCTS_LIST = `/${PRODUCTS}`
export const PRODUCTSFILTERLIST = `${ROOT}${PRODUCTS}/:sitename`
export const PRODUCT_DETAIL = `${ROOT}:url`
export const getProductListFilterRoute = (filter) => replaceSitename(PRODUCTSFILTERLIST, filter)
// images listing
export const BRANDSFILTERLIST = `${ROOT}brands/:sitename`
export const getBrandsFilterRoute = (filter) => replaceSitename(BRANDSFILTERLIST, filter)

// export const getImageDetailRoute = (name, id) => getImagesRoute(IMAGEDETAIL, name, id);
// export const getEditImageRoute = (name, id) => getImagesRoute(EDITGAME, name, id);
// export const getGByCityRoute = city => getImagesCityRoute(IMAGESCITY, city);

// images writing
export const ADDIMAGE = `${PRODUCTS_LIST}/share-your-fit`
export const TAGPRODUCTPREFIX = `/${PRODUCTS}/tag-product`
export const ADDPRODUCT = `${TAGPRODUCTPREFIX}/:url?`
// export const getAddProductPageRoute = (url) => `${ADDPRODUCT}/${url}`

export const getAddProductPageRoute = (filter) => ADDPRODUCT.replace(':url?', `${filter}`)


