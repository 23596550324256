
import React from 'react'
import {
  Message, Button
} from 'semantic-ui-react'

import { logEvent } from '../../services/analytics'

const onAcceptTapped = (setCookiesAcceptedAction) => {
  logEvent('AcceptCookiesAccepted')
  setCookiesAcceptedAction && setCookiesAcceptedAction()
}

const renderMessage = (setCookiesAcceptedAction) => (
  <center>
    <Message color='black' compact>
      <p>
        <span dangerouslySetInnerHTML={{ __html: '<!--googleoff: index-->' }} />
        We use cookies to ensure you get the best experience on our website
        <Button
          as='span'
          // floated='right'
          compact
          size='tiny'
          onClick={() => onAcceptTapped(setCookiesAcceptedAction)}
          style={{ marginLeft: '5px' }}
        >OK
        </Button>
        <span dangerouslySetInnerHTML={{ __html: '<!--googleon: index-->' }} />
      </p>
      {/* <Divider clearing hidden /> */}
    </Message>
  </center>)

const AcceptCookies = ({ isCookiesAccepted, setCookiesAcceptedAction }) =>
  isCookiesAccepted ? <></> : renderMessage(setCookiesAcceptedAction)

export default AcceptCookies
