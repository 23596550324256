import React from 'react'
import {
  Header,
  Grid,
  Segment,
  Image,
  Container
} from 'semantic-ui-react'

import appqrcodeimg from '../../images/appqrcode.png'
import { logEvent } from '../../services/analytics'
import { Media } from '../../utils/AppMediaBreakPoints'
import MailchimpSubscripe from '../MailchimpSubscripe'

const DownloadAppBannerLeftImg = ({ siteName, headline, description, ctaText, appInstallCampaign, heroImg, analyticsLabel }) => (
  <Segment
    style={{
      borderRadius: '20px',
      padding: '25px 10px',
      backgroundImage: `url(${heroImg})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top left'
    }} inverted
  >

    <Grid container stackable verticalAlign='middle' centered>
      <Grid.Row>
        <Grid.Column floated='left' largeScreen={6} style={{ height: '300px' }} />
        <Grid.Column largeScreen={10}>
          <Header as='h2'>{headline}</Header>
          <p style={{ fontSize: '1.33em' }}>{description}</p>
          {/* <Subscribe siteName={siteName} /> */}
          {/*  <Header>
            {ctaText} <span role='img' aria-label='Pointing Down'>👇</span>
          </Header>
        <a
            href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=${appInstallCampaign}${siteName ? `_${siteName}` : ''}&mt=8`}
            onClick={() => logEvent(`${analyticsLabel}iOSAppstoreClicked`)}
            target='_blank' rel='noopener noreferrer'
          >
            <img src={appstoreimg} alt='Seemyfit app' />
          </a> */}

          <MailchimpSubscripe />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Container as={Media} greaterThanOrEqual='computer'>
      <a
        href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=${appInstallCampaign}_qr${siteName ? `_${siteName}` : ''}&mt=8`}
        onClick={() => logEvent(`${analyticsLabel}iOSAppQRClicked`)}
        target='_blank' rel='noopener noreferrer'
        style={{ position: 'absolute', left: 0, bottom: '-25px' }}
      >
        <Image size='small' src={appqrcodeimg} floated='right' circular />
      </a>
    </Container>
    {/* // style={{ position: 'absolute', bottom: 0, marginBottom: '-58px' }} /> */}
  </Segment>
)

export default DownloadAppBannerLeftImg
