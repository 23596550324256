
import React, { Component } from 'react'
import {
  Label, Icon, Popup
} from 'semantic-ui-react'
import copy from 'copy-to-clipboard'

import { logEvent } from '../../services/analytics'

class PromocodeLabel extends Component {
  onPromocodeCopied(promocode, analyticsEventName) {
    copy(promocode, {
      message: "Couldn't auto-copy. Please select and copy"
    })
    logEvent(`${analyticsEventName}_${promocode}`)
  }

  renderTrigger() {
    const { promocode, promocodeDescription, color, size, pointing, basic } = this.props

    const colorValue = color || 'black'
    const sizeValue = size || 'mini'

    return (
      <Label
        color={colorValue} size={sizeValue} pointing={pointing} basic={basic}
        onClick={() => this.onPromocodeCopied(promocode)}
      >
        <Icon name='tags' />
        {promocode}
        <Label.Detail><Icon name='copy' /></Label.Detail>
        {promocodeDescription && (
          <Label size='tiny' color={colorValue}>
            <Label.Detail>{promocodeDescription}</Label.Detail>
          </Label>)}
      </Label>
    )
  }

  renderContent() {
    const { promocode, promocodeDescription, color, size, pointing, basic } = this.props

    const colorValue = color || 'black'
    const sizeValue = size || 'mini'

    return (
      <Label
        color={colorValue} size={sizeValue} pointing={pointing} basic={basic}
        onClick={() => this.onPromocodeCopied(promocode)}
      >
        <Icon name='tags' />
        {promocode}
        {promocodeDescription && (<Label.Detail>{promocodeDescription}</Label.Detail>)}
      </Label>
    )
  }

  render() {
    return (
      <Popup
        trigger={this.renderTrigger()}
        content=' Promo Code Copied!'
        on='click'
        size='mini'
        inverted
        hideOnScroll
      />
    )
  }
}

export default PromocodeLabel
