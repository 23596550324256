
import React, { Component } from 'react'
import {
  Segment, Header, Container
} from 'semantic-ui-react'
import { QRCode } from 'react-qrcode-logo'

import AppIconLogo from '../../images/appIconLogo.png'
import appstoreimg from '../../images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'
import { logEvent } from '../../services/analytics'
import { Media } from '../../utils/AppMediaBreakPoints'

// const options = [
//   { key: 'produt', text: 'Product', value: 'produt' },
//   { key: 'post', text: 'Instagram Post', value: 'post' },
//   { key: 'profile', text: 'Profile', value: 'profile' },
// ]

class InstallMobileAppBanner extends Component {
  render() {
    const { title, subtitle, siteName, appInstallCampaign } = this.props

    const headerTitle = title
    const headerSubtitle = subtitle

    const url = `https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=${appInstallCampaign}${siteName ? `_${siteName}` : ''}&mt=8`

    return (
      <Segment
        style={{
          minHeight: '100px',
          borderRadius: '20px'
          // background: '#a5673f0d',
          // border: '1px solid rgba(165, 103, 63, 0.6)'
          // paddingTop: 0
        }} inverted textAlign='center'
      >
        <Container as={Media} greaterThanOrEqual='computer'>
          <QRCode
            value={url}
            logoImage={AppIconLogo}
          />
        </Container>
        <Header as='h3' inverted style={{ marginTop: '5px' }}>
          {headerTitle}
          <Header.Subheader>{headerSubtitle}</Header.Subheader>
        </Header>
        <a
          href={url}
          onClick={() => logEvent(`InstallMobileAppBanner${appInstallCampaign}iOSAppClicked`)}
          target='_blank' rel='noopener noreferrer'
        >
          <img src={appstoreimg} alt='Seemyfit app' />
        </a>
      </Segment>
    )
  }
}

export default InstallMobileAppBanner
