import React, { useContext, useState } from 'react';

import { AppContext } from '../../../../shared/context';
import FitChallengesSelector from '../../../../components/FitChallengesSelector';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';
import { Header, Icon } from 'semantic-ui-react';

const SelectFitChallengesTab = (({
}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fitChallenges, setFitChallenges] = useState((userContext && userContext.fitChallenges) || [1, 1, 1, 1]);

  const onSave = async () => {
    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = { fitChallenges }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };

  return (
    <div>
      {renderSaving({ isSaving })}
      <Header as='h5'>
        <Icon name='lock' />
        <Header.Content>Only Visible to you.</Header.Content>
      </Header>
      <FitChallengesSelector
        initialValues={fitChallenges}
        onChange={setFitChallenges}
      />
      {renderSave(onSave, { isSuccess, errorMessage })}
    </div>
  );
});

export default SelectFitChallengesTab;