import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
    Container,
    Divider,
    Dimmer,
    Loader,
    Segment,
    Message,
    Grid,
    Image,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import TryItNowDesktopOnly from '../../../components/TryItNowDesktopOnly'
import { getDetailPageRoute, getUsernameRoute, POSTS_LIST, } from '../../../constants/routes'
import Footer from '../../../layouts/components/Footer'

import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'
import { DOMAINNAME_WITH_HTTPS } from '../../../shared/constants/apiroutes'
import { logEvent, logPageView } from '../../../services/analytics'
import { getUserBrandFilterFullUrl, getUserFullUrlFromUsername } from '../../../shared/utils/generateFullUrl'
import { getUsernameFromInstagramUrl, isPartialInstagramPost, removeIGDomainName } from '../../../shared/utils/instagramUrl'
import { getProdctsFromPostResponse, getProductFromResponse, getProductreferralsFromResponse, getUserFromResponse } from '../../../shared/utils/post'
import { getBrandNameFromUrl, getSiteNameFromUrl } from '../../../shared/utils/product'
import { extensionInstalled } from '../../../utils/chromeExtension'
import SelectedPost from '../../Products/components/SelectedPost'
import PlayIcon from '../../../images/InstagramPlay.svg'
// import RelatedPostsContainer from '../../Posts/RelatedPosts/RelatedPostsContainer'
// import { getSiteNameFromUrl } from '../../../shared/utils/product'


class PostDetailPage extends Component {
    state = {
        statusText: null,
        isInstalled: true,
        isGetIGPostLoading: false,
        selectedPost: null,
        product1: null,
        product2: null,
        product3: null,
        postUrl: null
    }

    componentDidMount() {
        this.loadPost()
        let self = this
        window.onload = function () {
            self.setState({ isInstalled: extensionInstalled() })
        }
    }

    loadPost() {
        const { pathname, search } = this.props.location
        let isUrlChanged = false
        let url = `${pathname.substr(1)}${search}`
        if (!url.includes('https://')) {
            url = url.replace('https:/', 'https://')
            isUrlChanged = true
        }

        if (url.includes('?')) {
            url = url.substring(0, url.indexOf('?'))
            isUrlChanged = true
        }

        if (!url.endsWith('/')) {
            url += '/'
            isUrlChanged = true
        }

        if (url.startsWith('https://www.instagram.com/') || url.startsWith('https://instagr.am')) {
            url = removeIGDomainName(url)
            isUrlChanged = true
        }

        // reconstruct original IG url
        const igUrlWithDomainName = `https://www.instagram.com/${url}`

        const isIGPost = isPartialInstagramPost(igUrlWithDomainName)
        if (!isIGPost) {
            const username = getUsernameFromInstagramUrl(igUrlWithDomainName)
            this.props.history.push({
                pathname: getUsernameRoute(username),
            })
            return
        }

        if (isUrlChanged) {
            this.props.history.push({
                pathname: getDetailPageRoute(url),
            })
        }

        this.setState({ postUrl: igUrlWithDomainName, postIGId: url })

        this.props.getPostAction({
            filter: {
                pid: url
            },
            onSuccess: (posts) => this.onPostReady(posts)
        })
    }

    onProductreferralAdded() {
        this.loadPost()
    }

    onPostReady(posts) {
        const selectedPost = posts && posts[0]
        selectedPost && this.setState({ selectedPost, postNotFound: true })
        selectedPost && logPageView({ name: 'PostDetailPage', id: selectedPost && selectedPost.id })
        this.setStructuredData(selectedPost)
        this.refreshIGPost(selectedPost, this.state.postUrl)
        // this.loadProducts(posts) // disabled this can be remove later as the products are included in the reponse now
    }

    refreshIGPost(selectedPost, postUrl) {
        // Post not found or if it's a video
        const isVideo = selectedPost && selectedPost.post_is_video === '1'

        let postNeedsRefersh = false
        if (selectedPost && selectedPost.url_original_post) {
            const isMissingCaption = !selectedPost.post_caption
            // After every API change this can be used to refresh 
            // the existing post and populate the new attributes
            // eg. const isMissingVideoUrl = !post.post_video_url
            postNeedsRefersh = isMissingCaption
        }

        if (isVideo) {
            postNeedsRefersh = true
        }

        if (!selectedPost) {
            // if the post doesn't exist don't create it
            this.setState({ postNotFound: true })
            postNeedsRefersh = false
        }

        postNeedsRefersh = false // this is to stop syncing from IG
        if (postNeedsRefersh) {
            const requestStep1 = {
                url: postUrl,
                onSuccess: (data) => this.onSuccessGetPostStep1(data, selectedPost)
            }
            this.props.getPostStep1Action(requestStep1)
        }
    }

    onSuccessGetPostStep1(igPost, selectedPost) {
        const postId = selectedPost && selectedPost.id

        // update existing post
        if (postId) {
            const requestStep2 = {
                postId,
                igPost, // TODO UPDATE
                commissionLink: selectedPost.commissionLink,
                promocode: selectedPost.promocode,
                onSuccess: (data) => this.onSuccessAddPostStep2(data, selectedPost, null)
            }

            this.props.updatePostStep2(requestStep2)
        } else {
            // add new post
            const owner = igPost && igPost.owner
            const requestStep2 = {
                urlOriginalPost: this.state.postUrl,
                url: igPost && igPost.igPostImgUrl,
                igPost,
                user: owner,
                onSuccess: (data) => this.onSuccessAddPostStep2(data, selectedPost, owner)
            }

            this.setState({ isGetIGPostLoading: true })
            this.props.addPostStep2(requestStep2)
        }
    }

    onSuccessAddPostStep2(igPost, selectedPost, owner) {
        this.setState({ isGetIGPostLoading: false })
        this.mergeSelectedPostWithResponse(igPost, selectedPost, owner)
    }

    mergeSelectedPostWithResponse(igPost, selectedPost, owner) {
        if (selectedPost) {
            let post = null
            post = selectedPost
            post.post_video_url = igPost.post_video_url
            this.setState({ selectedPost: post })
        } else {
            const post = {
                ...igPost,
                user: { data: owner }
            }
            this.setState({ selectedPost: post })
        }
    }

    // todo remove this is unused anymore
    // loadProducts(posts) {
    //     const products = getProductsFromPostsResponse(posts)

    //     const product1 = products && products[0]
    //     const product2 = products && products[1]
    //     const product3 = products && products[2]

    //     const product2onSuccess = (product) => {
    //         const post2 = posts && posts[1]
    //         this.setState({ product2: product, promocode2: post2.promocode })
    //         product3 && this.props.getProductAction({
    //             filter: { url: product3.url },
    //             onSuccess: (product) => {
    //                 const post3 = posts && posts[2]
    //                 this.setState({ product3: product, promocode3: post3.promocode })
    //             }
    //         })
    //     }

    //     const product1onSuccess = (product) => {
    //         const post1 = posts && posts[0]
    //         this.setState({ product1: product, promocode1: post1.promocode })
    //         product2 && this.props.getProductAction({
    //             filter: { url: product2.url },
    //             onSuccess: product2onSuccess
    //         })
    //     }

    //     product1 && this.props.getProductAction({
    //         filter: {
    //             url: product1.url
    //         },
    //         onSuccess: product1onSuccess
    //     })
    // }

    setStructuredData(selectedPost) {
        if (!selectedPost) {
            return
        }

        const products = getProdctsFromPostResponse(selectedPost)

        const breadcrumbList = this.getBreadcrumbStructuredData(selectedPost, products)
        const structuredData = [
            breadcrumbList,
        ];

        const ldjsonDocumentId = "googleJobNetworkScript"
        const element = document.getElementById(ldjsonDocumentId)
        if (element) {
            element.remove();
        }

        const googleJobNetworkScript = document.createElement("script")
        googleJobNetworkScript.type = "application/ld+json"
        googleJobNetworkScript.id = ldjsonDocumentId
        googleJobNetworkScript.innerHTML = JSON.stringify(structuredData)
        document.head.appendChild(googleJobNetworkScript)
    }

    getBreadcrumbStructuredData(post, products) {
        if (!post) return

        const itemListElement = [{
            "@type": "ListItem",
            "position": 1,
            "name": "seemyfit.at",
            "item": DOMAINNAME_WITH_HTTPS
        }]

        const user = getUserFromResponse(post)
        user && itemListElement.push({
            "@type": "ListItem",
            "position": 2,
            "name": user.instagram_username,
            "item": getUserFullUrlFromUsername(user.instagram_username)
        })

        if (products) {
            const product = products && (products.map ? products[0] : products)
            const brandName = product && getBrandNameFromUrl(product.url)
            if (brandName) {
                itemListElement.push({
                    "@type": "ListItem",
                    "position": 3,
                    "name": brandName,
                    "item": getUserBrandFilterFullUrl(user.instagram_username, brandName)
                })
            }
        }

        return {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": itemListElement
        }
    }

    renderPost(post, selectedPost) {
        let isSelectedPost = false
        if (selectedPost) {
            isSelectedPost = selectedPost.id === post.id
        }

        const lookUrl = removeIGDomainName(post.url_original_post)
        return (
            <Grid.Column
                key={post.id} id={post.id}
                style={{
                    overflow: 'hidden',
                    padding: '1px'
                }}
                mobile={8} tablet={8} computer={4}
            >
                {/* {isSelectedPost && this.renderFocusText('Selected', 'caret up')} */}
                <a
                    href={`/${lookUrl}`}
                    onClick={() => logEvent('postDetailRelatedLooksImageClicked')}
                >
                    <div style={{
                        opacity: isSelectedPost ? '0.2' : 1,
                        float: 'right',
                        borderRadius: '4px',
                        backgroundImage: `url(${post.url})`,
                        backgroundSize: 'cover',
                        height: '150px',
                        width: '100%',
                        backgroundPosition: 'center',
                        margin: '2px'
                    }}
                    />
                    {
                        post && post.post_is_video === '1' && (
                            <div
                                className='post-play-icon'
                            >
                                <Image src={PlayIcon} size='tiny' spaced alt='Play Instagram Video' />
                            </div>
                        )
                    }
                </a>
            </Grid.Column >
        )
    }

    renderPosts(allLooks, selectedPost) {
        return (
            allLooks && allLooks.length > 0 && (
                <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
                    <Segment basic textAlign='center'>
                        {/* <Header as='h2' inverted>
                            Styled with
                            <Header.Subheader>others</Header.Subheader>
                        </Header> */}
                        <Grid style={{ padding: '0px' }} centered>
                            {allLooks && allLooks
                                .map((p) => this.renderPost(p, selectedPost))}
                        </Grid>
                    </Segment>
                </Segment>)
        )
    }

    renderResponse(post) {
        return post && <SelectedPost post={post} onProductreferralAdded={() => this.onProductreferralAdded()} />
    }

    renderLoading(isLoading) {
        return (
            <Dimmer active={isLoading}>
                <Loader size='mini'>Loading ...</Loader>
            </Dimmer>
        )
    }

    render404() {
        const { postIGId } = this.state
        return (
            <Segment basic textAlign='center'>
                <Message warning={true} compact>
                    Sorry we couldn't find any look for <b>{postIGId}</b> 😐 <br /><br />
                    <Link to={POSTS_LIST}>You can find the list of looks here</Link> <br />
                </Message>
            </Segment>
        )
    }

    render() {
        const { isInstalled,
            selectedPost, isGetIGPostLoading, postNotFound } = this.state
        const { posts, isLoading } = this.props
        const post = selectedPost || (posts && posts[0])

        const productreferrals = getProductreferralsFromResponse(post) || []
        const product1 = productreferrals && productreferrals[0] && getProductFromResponse(productreferrals[0])

        const allLooksOfProductsArray = productreferrals &&
            productreferrals.filter(pr => pr.product).map(pr => pr.product && pr.product.productreferrals)

        const allLooksOfProduct = allLooksOfProductsArray && allLooksOfProductsArray.reduce((a, b) => a.concat(b), [])
        const allLooks = allLooksOfProduct && allLooksOfProduct.filter(pr => pr && pr.post).map(pr => pr && pr.post)

        const allLooksDedup = allLooks && allLooks.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)

        return (
            <ResponsiveContainer
                isInstalled={isInstalled}
                history={this.props.history}
                location={this.props.location}
            >
                {product1 && this.renderMeta(product1, post)}
                <Container>
                    <Divider hidden />
                    <Segment basic style={{ minHeight: '200px', borderRadius: '20px', padding: '0' }}>
                        {this.renderLoading(isLoading || isGetIGPostLoading)}
                        {this.renderResponse(post)}
                        {postNotFound && (!post && !isLoading) && this.render404()}
                    </Segment>
                    <div>
                        {allLooksDedup && this.renderPosts(allLooksDedup, selectedPost)}
                    </div>
                    {!isInstalled && <TryItNowDesktopOnly />}
                    {/* {siteName && (<RelatedPostsContainer // todo fix this
                        siteName={siteName}
                        history={this.props.history} />)
                    } */}
                </Container>
                <Divider hidden />
                <Footer history={this.props.history} />
            </ResponsiveContainer >
        )
    }

    renderMeta(product, post) {
        const siteName = getSiteNameFromUrl(product.url)

        // const posts = getPostsFromResponse(product)
        // const reviews = posts && posts.map()

        const user = getUserFromResponse(post)
        const postCaption = post && post.post_caption
        const title = `${product.name} by @${user && user.instagram_username}`
        const description = `${postCaption} - seemyfit.at`

        return (
            <>
                <Helmet title={title} >
                    <meta property='og:url' content={window.location.href} />
                    <meta property='og:type' content='product' />
                    {product && product.name && <meta property='og:title' content={title} />}
                    {product && product.name && <meta property="og:description" content={description} />}
                    <meta property='og:image' content={product.image_url} />
                    <meta property='og:image' content={post.url} />
                    <meta property="product:brand" content={siteName} />
                    <meta property="product:condition" content="new" />
                    <meta property="availability" content="available for order" />
                    {product && product.price && <meta property="product:price:amount" content={product.price} />}
                    {product && product.currency && <meta property="product:price:currency" content={product.currency} />}
                    {/* <meta property="product:retailer_item_id" content="facebook_tshirt_001"> */}
                    {/* <meta property="product:item_group_id" content="fb_tshirts"/> */}
                </Helmet>
            </>
        )
    }

}
export default PostDetailPage