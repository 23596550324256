import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import configureStore from './createStore'
import rootSaga from '../sagas/'
import ReduxPersist from './config/reduxPersist'

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  // nav: require('./NavigationRedux').reducer,
  // login: require('./LoginRedux').reducer,
  sessionState: require('./SessionRedux').reducer,

  postsState: require('./posts/PostsListRedux').reducer,
  postDetailState: require('./posts/PostDetailRedux').reducer,

  addPostGetProductState: require('./posts/add/GetProductStep1Redux').reducer,
  addPostGetOwnerStep2State: require('./posts/add/GetOwnerStep2Redux').reducer,
  addPostStep3State: require('./posts/add/AddPostStep3Redux').reducer,

  productDetailState: require('./products/ProductDetailRedux').reducer,
  productsState: require('./products/ProductsListRedux').reducer,
  updateProductState: require('./products/UpdateProductRedux').reducer,
  relatedPostsState: require('./products/ProductRelatedPostsRedux').reducer,

  userDetailState: require('./users/UserDetailRedux').reducer,
  usersState: require('./users/UsersListRedux').reducer,
  instagramUserDetailState: require('./users/InstagramUserDetailRedux').reducer
})

export default () => {
  let finalReducers = reducers
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig
    finalReducers = persistReducer(persistConfig, reducers)
  }

  let { store, sagasManager, sagaMiddleware } = configureStore(finalReducers, rootSaga)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas)
      })
    })
  }

  return store
}
