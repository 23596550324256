import React, { useState } from 'react'
import {
  Form, Message, Header, Icon, Button
} from 'semantic-ui-react'
// import firebase from 'firebase'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const MailchimpSubscripe = () => {
  const [email, setEmail] = useState('')
  // Connect user to firebase
  // const auth = firebase.auth()
  // const [currentUser, currentUserLoading] = useAuthState(auth)
  // const [
  //   createUserWithEmailAndPassword,
  //   user,
  //   loading,
  //   error
  // ] = useCreateUserWithEmailAndPassword(auth)

  const url = 'https://seemyfit.us1.list-manage.com/subscribe/post?u=55fdca9bc449b194d48508bb9&amp;id=a3715de311'

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => renderForm(subscribe, status, message, email, setEmail)}
    />

  )
}

const renderForm = (subscribe, status, message, email, setEmail) => {
  const error = status === 'error'
  const loading = status === 'sending'
  const success = status === 'success'

  if (error) {
    return renderError({ message })
  }

  if (success) {
    return renderMessage(message)
  }

  return (
    <div>
      <Form
        size='tiny'
        success={!error}
        loading={loading}
        style={{ paddingTop: 15 }}
        onSubmit={() => {
          console.log('renderForm formData', { email })
          return subscribe({ EMAIL: email })
        }}
      >
        <Form.Group>
          <Form.Input
            label={(<Header>
              Enter your email below to subscribe <span role='img' aria-label='Pointing Down'>👇</span>
            </Header>)}
            placeholder='Your email'
            name='EMAIL'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            iconPosition='left'
            action
          >
            <Icon name='at' />
            <input />
            <Button type='submit'>Subscribe</Button>
          </Form.Input>
          {/* <Form.Button content='Subscribe' size='tiny' type='submit' /> */}
        </Form.Group>
      </Form>

      {/* {status === "sending" && <div style={{ color: "blue" }}>sending...</div>} */}
      {/* {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }} />} */}
      {/* {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>} */}
    </div>
  )
}

const renderMessage = (message) => {
  return (
    <Message success>
      {message}
    </Message>
  )
}

const renderError = (error) => {
  console.log(error)
  return (
    <Message info>
      {error && error.message && <span dangerouslySetInnerHTML={{ __html: error.message }} />}
    </Message>
  )
}

export default MailchimpSubscripe
