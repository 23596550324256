import React, { Component } from 'react'
import {
  Button,
  Icon
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { logEvent } from '../services/analytics'
import TryUsername from '../layouts/components/TryUsername'
import { ADDPRODUCT, getAddProductPageRoute } from '../constants/routes'

/* eslint-disable react/no-multi-comp */
/* Heads up! Home uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
class InstallAddonButton extends Component {
  renderButton() {
    return (
      <Button
        as='a'
        primary
        size='large'
        href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk'
        target='_blank'
        rel='noopener noreferrer'
        icon labelPosition='left'
        style={{ height: '40px' }}
        onClick={() => logEvent('desktopHeaderInstallAddonClicked')}
      >
        <Icon name='chrome' />
        Try it on Chrome
      </Button>
    )
  }

  renderTryUsername() {
    return (
      <span style={{ marginTop: '6px' }}>
        {/* <Label pointing='right' basic>Turn your feed into a <b>clickable</b>, <b>optimized</b> <b>landing page</b>!</Label> */}
        <TryUsername history={this.props.history} />
      </span>
    )
  }

  // renderAddProduct() {
  //   return (
  //     <Link to={`/${getAddProductPageRoute(ADDPRODUCT)}`}>
  //       <Button
  //         as='a'
  //         primary
  //         size='large'
  //         target='_blank'
  //         rel='noopener noreferrer'
  //         icon labelPosition='right'
  //         style={{ height: '40px', marginTop: '5px' }}
  //         onClick={() => logEvent('desktopHeaderInstallAddonClicked')}
  //       >
  //         Tag Product
  //         <Icon name='shopping bag' />
  //       </Button>
  //     </Link>
  //   )
  // }

  render() {
    if (!this.props.isInstalled) {
      return this.renderButton()
    } else {
      // return this.renderTryUsername()
      // return this.renderAddProduct()
      return <></>
    }
  }
}

export default InstallAddonButton
