
import React from 'react'
import {
  Segment
} from 'semantic-ui-react'
import InstallMobileAppBannerContainer from '../../components/InstallMobileAppBanner/InstallMobileAppBannerContainer'
import AddLooksToProductButton from '../../routes/Products/components/AddLooksToProductButton'

import { Media } from '../../utils/AppMediaBreakPoints'
// import TryUsername from './TryUsername'

const FooterMobile = (props) => (
  <Segment as={Media} at='mobile' inverted vertical textAlign='center'>
    {/* <InstallMobileAppBannerContainer
      title='Seemyfit - A space that celebrates you, as you are!'
      subtitle={(<>Grow your online presence as a fashion creator and showcase your best looks!<br /></>)}
      analyticsLabel='AppInviteFromMobileFooter'
      appInstallCampaign='w_footer_mobile'
    /> */}
    <AddLooksToProductButton
    />
    {/* <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <Header inverted as='h4' content='Check your Page' />
            <List link inverted>
              <List.Item as='span'>Enter your <b>Instagram username</b> below and add products to your Instagram feed! <span role='img' aria-label='Woman Gesturing OK'>👇</span></List.Item>
            </List>
            <TryUsername history={props.history} inverted />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
    </Container> */}
    <center>©  2022 Logired</center>
  </Segment>
)

export default FooterMobile
