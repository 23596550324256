import React from 'react'
import {
  Container,
  Header,
  Segment,
  Divider
} from 'semantic-ui-react'

import ResponsiveContainer from '../../layouts/components/Responsive/ResponsiveContainer'
import Footer from '../../layouts/components/FooterDesktop'

const PrivacyPage = (props) => (
  <ResponsiveContainer history={props.history} location={props.location}>
    <Divider hidden />
    <Container text>
      <Segment
        style={{
          borderRadius: '20px'
        }} inverted padded
      >
        <Header as='h3' style={{ fontSize: '2em' }}>
          Privacy Policy - seemyfit
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Privacy Policy - seemyfit
          This application does not share any personal information with third parties nor does it store any information about you. This privacy policy is subject to change without notice and was last updated on 02/17/2020. If you have any questions feel free to contact us directly at seemyfit@outlook.com.
        </p>
      </Segment>

      <Segment
        style={{
          borderRadius: '20px'
        }} inverted padded
      >
        <Header as='h3' style={{ fontSize: '2em' }}>
          Term of use - seemyfit
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          GENERAL TERMS OF USE AND LEGAL INFORMATION
          <br />
          The following general terms of use and legal information (hereinafter, "Terms of Use") govern the use and access to the website www.seemyfit.at and the corresponding mobile application (hereinafter referred to collectively as the "Platform"), title seemyfit APP, SL (Hereinafter, "seemyfit").
          Registration, access and browsing on the Platform as well as downloading and installing the App seemyfit implies a full and unreserved acceptance of these present Terms of Use on the behalf of browsers and platform Users.

          <br /><br /><b>1. OBJECT</b><br />
          seemyfit is a fashion social network that aims to provide community members (hereinafter, the "Users") a platform and method for sharing photos containing clothes or accessories and opinions about them, with the intention of sharing decisions to purchase with other Users through a simple and intuitive access to online stores and fashion companies.
          The present Terms of Use are intended to regulate the terms and conditions that apply to the provision and management of information provided through the platform and the services offered by seemyfit. These conditions incorporate our privacy policy, accessible on the application found in the Preferences section of the Platform.
          <br />
          <br /><b>2. SIGN-UP, REGISTRATION AND USER PROFILES</b><br />
          To be a user of the platform it is essential that the following requirements are met:Truthfully complete the required fields on the registration form, in which personal data such as username and email address, and/or Instagram are requested.
          Accept the present Terms of Use (including our Privacy Policy), expressed through proceeding with the registration on the Platform.
          Provision of services by seemyfit is free for all Users. However, some of the services provided by the provider through the application are subject to the payment of a specific price in these general terms and conditions of business.
          In the case where it is estimated that a user may be under 18 years old, seemyfit reserves the right to require that the user provides evidence to society that he/she does have the age, if not and is less, the expressed permission of their parents or legal guardians to accept these Terms of Use and perform all actions that are available to them on the Platform.
          The User guarantees that all data concerning the identity and legitimacy provided to seemyfit on their registration forms of the Platform are truthful, accurate and complete. Also, commits to keeping the data updated at all times. In the event that the User provides any false, inaccurate or incomplete data or if seemyfit considers that there are grounds to doubt the truthfulness, accuracy and completeness of these, seemyfit may deny access and present or future use of the Platform or any of its contents and/ or services; likewise terminating the User and cancelling the account.
          If the User subscribes an account through other social network/portal or internet service, he/she accepts the validity and effectiveness of all communications sent by seemyfit to that account.
          When registering on the Platform, the User requires to choose an email which can be used to sign-in into the seemyfit app.
          You must allow the Platform to access account information from other third parties platforms to use the same username (Username) on seemyfit.
          Your third party email account is used to access seemyfit and is strictly confidential, personal and non-transferable (collectively the "Access Keys").
          The User agrees not to disclose these Access Keys (email and password) or make them accessible to third parties. Since seemyfit cannot guarantee the identity of registered Users, the User shall be solely liable for any use of the Access Keys by third parties, including the findings made on the Platform or through the Platform, or any other action that is carried out by using the Access Keys.
          Users are obliged to promptly inform seemyfit if there is any theft, unintended disclosure or loss of the Access Keys via the email seemyfit+login+issues@outlook.com.
          Once registration is completed, any user can access his/her profile and complete and/or edit it as it deems appropriate provided it complies with these present Terms of Use.
          <br />
          <br /><b>3. UNSUBSCRIBING OF USERS AND TERMINATION OF SERVICES</b><br />
          In order for the User to unsubscribe the services, he/she can unsubscribe the services by sending an email to seemyfit+delete+account@outlook.com with the subject heading "User Drop" together with information of the Username (Username), email or username of the social network that used for registering the services with the Platform.
          seemyfit has the right to suspend or terminate an account (and this service contract) immediately of the User if
          (i) the User fails to comply with any terms and conditions of this Terms of Use;
          (ii) the User is idle for more than 6 months (in this case, the User will be notified in writing 15 days before termination)
          (iii) seemyfit no longer providing the services through the Platform and
          (iv) at any time seemyfit provides a 30 days written notice of termination without the requirement of stating the cause for termination.
          For the avoidance of doubt, seemyfit shall not be liable to the User or any other parties of any loss or damages as a result of the termination thereof.
          <br />
          <br /><b>4. USER OBLIGATIONS</b><br />
          Users are solely responsible for the access to and proper use of their profile and other contents on the Platform, subject to the law, whether national or international, as well as the principles of good faith, morals, good customs and public order.
          Specifically but without limitation, they agree to diligently observe these Terms of Use.
          Generally, Users will refrain from using their profile and the remaining content and services of the Platform, for purposes or effects (a) other than to share photos, opinions and purchasing decisions on garments, clothing and fashion or (b) are unlawful or harmful to the rights and interests of others, or in any way damage, disable, affect or impair the Platform (include the reputation of seemyfit), its contents and services.
          Users also prohibited to use the platform in any way that prevents or hinders the proper functioning of the Platform or the normal use or enjoyment of the Platform of other Users.
          Users hereby agree to (including but not limited to):Provide accurate and updated information, in any communication on seemyfit or any content or information communicated on the Platform.
          Not to post sensitive personal data -- whether the Users or third party shall not post any sensitive information and information may be relating to an identified or identifiable individual, such as: racial origin; union membership; religion, ideology or sexual orientation; health data; or data relating to criminal records, procedures and sanctions or fines arising thereof or breaches of monetary obligations.
          Not to use false identities or impersonate others in the use of the Platform or any services thereof.
          Not to use the Platform for illegal or unauthorized purposes.
          Not to host, store, disclose, publish, distribute or share any content that may involve unlawful interference or violation of any form of rights such as image and personal and family privacy data of third parties and, especially of any underage person.
          Not to publish on the Platform any comments or information that is illegal, racist, xenophobic, obscene, pornographic, abusive, defamatory, misleading, fraudulent or otherwise contrary to morality or public order.Not to alter or modify the Platform, in whole or in part, circumventing, disabling or manipulating any other functions or services of the same.
          Abide by our Privacy Policy and not infringe the rules governing the protection of personal data.
          Not to enter, store or post through the Platform any content that infringes industrial property rights and/ or intellectual or confidential information from others, or in general any content that does not have, in accordance with the law, the right to make it available to third parties.Not to use the platform to injure, defame, intimidate or harass other users.
          Not to access the email accounts of other users.
          Not to enter, store or transmit through the Platform any programs, data, code, computer viruses, corrupted files, or any other use any electronic or fixed devices that may cause damage or alterations in the computers, networks, contents or systems of seemyfit, or any other users or third parties.
          Not to destroy, alter, use, disable or damage data, information, programs or electronic documents of seemyfit, its users or third parties.Not to send emails to other users for commercial purposes, or provide or send email addresses to third parties without their consent.Refrain from fraudulent use of the reward system in order to obtain revenue from fake purchases, either through fake users or any forms of dishonest or fraudulent acts.Comply at all times with the advertising, administrative regulations and advertising self-regulation along with not making "influential" comments contrary to fair competition.Users violate such obligations shall be liable for any loss or damage incurred to the maximum extent permitted by the relevant law, seemyfit will not be responsible for any consequences or damages that may arise from any breach of the Terms of Use or illicit use by any third parties.
          Access to the platform may be refused by seemyfit to those users who, in any way, violate the obligations or violate the Terms of Use or any other relevant laws and regulation of any country of use.
          <br />
          <br /><b>5. CONTENT GENERATED BY THE USERS</b><br />
          The User may post photographs, opinions and comments on the Platform (“User Content”).
          In accordance with the provisions above, the User guarantee that User Content does not infringe any intellectual property rights, moral rights of any person or entity, or any trademark rights, privacy or image; and that they have obtained all necessary authorizations from the models, as well as data releases for the purposes of advertising, commercial or business of a similar nature, in compliance.
          The User expressly acknowledges and agrees that seemyfit does not monitor, edit or control the User Content. Notwithstanding the foregoing, seemyfit reserves the right to cancel, withdraw or not to publish, at any time and without notice to Users, any User Content it deems, in its sole discretion, as unsuitable or that violates the present Terms of Use, any laws or rights of third parties.
          The User expressly agrees and gives consent to seemyfit to add product image or other content to the User Content page for sales, advertising or other business purposes.
          <br />
          <br /><b>6. PAYMENT SYSTEM TO USERS</b>
          <br /><b>6.1 REMUNERATION USERS</b><br />
          seemyfit offers the possibility of rewards to users for generating content of interest to other users within the Platform.
          For users eligible for remuneration, the User must be 18 years of age or above and/or have the right, capacity and legitimacy to agree and give consent to these present Terms of Use and to comply with them or, if they are under 18 but over 14 years of age, have the express permission and consent from his/her parent or guardian to accept these Terms of Use and permitted to perform all actions that are in the Platform and have such, express and written authorization or consent from their parents or legal guardians and provide to seemyfit within (48) hours upon request.
          Users will be remunerated for purchase made by other users from the User Content. The main factor governing the amount to be paid will be the purchase price of a branded product that was generated from a sale.
          The remuneration is between 3% and 9% of the sale. seemyfit reserves the right to modify these rates of remuneration without prior notification, for this reason, when the User wishes to obtain accurate and most current information, they should check the commercial details of the present Terms of Use.

          <br /><b>6.2 PAYMENT AND SETTLEMENT</b><br />
          In order to receive remuneration for payment, it is necessary the payment sum exceeds a total of 10 US Dollars. In the event that the User requests for transfer of remuneration provided the above condition are met, the User shall provide the necessary information for effecting the transfer of payment.
          The said transfer shall be effected by PayPal only. The User must have (or register for) a valid PayPal account to receive payment. Otherwise, seemyfit shall have no obligation to make any payments to the User.
          Any transfer costs through payment of PayPal shall be borne by the User.

          <br /><b>6.3 TAX OBLIGATIONS</b><br />
          The User agrees to comply with the law regarding the taxation of the rewards or remuneration obtained from seemyfit.
          The User shall be solely responsible for their taxation and seemyfit shall not be liable for any tax liability for the payment of remuneration derived of the User.

          <br /><br /><b>7. BREACHES / COMPLAINTS</b><br />
          User may inform seemyfit when he/she found another user is violating the Terms of Use by email to seemyfit+complaints@outlook.com or by clicking the "Report" icon and choose the reason for the violation of the Terms of Use of a particular user.
          seemyfit shall verify the report as soon as possible, and take measures it deems appropriate and may terminate or suspend the user’s seemyfit account or remove any User Content deemed illegal or offensive, without prior notice to the user in breach.
          <br />
          <br /><b>8. RESPONSIBILITY OF seemyfit</b><br />
          <b>FUNCTIONING</b><br />
          The User is responsible for its internet service and equipment require to connect to the Platform. In the event of any incident or difficulty accessing the Platform, the User can report to seemyfit through email to seemyfit+login+issues@outlook.com which shall assist the User to resolve the matter relating the Platform as soon as possible.
          seemyfit will not respond in cases of service interruptions, connection errors, unavailability or deficiencies in accessing Internet services or interruptions of the Internet or for any other reason beyond its control.
          seemyfit reserves the right to cutoff the User’s access to the Platform at any time and without notice, either for technical reasons, security, control, maintenance, by power failure or any other cause.
          <br /><b>USAGE BY THE USER AND USER CONTENT</b><br />
          As seemyfit has no obligation to check and verify the identity of the users and the accuracy, validity, completeness and/or authenticity of the content or data the users provide. seemyfit has no obligation and does not provide warranty to the users of the Platform.
          The User expressly agrees that any loss or damage of the users of the Platform will be on the responsibility and risks of the User.
          To the maximum extent permitted by applicable law, seemyfit excludes any liability for damages of any kind that may result from the illicit use of the Platform by users or that may result from the lack of veracity, completeness and/or authenticity of the information that users provide to other users about themselves, including but not limited to damages of any kind that may be due to the impersonation of a third party by a user in any kind of correspondence through the Platform.
          Users assume all liability for the use of the Platform, being solely responsible for any direct or indirect cause resulting from the use of the Platform, including but not limited to, all economic, technical and/ or legal aspects, and the failure to meet expectations generated by this platform.
          The users agree to release seemyfit of all liability for any claims arising directly or indirectly from such cause. Notwithstanding the foregoing, seemyfit reserves the right to limit, in whole or in part, access to the Platform to certain Users, and to cancel, suspend, block or remove certain types of content, using suitable technological tools to the effect, if the activity or information stored is unlawful or harmful or its property or rights of a third party or its fraudulent.
          In this regard, seemyfit may implement the necessary filters to prevent the content be posted on the Platform.
          <br />
          <br /><b>9. PROTECTION OF PERSONAL DATA</b><br />
          seemyfit is deeply committed to compliance with personal data protection regulations and hereby guarantees full compliance with the obligations set forth, as well as the implementation of the security measures provided for in the laws of country of use for the protection of personal data.
          For more information, please see our Privacy Policy.
          <br />
          <br /><b>10. INTELLECTUAL PROPERTY</b><br />
          seemyfit is the owner or licensee of all intellectual property of the Platform, including but not limited to texts, images, photographs, graphic designs, navigation structure, information, source codes, databases.
          User authorized for access, use of the Platform and the services offered therein do not imply consent for any transfer of intellectual property or any content therein except as mentioned below.
          seemyfit grants the User a limited license to access and make personal use of the Platform and the services offered therein including download of the seemyfit mobile application.
          Such license granted on the condition the User complies with the Terms of Use (right of use and unique reproduction of the mobile application in a single backup device) and the license is a non-exclusive, global and its duration is limited only to the validity of the contractual relationship with seemyfit Users under these Terms of Use.
          Any User share User Content through the Platform grants a license free and warrant its free from any claim to seemyfit and the users of the Platform, pertaining only to the intellectual property rights necessary for the use of the Platform and also for advertising purposes, promotional, educational and/or identification of the Platform (including rights of public communication, reproduction in the Platform and transformation for the sole purposes of adaptation/editing content on the Platform) on a non-exclusive basis, globally and for the maximum legal duration of the rights granted and shall release seemyfit of any liability for the content and legality of the information published and shall indemnify seemyfit in the event of any breach of this warranty.
          <br />
          <br /><b>11. LINKS</b><br />
          On the Platform, the User may find links to other websites operated by third parties. Any links, redirections or associations ("links") to other third party websites (www) from the Platform does not imply any kind of relationship, collaboration or dependency between seemyfit, and seemyfit does not guarantee any accuracy of the content of the said websites.
          <br />
          <br /><b>12. SEVERABILITY CLAUSE</b><br />
          If any of the clauses from these present Terms of Use should be nulled of full right or voidable, such clause shall be removed.
          Such a declaration of invalidity shall not invalidate the rest of the Terms of Use, which shall remain valid and effective.
          <br />
          <br /><b>13. PLATFORM UPGRADE AND MODIFICATION</b><br />
          seemyfit reserves the right to modify at any time without prior notice, the structure, configuration and design of the platform as well as these present Terms of Use and Privacy Policy.
          Users should carefully read these Terms of Use prior to using and accessing the Platform.
          seemyfit reserves the right to make, at any time and without notice, upgrades, modifications or elimination of information contained in the Platform.
          <br />
          <br /><b>14. GOVERNING LAW</b><br />
          The validity, execution and interpretation of these Terms of Use shall be governed by the Laws of Canada. In the event of any conflict or discrepancy in the interpretation or application of these present Terms of Use for the use of the Platform, the case will be submitted to the Courts in Canada.
          <br />
          <br />
          <br /><b>Privacy Policy:</b><br />
          At seemyfit, accessible from www.seemyfit.at, one of our main priorities is the privacy of our visitors.
          This Privacy Policy document contains types of information that is collected and recorded by seemyfit and how we use it.

          If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
          This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in seemyfit.

          This policy is not applicable to any information collected offline or via channels other than this website.
          <br /><b>Consent</b><br />
          By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          <br /><b>Information we collect</b><br />
          The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
          If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
          When you register for an Account, we may ask for your contact information, including items such as name, address, email address, and telephone number.
          <br /><b>How we use your information</b><br />
          We use the information we collect in various ways, including to:
          <br />Provide, operate, and maintain our website
          <br />Improve, personalize, and expand our website
          <br />Understand and analyze how you use our website
          <br />Develop new products, services, features, and functionality
          <br />Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes
          <br />Send you emails
          <br />Find and prevent fraud
          <br />Log Files
          <br />seemyfit follows a standard procedure of using log files. These files log visitors when they visit websites.
          All hosting companies do this and a part of hosting services' analytics.
          The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks.
          These are not linked to any information that is personally identifiable.
          The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
          <br />Cookies and Web Beacons
          Like any other website, seemyfit uses 'cookies'.
          These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited.
          The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
          <br /><br />
          <b>Advertising Partners Privacy Policies</b><br />
          You may consult this list to find the Privacy Policy for each of the advertising partners of seemyfit.
          Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on seemyfit, which are sent directly to users' browser.
          They automatically receive your IP address when this occurs.
          These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
          Note that seemyfit has no access to or control over these cookies that are used by third-party advertisers.
          Third Party Privacy Policies seemyfit's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information.
          It may include their practices and instructions about how to opt-out of certain options.
          You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.
          You can choose to disable cookies through your individual browser options.
          To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
          <br />
          <br />
          <br /><b>CCPA Privacy Rights (Do Not Sell My Personal Information)</b>
          Under the CCPA, among other rights, California consumers have the right to:
          Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
          Request that a business delete any personal data about the consumer that a business has collected.Request that a business that sells a consumer's personal data, not sell the consumer's personal data.If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
          <br />
          <br />
          <br /><b>GDPR Data Protection Rights</b><br />
          We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
          <br />
          <br /><b>The right to access</b> - You have the right to request copies of your personal data. We may charge you a small fee for this service.
          <br /><b>The right to rectification</b> - You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
          <br /><b>The right to erasure</b> - You have the right to request that we erase your personal data, under certain conditions.
          <br /><b>The right to restrict processing</b> - You have the right to request that we restrict the processing of your personal data, under certain conditions.
          <br /><b>The right to object to processing</b> - You have the right to object to our processing of your personal data, under certain conditions.
          <br /><b>The right to data portability</b> - You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
          <br />
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
          <br />
          <br />
          <br /><b>Children's Information</b><br />
          Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
          <br /><br />
          seemyfit does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
        </p>
      </Segment>

    </Container>
    <Divider hidden />
    <Footer />
  </ResponsiveContainer>
)

export default PrivacyPage
