import React, { Component } from 'react'
import {
    Container,
    Segment,
    Grid,
    Label,
    Message,
    Divider,
    Header,
    Dimmer,
    Loader,
    Breadcrumb
} from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet'

import { logEvent, logPageView } from '../../../services/analytics'
import { extensionInstalled } from '../../../utils/chromeExtension'

import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'

import { Media } from '../../../utils/AppMediaBreakPoints'
import { getSiteNameFromUrl, getBrandFilterFromUrl, getNumberOfSharedPosts, getBrandNameFromUrl } from '../../../shared/utils/product'
import { ADDIMAGE, ROOT, getProductListFilterRoute, getDetailPageRoute, PRODUCTS_LIST } from '../../../constants/routes'
import Footer from '../../../layouts/components/Footer'
import PlaceholderCard from '../components/PlaceholderCard'
import { FILTERS } from '../ProductsList/components/ProductFilters'
import TryItNowDesktopOnly from '../../../components/TryItNowDesktopOnly'
import RelatedPostsContainer from '../../Posts/RelatedPosts/RelatedPostsContainer'
import ProductCard from '../components/ProductCard'
import ShareButtons from '../../../components/ShareButtons'
import NativeShareButtonIcon from '../../../components/NativeShareButtonIcon'

import { getPostsFromResponse, getProductreferralsFromResponse, getUserFromResponse, getPostFromResponse } from '../../../shared/utils/post'
// import { DOMAINNAME_WITH_HTTPS } from '../../../shared/constants/apiroutes'
import { getUserFullUrlFromUsername } from '../../../shared/utils/generateFullUrl'
import { getProductUrlToShare } from '../../../shared/utils/generateUrlForShare'
import { RELATED_POSTS_COUNT } from '../../Posts/RelatedPosts/RelatedPosts'
import BigPostCard from '../../Posts/components/BigPostCard'
import InstallMobileAppBannerContainer from '../../../components/InstallMobileAppBanner/InstallMobileAppBannerContainer'
import AddLooksToProductButton from '../components/AddLooksToProductButton'
// import SubscribeToProduct from '../components/SubscribeToProduct'

class ProductDetailPage extends Component {
    state = {
        isInstalled: true,
        selectedPost: null
    }

    componentDidMount() {
        this.loadProduct()
        let self = this
        window.onload = function () {
            self.setState({ isInstalled: extensionInstalled() })
        }

        const { product } = this.props
        logPageView({ name: 'ProductDetailPage', id: product && product.id })
    }

    getCarouselStructredData(productreferrals, product) {
        if (!productreferrals || !product) return

        const usernameArray = productreferrals && productreferrals.length > 0 && productreferrals.map(pr => {
            const post = getPostFromResponse(pr)
            const user = getUserFromResponse(post)
            return user && user.instagram_username
        })
        const uniqueUsernames = Array.from(new Set(usernameArray))
        const itemListElement = uniqueUsernames && uniqueUsernames.map((username, i) => {
            return ({
                "@type": "ListItem",
                "position": i + 1,
                "url": username && getUserFullUrlFromUsername(username)
            })
        })

        return {
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": itemListElement
        }
    }

    // TODO not used for now
    // getBreadcrumbStructuredData(product) {
    //     if (!product) return

    //     const itemListElement = [{
    //         "@type": "ListItem",
    //         "position": 1,
    //         "name": "seemyfit.at",
    //         "item": DOMAINNAME_WITH_HTTPS
    //     }]

    //     const brandName = getBrandNameFromUrl(product.url);
    //     if (brandName) {
    //         itemListElement.push({
    //             "@type": "ListItem",
    //             "position": 2,
    //             "name": brandName,
    //             "item": getBrandFullUrlFromProduct(product)
    //         })
    //     }

    //     // todo add breakcurmbs for selected post
    //     // const { selectedPost } = this.state
    //     // if (selectedPost) {
    //     //     const user = getUserFromResponse(selectedPost)
    //     //     user && itemListElement.push({
    //     //         "@type": "ListItem",
    //     //         "position": 2,
    //     //         "name": user.instagram_username,
    //     //         "item": getUsernameRoute(user.instagram_username)
    //     //     })
    //     // }

    //     return {
    //         "@context": "https://schema.org",
    //         "@type": "BreadcrumbList",
    //         "itemListElement": itemListElement
    //     }
    // }

    setStructuredData(product) {
        if (!product) {
            return
        }

        const siteName = getSiteNameFromUrl(product.url)
        const brandName = getBrandNameFromUrl(product.url);
        // const breadcrumbList = this.getBreadcrumbStructuredData(product)
        const productreferrals = getProductreferralsFromResponse(product)

        const carouseList = this.getCarouselStructredData(productreferrals, product)

        // TODO Add aggregated review
        // const reviews = posts && posts.map((p) => {
        //     const user = getUserFromResponse(p)
        //     return user && {
        //         "@type": "Review",
        //         "author": {
        //             "@type": "Person",
        //             "name": user.instagram_username
        //         }
        //     }
        // })

        const structuredData = [
            carouseList,
            {
                "@context": "http://schema.org",
                "@type": "Product",
                "image": product.image_url,
                "brand": {
                    "@type": "Brand",
                    "name": brandName
                },
                "name": product.name,
                "description": `${product.name} looks on Instagram - seemyfit.at`,
                "sku": product.id,
                "mpn": product.id,
                // TODO Add aggregated review
                // "review": reviews,
                // "aggregateRating": {
                //     "@type": "AggregateRating",
                //     "ratingValue": "88",
                //     "bestRating": "100",
                //     "ratingCount": reviews && reviews.length
                // },
                "offers": {
                    "@type": "Offer",
                    "url": getProductUrlToShare(product),
                    "priceCurrency": product.currency,
                    "price": product.price,
                    "itemCondition": "https://schema.org/NewCondition",
                    "availability": `https://schema.org/${product.productAvailability ? product.productAvailability : 'InStock'}`,
                    "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString("en-US"),
                    "seller": {
                        "@type": "Organization",
                        "name": siteName
                    }
                }
            }];

        const ldjsonDocumentId = "googleJobNetworkScript"
        const element = document.getElementById(ldjsonDocumentId)
        if (element) {
            element.remove();
        }

        const googleJobNetworkScript = document.createElement("script")
        googleJobNetworkScript.type = "application/ld+json"
        googleJobNetworkScript.id = ldjsonDocumentId
        googleJobNetworkScript.innerHTML = JSON.stringify(structuredData)
        document.head.appendChild(googleJobNetworkScript)
    }

    onProductReady(product) {
        this.setStructuredData(product)
        const siteName = getSiteNameFromUrl(product.url);
        siteName && this.props.getRelatedPostsAction({
            filter: {
                ...(siteName && { dn: siteName })
            },
            page: {
                size: RELATED_POSTS_COUNT
            }
        })
    }

    loadProduct() {
        const { pathname, search } = this.props.location
        let isUrlChanged = false
        let url = `${pathname.substr(1)}${search}`
        if (!url.includes('https://')) { // for pinterest
            url = url.replace('https:/', 'https://')
            isUrlChanged = true
        }

        if (url.includes('https://www.seemyfit.at/')) {
            url = url.replace('https://www.seemyfit.at/', '')
            isUrlChanged = true
        }

        if (url.includes('https://seemyfit.at/')) {
            url = url.replace('https://seemyfit.at/', '')
            isUrlChanged = true
        }

        if (isUrlChanged) {
            this.props.history.push({
                pathname: getDetailPageRoute(url),
            })
        }

        this.props.getProductAction({
            filter: {
                url
            },
            onSuccess: (product) => this.onProductReady(product)
        })
    }

    clientSide404Redirect(response, url) {
        if (!response && response.message === 'Product not found.') {
            // if we don't support the URL redirect to home or filter by brand
            // is it a brand URL?
            const brand = getBrandFilterFromUrl(url, FILTERS)
            const siteName = brand && brand.name;
            this.props.history.push({
                pathname: siteName ? getProductListFilterRoute(siteName) : ROOT,
            })
        }
    }

    onImagedAdded() {
        this.loadProduct()
    }

    renderProduct(product, isLoading) {
        const brandName = getBrandNameFromUrl(product.url);
        return (
            <center>
                <ProductCard
                    key={product.id}
                    product={product}
                    analytisEventName='productDetailCardClicked'
                    withoutLooks
                    loading={isLoading}
                    analyticsVendorLinkEventName='detailPageOriginalUrlClickedMobile'
                />
                {/* TODO <SubscribeToProduct product={product} /> */}
                <NativeShareButtonIcon
                    url={`https://seemyfit.at/${product.url}`}
                    name={product.name}
                    brandName={brandName}
                    media={product.image_url}
                />
                <Divider />
                <Header inverted>
                    <Label pointing='right' prompt>Share On</Label>
                    <Header.Content>
                        <ShareButtons
                            url={`https://seemyfit.at/${product.url}`}
                            name={product.name}
                            brandName={brandName}
                            media={product.image_url}
                        />
                    </Header.Content>
                </Header>
            </center >
        )
    }

    onSuccessAddPostStep2(igPost) {
        const selectedPost = this.state.selectedPost

        var mergeConfig = {
            merger: (current, other, config) => {
                if (!other || Object.keys(other).length === 0) {
                    return current
                }
                return undefined;
            }
        }

        this.setState({ selectedPost: selectedPost.merge(igPost, mergeConfig) })
    }

    onSuccessGetPostStep1(igPost) {
        const selectedPost = this.state.selectedPost
        const postId = selectedPost && selectedPost.id
        const requestStep2 = {
            postId,
            igPost,
            onSuccess: (data) => this.onSuccessAddPostStep2(data)
        }

        this.props.updatePostStep2(requestStep2)
    }

    onPostSelected(selectedPost) {
        this.setState({ selectedPost })
        logEvent('detailPageProductCardImageClicked')
        document.getElementById("selected-post-img").scrollIntoView({ behavior: 'smooth', block: 'start' });

        const requestStep1 = {
            url: selectedPost && selectedPost.url_original_post,
            onSuccess: (data) => this.onSuccessGetPostStep1(data)
        }

        const isVideo = selectedPost && selectedPost.post_is_video === '1'

        let postNeedsRefersh = false
        if (selectedPost && selectedPost.url_original_post) {
            const isMissingCaption = !selectedPost.post_caption
            // After every API change this can be used to refresh 
            // the existing post and populate the new attributes
            // eg. const isMissingVideoUrl = !selectedPost.post_video_url
            postNeedsRefersh = isMissingCaption
        }

        if (isVideo) {
            postNeedsRefersh = true
        }

        if (postNeedsRefersh) {
            this.props.getPostStep1Action(requestStep1)
        }
    }

    renderPost(productreferral) {
        const post = productreferral.post
        const product = productreferral.product
        if (!post) {
            // console.log("post not found productreferral", productreferral)
            return <></>
        }
        return (
            <Grid.Column
                key={productreferral.id}
                id={productreferral.id}
                post-id={post && post.id}
                style={{ padding: '1px', overflow: 'hidden' }}
                mobile={16} tablet={8} computer={8}
            >
                <BigPostCard
                    productreferral={productreferral}
                    post={post}
                    product={product}
                    analytisClicked='postThumbClickedProductDetailPage'
                />
            </Grid.Column >
        )
    }

    renderPosts(product, isLoading) {
        const rankBasedOnProductreferralsCount = (p1, p2) => {
            // console.log("rankBasedOnProductreferralsCount p1", p1)
            // console.log("rankBasedOnProductreferralsCount p2", p2)
            const p1ReferralsCount = (p1 && p1.post && p1.post.productreferrals
                && p1.post.productreferrals.length) || 0
            const p2ReferralsCount = (p2 && p2.post && p2.post.productreferrals
                && p2.post.productreferrals.length) || 0
            // console.log("rankBasedOnProductreferralsCount p1ReferralsCount", p1ReferralsCount)
            // console.log("rankBasedOnProductreferralsCount p2ReferralsCount", p2ReferralsCount)
            return p2ReferralsCount - p1ReferralsCount
        }

        const postsSharedCount = getNumberOfSharedPosts(product)
        const productreferrals = product && product.productreferrals

        const sortedProductreferrals = productreferrals && productreferrals.length > 0 &&
            Array.from(productreferrals).sort(rankBasedOnProductreferralsCount)

        return (
            <Grid style={{ padding: '0px' }} centered>
                <Dimmer active={isLoading}>
                    <Loader size='mini'>Loading Looks...</Loader>
                </Dimmer>
                {postsSharedCount > 0 && sortedProductreferrals.map((pr) => this.renderPost(pr))}
            </Grid >
        )
    }

    renderInfoBanner() {
        return (
            <></>
            /*<Responsive as='center' {...Responsive.onlyMobile}>
                 <Message compact size='mini'
                    style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        paddingBottom: '0',
                        paddingTop: '0',
                        color: '#FAFAFA',
                    }}>
                    Tap on username for more
                    </Message> 
            </Responsive>*/
        )
    }

    renderAddPostComponent(product) {
        const siteName = product && getSiteNameFromUrl(product.url)
        const brandName = product && getBrandNameFromUrl(product.url)

        return (
            // <InstallMobileAppBannerContainer
            //     siteName={siteName}
            //     title='Scan to add your style'
            //     subtitle={(<>Get the app and share your {brandName} outfits</>)}
            //     analyticsLabel='AppInviteFromProductPage'
            //     appInstallCampaign={'w_product_page'}
            // />
            // <AddIGPostToProductContainer
            //     product={product}
            //     imagedAdded={() => this.onImagedAdded()}
            //     productPage={true} />
            <AddLooksToProductButton product={product}
                subtitle={`Do you have the same? Add your true-fit review for ${product && product.name}`}
            />
        )

    }

    renderAddImage(product) {
        return (
            <>
                <Divider hidden />
                <Divider horizontal inverted>Share Your Style</Divider>
                {this.renderAddPostComponent(product)}
            </>
            // <AddImageToProduct product={product} imagedAdded={() => this.onImagedAdded()} />
        )
    }

    renderAddImageSide(product) {
        return (
            <Segment style={{ borderRadius: '20px' }} inverted textAlign='center'>
                <Header as='h1' inverted>Share Your Style</Header>
                {/* <Header.Subheader>Spotted the same? add it here <span role='img' aria-label='Woman Gesturing OK'>👇</span></Header.Subheader> */}
                {/* <br /> */}
                {this.renderAddPostComponent(product)}
            </Segment>
        )
    }

    render404() {
        return (
            <Message warning={true}>
                {"Sorry we couldn't find this product 😐"}
                <Link to={ADDIMAGE}>You can add it from here</Link> <br />
            </Message>
        )
    }

    renderResponse(product, isLoading) {
        const postsSharedCount = getNumberOfSharedPosts(product)

        return (
            <Grid columns='equal' centered stackable>
                {/* <Responsive as={Grid.Column} width={5} minWidth={Responsive.onlyTablet.minWidth}> */}
                <Grid.Column style={{ paddingTop: '0px' }}>
                    <Container as={Media} lessThan='computer'
                        style={{ marginLeft: '1em', marginRight: '1em' }}>
                        {/* <Responsive
                        as='div'
                        {...Responsive.onlyMobile}
                        style={{ marginLeft: '1em', marginRight: '1em' }}> */}
                        {product && this.renderProduct(product, isLoading)}
                        {isLoading && <PlaceholderCard />}
                    </Container>
                    {(!product && !isLoading) && this.render404()}
                    {/* {product && product.files.length > 6 && this.renderAddImage(product)} */}
                    {/* {this.renderContactUsSideBar()} */}
                    <div id="selected-post-img" />
                    {/* This div is used to auto scroll after selecting a product */}
                    {/* {selectedPost && this.renderSelectedPost(selectedPost, product)} NOT USED ANY MORE */}

                    <Segment style={{ minHeight: '100px', borderRadius: '20px', overflow: 'hidden' }} inverted>
                        {/* 
                        Add filters
                        <Segment basic textAlign='center'>
                            <Header as='h1' inverted>
                                Shared Looks
              {product && <Header.Subheader>{product.name} looks from<Image src={InstagramTextLogo} size='tiny' spaced alt='Instagram' /></Header.Subheader>}
                            </Header>
                            {postsSharedCount > 0 && this.renderInfoBanner()}
                        </Segment> */}
                        {postsSharedCount > 0 && (
                            this.renderPosts(product, isLoading))}
                        <Grid.Column width={5} as={Media} greaterThanOrEqual='computer'>
                            {/* Add Product From Desktop */}
                            <Grid centered doubling>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    {postsSharedCount <= 6 && this.renderAddImage(product)}
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                        {/* Add Product From Mobile */}
                        <Grid.Column as={Media} lessThan='computer'>
                            {/* <Responsive as={Grid.Column} width={5} {...Responsive.onlyMobile}> */}
                            {!isLoading && this.renderAddImage(product)}
                        </Grid.Column>
                    </Segment>
                    <Divider hidden />
                    {/* {product && product.files.length <= 6 && <HelpCommunity />} */}
                </Grid.Column>
                <Grid.Column width={5} as={Media} greaterThanOrEqual='computer'>
                    {product && this.renderProduct(product, isLoading)}
                    {isLoading && <PlaceholderCard />}
                    {postsSharedCount >= 7 && this.renderAddImageSide(product)}
                    {/* {this.renderContactUsSideBar()} */}
                </Grid.Column>
            </Grid >
        )
    }

    renderBreadcrumb = (brandName, product) => {
        return (
            <>
                <Segment inverted>
                    <Breadcrumb size='small' >
                        <Breadcrumb.Section>
                            <Link
                                to={ROOT}
                                onClick={() => logEvent('productDetailBreadcrumbHomeClicked')}>
                                <Header as='h3' inverted>Home</Header>
                            </Link>
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' color='blue' />
                        <Breadcrumb.Section >
                            <Link
                                to={PRODUCTS_LIST}
                                onClick={() => logEvent('productDetailBreadcrumbProductsClicked')}>
                                <Header as='h3' inverted>Products</Header>
                            </Link>
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' color='blue' />
                        <Breadcrumb.Section link
                            href={brandName ? getProductListFilterRoute(brandName) : PRODUCTS_LIST}
                            onClick={() => logEvent(`productDetailBreadcrumb${brandName}Clicked`)}
                        ><Header as='h3' inverted>{brandName}</Header></Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' color='blue' />
                        <Breadcrumb.Section active>{product.name}</Breadcrumb.Section>
                    </Breadcrumb>
                </Segment>
            </>
        )
    }

    render() {
        const { isInstalled } = this.state
        const { isLoading } = this.props
        let { product } = this.props

        const brandName = product && getBrandNameFromUrl(product.url)

        return (
            <ResponsiveContainer
                isInstalled={isInstalled}
                history={this.props.history}
                brandName={brandName}
                location={this.props.location}
            >
                {product && this.renderMeta(product)}
                <Container className='no-horizontal-margin'>
                    {product && this.renderBreadcrumb(brandName, product)}
                    <Divider hidden />
                    {this.renderResponse(product, isLoading)}
                    {
                        <RelatedPostsContainer
                            product={product}
                            history={this.props.history} />
                        // <RelatedLookByBrand history={this.props.history} profile={product} />
                    }
                    {!isInstalled && <TryItNowDesktopOnly />}
                </Container>
                <Divider hidden />
                <Footer history={this.props.history} />
            </ResponsiveContainer >
        )
    }

    renderMeta(product) {
        const title = `${product ? product.name : "See my fit"} on different sizes and styles!`
        const siteName = getSiteNameFromUrl(product.url)

        // const posts = getPostsFromResponse(product)
        // const reviews = posts && posts.map()

        return (
            <>
                <Helmet title={title} >
                    <meta property='og:url' content={window.location.href} />
                    <meta property='og:type' content='product' />
                    {product && product.name && <meta property='og:title' content={product.name} />}
                    {product && product.name && <meta property="og:description" content={`${product.name} - seemyfit.at`} />}
                    <meta property='og:image' content={product.image_url} />
                    {this.renderMetaImages(product)}
                    <meta property="product:brand" content={siteName} />
                    <meta property="product:condition" content="new" />
                    <meta property="availability" content="available for order" />
                    {product && product.price && <meta property="product:price:amount" content={product.price} />}
                    {product && product.currency && <meta property="product:price:currency" content={product.currency} />}
                    {/* <meta property="product:retailer_item_id" content="facebook_tshirt_001"> */}
                    {/* <meta property="product:item_group_id" content="fb_tshirts"/> */}
                </Helmet>
                {/* <div itemScope itemType="http://schema.org/Product">
                    <meta itemProp="brand" content={siteName} />
                    {product && product.name && <meta itemProp="name" content={product.name} />}
                    {product && product.name && <meta itemProp="description" content={`${product.name} - seemyfit.at`} />}
                    <meta itemProp="productID" content={product.id} />
                    <meta itemProp="url" content={window.location.href} />
                    <meta itemProp="image" content={product.image_url} />
                    <meta itemProp="sku" content={product.id} />
                    <meta itemProp="mpn" content={product.id} />
                    {product && product.price && (
                        <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                            <link itemProp="availability" href="http://schema.org/InStock" />
                            <link itemProp="itemCondition" href="http://schema.org/NewCondition" />
                            <meta itemProp="url" content={window.location.href} />
                            <meta itemProp="price" content={product.price} />
                            {product.currency && <meta itemProp="priceCurrency" content={product.currency} />}
                        </div>
                    )}
                </div> */}
            </>
        )
    }

    renderMetaImages(product) {
        const posts = getPostsFromResponse(product)

        return (posts && posts.length > 0 &&
            posts.map((p, i) => <meta property='og:image' content={p.url} key={i} />))
    }

}
export default ProductDetailPage