
import React from 'react'
import {
  Form, Input, Dropdown
} from 'semantic-ui-react'

const curencyOptions = [
  { key: 'USD', text: 'USD $', value: 'USD', symbol: '$' },
  { key: 'CAD', text: 'CAD $', value: 'CAD', symbol: 'C$' },
  { key: 'EUR', text: 'EUR €', value: 'EUR', symbol: '€' },
  { key: 'GBP', text: 'GBP £', value: 'GBP', symbol: '£' },
  { key: 'AUD', text: 'AUD $', value: 'AUD', symbol: 'A$' },
  { key: 'BRL', text: 'BRL $', value: 'BRL', symbol: 'R$' }
]
const PriceCurrencyInput = (props) => (
  <Form.Group>
    <Form.Field>
      <Input
        placeholder='Price eg. 20'
        name={props.priceFieldName}
        size='mini'
        type='number'
        required
        value={props.productPrice || ''}
        onChange={(e, { value }) => props.onPriceChange(value)}
      />
    </Form.Field>
    <Form.Field>
      <Dropdown
        inline
        name={props.currencyFieldName}
        defaultValue={props.productCurrency}
        onChange={(e, { value }) => props.onCurrencyChange(value)}
        options={curencyOptions}
        size='mini'
      />
    </Form.Field>
  </Form.Group>
)

export default PriceCurrencyInput
