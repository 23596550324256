// import Kitsu from 'kitsu'
import { error, serialise } from 'kitsu-core'
import { deserialize } from 'deserialize-json-api'
import axios from 'axios';
import Qs from 'qs'

import { API } from '../constants/apiroutes'
// import DropDownAlertService from './DropDownAlertService'
// import I18n from '../I18n'

// our "constructor"
const create = (baseURL = API.ROOT) => {
  const api = axios.create({
    baseURL: baseURL,
    // timeout: 1000,
    maxRedirects: 5, // default
    // headers: {'X-Custom-Header': 'foobar'}
    headers: { 'Content-Type': 'application/vnd.api+json' },
    // transformResponse: [function (data) {
    //   // Do whatever you want to transform the data
    //   return deserialize(data)
    // }],

    paramsSerializer: {
      serialize: (params) => Qs.stringify(params, { arrayFormat: 'brackets' })
    },
  })

  api.interceptors.request.use(config => {
    // Do something before request is sent
    // console.log("config", config)
    return config
  }, error => {
    // Do something with the request error
    return Promise.reject(error)
  })

  api.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // return response && deserialise(response)
    if (response && response.status === 200) {
      // const errorMessageTitle = I18n.t('SessionExpired')
      // const errorMessageSubTitle = I18n.t('SessionExperiedPleaseLoginAgain')
      // DropDownAlertService.alert('error', errorMessageTitle, errorMessageSubTitle)
      // response.isSuccess = true
    }
    // console.log("response", response)
    // console.log("deserialize(data)", deserialize(response.data))
    return response && deserialize(response.data) // , deserialize(response.data, { transformKeys: "camelCase" })
  }, errors => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const errorPayload = errors && errors.response
    if (errorPayload && errorPayload.status === 401) {
      // const errorMessageTitle = I18n.t('SessionExpired')
      // const errorMessageSubTitle = I18n.t('SessionExperiedPleaseLoginAgain')
      // DropDownAlertService.alert('error', errorMessageTitle, errorMessageSubTitle)
      errorPayload.sessionExpired = true
    }
    return Promise.reject(error(errorPayload))
  })

  // Users
  // const login = (request) => api.post(API.LOGIN, request)
  // const signup = (request) => api.post(API.SIGNUP, request)
  // const logout = () => api.post(API.LOGOUT)

  const setAuthToken = (userAuth) => { api.headers.Authorization = 'Bearer ' + userAuth }
  const removeAuthToken = () => delete api.headers.Authorization

  // Items
  const getPosts = (config) => api.get(API.POSTS, config)
  const addPost = (config) => api.post(API.POSTS, serialise(API.POSTS, config, 'POST'))
  const updatePost = (config) => api.patch(`${API.POSTS}/${config?.id}`, serialise(API.POSTS, config, 'patch'))

  const getProducts = (config) => api.get(API.PRODUCTS, config)
  const updateProduct = (config) => api.patch(`${API.PRODUCTS}/${config?.id}`, serialise(API.PRODUCTS, config, 'patch'))

  const getUsers = (config) => api.get(API.USERS, config)
  const getUser = (config) => api.get(`${API.USERS}/${config?.id}`, config)

  const updateUser = (config) => api.patch(`${API.USERS}/${config?.id}`, serialise(API.USERS, config, 'patch'))
  const addUser = (config) => api.post(API.USERS, serialise(API.USERS, config, 'POST'))


  const addProductreferral = (config) => api.post(API.PRODUCTREFERRALS, serialise(API.PRODUCTREFERRALS, config, 'POST'))
  const updateProductreferral = (config) => api.patch(`${API.PRODUCTREFERRALS}/${config?.id}`, serialise(API.PRODUCTREFERRALS, config, 'patch'))
  // const updateItem = (p) => api.put(API.USER_ITEM(p.userId, p.itemId), p.request)
  // const deleteItem = (r) => api.delete(API.USER_ITEM(r.userId, r.itemId))
  // const addItem = (p) => api.post(API.POST_ITEM(p.userId), p.request)

  return {
    setAuthToken,
    removeAuthToken,

    getPosts,
    addPost,
    updatePost,

    getProducts,
    updateProduct,

    getUsers,
    getUser,
    updateUser,
    addUser,

    addProductreferral,
    updateProductreferral
  }
}

// let's return back our create method as the default.
export default {
  create
}
