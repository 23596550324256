import React, { useContext, useState } from 'react';
import {
    Accordion, Button, Divider, Grid, GridColumn, Header, Icon, Image, Label,
    List, Message, Popup, Segment
} from 'semantic-ui-react';
import PromocodeLabel from '../../../layouts/components/PromocodeLabel';
import { logEvent } from '../../../services/analytics';
import { AppContext } from '../../../shared/context';
import { getUserFromResponse } from '../../../shared/utils/post';
import { getSiteNameFromUrl, getTrackedUrlFromUrl } from '../../../shared/utils/product';
import { getSaleConditionStringFromKey } from '../../../shared/utils/productSaleCondition';
import { getMatchColorByPercent, getTotalScoreMatchPercent } from '../../../shared/utils/styleScore';
import isValidHttpUrl from '../../../shared/utils/url';
import ProductRating from '../../Posts/AddPost/ProductRating';
import ProductTrueFit from '../../Posts/AddPost/ProductTrueFit';


const ProductreferralHorizontalCard = (({
    post,
    productreferral
}, ref) => {
    const { user: userContext } = useContext(AppContext)
    const [activeAccordionIndexForeSale, setActiveAccordionIndexForSale] = useState(-1);

    const product = (productreferral && productreferral.product && productreferral.product) || {}
    const siteName = product && getSiteNameFromUrl(product.url)
    const productUrl = product && product.url

    const productproductreferrals = (product && product.productreferrals) || []
    const posts = productproductreferrals.filter(pr => pr.post).map(pr => pr.post)

    const promocode = productreferral.promocode
    const commissionLink = productreferral.commission_link

    const user = getUserFromResponse(post)

    const urlToUse = (commissionLink && isValidHttpUrl(commissionLink)) ? commissionLink : productUrl
    let productTrackedUrl = urlToUse
    try {
        productTrackedUrl = getTrackedUrlFromUrl(urlToUse, user)
    } catch (e) {
        // TODO use crashlytics
        logEvent('Exception: bad commission_link used')
    }

    const dataAttrs = {
        'data-pr-id': productreferral && productreferral.id,
        'data-product-id': product && product.id
    }

    const renderPostThumbnail = (post) => {
        return (
            post ? <div
                style={{
                    float: 'right',
                    borderRadius: '4px',
                    backgroundImage: `url(${post.url})`,
                    backgroundSize: 'cover',
                    height: '50px',
                    width: '50px',
                    backgroundPosition: 'center',
                    margin: '2px'
                }}
            /> : <></>
        )
    }

    const renderForSaleBlock = () => {
        const { salePrice, saleCurrency, saleIGUsername, saleDescription, saleCondition,
            size, color, saleEmail } = productreferral
        const username = user && user.instagram_username
        return (<div>
            <br />
            <Accordion>
                <Accordion.Title
                    index={0}
                    active={activeAccordionIndexForeSale === 0}
                    onClick={(e, { index }) => setActiveAccordionIndexForSale(activeAccordionIndexForeSale === index ? -1 : index)}
                >
                    <Label color='grey' size='mini' icon labelPosition='right'>
                        Similar product for sale
                        <Label.Detail>{salePrice}{saleCurrency}</Label.Detail>
                        <Label.Detail><Icon name='down arrow' /></Label.Detail>
                    </Label>
                </Accordion.Title>
                <Accordion.Content active={activeAccordionIndexForeSale === 0}>
                    <p>
                        <Message info size='small'>
                            <Message.Header>Buy {product && product.name} from {saleIGUsername}</Message.Header>
                            <p>
                                <b>{saleIGUsername}</b> is selling the same <b>{getSaleConditionStringFromKey(saleCondition)}</b> for <b>{salePrice}{saleCurrency}</b>.
                                "{saleDescription}"
                            </p>
                            <List horizontal>
                                <List.Item>
                                    {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/tom.jpg' /> */}
                                    <List.Content>
                                        <List.Header>Condition</List.Header>
                                        {getSaleConditionStringFromKey(saleCondition)}
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/christian.jpg' /> */}
                                    <List.Content>
                                        <List.Header>Price</List.Header>
                                        {salePrice}{saleCurrency}
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' /> */}
                                    <List.Content>
                                        <List.Header>Size</List.Header>
                                        {size}
                                    </List.Content>
                                </List.Item>
                            </List>
                            <br />
                            <Divider />
                            <Message.Header as={"h5"}>Contact Seller</Message.Header>
                            <Button
                                compact size='mini' icon
                                href={'mailto:' + saleEmail}
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={() => logEvent('contactSellerClicked')}
                            >
                                email <Icon name='mail' />
                            </Button>

                            {saleIGUsername && (<Button
                                compact size='mini' icon
                                href={'https://instagram.com/' + saleIGUsername}
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={() => logEvent('contactSellerByIGClicked')}
                            >
                                or at @{saleIGUsername}
                                <Icon name='instagram' />
                            </Button>)}
                            <div>
                                <small>
                                    Seemyfit is not responsible for the transaction between you and the seller, please reach out to the seller by email or on Instagram if you're interested
                                </small>
                            </div>
                        </Message>
                    </p>
                </Accordion.Content>
            </Accordion>
        </div>
        )
    }

    return (
        <Segment inverted style={{ padding: 0 }} {...dataAttrs} key={productreferral.id}>
            <div style={{ float: 'left', marginRight: '8px' }}>
                <a
                    href={`/${product.url}`}
                    target='_blank' rel='noopener noreferrer'
                    onClick={() => logEvent('postDetailOpenTrackedUrlProductImage')}
                >
                    <Image size='tiny' src={product.image_url} style={{ borderRadius: '8px' }} />
                </a>
            </div>
            <div style={{ marginTop: '5px' }}>
                <a
                    href={`/${product.url}`}
                    target='_blank' rel='noopener noreferrer'
                    onClick={() => logEvent('postDetailOpenTrackedUrlProductHeader')}
                >
                    <Header as='h4' inverted style={{ marginBottom: '0' }}>{product.name}</Header>
                </a>
                <a
                    href={`${productTrackedUrl}`}
                    target='_blank' rel='noopener noreferrer'
                    onClick={() => logEvent('postDetailOpenTrackedUrlProductHeader')}
                >
                    <small>{siteName} via @{user && user.instagram_username}
                    </small>
                </a>
            </div>
            <div style={{ marginTop: '5px' }}>
                {productreferral.size && (
                    <Label size='mini' color={productreferral.color ? productreferral.color.toLowerCase() : 'white'}>
                        {productreferral.size}
                        {productreferral.color && (<Label.Detail>{productreferral.color.toUpperCase()}</Label.Detail>)}
                    </Label>)}
                {promocode && (
                    <PromocodeLabel
                        promocode={promocode}
                        // promocodeDescription='20% OFF'
                        color='black'
                        analytisEventName='userfeedPromoCodeCopied'
                        style={{ borderWidth: 1, borderColor: 'white' }}
                    />
                )}
            </div>
            <div style={{ marginTop: '8px', textAlign: 'right' }}>
                {posts && posts.length > 0 && <div style={{ marginBottom: '8px', textAlign: 'right', height: '50px' }}>
                    {posts.slice(0, 3).map(p => renderPostThumbnail(p))}
                </div>}

                <Button
                    compact primary size='mini'
                    href={`/${productUrl}`}
                    onClick={() => logEvent('postDetailOpenProductDetailClicked')}
                >
                    IRL Looks
                    <Icon name='right chevron' />
                </Button>
            </div>
            {productreferral && (!!productreferral.productReviewComment || !!productreferral.productRating) &&
                (<>
                    <Header as={'h5'}>
                        {!!productreferral.productRating && <ProductRating
                            rating={productreferral.productRating}
                            readOnly
                            size='tiny'
                        />}
                        {productreferral && !!productreferral.productReviewComment && (
                            <span>
                                <Icon name='quote left' color='white'></Icon>
                                {productreferral.productReviewComment}
                            </span>
                        )}
                    </Header>
                </>)}
            {!!productreferral.productTrueFit && <ProductTrueFit value={productreferral.productTrueFit} readOnly compact />}
            {productreferral.forSale === 1 ? renderForSaleBlock() : <br />}
            <Divider clearing />
        </Segment>
    )
});

export default ProductreferralHorizontalCard;