import { remoteConfigSmftABHomeLandingHeadlinev1Enabled } from './remoteConfig'

const variants = [{
  id: 0,
  payload: {
    headline: 'Outfit Inspiration for your unique fit and style!',
    description: 'We don\'t believe that one size fits all but we do believe we have the perfect fit for you. Download the app to know more!',
    ctaText: 'Search seemyfit on App Store'
  }
},
{
  id: 1,
  payload: {
    headline: 'A Space that celebrates you as you are!',
    description: 'Looking for outfit ideas? Use seemyfit to see how other people have styled similar looks',
    ctaText: 'Give it a try!'
  }
},
{
  id: 1,
  payload: {
    headline: 'Find the right fit!',
    description: "So, let seemyfit become part of your online shopping you've always needed. Get inspired by people with similar style preferences and fit challenges as you!",
    ctaText: 'Give it a try!'
  }
}]

export const getLandingHeadlineV1Expr = () => {
  const variantID = remoteConfigSmftABHomeLandingHeadlinev1Enabled()
  let result = null
  // console.log("getLandingHeadlineV1Expr variantID", variantID)

  try {
    result = variants[0]
  } catch (e) {
    // console.log("getLandingHeadlineV1Expr error", e)
    result = variants[0]
  }

  // console.log("getLandingHeadlineV1Expr result", result)

  return result
}
