import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'

// import { sessionExpired } from './LoginSagas'

export function * updateUser ({ api, Actions, onSuccessAction, withoutInclude }, { request }) {
  //   const { filter } = request
  const include = 'posts.product'
  const config = {
    params: {
      ...(!withoutInclude && { include })
    },
    ...request
  }

  try {
    const response = yield call(api.updateUser, config)
    if (response) {
      const data = path(['data'], response)
      // do data conversion here if needed
      yield put(Actions.success(data))

      if (onSuccessAction) {
        yield put(onSuccessAction(data))
      }
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }

    yield put(Actions.failure())
  }
}
