import psl from 'psl'
import urlUtil from 'url'

import { getProductreferralsFromResponse } from './post'

export const getSiteNameFromUrl = (url) => {
  let siteName
  try {
    const hostname = (urlUtil.parse(url)).host
    // const hostname = (new URI(url)).hostname
    const parsed = hostname && psl.parse(hostname)
    siteName = parsed && parsed.domain
  } catch (e) {
    return null
  }

  return siteName
}

export const getBrandNameFromUrl = (url) => {
  const siteName = getSiteNameFromUrl(url)
  return getBrandNameFromSiteName(siteName)
}

export const getBrandNameFromSiteName = (siteName) => {
  return siteName && siteName.split('.')[0]
}

export const getBrandFilterFromUrl = (url, FILTERS) => {
  const siteName = getSiteNameFromUrl(url)
  if (!siteName) {
    return null
  }

  const filterFound = FILTERS.find(f => {
    return siteName.includes(f.name)
  })

  return filterFound
}

export const filterFilesByDomainName = (files, siteName) => {
  return siteName
    ? files.filter(f => f.product_url && f.product_url.includes(siteName))
    : files
}

export const getNumberOfSharedPosts = (product) => {
  const productreferrals = getProductreferralsFromResponse(product)
  return (productreferrals && productreferrals.length) || 0
}

export const getPopularDomainNamesFromPosts = (posts) => {
  const brandsArray = []
  posts && posts.map(p =>
    p.productreferrals && p.productreferrals.length > 0 &&
    p.productreferrals.map(pr => {
      brandsArray.push(getSiteNameFromUrl(pr.product.url))
    })
  )
  // console.log("brandsArray,", posts)
  const brands = new Map([...new Set(brandsArray)].map(
    x => [x, brandsArray.filter(y => y === x).length]
  ).sort((a, b) => b[1] - a[1]))
  const result = brands || brandsArray.get(0)
  return [...result.keys()]
}

export const getPopularBrandsFromPosts = (posts) => {
  const brandsArray = []
  posts && posts.map(p =>
    p.productreferrals && p.productreferrals.map(pr => {
      brandsArray.push(getSiteNameFromUrl(pr.product.url))
    })
  )
  const brands = new Map([...new Set(brandsArray)].map(
    x => [x, brandsArray.filter(y => y === x).length]
  ).sort((a, b) => b[1] - a[1]))
  const result = brands || brandsArray.get(0)
  return [...result.keys()]
}

export const getPopularDomainNameFromPosts = (posts) => {
  const brandsArray = []
  posts && posts.map(p =>
    p.productreferrals &&
    p.productreferrals.map(pr => {
      brandsArray.push(getSiteNameFromUrl(pr.product.url))
    })
  )
  const brands = [...new Set(brandsArray)]
  return brands && brands.length > 0 && brands[0]
}

export const getTotalViewsFromPosts = (posts) => {
  return (posts && posts.length > 0)
    ? posts.filter(p => p.product).map(f => f.product.number_requests).reduce((a, b) => a + b, 0)
    : 0
}

export const getProductsGroupedByBrandFromPosts = (posts) => {
  const groups = posts.reduce((groups, item) => {
    const siteName = getSiteNameFromUrl(item.product.url)
    const group = (groups[siteName] || [])
    group.push(item)
    groups[siteName] = group
    return groups
  }, {})
  // console.log(groups)

  return groups
}

export const getLooksGroupedByProductPosts = (posts) => {
  const groups = posts.reduce((groups, item) => {
    const url = item.product && item.product.url
    const group = (groups[url] || [])
    group.push(item)
    groups[url] = group
    return groups
  }, {})
  // console.log(groups)

  return groups
}

export const getTrackedUrlFromUrl = (url, user) => {
  const trackedUrl = new URL(url)
  const brandName = getBrandNameFromUrl(trackedUrl)
  const username = (user && user.instagram_username) || 'seemyfit'
  const utmCampaign = `seemyfit_${username}`
  const utmSource = 'seemyfit' // change this by user
  const utmMedium = `seemyfit_${brandName}`
  const utmTerm = 'social_media'
  const utmContent = user && user.id

  const searchParams = trackedUrl.searchParams
  searchParams.set('utm_campaign', utmCampaign)
  searchParams.set('utm_source', utmSource)
  searchParams.set('utm_medium', utmMedium)
  searchParams.set('utm_term', utmTerm)
  searchParams.set('utmContent', utmContent)

  trackedUrl.search = searchParams.toString()
  return trackedUrl
}

export const getProductTrackedUrl = (product, user) => {
  const productUrl = product && product.url
  if (!productUrl) {
    return null
  }

  return getTrackedUrlFromUrl(productUrl)
}
