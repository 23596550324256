import React, { useContext, useState } from 'react';
import { Header, Input } from 'semantic-ui-react';

import { AppContext } from '../../../../shared/context';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';


const SelectPromoCodesTab = (({
}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [promocode, setPromocode] = useState((userContext && userContext.promocode) || '');
  const [website, setWebsite] = useState((userContext && userContext.website) || '');

  const onSave = async () => {
    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = { promocode, website }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };

  return (
    <div>
      {renderSaving({ isSaving })}
      <div>
        <Header as='h4' inverted>
          What your promocode?
        </Header>
        <Input
          label="Promocode"
          placeholder='Eg. 20OFFCODE'
          onChange={(e, { value }) => setPromocode(value)}
          value={promocode}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      <br />
      <div>
        <Input
          label="Website"
          placeholder='Eg. mywebsite.com'
          onChange={(e, { value }) => setWebsite(value)}
          value={website}
          maxLength={200}
          autoCapitalize='characters'
        />
      </div>
      {renderSave(onSave, { isSuccess, errorMessage })}
    </div>
  );
});

export default SelectPromoCodesTab;