
import React from 'react'
import {
  Segment, Container, Grid, Header, List, Icon, Button, Image, Divider
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
/* eslint-disable react/no-multi-comp */
/* Heads up! Home uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
import { Media } from '../../utils/AppMediaBreakPoints'
import { logEvent } from '../../services/analytics'
import { PRIVACY, FAQ, DOWNLOAD_APP } from '../../constants/routes'
import availableChrome from '../../images/ChromeWebStoreBadge.png'
import appstoreimg from '../../images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'

const Footer = () => (
  <Segment as={Media} greaterThanOrEqual='tablet' inverted vertical style={{ padding: '5em 0em' }}>
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <Header inverted as='h4' content='About' />
            <List link inverted>
              <List.Item as='span'><b>Seemyfit</b> is a space for fashion-lovers who embrace diversity, inclusivity and creativity, a space that celebrate you as you are.
                It helps online shoppers find the right fit by showing them IRL photos of online products before they buy them, and it helps creators who share those looks by expanding their affiliate reach.
              </List.Item>
            </List>
            <List link inverted>
              <List.Item>
                <Link to={DOWNLOAD_APP} className='yellow' onClick={() => logEvent('footerDownloadAppFAQClicked')}>How to use see my fit?</Link>
              </List.Item>
              <List.Item>
                <Link to={FAQ} className='yellow' onClick={() => logEvent('footerFAQClicked')}>How to see online clothes on different body types?</Link>
              </List.Item>
              <List.Item>
                <Link to={PRIVACY} className='yellow' onClick={() => logEvent('footerPrivacyClicked')}>Privacy</Link> <br />
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header inverted as='h4' content='Contact us' />
            <List link inverted>
              <List.Item as='span'>Need help with something? chat with us directly on Instagram</List.Item>
              {/* <List.Item as='a' href='mailto:seemyfit@outlook.com' target='_blank' className='yellow' onClick={() => logEvent('footerContactUsEmailClicked')}><Icon name='at' />Email seemyfit@outlook.com</List.Item> */}
              <List.Item as='a' href='https://www.instagram.com/seemyfit/' target='_blank' className='yellow' onClick={() => logEvent('footerContactUsIGClicked')}><Icon name='instagram' />@seemyfit via Instagram</List.Item>
              <List.Item as='a' href='https://m.me/seemyfit' target='_blank' className='yellow' onClick={() => logEvent('footerContactUsMessengerClicked')}><Icon name='facebook messenger' />@seemyfit via Messenger</List.Item>
              <List.Item>
                <br />
                <br />
                <br />
                <center>©  2022 Logired</center><br />
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={6}>
            <Header inverted as='h4' content='Follow us' />
            <p>You can find us on <span role='img' aria-label='bellow'>👇</span></p>
            <Button circular color='facebook' icon='facebook' href='https://www.facebook.com/seemyfit' target='_blank' onClick={() => logEvent('footerFollowUsFBClicked')} />
            <Button circular color='instagram' icon='instagram' href='https://www.instagram.com/seemyfit' target='_blank' onClick={() => logEvent('footerFollowUsInstaClicked')} />
            <Button circular color='twitter' icon='twitter' href='https://twitter.com/seemyfit' target='_blank' onClick={() => logEvent('footerFollowUsTwitterClicked')} />
            <Button circular color='red' icon='pinterest' href='https://www.pinterest.fr/seemyfit/' target='_blank' onClick={() => logEvent('footerFollowUsPinterestClicked')} />
            <Divider hidden />
            <Button
              as='a'
              floated='left'
              basic
              href='https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=w_footer_desktop&mt=8' target='_blank'
              onClick={() => logEvent('footerInstalliOSAppClicked')}
            >
              <Image src={appstoreimg} />
            </Button>
            <Button
              as='a'
              floated='left'
              basic
              href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk' target='_blank'
              onClick={() => logEvent('footerInstallAddonClicked')}
            >
              <Image src={availableChrome} />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment >
)

export default Footer
