import React, { Component } from 'react'
import {
    Container,
    Grid,
    Visibility,
    Segment,
    Button,
    Icon,
    Divider, Image, Header, Loader
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
// import _ from 'lodash'

import { logEvent, logPageView } from '../../../services/analytics'

import Footer from '../../../layouts/components/Footer'
import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'

import { POSTS_LIST, getPostsListFilterRoute } from '../../../constants/routes'

import PlaceHolderSquareCard from '../components/PlaceHolderSquareCard'
import LookCardWithProductName from '../components/LookCardWithProductName'
import ProductFilters from './components/ProductFilters'
import InstagramTextLogo from '../../../images/Instagram_logo_white.svg'
import { DOMAINNAME_WITH_HTTPS } from '../../../shared/constants/apiroutes'
import { getLooksFullUrlWithBrand, getFullUrlFromRoute } from '../../../shared/utils/generateFullUrl'
import InstallMobileAppBannerContainer from '../../../components/InstallMobileAppBanner/InstallMobileAppBannerContainer'
import { extensionInstalled } from '../../../utils/chromeExtension'
import { Media } from '../../../utils/AppMediaBreakPoints'
import UserBrandsFilter from '../../User/components/UserBrandsFilter'
import { getTrendingBrands } from '../../Products/ProductsList/ProductsList'
import AddLooksToProductButton from '../../Products/components/AddLooksToProductButton'


class PostsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            siteName: null,
            showLoadNext: false,
            isInstalled: true,
            activeFilters: []
        }
    }

    componentDidMount() {
        const initialRouteName = POSTS_LIST
        const { history } = this.props
        const location = this.props.location || (history && history.location)
        const path = location && location.pathname
        const siteName = path && path.replace(initialRouteName, '') && path.split('/').pop()

        this.setSitenameFilter(siteName)

        logPageView({ name: 'PostsList' })

        let self = this
        window.onload = function () {
            self.setState({ isInstalled: extensionInstalled() })
        }
    }

    setStructuredData(siteName) {
        const initialRouteName = POSTS_LIST

        const brandName = siteName;
        const itemListElement = [{
            "@type": "ListItem",
            "position": 1,
            "name": "seemyfit.at",
            "item": DOMAINNAME_WITH_HTTPS
        }, {
            "@type": "ListItem",
            "position": 2,
            "name": `seemyfit.at${initialRouteName}`,
            "item": getFullUrlFromRoute(initialRouteName)
        }]
        if (brandName) {
            itemListElement.push({
                "@type": "ListItem",
                "position": 3,
                "name": `seemyfit.at${initialRouteName}/${brandName}`,
                "item": getLooksFullUrlWithBrand(brandName)
            })
        }

        const breadcrumbList = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: itemListElement
        }
        const structuredData = [breadcrumbList]
        const ldjsonDocumentId = 'googleJobNetworkScript'
        const element = document.getElementById(ldjsonDocumentId)
        if (element) {
            element.remove()
        }

        const googleJobNetworkScript = document.createElement('script')
        googleJobNetworkScript.type = 'application/ld+json'
        googleJobNetworkScript.id = ldjsonDocumentId
        googleJobNetworkScript.innerHTML = JSON.stringify(structuredData)
        document.head.appendChild(googleJobNetworkScript)
    }

    setSitenameFilter(newSitename) {
        let siteName = newSitename
        if (this.state.siteName && this.state.siteName.includes(newSitename)) {
            siteName = null
        }

        const { filterLoaded, pageNumberLoaded } = this.props
        let pageNumber = pageNumberLoaded

        if (filterLoaded) {
            if (filterLoaded.dn !== siteName) {
                pageNumber = 1
            }
        }

        this.setState({ siteName })
        this.setState({ activeFilters: siteName ? [siteName] : [] });
        this.loadItems(pageNumber, siteName)
        this.setStructuredData(siteName)
    }

    onProductFilterChange(siteName) {
        this.setSitenameFilter(siteName)
        siteName
            ? logEvent('postsListBrandFilterClicked', { filterName: siteName })
            : logEvent('postsListBrandFilterClicked')
    }

    loadItems(pageNumber, siteName) {
        let filter = { wrp: true } // this is temporary to support the migration to productreferrals

        if (siteName) {
            filter = {
                dn: siteName
            }
        }

        this.props.getPostsAction({
            filter,
            page: {
                number: pageNumber,
                size: 20
            }
        })
    }

    onLoadNextPressed() {
        const pageNumber = this.props.pageNumberLoaded + 1
        const siteName = this.state.siteName

        this.loadItems(pageNumber, siteName)
    }

    handleVisibilityUpdate = (e, { calculations }) => {
        if (calculations.bottomVisible) {
            // this.setState({ showLoadNext: true })
        }
    }

    onBrandFilterChange(activeFilters) {
        const lastClickedTag = activeFilters[activeFilters.length - 1] || "" // todo enable filering by more

        this.props.history.push({
            pathname: lastClickedTag ? getPostsListFilterRoute(lastClickedTag) : POSTS_LIST
        })
        logEvent(`looksListTagsFilter${lastClickedTag}`)

        this.setState({ activeFilters: lastClickedTag && [lastClickedTag] });
        this.setSitenameFilter(lastClickedTag)
    }

    renderPlaceholders() {
        return (
            <>
                <PlaceHolderSquareCard />
                <PlaceHolderSquareCard />
                <PlaceHolderSquareCard />
                <PlaceHolderSquareCard />
                <PlaceHolderSquareCard />
                <PlaceHolderSquareCard />
                <PlaceHolderSquareCard />
                <PlaceHolderSquareCard />
            </>
        )
    }

    renderItem(post) {
        // const siteName = getSiteNameFromUrl(file.url);
        // const formattedDate = moment(file.created_at).format("MMM Do YYYY")
        return (
            <Grid.Column
                key={post.id}
                id={post.id}
                style={{ overflow: 'hidden' }}
                mobile={16} tablet={8} computer={4}
                className='no-padding'
            >
                <LookCardWithProductName
                    post={post}
                    analytisEventName='postListProductCardImageClicked'
                />
            </Grid.Column>
        )
    }

    renderFilters() {
        return (
            <ProductFilters
                siteName={this.state.siteName}
                onProductFilterChange={(newSitename) => this.onProductFilterChange(newSitename)}
            />
        )
    }

    renderLoadMoreLooks(siteName) {
        const { lastPageReached, isNextPageLoading, activeFilters, isLoading } = this.props
        const trendingBrands = getTrendingBrands()

        return (<Segment basic clearing>
            {!lastPageReached && <Button
                floated='right' loading={isNextPageLoading}
                onClick={() => this.onLoadNextPressed()}
            >{`More ${siteName || ''} `}
                <Icon name='down arrow' />
            </Button>}
            {(!isLoading && !isNextPageLoading) && <UserBrandsFilter
                brandsList={trendingBrands}
                activeFilters={activeFilters}
                withTags
                onBrandFilterChange={(activeFilters) => {
                    setTimeout(() => document && document.body && document.body.scrollTo({ top: 0, behavior: 'smooth' }), 500)
                    this.onBrandFilterChange(activeFilters)
                }}
            />}
        </Segment>)
    }


    renderList() {
        const { items, isLoading } = this.props

        // console.log('renderList posts', items)

        return (
            <Visibility onUpdate={this.handleVisibilityUpdate}>
                {/* {this.renderFilters()} */}
                <Grid centered>
                    {
                        isLoading
                            ? this.renderPlaceholders()
                            : (items && items.map((p) => this.renderItem(p)))
                    }
                </Grid>
            </Visibility >
        )
    }

    render() {
        const { siteName, isInstalled, activeFilters } = this.state
        const { isNextPageLoading, isLoading } = this.props

        let title = "Fashion-lovers community of all shapes and sizes 📸!"
        if (siteName) {
            title = `${siteName} See my fit 📸!`
        }

        const trendingBrands = getTrendingBrands()

        return (
            <ResponsiveContainer
                history={this.props.history}
                isInstalled={isInstalled}
                location={this.props.location}
            >
                <Helmet title={title} />
                <Container className='no-horizontal-margin'>
                    <Divider hidden />
                    {/* <Responsive {...Responsive.onlyMobile}>
                    <HomepageHeadingMobile history={this.props.history} />
                </Responsive> */}
                    <Divider hidden />
                    <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
                        <Segment basic textAlign='center'>
                            <Header as='h1' inverted>
                                {siteName && `#${siteName}`} Trending Looks
                                <Header.Subheader>
                                    {(isNextPageLoading || isLoading) && <Loader active inline size='mini' />}
                                    Recent <b>{siteName && `${siteName} `}</b> looks shared on<Image src={InstagramTextLogo} size='tiny' spaced alt='Instagram' />with <b>#seemyfit</b>
                                </Header.Subheader>
                            </Header>
                            <UserBrandsFilter
                                brandsList={trendingBrands}
                                activeFilters={activeFilters}
                                withTags={true}
                                onBrandFilterChange={(activeFilters) => this.onBrandFilterChange(activeFilters)}
                            />
                        </Segment>
                        {this.renderList()}
                        {this.renderLoadMoreLooks(siteName)}
                    </Segment>
                    <Container as={Media} greaterThanOrEqual='computer'>

                        {/* <InstallMobileAppBannerContainer
                            history={this.props.history}
                            siteName={siteName}
                            title='Seemyfit - A space that celebrates you, as you are!'
                            subtitle={`Get seemyfit app and discover how shoppers like you style their ${siteName ? `${siteName} ` : ''}outfits`}
                            analyticsLabel='AppInviteFromPostsList'
                            appInstallCampaign={'w_looks_page'}
                        /> */}

                        <AddLooksToProductButton
                            title={`Share your ${siteName ? `${siteName}` : 'Style'}`}
                        />
                    </Container>
                </Container>
                <Divider hidden />
                <Footer history={this.props.history} />
            </ResponsiveContainer>
        )
    }

}
export default PostsList