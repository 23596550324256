import React, { useState, useContext, useEffect } from 'react'
import {
  Form, Message, Icon, Button, Loader, Image
} from 'semantic-ui-react'

import { useAuthState } from 'react-firebase-hooks/auth'
import { Link } from 'react-router-dom'

import { logEvent } from '../services/analytics';
import { sendSignInLink } from '../firebase/firebase';
import { EDITPROFILE } from '../constants/routes';
import { AppContext } from '../shared/context';

export const getSpinerEmailLink = (email) => {
  const gmailRegex = /^[\w-\.]+@([gmail+\.])+[\w-]{2,4}$/;
  const outlookRegex = /^[\w-\.]+@([outlook+\.]|[live+\.])+[\w-]{2,4}$/;
  const gmailLink = `https://mail.google.com/mail/u/0/#search/from%3A%40(%40seemyfit)+in%3Aanywhere`
  var emailLink = email
  if (email && email.match(gmailRegex)) {
    emailLink = <a href={gmailLink} target='_blank'
      rel='noopener noreferrer'>Open gmail <Icon name="external alternate"></Icon></a>
  } if (email && email.match(outlookRegex)) {
    emailLink = <a href={"https://outlook.live.com/"} target='_blank'
      rel='noopener noreferrer'>Open Outlook <Icon name="external alternate"></Icon></a>
  }
  return emailLink
}
const EmailLoginForm = ({ styleProp, auth, btnLabelText = 'Login' }) => {
  const [email, setEmail] = useState('')
  const [errorSignin, setErrorSignin] = useState(null)
  const [emailSent, setEmailSent] = useState(false)
  const [loadingSignin, setLoadingSignin] = useState(false)
  const [user, loading, error] = useAuthState(auth);


  const { user: userContext } = useContext(AppContext);

  useEffect(() => {
    // clean up controller
    let isSubscribed = true;

    // cancel subscription to useEffect
    return () => (isSubscribed = false)
  }, []);

  const handleSubmit = () => {
    setLoadingSignin(true)
    setEmailSent(false)
    const onSuccess = () => {
      setLoadingSignin(false)
      setEmailSent(true)
    }
    const onError = (error) => {
      setLoadingSignin(false)
      setErrorSignin(error)
      logEvent('sendSignInLinkError')
      console.error("Error sending email: ", error)
    }
    sendSignInLink(email, onSuccess, onError, 'EmailLoginForm')
  }


  if (loading || loadingSignin) {
    return <Loader active inline='centered' />
  }

  if (errorSignin) {
    return renderError(errorSignin)
  }

  if (emailSent) {
    const emailLink = getSpinerEmailLink(email)

    const message = (
      <>
        <b>Check your inbox {email}</b> tap the link we sent you to confirm your email and login. {emailLink}
      </>
    )
    return renderMessage(message)
  }


  const userContextAvatar = (user && user.photoURL) || (userContext && userContext.photoURL)
  const userContextUsername = (user && user.username) || (userContext && userContext.username)

  if (user) {
    const message = (
      <div>
        {(!userContextUsername)
          ? (
            <div>
              <div style={{ color: 'FEFEFE', fontSize: '10px', marginBottom: '2px', textAlign: 'center' }}>
                <small>{user.email} email <b>verified!</b></small>
              </div>
              <Link to={EDITPROFILE}>
                <Button
                  primary
                  compact
                  // size='large'
                  icon labelPosition='right'
                  // style={{ height: '40px' }}
                  onClick={() => logEvent('desktopHeaderInstallAddonClicked')}
                >
                  Complete your profile
                  <Icon name='right arrow' />
                </Button>
              </Link>
            </div>
          )
          : (<a href={EDITPROFILE}><Image avatar src={userContextAvatar} /><small>@{userContextUsername}</small></a>)
        }

      </div>
    )
    return message
  }

  // if (user) {
  //   const message = (<><b>Thanks!</b> You'll head from us soon on <b>{email}</b></>)
  //   return renderMessage(message)
  // }

  return (
    <Form
      size='tiny'
      success={!error}
      // loading={loading || currentUserLoading}
      style={{
        // paddingTop: '30px',
      }}
      onSubmit={() => handleSubmit()}
    >
      {/* <Form.Group> */}
      <Form.Input
        placeholder='Your email'
        name='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        iconPosition='left'
        action
      >
        <Icon name='at' />
        <input />
        <Button type='submit'>{btnLabelText}</Button>
      </Form.Input>
      {/* <Form.Button content='Subscribe' size='tiny' type='submit' /> */}
      {/* </Form.Group> */}
    </Form >
  )
}

const renderMessage = (message) => {
  return (
    <Message success size="tiny" compact>
      {message}
    </Message>
  )
}

const renderError = (error) => {
  return (
    <Message error>
      Ops we couldn't get your request, please try again, or reach out to us on IG.
      {error && <small> {error.message}</small>}
    </Message>
  )
}

export default EmailLoginForm
