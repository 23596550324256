import { getPopularBrandsFromPosts } from "../../utils/product";
import { formatNumber } from "../../utils/number";
import { getInfluencerStatusFromUser, getUserInfluencerLabel, influencersStatus } from "../../utils/influencerStatus";

const transformUser = (user) => {
    // Transform User
    // console.log("transformUser: ", user)
    const productreferrals = (user && user.productreferrals) || []
    const posts = (user && user.posts) || [] // legacy to be removed
    const looksShared = productreferrals.length > 0 ? formatNumber(productreferrals.length) :
        (formatNumber(posts.length) || 0)
    const followersCountStr = user && user.ig_followed_by_count && user.ig_followed_by_count.toString().replace(/,/g, '') || ''
    const followersCount = formatNumber(parseInt(followersCountStr))
    const brands = getPopularBrandsFromPosts(posts) || []
    const brandsCount = formatNumber(brands && brands.length)

    const website = user.website
    let websitename = website && website.replace('https://', '').replace('http://', '').replace('www.', '').replace(/\/$/, '')
    if (!websitename) {
        websitename = website
    }

    let tipUrls, bodyProperties, fitChallenges, styles, influencedBy = ''
    try { tipUrls = JSON.parse(user.tip_urls) } catch (e) { }
    try { bodyProperties = JSON.parse(user.body_properties) } catch (e) { }
    try { fitChallenges = JSON.parse(user.fit_challenges) } catch (e) { }
    try { styles = JSON.parse(user.styles) } catch (e) { }
    try { influencedBy = JSON.parse(user.influenced_by) } catch (e) { }

    const status = getInfluencerStatusFromUser(user)
    const subStatus = getUserInfluencerLabel(user)
    const isMega = influencersStatus.Mega === status
    const isMacro = influencersStatus.Macro === status

    const transformedUser = {
        ...user,
        productreferrals,
        websitename,
        looksShared,
        followersCount,
        brandsCount,
        brands,
        InfluencerStatus: status,
        InfluencerSubStatus: subStatus,
        isMega,
        isMacro,
        tipUrls,
        bodyProperties,
        fitChallenges,
        styles,
        influencedBy,
    }
    // console.log("transformed User: ", transformedUser)

    return transformedUser
}

export { transformUser }