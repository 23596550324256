import React, { useContext, useState, useEffect } from 'react';
import {
  Menu, Label, Dimmer, Loader, Icon, List, Divider,
  Button, Message, Segment, Container, Tab
} from 'semantic-ui-react';

import { AppContext } from '../../../../shared/context';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { updateWebUser } from '../../../../shared/api/web/users/userWebApi';
import updateFirebaseUser from '../../../../firebase/users/updateFirebaseUser';
import LogoutHyperLink from '../components/LogoutHyperLink';
import SelectGeneralTab from '../components/SelectGeneralTab';
import SelectBodyShapeTab from '../components/SelectBodyShapeTab';
import SelectFitChallengesTab from '../components/SelectFitChallengesTab';
import SelectSizesTab from '../components/SelectSizesTab';
import SelectStylesTab from '../components/SelectStylesTab';
import SelectSocialProfilesTab from '../components/SelectSocialProfilesTab';
import SelectPromoCodesTab from '../components/SelectPromoCodesTab';
import SelectTipJarTab from '../components/SelectTipJarTab';
import SelectCityTab from '../components/SelectCityTab';
import { extensionInstalled } from '../../../../utils/chromeExtension';

export const updateWebAndFirebaseUser = async (userContext, payload, setIsSuccessCallBack, setErrorMessageCallback, updateUserContext) => {

  //   // save this to firestore
  //   // save this locally 
  //   // save this to web api

  if (userContext && userContext.uid) {
    try {
      // console.log("updateWebAndFirebaseUser updateFirebaseUser userContext:", userContext)
      const fbRequest = payload
      const updatedUser = {
        ...fbRequest
      }
      const firebaseResult = await updateFirebaseUser(userContext.uid, updatedUser)
      updateUserContext(updatedUser)
      // console.log("updateWebAndFirebaseUser updateUser firebaseResult:", firebaseResult)
      setIsSuccessCallBack(true)
    } catch (e) {
      // console.log("updateWebAndFirebaseUser e", e)
      setIsSuccessCallBack(false)
      setErrorMessageCallback(`Failed to save, please try again. Error code: fbase-failed`)
    }

    const webUser = userContext.webUser
    if (webUser && webUser.id) {
      const webRequest = {
        id: webUser.id,
        ...payload
      }

      let webResult = null
      // console.log("updateWebAndFirebaseUser updateUser webRequest:", webRequest)
      try {
        webResult = await updateWebUser(webRequest)
        // console.log("updateWebAndFirebaseUser updateUser webResult:", webResult)
      } catch (e) {
        // console.log("updateWebAndFirebaseUser e", e)
        setIsSuccessCallBack(false)
        setErrorMessageCallback(`Failed to save, please try again. Error code: w-failed`)
      }
    }
  }
};

export const renderSave = (onSave, { isSaving, isSuccess, errorMessage }) => {
  return (
    <>
      <Divider />
      <div>
        <Button onClick={onSave} compact primary loading={isSaving}>
          Save
        </Button>
        {isSuccess && <Message success compact size='mini' floated='left'>Changes saved!</Message>}
        {errorMessage && <Message error compact size='mini' floated='left'>{errorMessage}</Message>}
      </div>
    </>
  )
}

export const renderSaving = ({ isSaving }) => {
  return (
    isSaving
      ? <Dimmer active={isSaving}>
        <Loader size='mini'>Saving ...</Loader>
      </Dimmer>
      : <></>
  )
}

const UserProfileTabs = (({ history }, ref) => {

  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth);
  const [isInstalled, setIsInstalled] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // TODO change this to use context instead of auth
  const { user: userContext, updateUser: updateUserContext, theme } = useContext(AppContext);

  useEffect(() => {
    // console.log("UserProfileTabs useEffect:");
    setIsInstalled(extensionInstalled())
    // async function getAndLoadUser() {
    //   try {
    //     const uid = user && user.uid
    //     const fbuser = await getFirebaseUser(uid);
    //     console.log("fbuser:", fbuser)
    //     updateUserContext({ uid, ...fbuser })
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };
    // getAndLoadUser();
  }, [])

  const renderMenuItem = ({ key, content, icon, number, index }) => (
    <Menu.Item key={key} active={index === activeTabIndex}>
      {icon && <Icon name={icon} />}
      {content}{number && (<Label>{number}</Label>)}
    </Menu.Item>
  )

  const renderGeneralTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectGeneralTab history={history} isInstalled={isInstalled} />
      </Tab.Pane>
    )
  }

  const renderBodySizeTabPane = () => {
    return <Tab.Pane inverted attached={false}>
      <SelectBodyShapeTab />
    </Tab.Pane>
  }

  const renderFitChallengesTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectFitChallengesTab />
      </Tab.Pane>
    )
  }

  const renderSizesTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectSizesTab />
      </Tab.Pane>
    )
  }

  const renderStylesTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectStylesTab />
      </Tab.Pane>
    )
  }

  const renderSocialProfilesTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectSocialProfilesTab />
      </Tab.Pane>
    )
  }

  const renderTipJarTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectTipJarTab />
      </Tab.Pane>
    )
  }

  const renderCityTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectCityTab />
      </Tab.Pane>
    )
  }

  const renderPromoCodesTabPane = () => {
    return (
      <Tab.Pane inverted attached={false}>
        <SelectPromoCodesTab />
      </Tab.Pane>
    )
  }

  const panes = [
    {
      menuItem: renderMenuItem({ key: 'general', content: 'General', icon: 'user circle', index: 0 }),
      render: () => renderGeneralTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'bodyshape', content: 'Body shape', icon: 'lock', index: 1 }),
      render: () => renderBodySizeTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'fitchallenges', content: 'Fit challenges', icon: 'lock', index: 2 }),
      render: () => renderFitChallengesTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'sizes', content: 'Sizes', icon: 'lock', index: 3 }),
      render: () => renderSizesTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'styles', content: 'Styles', icon: 'th', index: 4 }),
      render: () => renderStylesTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'socialprofiles', content: 'Social profiles', icon: 'youtube', index: 5 }),
      render: () => renderSocialProfilesTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'city', content: 'City', icon: 'map pin', index: 6 }),
      render: () => renderCityTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'tipjar', content: 'Tip jar', icon: 'dollar', index: 7 }),
      render: () => renderTipJarTabPane(),
    },
    {
      menuItem: renderMenuItem({ key: 'promocodes', content: 'Promo Codes', icon: 'tags', index: 8 }),
      render: () => renderPromoCodesTabPane(),
    }
  ]

  return (
    <Container>
      <Divider hidden />
      <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
        <Tab
          panes={panes}
          menu={{ inverted: true, stackable: true, secondary: true, vertical: true }}
          activeIndex={activeTabIndex}
          onTabChange={(e, { activeIndex }) => setActiveTabIndex(activeIndex)}
        />
      </Segment>
      <LogoutHyperLink history={history} />
    </Container>
  );
});

export default UserProfileTabs;