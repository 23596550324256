import React, { useState, createContext, useEffect, } from 'react';
import { Theme } from '../theme';
// import { ThemeColors } from '../theme/types/theme';

// export type UserType = {
//   id?: string, // firebase user id 
//   igUser?: object, // instagram user
//   webUser?: object, // seemyfit api user
//   photoURL?: string,
//   username?: string,
//   email?: string,
//   igCode?: string,
//   shortAccessToken?: string,
//   bodyProperties?: object,
//   fitChallenges?: object,
//   sizes?: [],
//   styles?: [],
//   cityLocation?: string,
//   influencedBy?: [],
//   isNewUser?: boolean, // firebase isNewUser
//   creationTime?: string, // firebase metadata
//   lastSignInTime?: string, // firebase metadata
//   brands?: []
// };

// type AppContextType = {
//   user: UserType,
//   updateUser: (user: UserType) => void
//   theme: ThemeColors,
//   themeType: string,
//   toggleTheme: (type: string) => void,
//   unreadMessages: number,
//   updateUnreadMessages: (count: number) => void,
//   draftPost: object,
//   updateDraftPost: (draftPost: object) => void,
//   blockedUserUids: [],
//   updateBlockedUsersUids: (draftPost: object) => void,
// };

export const appContextKey = "smft_actx"

export const AppContext = createContext({});

const initState = {
  id: null, // firebase id
  photoURL: null,
  username: null
};


let userLoaderAfterAppStart = null

export const AppContextProvider = props => {
  const getDefaultState = async () => {
    if (userLoaderAfterAppStart) return userLoaderAfterAppStart

    // console.log('getDefaultState loadUserObjString starting')
    try {
      const userObjStr = JSON.parse(window.localStorage.getItem(appContextKey))
      // console.log('getDefaultState userObjStr', userObjStr)
      userLoaderAfterAppStart = userObjStr
      // TODO rehydrate from persistent storage (localStorage.getItem(myLastSavedStateKey)) ?
      return userLoaderAfterAppStart
    } catch (e) {
      userLoaderAfterAppStart = null
      console.log('getDefaultState error:', e?.message)
      return null
    }
  }

  const [user, setUser] = useState(initState);
  const [theme, setTheme] = useState(Theme.dark.colors); // default theme
  const [themeType, setThemeType] = useState(Theme.dark.type); // default theme
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [draftPost, setDraftPost] = useState({});
  const [blockedUserUids, setBlockedUserUids] = useState([]);

  const updateUser = (newValues) => {
    console.log('AppContext updateUser with:', { ...newValues })
    const value = { ...user, ...newValues }
    setUser(value);
    console.log('AppContext updated user:', value)
    window.localStorage.setItem(appContextKey, JSON.stringify(value))
  };

  const clearUser = () => {
    console.log('AppContext clearUser')
    setUser(initState);
    window.localStorage.removeItem(appContextKey);
  };

  const toggleTheme = (type) => {
    setTheme(Theme[type].colors);
    setThemeType(type);
    // saveThemeType(type);  TODO save theme
  };

  const updateUnreadMessages = (count) => {
    setUnreadMessages(count);
  };

  const updateDraftPost = (draftPost) => {
    setDraftPost(draftPost);
  };

  const updateBlockedUsersUids = (blockedUserUids) => {
    setBlockedUserUids(blockedUserUids);
  };

  const initContext = () => {
    getDefaultState()
      .then((userLoader) => {
        if (userLoader) setUser(userLoader)
      })
  }
  useEffect(initContext, [])


  const value = {
    user,
    updateUser,
    theme,
    themeType,
    toggleTheme,
    unreadMessages,
    updateUnreadMessages,
    draftPost,
    updateDraftPost,
    blockedUserUids,
    updateBlockedUsersUids,
    clearUser
  };

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  );
};