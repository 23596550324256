import React, { useContext, useState, useEffect } from 'react'
import {
  Image, Message, Header, Icon, Button, Loader, Tab, Container, Grid, Input
} from 'semantic-ui-react'
import _ from 'lodash'
import { getAuth } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import { logEvent } from '../services/analytics';
import BodySizeSelector from './BodySizeSelector';
import { sendSignInLink } from '../firebase/firebase';
import { getSpinerEmailLink } from './EmailLoginForm';
import { AppContext } from '../shared/context';
import { getIGMedia } from '../shared/api/instagram/igMedia';
import { IG_MEDIA_LIMIT } from '../shared/services/instagramBasicDisplayApi';

const IgMediaSelector = ({ styleProp, onMediaSelected, itemCountToShow = 15 }) => {
  const [mediaArray, setMediaArray] = useState([])
  const [nextPage, setNextPage] = useState(null)
  const [endMediaReached, setEndMediaReached] = useState(false)
  const [internalPageCursor, setInternalPageCursor] = useState(itemCountToShow)
  const [loadingMedia, setLoadingMedia] = useState(false)
  const [selectedPost, setSelectedPost] = useState(null)
  const { user: userContext } = useContext(AppContext);

  const loadIgMedia = async (next) => {
    try {
      setLoadingMedia(true)
      const shortToken = userContext && userContext.igBasicApi && userContext.igBasicApi.access_token
      const request = { shortToken, next }
      const response = await getIGMedia(request);

      if (response) {
        setLoadingMedia(false)
        setMediaArray(response.data)
        const nextPage = response.paging && response.paging.next
        if (nextPage) {
          setNextPage(nextPage)
        } else {
          setEndMediaReached(true)
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (onMediaSelected) {
      // Todo later transform here based on type
      const post = selectedPost && {
        mediaUrl: selectedPost.media_url,
        url: selectedPost.permalink,
        original: selectedPost
      }
      onMediaSelected(post)
    }
  }, [selectedPost])

  useEffect(() => {
    async function getAndLoadMedia() {
      await loadIgMedia(null)
    };
    getAndLoadMedia();
  }, [])

  if (loadingMedia) {
    return <Loader active inline='centered' />
  }

  const onPostPress = (item) => {
    logEvent("IgMediaSelectorOnPostClicked")
    setSelectedPost(item)
  }

  const onLoadMorePress = () => {
    const newCursor = internalPageCursor + itemCountToShow
    // If internal pagination of 100 is reached, load next page
    if (newCursor >= IG_MEDIA_LIMIT) {
      if (nextPage) {
        setNextPage(nextPage)
        loadIgMedia(nextPage)
        setInternalPageCursor(itemCountToShow)
      }
    } else {
      setInternalPageCursor(newCursor)
    }

    logEvent("IgMediaSelectoronLoadMoreClicked")
  };

  const onRefreshPress = () => {
    setInternalPageCursor(itemCountToShow)
    setEndMediaReached(false)
    loadIgMedia(null)
    logEvent("IgMediaSelectoronRefreshClicked")
  };

  const renderMedia = (item) => {
    const id = item && item.id
    const selected = (selectedPost && selectedPost.id) === id

    return (
      <Grid.Column onClick={() => onPostPress(item)} key={id} width='3'
        {...(selected ? { color: "blue" } : {})}
      >
        {item &&
          <Image src={item.media_url}
            size='small'
            style={{ width: "100px" }}
          />
        }
      </Grid.Column >
    )
  }

  const medias = mediaArray && mediaArray.map && mediaArray.slice(0, internalPageCursor)

  return (
    <div>
      <Grid columns='equal'>{medias.map(renderMedia)}
      </Grid>
      <Message color='black' >
        <center>
          <Button
            compact
            color='grey'
            style={{ marginBottom: '10px' }}
            onClick={() => endMediaReached ? onRefreshPress() : onLoadMorePress()}>
            {endMediaReached ? "Refresh" : "Load more"}
          </Button>
        </center>
      </Message>
    </div>
  )
}

const renderMessage = (message) => {
  return (
    <Message success>
      {message}
    </Message>
  )
}

const renderError = (error) => {
  return (
    <Message error>
      Ops we couldn't get your request, please try again, or reach out to us on IG.
      {error && <small> {error.message}</small>}
    </Message>
  )
}

export default IgMediaSelector
