import React from 'react'
import ReactDOM from 'react-dom'

import createStore from './store/redux'
import './index.css'
import AppContainer from './AppContainer'
import 'semantic-ui-less/semantic.less'
import * as serviceWorker from './serviceWorker'

const store = createStore()

const rootElement = document.getElementById('root')

const renderApp = () =>
  ReactDOM.render(
    <AppContainer store={store} />,
    rootElement
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./AppContainer', renderApp)
}

renderApp()

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
