
const appId = '4159100cc4msh3fd8f31ba5ac550p1c3d93jsnfc35f44c6edb'

export const getIgProfilePicByUsername = async (username) => {
  try {
    const response = await fetch(`https://instagram188.p.rapidapi.com/userphoto/${username}`,
      {
        method: "GET",
        headers: {
          'X-RapidAPI-Key': appId,
          'X-RapidAPI-Host': 'instagram188.p.rapidapi.com'
        }
      })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}

export const getIgPostDetailByUrl = async (shortcode) => {
  try {
    const response = await fetch(`https://instagram188.p.rapidapi.com/postinfo/${shortcode}`,
      {
        method: "GET",
        headers: {
          'X-RapidAPI-Key': appId,
          'X-RapidAPI-Host': 'instagram188.p.rapidapi.com'
        }
      })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}
