import React, { useContext, useEffect } from 'react';
import {
  Container
} from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

import { AppContext } from '../../shared/context';
import getFirebaseUser from '../../firebase/users/getFirebaseUser';
import LastPostsContainer from '../Posts/LastPosts/LastPostsContainer';
import LastProductsContainer from '../Products/LastProducts/LastProductsContainer';


const PersonalizedHomePage = (({ history, isInstalled, siteName }, ref) => {

  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth);
  const { user: userContext, updateUser: updateUserContext, theme } = useContext(AppContext);

  // useEffect(() => {
  //   console.log("---------------PersonalizedHomePage useEffect");
  //   async function getAndLoadUser() {
  //     try {
  //       const uid = user && user.uid
  //       if (uid) {
  //         console.log("PersonalizedHomePage getFirebaseUser:");
  //         const fbuser = await getFirebaseUser(uid);
  //         console.log("fbuser:", fbuser)
  //         updateUserContext({ uid, ...fbuser })
  //       }
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };
  //   getAndLoadUser();
  // }, [user])

  console.log("PersonalizedHomePage get user with ID:", user && user.uid)

  // TODO change this based on the current user

  return (
    <Container>


      {!!user && <LastPostsContainer history={history} siteName={siteName} />}

      {/* you can put here any type of diver taken from param */}

      {!!user && <LastProductsContainer history={history} siteName={siteName} />}
    </Container>
  );
});

export default PersonalizedHomePage;