import React, { useState } from 'react'
import {
  Form, Message, Header, Icon, Button, Label, Input, List, Image
} from 'semantic-ui-react'
import SelectorButtons from './SelectorButtons'



const StylesSelector = ({ styles, intialSelectedStyles, initialInfluencedBy,
  maxStyleSelection = 10, maxFavouriteInfluencersSelection = 4,
  onStyleChange, onInfluencedByChange, onMaxSelectionReached = () => { } }) => {


  const [selectedIds, setSelectedIds] = useState(intialSelectedStyles || [2, 4]);
  const [favouriteInfluencer, setFavouriteInfluencer] = useState('');

  const onSelected = (id) => {
    console.log('onSelected:', id)
    let ids = [...selectedIds]
    if (ids.includes(id)) {
      setSelectedIds(ids.filter(i => i !== id))
    } else {
      ids.push(id)
      if (ids.length > maxStyleSelection) {
        onMaxSelectionReached()
      } else {
        setSelectedIds(ids)
      }
    }

    onStyleChange(ids)
  }
  const onAddFavouriteInfluencerPress = () => {
    console.log('onAddFavouriteInfluencerPress favouriteInfluencer:', favouriteInfluencer)
    if (!favouriteInfluencer) return
    const influencedByArry = [...initialInfluencedBy]
    const value = favouriteInfluencer.replace('@', '')
    influencedByArry.push(value.replace(' ', ''))
    console.log('onAddFavouriteInfluencerPress array:', influencedByArry)
    console.log('onAddFavouriteInfluencerPress new one:', favouriteInfluencer)
    onInfluencedByChange(influencedByArry)
    setFavouriteInfluencer('')
  }
  const onRemoveFavouriteInfluencerPress = (influencerUsername) => {
    console.log('onRemoveFavouriteInfluencerPress influencerUsername:', influencerUsername)
    const influencedByArry = [...initialInfluencedBy]
    onInfluencedByChange(influencedByArry.filter(i => i !== influencerUsername))
    setFavouriteInfluencer('')
  }

  const renderFavouriteInfluencer = (influencerUsername, index) => {
    return (
      <Label as='a' basic
        key={index}
        onClick={() => onRemoveFavouriteInfluencerPress(influencerUsername)}
      >
        @{influencerUsername}
        <Icon name='delete' />
      </Label>
    )
  }

  const renderStyle = (item, index) => {
    const isSelected = selectedIds.includes(item && item.id)
    return (
      <List.Item inverted active={isSelected}
        onClick={() => onSelected(item.id)} style={{ margin: '3px' }}>
        <Image avatar src={item.background} />
        <List.Content>
          <List.Header inverted>{item.name}</List.Header>
        </List.Content>
      </List.Item>

      // <Label as='a' basic inverted image
      //   key={index} onPress={() => onSelected(item.id)}
      //   color={isSelected ? 'red' : 'black'}>
      //   <img src={item.background} />
      //   {item.name}
      // </Label>
    )
  }

  return (
    <div>
      <List horizontal inverted relaxed selection verticalAlign='middle' animated size={'large'}>
        {styles.slice(0, 4).map(renderStyle)}
        {styles.slice(4, 8).map(renderStyle)}
        {styles.slice(8, 12).map(renderStyle)}
        {styles.slice(12, 16).map(renderStyle)}
        {styles.slice(16, 20).map(renderStyle)}
        {styles.slice(20, 25).map(renderStyle)}
      </List>

      <Header as='h4' inverted>
        What's your favourite Instagram Influencers at the moment?
      </Header>

      {initialInfluencedBy && initialInfluencedBy.length <= maxFavouriteInfluencersSelection && (
        <div>
          <Input
            placeholder={`Eg. @elena`}
            value={favouriteInfluencer}
            onChange={(e, { value }) => setFavouriteInfluencer(value)}
            action={{ content: 'Add', color: 'basic', inverted: true, onClick: onAddFavouriteInfluencerPress }}
          />
        </div>
      )
      }
      <br />
      {initialInfluencedBy && initialInfluencedBy.map(renderFavouriteInfluencer)}
    </div >
  )
}

export default StylesSelector
