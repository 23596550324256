import React from 'react'
import {
  // Container,
  Divider
  // Grid,
  // Segment,
  // Header,
  // Image
} from 'semantic-ui-react'

import TryItNow from '../../components/TryItNow'
import HelpCommunity from '../../components/HelpCommunity'
import ResponsiveContainer from '../../layouts/components/Responsive/ResponsiveContainer'
import Footer from '../../layouts/components/FooterDesktop'
import BrowserDemo from '../Home/components/BrowserDemo'
// import looksexample from '../../images/seemyfit-looks.jpg'
// import InstallAddonButton from '../../components/InstallAddonButton'
// import { logEvent } from '../../services/analytics'

// const renderBloc1 = () => (
//   <Container>
//     <Segment
//       style={{
//         // backgroundImage: `url(${bgTryitNow})`,
//         backgroundSize: 'cover'
//       }} inverted
//     >
//       <Segment
//         style={{
//           margin: '15px 60px',
//           background: 'rgba(27, 28, 29, 0.95)',
//           borderRadius: '20px',
//           padding: '30px'
//         }} inverted
//       >
//         <Grid centered>
//           <Grid.Row>
//             <Header
//               as='h3'
//               style={{
//                 fontSize: '1.7em',
//                 fontWeight: 'normal'
//               }}
//             >
//               Check Internet's most-beloved fashion brands and see if they look as good in real life, as they do online. <span role='img' aria-label='Backhand Index Pointing Down'>👇</span>

//               <br />
//               <br />
//               <span role='img' aria-label='Stunning'>🤩</span> Obsessed with a dress?
//               <br />
//               <span role='img' aria-label='Pointing Hand Right'>👉</span> Tap  Seemyfit and see how it looks IRL.
//               <br />
//               <br />
//               {/* and help you <b className='underlined'> Find Clothes That Fit! </b> */}
//             </Header>
//           </Grid.Row>
//           <Grid.Row>
//             <Image bordered rounded size='large' src={looksexample} />
//           </Grid.Row>
//         </Grid>
//         <Grid container stackable verticalAlign='middle' centered>
//           <Grid.Row>

//           </Grid.Row>
//         </Grid>
//       </Segment>
//     </Segment>
//   </Container>
// )

// const renderBloc2 = () => (
//   <Container>
//     <Segment
//       style={{
//         // backgroundImage: `url(${bgTryitNow})`,
//         backgroundSize: 'cover'
//       }} inverted
//     >
//       <Segment
//         style={{
//           margin: '15px 60px',
//           background: 'rgba(27, 28, 29, 0.95)',
//           borderRadius: '20px',
//           padding: '30px'
//         }} inverted
//       >
//         <Grid centered>
//           <Grid.Row>
//             <Header
//               as='h3'
//               style={{
//                 fontSize: '1.7em',
//                 fontWeight: 'normal'
//               }}
//             >Use <b>seemyfit</b> browser add-on while shopping your favorite stores. <br />
//             We show you instagram photos of the same product <br />
//             instantly saving you fit-guessing... <br />
//               {/* and help you <b className='underlined'> Find Clothes That Fit! </b> */}
//             </Header>
//           </Grid.Row>
//           <Grid.Row>
//             <InstallAddonButton />
//           </Grid.Row>
//         </Grid>
//         <Grid container stackable verticalAlign='middle' centered>
//           <Grid.Row>
//             <Grid.Column floated='right' largeScreen={6} widescreen={1}>
//               {/* <Image bordered rounded size='large' src={herophoto1} /> */}
//             </Grid.Column>
//             <Grid.Column largeScreen={8} widescreen={1}>
//               <Header as='h2' style={{ fontSize: '2em' }}>
//                 Try it now! <span role='img' aria-label='Flirting'>😏</span>
//               </Header>
//               <p style={{ fontSize: '1.33em' }}>
//                 <b>1:</b>{' Add '}
//                 <a
//                   href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk'
//                   target='_blank' rel='noopener noreferrer' className='yellow'
//                   onClick={() => logEvent('tryItInstallAddonClicked')}
//                 >
//                   seemyfit extension
//                 </a> to your chome<br />
//                 <br />
//                 <b>2:</b> {'Visit this '}
//                 <a
//                   href='https://ca.boohoo.com/premium-feather-trim-cupped-bralet/RZZ99669.html'
//                   target='_blank' rel='noopener noreferrer' className='yellow'
//                   onClick={() => logEvent('tryItVisitLinkClicked')}
//                 >
//                   Premium Feather Trim Cupped Bralet
//                 </a><br /><br />
//                 <b>3:</b> Click <b>"See my Fit"</b> button and that's it!<br /><br />
//                 {/* <b>Now pause for a moment <br />
//                                 and appreciate how awesome is this! <span role='img' aria-label='Namaste'>🙏</span>
//                   </b><br />
//                             We show you instagram photos of the same clothes in the same page to help you get an idea of how the outfit looks IRL and help you find what's fit your taste! */}
//               </p>
//             </Grid.Column>
//           </Grid.Row>
//         </Grid>
//       </Segment>
//     </Segment>
//   </Container>
// )

const FAQPage = (props) => (
  <ResponsiveContainer history={props.history} location={props.location}>
    {/* {renderBloc1()} */}
    {/* <Divider hidden /> */}
    {/* {renderBloc2()} */}
    <TryItNow />
    <BrowserDemo />
    <Divider hidden />
    <HelpCommunity />
    <Divider hidden />
    <Footer />
  </ResponsiveContainer>
)

export default FAQPage
