import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'

// import { sessionExpired } from './LoginSagas'

export function * getUsers ({ api, Actions }, { request }) {
  const { filter, page } = request

  if (page && page.number > 1) {
    yield put(Actions.nextPageRequest())
  }

  const include = 'posts.product'
  const sort = '-updated_at'

  const config = {
    params: {
      include,
      sort,
      ...(filter && { filter: { ...filter } }),
      ...(page && { page: { ...page } })
    }
  }

  try {
    const response = yield call(api.getUsers, config)
    if (response) {
      const itemsPage = path(['data'], response)
      const paging = path(['meta', 'page'], response)
      const lastPageNumber = paging && paging['last-page']
      const total = paging && paging.total
      // do data conversion here if needed
      yield put(Actions.success(itemsPage, lastPageNumber, total))
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }

    yield put(Actions.failure())
  }
}
