import { call, put } from 'redux-saga/effects'
import { path } from 'ramda'

// import { sessionExpired } from './LoginSagas'

export function * getPost ({ api, Actions, onSuccessAction, withoutInclude, withOpenGraph }, { request }) {
  const { filter, onSuccess } = request
  const include = 'user.posts,productreferrals.product.productreferrals.post'
  // TODO enable when this is fixed https://github.com/wopian/kitsu/issues/133
  // ,productreferrals.product.productreferrals.post
  const config = {
    params: {
      ...(!withoutInclude && { include }),
      ...(filter && { filter: { ...filter } })
    }
  }

  try {
    const response = yield call(api.getPosts, config)
    if (response) {
      const data = path(['data'], response)
      // TODO if there is no post get it from instagram

      // do data conversion here if needed
      yield put(Actions.success(data))

      if (onSuccessAction) {
        yield put(onSuccessAction(data))
      }

      if (onSuccess) {
        yield put(onSuccess(data))
      }
    } else {
      yield put(Actions.failure())
    }
  } catch (error) {
    // if (error.sessionExpired) {
    //     yield sessionExpired(api)
    //     return
    // }

    yield put(Actions.failure())
  }
}
