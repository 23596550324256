import React, { Component } from 'react'
import {
  Image,
  Header,
  Grid,
  Segment,
  Container
} from 'semantic-ui-react'

import herophoto2 from '../images/herophoto2.jpg'

class HelpCommunity extends Component {
  render () {
    return (
      <Container>
        <Segment
          style={{
            borderRadius: '20px'
          }} inverted
        >
          <Grid container stackable verticalAlign='middle' centered>
            <Grid.Row>
              <Grid.Column largeScreen={9}>
                <Header as='h2'>
                  Share your looks. Help the community!
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  Online dresses always look totally different when you try them on yourself. Use <b>Seemyfit</b> browser extension while shopping your favorite stores to automatically find Creators photos and videos of the same product on different body types, instantly saving you fit-guessing! <span role='img' aria-label='Victory Hand'>✌️</span>
                  <br /><br />
                  <Header as='h2'>
                    Expand your Instagram audience 100% Free!
                  </Header>
                  <span role='img' aria-label='Stunning'>👗</span> You can add your Instagram post to any seemyfit product by adding the URL of your post to the product page
                  or you can use <b>seemyfit.at/yourInstagramUsername</b> as bio link to tag products on Instagram posts and turn your feed into a shoppable, optimized landing page!
                </p>
              </Grid.Column>
              <Grid.Column floated='left' largeScreen={7}>
                <Image bordered rounded size='medium' src={herophoto2} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    )
  }
}
export default HelpCommunity
