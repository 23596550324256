import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import postsListActions from '../../../store/redux/posts/PostsListRedux'

import LastPosts from './LastPosts'

export default connect(
  (state, ownProps) => ({
    history: ownProps.history,
    siteName: ownProps.siteName,
    // currentUser: state.login.currentUser,
    items: state.postsState.items.asMutable(),
    filterLoaded: state.postsState.filterLoaded,
    pageNumberLoaded: state.postsState.pageNumberLoaded,
    isLoading: state.postsState.fetching,
    isNextPageLoading: state.postsState.isNextPageLoading,
    lastPageReached: state.postsState.lastPageReached,
    statusText: state.postsState.statusText
  }),
  dispatch => ({
    getPostsAction: bindActionCreators(postsListActions.request, dispatch)
  })
)(LastPosts)
