import React, { Component } from 'react'
import {
  Segment,
  Grid,
  Icon,
  Image,
  Header,
  Label,
  Statistic,
  Divider,
  Message, Container
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
// import _ from 'lodash'

import { logEvent } from '../../../services/analytics'
import {
  getTotalViewsFromPosts,
  getPopularDomainNamesFromPosts,
  getLooksGroupedByProductPosts
} from '../../../shared/utils/product'
import { formatNumber } from '../../../shared/utils/number'
import PlaceHolderSquareCard from '../../Posts/components/PlaceHolderSquareCard'
// import AddIGPostToProductContainer from '../../Posts/AddPost/AddIGPostToProductContainer'
import LookCardWithProductName from '../../Posts/components/LookCardWithProductName'
import { Media } from '../../../utils/AppMediaBreakPoints'

// import instagramLogo from '../../../images/instagram-icon.png'
import tiktokLogo from '../../../images/tiktok.png'
// import snapchatLogo from '../../../images/snapchat.png'
// import youtubeLogo from '../../../images/youtube.png'
// import pinterestLogo from '../../../images/pinterest.png'
// import facebookLogo from '../../../images/facebook.png'
// import twitterLogo from '../../../images/twitter.png'
import UserBrandsFilter from './UserBrandsFilter'
import UserHeaderComponent from './UserHeaderComponent'
import { getPostsFromResponse } from '../../../shared/utils/post'
import { isVerifiedAccount, getUserInfluencerLabel, getInfluencerStatusFromUser, influencersStatus } from '../../../shared/utils/influencerStatus'
import PromocodeLabel from '../../../layouts/components/PromocodeLabel'
import { renderUserAvatarWithFallback, renderUsernameVerifiedBadge } from './UserCard'
import InstallMobileAppBannerContainer from '../../../components/InstallMobileAppBanner/InstallMobileAppBannerContainer'
import AppIconLogo from '../../../images/appIconLogo.png'
import { transformUser } from '../../../shared/api/transformers/userTransformer'
import AddLooksToProductButton from '../../Products/components/AddLooksToProductButton'

class UserFeed extends Component {
  onBrandFilterChange(activeFilters) {
    this.props.onBrandFilterChange && this.props.onBrandFilterChange(activeFilters)
  }

  renderSocialIcon(image, label, url) {
    return (
      <a
        href={url}
        style={{
          paddingLeft: '5px',
          paddingRight: '5px',
          textAlign: 'center',
          display: 'inline-block'
        }}
        className='scrolling content'
      >
        <Image src={image} centered size='mini' />
        {/* <p style={{
          // whiteSpace: 'nowrap',
          overflow: 'hidden',
          // textOverflow: 'ellipsis',
          display: 'block',
          // flexWrap: 'nowrap',
          color: 'white',
          paddingTop: '4px',
          fontSize: '1em'
        }}
        >
          {label}
        </p> */}
      </a>
    )
  }

  // renderSocialMedia(user) {
  //   // TODO prepare the links
  //   return (
  //     // <Segment inverted className='horizontal-scroll-container'>
  //     /* <Divider horizontal inverted>Links</Divider> */
  //     <div className='horizontal-scroll-content'>
  //       {user.instagram_username && this.renderSocialIcon(instagramLogo, user.instagram_username, `https://instagram.com/${user.instagram_username}`)}
  //       {user.tiktok && this.renderSocialIcon(tiktokLogo, user.tiktok, `https://tiktok.com/@${user.tiktok}`)}
  //       {user.snapchat && this.renderSocialIcon(snapchatLogo, user.snapchat, `https://www.snapchat.com/add/${user.snapchat}`)}
  //       {user.youtube && this.renderSocialIcon(youtubeLogo, 'youtube', user.youtube)}
  //       {user.pinterest && this.renderSocialIcon(pinterestLogo, user.pinterest, `https://pinterest.com/${user.pinterest}`)}
  //       {user.facebook && this.renderSocialIcon(facebookLogo, user.facebook, `https://facebook.com/${user.facebook}`)}
  //       {user.twitter && this.renderSocialIcon(twitterLogo, user.twitter, `https://twitter.com/${user.twitter}`)}
  //     </div>
  //     // </Segment>
  //   )
  // }

  renderBrands(allPosts, activeFilters) {
    return (
      <UserBrandsFilter
        allPosts={allPosts}
        activeFilters={activeFilters}
        onBrandFilterChange={(activeFilters) => this.onBrandFilterChange(activeFilters)}
      />
    )
  }

  // TODO not used
  // renderUserHeaderMobile(
  //   profile,
  //   username,
  //   isLoadingInstagram,
  //   isLoadingProfile,
  //   followersCount,
  //   looksShared,
  //   totalProductViews,
  //   website, websitename, promocode, lastPost,
  //   isMega, isMacro, subStatus
  // ) {
  //   return (
  //     <Container
  //       as={Media} at='mobile'
  //       style={{ marginRight: '0', marginLeft: '0' }}
  //     >
  //       {/* <Responsive
  //       as='div'
  //       {...Responsive.onlyMobile}
  //     > */}
  //       <Header as='h3' inverted>
  //         {profile && (
  //           <Image
  //             circular
  //             src={profile.instagram_avatar}
  //             onClick={() => logEvent('instagramProfileAvatarClickedMobile')}
  //           />
  //         )}
  //         <Header.Content style={{ textAlign: 'left' }}>
  //           @{username}
  //           <Header.Subheader>
  //             {isLoadingProfile ? '...' : profile && profile.name}{profile && (isVerifiedAccount(profile)) &&
  //               (
  //                 <Icon name='check circle' color='blue' />
  //               )}
  //           </Header.Subheader>
  //         </Header.Content>
  //       </Header>
  //       <Segment
  //         inverted style={{
  //           position: 'absolute',
  //           padding: '10px',
  //           borderRadius: '5px',
  //           width: '350px',
  //           right: 0,
  //           left: 0,
  //           margin: 'auto',
  //           zIndex: '99',
  //           background: 'rgba(0,0,0,.8)'
  //         }}
  //       >
  //         <Header as='h5'>
  //           {`Download the App to see how your overall style & fit match with ${username}`}
  //           <Label
  //             floated='right'
  //             as='a'
  //             image
  //             primary
  //             color='blue'
  //             // size='large'
  //             href={`https://apps.apple.com/app/apple-store/id1510249148?pt=120943506&ct=w_profile_${username}&mt=8`}
  //             target='_blank'
  //             rel='noopener noreferrer'
  //             // icon labelPosition='left'
  //             style={{ marginTop: '5px' }}
  //             onClick={() => logEvent('MobileProfilePageInstalliOSApp')}
  //           >
  //             <img
  //               src={AppIconLogo}
  //               alt='Seemyfit app'
  //             // size='small' centered floated='left'
  //             // style={{ width: '40px', height: '40px', verticalAlign: 'middle' }}
  //             />
  //             Download
  //           </Label>
  //         </Header>
  //       </Segment>
  //       <Grid centered style={{ marginTop: '20px', marginBottom: '10px' }}>
  //         <Statistic.Group
  //           size='tiny'
  //           // {...Responsive.onlyMobile}
  //           inverted
  //           {...(isMega ? { color: 'red' } : {})}
  //           {...(isMacro ? { color: 'orange' } : {})}
  //           style={{ padding: '0' }}
  //         >
  //           <Statistic style={{ margin: '0' }}>
  //             <Statistic.Value>--</Statistic.Value>
  //             <Statistic.Label>Style match</Statistic.Label>
  //           </Statistic>
  //           <Statistic>
  //             <Statistic.Value>--</Statistic.Value>
  //             <Statistic.Label>Fit match</Statistic.Label>
  //           </Statistic>
  //           {totalProductViews !== '0' && totalProductViews !== 0 && (
  //             <Statistic>
  //               <Statistic.Value>{totalProductViews}</Statistic.Value>
  //               <Statistic.Label>Monthly Views</Statistic.Label>
  //             </Statistic>
  //           )}
  //         </Statistic.Group>
  //       </Grid>
  //       {(website || promocode) && (
  //         <div style={{ marginTop: '10px', paddingBottom: '1em' }} columns='2'>
  //           {website && (
  //             <a href={website} target='_blank' rel='noopener noreferrer'>
  //               {websitename}{' '}
  //               {promocode && (
  //                 <Label basic color='black' size='tiny' onClick={() => this.onPromocodeCopied(promocode)}>
  //                   <Icon name='tags' />Promo Code: {promocode}
  //                   <Label.Detail><Icon name='copy' /></Label.Detail>
  //                 </Label>
  //               )}
  //             </a>
  //           )}
  //         </div>
  //       )}
  //       {/* {this.renderSocialMedia()} */}
  //     </Container>
  //   )
  // }

  renderResponse() {
    const {
      user, isLoadingInstagram,
      isLoadingProfile, activeFilters, urlPathUsername,
      allPosts
    } = this.props

    const username = (user && user.instagram_username) || urlPathUsername

    const posts = (getPostsFromResponse(user)) || []
    const totalViews = getTotalViewsFromPosts(allPosts)

    const filteredLooksCount = posts.length
    let looksShared = 0
    if (isLoadingProfile) {
      looksShared = '--'
    } else {
      looksShared = formatNumber(allPosts.length) || 0
    }
    let totalProductViews = 0
    if (isLoadingProfile) {
      totalProductViews = '--'
    } else {
      totalProductViews = (totalViews && formatNumber(totalViews)) || 0
    }

    let followersCount
    if (isLoadingInstagram || isLoadingProfile) {
      followersCount = '--'
    } else {
      followersCount = formatNumber(user.ig_followed_by_count) || '--'
    }

    const website = user.website
    let websitename = website && website.replace('https://', '').replace('http://', '').replace('www.', '').replace(/\/$/, '')
    if (!websitename) {
      websitename = website
    }
    const promocode = user.promocode

    const lastPost = posts[posts.length - 1] || {}

    const popularBrandsCount = getPopularDomainNamesFromPosts(allPosts)
    const looksGroupedByProduct = getLooksGroupedByProductPosts(allPosts)
    const productsCount = looksGroupedByProduct && Object.keys(looksGroupedByProduct).length

    const status = getInfluencerStatusFromUser(user)
    const subStatus = getUserInfluencerLabel(user)
    const isMega = influencersStatus.Mega === status
    const isMacro = influencersStatus.Macro === status

    return (
      <div className='userprofile'>
        <Divider hidden />
        <center>
          <Segment
            style={{
              backgroundImage: `url(${lastPost.url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              padding: '5px',
              borderRadius: '20px'
            }} inverted
          >
            <Segment
              style={{
                background: 'rgba(27, 28, 29, 0.95)',
                borderRadius: '20px',
                padding: '20px'
              }} inverted
            >
              <UserHeaderComponent
                profile={transformUser(user)}
                username={username}
                isLoadingInstagram={isLoadingInstagram}
                isLoadingProfile={isLoadingProfile}
                followersCount={followersCount}
                looksShared={looksShared}
                totalProductViews={totalProductViews}
                website={website}
                websitename={websitename}
                promocode={promocode}
                lastPost={lastPost}
                isMega={isMacro}
                isMacro={isMacro}
                subStatus={subStatus}
              />
              {/* {this.renderUserHeaderMobile(user,
                username,
                isLoadingInstagram,
                isLoadingProfile,
                followersCount,
                looksShared,
                totalProductViews, website, websitename, promocode, lastPost,
                isMega, isMacro, subStatus)} */}
            </Segment>
            <div style={{ position: 'absolute', top: '15px', right: '5px', width: '50px' }}>
              <div style={{ marginBottom: '15px' }}>
                {/* <a
                  href={`https://instagram.com/${username}`} target='_blank' rel='noopener noreferrer'
                >
                  <Icon name='instagram' size='large' style={{ color: '#D3D3D3' }} />
                </a> */}
              </div>
              {user && user.tiktok && (
                <div style={{ marginBottom: '15px' }}>
                  <a
                    href={`https://tiktok.com/@${user.tiktok}`} target='_blank' rel='noopener noreferrer'
                    style={{ marginBottom: '5px', width: '25px', height: '25px' }}
                  >
                    <Image
                      src={tiktokLogo} centered size='mini'
                      style={{ width: '25px', height: '25px' }}
                    />
                  </a>
                </div>
              )}
              {user && user.youtube && (
                <div style={{ marginBottom: '15px' }}>
                  <a
                    href={user.youtube} target='_blank' rel='noopener noreferrer'
                    style={{ marginBottom: '5px' }}
                  >
                    <Icon name='youtube' size='large' style={{ color: '#D3D3D3' }} />
                  </a>
                </div>
              )}
              {user && user.twitter && (
                <div style={{ marginBottom: '15px' }}>
                  <a
                    href={`https://twitter.com/${user.twitter}`} target='_blank' rel='noopener noreferrer'
                    style={{ marginBottom: '5px' }}
                  >
                    <Icon name='twitter' size='large' style={{ color: '#D3D3D3' }} />
                  </a>
                </div>
              )}
              {user && user.pinterest && (
                <div style={{ marginBottom: '15px' }}>
                  <a
                    href={`https://pinterest.com/${user.pinterest}`} target='_blank' rel='noopener noreferrer'
                    style={{ marginBottom: '5px' }}
                  >
                    <Icon name='pinterest' size='large' style={{ color: '#D3D3D3' }} />
                  </a>
                </div>
              )}
            </div>
          </Segment>
        </center>
        <Divider hidden />
        <Segment
          inverted
          style={{ borderRadius: '20px' }}
          className='content-segment'
        >
          {/* <Divider horizontal inverted>{filteredLooksCount} Looks</Divider> */}

          <Segment basic textAlign='center'>
            <Header as='h1' inverted>
              {filteredLooksCount} Looks
              {((popularBrandsCount && popularBrandsCount.length > 1) ||
                productsCount > 1) && (
                  <Header.Subheader>
                    {productsCount > 1 && (<><b>{productsCount}</b> Tagged products</>)}
                    {popularBrandsCount.length > 1 && (<> from <b>{popularBrandsCount.length}</b> Brands</>)}
                  </Header.Subheader>
                )}
            </Header>
            {this.renderBrands(allPosts, activeFilters)}
          </Segment>

          {(isLoadingProfile || !posts) && this.renderLoadingFiles()}
          {!isLoadingProfile && posts && this.renderFiles(posts, user)}
          <Divider hidden />
          <Divider hidden />
          <Divider horizontal inverted>Tag Products</Divider>
          <Divider hidden />
          <Grid centered doubling>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              {!isLoadingProfile && (
                // <AddIGPostToProductContainer imagedAdded={() => this.props.imagedAdded()} />
                // <InstallMobileAppBannerContainer
                //   title='Share your outfits'
                //   subtitle={(<>Grow your online presence as a fashion creator and showcase your best looks!<br /></>)}
                //   analyticsLabel='AppInviteFromProductPage'
                //   appInstallCampaign={`w_profile_${username}`}
                // />

                <AddLooksToProductButton
                  subtitle={(<>Add your IRL Looks and true-fit product reviews<br /></>)}
                />
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    )
  }

  renderLoadingFiles() {
    return (
      <>
        <Grid>
          <PlaceHolderSquareCard />
          <PlaceHolderSquareCard />
          <PlaceHolderSquareCard />
          <PlaceHolderSquareCard />
        </Grid>
      </>
    )
  }

  renderNoFileFound() {
    return (
      <Label>
        No look found
      </Label>
    )
  }

  renderInfoBanner() {
    return (
      <Message info basic compact size='mini'>
        {/* Tap on username to see more */}
      </Message>
    )
  }

  renderFiles(posts, user) {
    // if (!files || files.length === 0) {
    //   return this.renderNoFileFound()
    // }

    return (
      <Grid>
        {/* {igUser && igUser.edge_owner_to_timeline_media &&
            igUser.edge_owner_to_timeline_media.edges.map(p => this.renderPost(p, files))} */}
        {/* {groupedPosts.map((url, posts) => this.renderPost(posts, user))} */}
        {/* {posts.map((post) => this.renderPost([post], user))} */}
        {posts.map((p) => this.renderPost(p, user))}
      </Grid>
    )
  }

  renderPost(post, user) {
    return post && post.productreferrals && post.productreferrals &&
      post.productreferrals.length > 0 &&
      (
        <Grid.Column
          key={post.id} id={post.id}
          style={{ padding: '1px', overflow: 'hidden' }}
          mobile={16} tablet={8} computer={4}
        >
          <LookCardWithProductName
            key={post.id}
            withoutAvatar
            user={user}
            post={post}
            analytisEventName='userfeedProductCardImageClicked'
          />
        </Grid.Column>
      )
  }

  render() {
    const { urlPathUsername } = this.props
    const title = urlPathUsername ? `Shop ${urlPathUsername}\`s feed` : 'Shop Instagram Feed'
    return (
      <>
        <Helmet
          title={title}
        >
          <meta property='og:url' content={window.location.href} />
          <meta property='og:type' content='profile' />
          <meta property='og:title' content={title} />
          {/* <meta property='og:description' content={description} /> */}
          {/* <meta property='og:image' content='https://blablagoal.fr/fb-share.jpg' /> */}
        </Helmet>
        {this.renderResponse()}
      </>
    )
  }
}
export default UserFeed
