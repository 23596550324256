import React from 'react'
import {
  Image, Grid, Header, Segment, Container, Divider
} from 'semantic-ui-react'
import { useAuthState } from 'react-firebase-hooks/auth'

import { logEvent } from '../services/analytics'
import herophoto1 from '../images/herophoto1.jpg'
import { Media } from '../utils/AppMediaBreakPoints'
import bgTryitNow from '../images/bg-tryitnow.jpeg'

const SignUpBanner = ({ callToActionButton, auth }) => {
  const [user, loading, error] = useAuthState(auth);

  return (
    (user || loading) ? <></> :
      <>
        <Container as={Media} greaterThanOrEqual='computer'>
          <Divider hidden />
          <Segment
            style={{
              backgroundImage: `url(${bgTryitNow})`,
              backgroundSize: 'cover'
            }} inverted
          >
            <Segment
              style={{
                margin: '15px 60px',
                background: 'rgba(27, 28, 29, 0.95)',
                borderRadius: '20px',
                padding: '30px'
              }} inverted
            >
              <Grid centered>
                <Grid.Row>
                  <Header
                    as='h2'
                  >
                    With one click on any online store you can see how the outfit<br />
                    you are about to buy look IRL on diverse body types.<br />
                    {/* and help you <b className='underlined'> Find Clothes That Fit! </b> */}
                  </Header>
                </Grid.Row>
                <Grid.Row>
                  {!user && callToActionButton}
                </Grid.Row>
              </Grid>
              <Grid container stackable verticalAlign='middle' centered>
                <Grid.Row>
                  <Grid.Column floated='right' largeScreen={8}>
                    <Image bordered rounded size='large' src={herophoto1} />
                  </Grid.Column>
                  <Grid.Column largeScreen={8}>
                    <Header as='h2' style={{ fontSize: '2em' }}>
                      Try it now!
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                      <b>1 -</b>{' Add '}
                      <a
                        href='https://chrome.google.com/webstore/detail/myyfit/dbekdnndlpdnoghkpmfbbpijlopndakk'
                        target='_blank' rel='noopener noreferrer' className='yellow'
                        onClick={() => logEvent('tryItInstallAddonClicked')}
                      >
                        seemyfit add-on
                      </a> to your chome<br />
                      <br />
                      <b>2 -</b> {'Visit this '}
                      <a
                        href='https://www.urbanoutfitters.com/shop/nirvana-unplugged-oversized-tee'
                        target='_blank' rel='noopener noreferrer' className='yellow'
                        onClick={() => logEvent('tryItVisitLinkClicked')}
                      >
                        Nirvana Unplugged T-Shirt Dress
                      </a><br /><br />
                      <b>3 -</b> Click <b>"See my Fit"</b> button and that's it!<br /><br />
                      {/* <b>Now pause for a moment <br />
                                and appreciate how awesome is this! <span role='img' aria-label='Namaste'>🙏</span>
                  </b><br />
                            We show you Creators photos of the same clothes in the same page to help you get an idea of how the outfit looks IRL and help you find what's fit your taste! */}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment>
          <Divider hidden />
        </Container>
        <Container as={Media} at='mobile' style={{
          marginRight: '0px !important',
          paddingRight: '0px !important',
          marginLeft: '0px !important',
          paddingLeft: '0px !important',
        }}>
          <Divider hidden />
          <div
            style={{
              margin: '0',
              padding: '20px 10px',
              backgroundImage: `url(${bgTryitNow})`,
              borderRadius: '20px',
              backgroundSize: 'cover'
            }} inverted
          >
            <Segment
              style={{
                // margin: '0',
                background: 'rgba(27, 28, 29, 0.95)',
                borderRadius: '30px',
              }} inverted
              textAlign='center'
            >
              <Header as='h1'>See products in real life before you buy
                {/* and help you <b className='underlined'> Find Clothes That Fit! </b> */}
              </Header>
              {!user && callToActionButton}
              <Header as='h4'>True-fit sizing reviews from other users help you find the perfect fit for your body type.
              </Header>
            </Segment>
          </div>
          <Divider hidden />
        </Container>
      </>
  )
}

export default SignUpBanner
