import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import productDetailActions from '../../../store/redux/products/ProductDetailRedux'
import getPostStep1Actions from '../../../store/redux/posts/update/GetPostStep1Redux'
import updatePostStep2Actions from '../../../store/redux/posts/update/UpdatePostStep2Redux'
import relatedPostsActions from '../../../store/redux/products/ProductRelatedPostsRedux'

import ProductDetailPage from './ProductDetailPage'

export default connect(
  state => ({
    // currentUser: state.login.currentUser,
    product: state.productDetailState.items,
    isLoading: state.productDetailState.fetching,
    statusText: state.productDetailState.statusText
  }),
  dispatch => ({
    getProductAction: bindActionCreators(productDetailActions.request, dispatch),
    getPostStep1Action: bindActionCreators(getPostStep1Actions.request, dispatch),
    updatePostStep2: bindActionCreators(updatePostStep2Actions.request, dispatch),
    getRelatedPostsAction: bindActionCreators(relatedPostsActions.request, dispatch)
  })
)(ProductDetailPage)
