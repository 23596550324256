import React, { useContext, useState, useEffect } from 'react';
import {
    Form, Dropdown, Header, Icon, Label,
} from 'semantic-ui-react';


let sizeOptions = [
    { key: 'UniSize', text: 'Uni Size', value: 'UniSize' },
    { key: 'XS', text: 'XS', value: 'XS' },
    { key: 'S', text: 'S', value: 'S' },
    { key: 'M', text: 'M', value: 'M' },
    { key: 'L', text: 'L', value: 'L' },
    { key: 'XL', text: 'XL', value: 'XL' },
    { key: '2XL', text: '2XL', value: '2XL' },
    { key: '3XL', text: '3XL', value: '3XL' },
]

let colorOptions = [
    { key: 'black', text: 'black', value: 'black' },
    { key: 'grey', text: 'grey', value: 'grey' },
    { key: 'brown', text: 'brown', value: 'brown' },
    { key: 'pink', text: 'pink', value: 'pink' },
    { key: 'purple', text: 'purple', value: 'purple' },
    { key: 'violet', text: 'violet', value: 'violet' },
    { key: 'blue', text: 'blue', value: 'blue' },
    { key: 'teal', text: 'teal', value: 'teal' },
    { key: 'green', text: 'green', value: 'green' },
    { key: 'olive', text: 'olive', value: 'olive' },
    { key: 'yellow', text: 'yellow', value: 'yellow' },
    { key: 'orange', text: 'orange', value: 'orange' },
    { key: 'red', text: 'red', value: 'red' },
]

const ProductreferralInputFields = (({
    post,
    promocode,
    commissionLink,
    productColor,
    productSize,
    setPromocode,
    setCommissionLink,
    setProductSize,
    setProductColor,
    isLoading
}, ref) => {
    const handleSizeAddition = (value) => {
        const capValue = value && value.toUpperCase()
        const key = capValue && capValue.replace(' ', '')
        sizeOptions = [{ key, text: capValue, value: capValue }, ...sizeOptions]
        setProductSize(capValue)
    }

    const handleColorAddition = (value) => {
        const capValue = value && value.toUpperCase()
        const key = capValue && capValue.replace(' ', '')
        colorOptions = [{ key, text: capValue, value: capValue }, ...colorOptions]
        setProductColor(capValue)
    }

    return (
        <Form inverted>
            <Form.Group >
                <Form.Input
                    label="Your commisionable link"
                    placeholder='eg: restyle.me/?+l55TlANBxjSIqU8pgSL4Mg'
                    name='commissionLink'
                    value={commissionLink}
                    onChange={(e, { value }) => setCommissionLink(value)}
                    loading={isLoading}
                    inverted
                    // width={10}
                    size={'mini'}
                />
                <Form.Input
                    label="Promo code"
                    placeholder='eg. PROMO20'
                    name='promocode'
                    value={promocode}
                    onChange={(e, { value }) => setPromocode(value)}
                    loading={isLoading}
                    inverted
                    // width={6}
                    size={'mini'}
                />
            </Form.Group>

            <Header as='h5' inverted style={{ marginBottom: '6px', marginTop: '10px' }}>
                <Header.Subheader>Which size and color you're reviewing?</Header.Subheader>
            </Header>
            <Form.Group columns={2} style={{ marginLeft: '4px' }}>
                <Form.Group>
                    <Form.Field>
                        <Header as='h5'>Size: {productSize && productSize}</Header>
                        <Dropdown
                            placeholder='Size'
                            name='productSize'
                            value={productSize}
                            options={sizeOptions}
                            onAddItem={(e, { value }) => handleSizeAddition(value)}
                            additionLabel='Eg. UK 8: '
                            onChange={(e, { value }) => setProductSize(value)}
                            size='mini' compact
                            allowAdditions selection search
                        />
                    </Form.Field>

                    <Form.Field>
                        <Header as='h5'>
                            {productColor && (<Label color={productColor} empty circular />)}
                            Color: {productColor && productColor}</Header>
                        <Dropdown
                            placeholder='Color'
                            name='productColor'
                            value={productColor}
                            options={colorOptions}
                            onAddItem={(e, { value }) => handleColorAddition(value)}
                            additionLabel='Eg. Gray melange: '
                            onChange={(e, { value }) => setProductColor(value)}
                            size='mini' compact
                            allowAdditions selection search
                        />
                        {/* <Dropdown
                        placeholder={productColor || 'Color'}
                        selection
                        text={productColor || 'Color'}
                        defaultValue={productColor}
                        onChange={this.handleChange}
                        onAddItem={this.handleColorAddition}
                        options={sizeOptions}
                        size='mini'
                        allowAdditions
                        search
                        compact
                    >
                        <Dropdown.Menu>
                            {colorOptions && colorOptions.map(option => (
                                <Dropdown.Item
                                    label={{ color: option.key, empty: true, circular: true }}
                                    {...option}
                                    onClick={(e) => {
                                        this.handleChange(e,
                                            {
                                                name: 'productColor',
                                                value: option.value.toUpperCase()
                                            })
                                    }}
                                />
                            ))
                            }
                        </Dropdown.Menu>
                    </Dropdown> */}
                    </Form.Field>
                </Form.Group>
            </Form.Group>
        </Form >
    )
})

export default ProductreferralInputFields;