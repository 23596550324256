import React, { useContext, useState } from 'react';
import { Header, Message } from 'semantic-ui-react';

import { AppContext } from '../../../../shared/context';
import StylesSelector from '../../../../components/StylesSelector';
import { renderSave, renderSaving, updateWebAndFirebaseUser } from './UserProfileTabs';

import VintageImg from '../../../../images/styles/vintage.jpg';
import BeachImg from '../../../../images/styles/beach.jpg';
import DenimImg from '../../../../images/styles/denim.jpg';
import LeatherImg from '../../../../images/styles/leather.jpg';
import MinimalImg from '../../../../images/styles/minimal.jpg';
import ArtsyImg from '../../../../images/styles/artsy.jpg';
import CasualImg from '../../../../images/styles/casual.jpg';
import ExoticImg from '../../../../images/styles/exotic.jpg';
import GrungeImg from '../../../../images/styles/grunge.jpg';
import ChicImg from '../../../../images/styles/chic.jpg';
import BohemianImg from '../../../../images/styles/bohemian.jpg';
import TrendyImg from '../../../../images/styles/trendy.jpg';
import VibrantImg from '../../../../images/styles/vibrant.jpg';
import ElegantImg from '../../../../images/styles/elegant.png';
import GothicImg from '../../../../images/styles/gothic.jpg';
import PunkImg from '../../../../images/styles/punk.jpg';
import FormalImg from '../../../../images/styles/formal.jpg';
import WFHImg from '../../../../images/styles/whm.jpg';
import SportImg from '../../../../images/styles/sport.jpg';
import GirlNextDoorImg from '../../../../images/styles/girlnextdoor.jpg';
import MaternityImg from '../../../../images/styles/maternity.jpg';
import VacationImg from '../../../../images/styles/vacation.jpg';

// TODO refactor with mobile
const STYLES_LIST = [
  { id: 1, name: "Vintage", background: VintageImg },
  { id: 2, name: "Beach", background: BeachImg },
  { id: 3, name: "Denim", background: DenimImg },
  { id: 4, name: "Leather", background: LeatherImg },
  { id: 5, name: "Minimal", background: MinimalImg },
  { id: 6, name: "Artsy", background: ArtsyImg },
  { id: 7, name: "Casual", background: CasualImg },
  { id: 8, name: "Exotic", background: ExoticImg },
  { id: 9, name: "Grunge", background: GrungeImg },
  { id: 10, name: "Chic", background: ChicImg },
  { id: 11, name: "Bohemian", background: BohemianImg },
  { id: 12, name: "Trendy", background: TrendyImg },
  { id: 13, name: "Vibrant", background: VibrantImg },
  { id: 14, name: "Elegant", background: ElegantImg },
  { id: 15, name: "Gothic", background: GothicImg },
  { id: 16, name: "Punk", background: PunkImg },
  { id: 17, name: "Formal", background: FormalImg },
  { id: 18, name: "WFH", background: WFHImg },
  { id: 19, name: "Sport", background: SportImg },
  { id: 20, name: "Girl Next Door", background: GirlNextDoorImg },
  { id: 21, name: "Maternity", background: MaternityImg },
  { id: 22, name: "Vacation", background: VacationImg },
]
export const MAX_STYLE_SELECTION = 10
export const MAX_INFLUENCER_SELECTION = 4

const SelectStylesTab = (({

}, ref) => {
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext)

  const [isSuccess, setIsSuccess] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [influencedBy, setIfluencedBy] = useState((userContext && userContext.influencedBy) || []);
  const [userStyles, setUserStyles] = useState((userContext && userContext.styles) || []);


  const [maxStyleSelectionReached, setMaxStyleSelectionReached] = useState(false);

  const onSave = async () => {
    setMaxStyleSelectionReached(false)

    setIsSaving(true)
    setIsSuccess(null)
    setErrorMessage("")

    const payload = {
      influencedBy,
      styles: userStyles
    }

    await updateWebAndFirebaseUser(userContext, payload, setIsSuccess, setErrorMessage, updateUserContext)
    setIsSaving(false)
  };

  const onStyleChange = (style) => {
    setUserStyles(style)
    setMaxStyleSelectionReached(style && style.length > MAX_STYLE_SELECTION)
  }

  const onMaxSelectionReached = () => {
    setMaxStyleSelectionReached(true)
  }


  return (
    <div>
      {renderSaving({ isSaving })}
      <Header as='h4' inverted>
        What's your goto <strong>Styles</strong>?
      </Header>
      {
        maxStyleSelectionReached
          ? <Message error compact size='tiny'>{`You can't select more than ${MAX_STYLE_SELECTION} styles`}</Message>
          : <small>Select top {MAX_STYLE_SELECTION} styles</small>
      }

      <StylesSelector
        initialInfluencedBy={influencedBy}
        intialSelectedStyles={userStyles}
        onStyleChange={onStyleChange}
        onInfluencedByChange={setIfluencedBy}
        maxStyleSelection={MAX_STYLE_SELECTION}
        maxFavouriteInfluencersSelection={MAX_INFLUENCER_SELECTION}
        onMaxSelectionReached={onMaxSelectionReached}
        styles={STYLES_LIST}
      />

      {renderSave(onSave, { isSuccess, errorMessage })}
    </div>
  );
});

export default SelectStylesTab;