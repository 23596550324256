import { path } from 'ramda'

import API from '../../../services/webApi'
import { serialise } from 'kitsu-core'

const api = API.create()

export const updateProductreferral = async (request) => {
    //   const { filter } = request
    const config = {
        params: {
        },
        ...request
    }

    const response = await api.updateProductreferral(config)
    if (response) {
        const data = path(['data'], response)
        // do data conversion here if needed
        return data
    }
    return response
}

export const addProductreferral = async (request) => {
    const {
        product_id,
        file_id,
    } = request

    let post = null
    if (file_id) {
        post = {
            data: {
                id: file_id,
                type: 'posts'
            }
        }
    }

    let product = null
    if (product_id) {
        product = {
            data: {
                id: product_id,
                type: 'products'
            }
        }
    }

    const config = {
        type: 'productreferrals',
        ...request,
        ...(post && { post }),
        ...(product && { product }),
    }


    const response = await api.addProductreferral(config)
    if (response) {
        const data = path(['data'], response)
        // do data conversion here if needed
        return data
    }
    return response
}

// export default function useUserMutation() {
//     return useMutation((request) => updateUser(request));
// }