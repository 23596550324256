
const appId = '6db96ea5-7e42-4d34-856d-eeef1409d53f'

export const loadUrlMetaData = async (url) => {
  let openGUrl = `https://opengraph.io/api/1.1/site/${encodeURIComponent(url)}?app_id=${appId}`

  try {
    const response = await fetch(openGUrl, {
      method: 'get',
      headers: {
        Accept: 'application/json'
      }
    })

    const jsonData = await response.json()
    return jsonData
  } catch (e) {
    return {
      success: false,
      message: 'Ops! an error occured. Please try again'
    }
  }
}
