
import React, { Component } from 'react'

import FooterMobile from './FooterMobile'
import FooterDesktop from './FooterDesktop'

class Footer extends Component {
  render () {
    return (
      <>
        <FooterMobile history={this.props.history} />
        <FooterDesktop history={this.props.history} />
      </>
    )
  }
}

export default Footer
