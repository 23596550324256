import React, { Component } from 'react'
import {
  Container,
  Grid,
  Visibility,
  Segment,
  Button,
  Icon,
  Divider,
  Header,
  Image
} from 'semantic-ui-react'
import { Helmet } from 'react-helmet'

import Footer from '../../../layouts/components/Footer'
import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'

import PlaceHolderSquareCard from '../components/PlaceHolderSquareCard'
import UserCard from '../components/UserCard'
import UserFilters from './components/UserFilters'
import { logPageView } from '../../../services/analytics'
import InstagramTextLogo from '../../../images/Instagram_logo_white.svg'
import InstallMobileAppBannerContainer from '../../../components/InstallMobileAppBanner/InstallMobileAppBannerContainer'
import { extensionInstalled } from '../../../utils/chromeExtension'
import { Media } from '../../../utils/AppMediaBreakPoints'
import AddLooksToProductButton from '../../Products/components/AddLooksToProductButton'

class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      siteName: null,
      showLoadNext: false,
      isInstalled: true
    }
  }

  componentDidMount() {
    const { pageNumberLoaded } = this.props
    const pageNumber = pageNumberLoaded
    this.loadItems(pageNumber)

    logPageView({ name: 'UsersList' })

    const self = this
    window.onload = function () {
      self.setState({ isInstalled: extensionInstalled() })
    }
  }

  async loadItems(pageNumber) {
    const filter = {
      uwpr: true
    }

    this.props.getUsersAction({
      filter,
      page: {
        number: pageNumber,
        size: 30
      }
    })
  }

  onLoadNextPressed() {
    const pageNumber = this.props.pageNumberLoaded + 1
    this.loadItems(pageNumber)
  }

  renderPlaceholders() {
    return (
      <>
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
        <PlaceHolderSquareCard />
      </>
    )
  }

  renderItem(item) {
    // const siteName = getSiteNameFromUrl(file.url);
    // const formattedDate = moment(file.created_at).format("MMM Do YYYY")
    return (
      <Grid.Column
        key={item.id}
        id={item.id}
        style={{ padding: '1px', overflow: 'hidden' }}
        mobile={16} tablet={8} computer={5}
      >
        <UserCard
          user={item}
          analytisEventName='postListProductCardImageClicked'
        />
      </Grid.Column>
    )
  }

  renderFilters() {
    return (
      <UserFilters
        siteName={this.state.siteName}
        onProductFilterChange={(newSitename) => this.onProductFilterChange(newSitename)}
      />
    )
  }

  renderLoadMoreLooks() {
    const { lastPageReached, isNextPageLoading } = this.props
    return !lastPageReached && (
      <Segment basic clearing>
        <Button
          floated='right' loading={isNextPageLoading}
          onClick={() => this.onLoadNextPressed()}
        >{'More '}
          <Icon name='down arrow' />
        </Button>
      </Segment>
    )
  }

  renderList() {
    const { items, isLoading } = this.props
    return (
      <Visibility onUpdate={this.handleVisibilityUpdate}>
        {/* {this.renderFilters()} */}
        <Grid centered>
          {
            isLoading
              ? this.renderPlaceholders()
              : (items && items.map((p) => this.renderItem(p)))
          }
        </Grid>
      </Visibility>
    )
  }

  render() {
    const title = 'Fashion-lovers community of all shapes and sizes 📸!'
    const { isInstalled } = this.state
    return (
      <ResponsiveContainer
        history={this.props.history}
        isInstalled={isInstalled}
        location={this.props.location}
      >
        <Helmet title={title} />
        <Container className='no-horizontal-margin'>
          <Divider hidden />
          <Segment style={{ minHeight: '100px', borderRadius: '20px' }} inverted>
            <Segment basic textAlign='center'>
              <Header as='h1' inverted>
                Trending Profiles
                <Header.Subheader>
                  Last viewed <b>Seemyfit</b> profiles on<Image src={InstagramTextLogo} size='tiny' spaced alt='Instagram' />
                </Header.Subheader>
              </Header>
            </Segment>
            {this.renderList()}
            {this.renderLoadMoreLooks()}
          </Segment>
          <Divider hidden />
          <Container as={Media} greaterThanOrEqual='computer'>
            {/* <InstallMobileAppBannerContainer
              history={this.props.history}
              title='Seemyfit - A space that celebrates you, as you are!'
              subtitle='Get seemyfit app and discover how shoppers like you style their outfits'
              analyticsLabel='AppInviteFromUserList'
              appInstallCampaign='w_profiles_page'
            /> */}
            <AddLooksToProductButton
              title={`Share your Style'}`}
              subtitle={(<>Add your IRL Looks and true-fit product reviews<br /></>)}
            />
          </Container>
        </Container>
        <Divider hidden />
        <Footer history={this.props.history} />
      </ResponsiveContainer>
    )
  }
}
export default UsersList
