import React, { useContext, useEffect } from 'react';
import {
  Container, Header, Button, Icon, Divider
} from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, isSignInWithEmailLink } from "firebase/auth";

import { AppContext } from '../../../shared/context';
import { connectUserByEmailLink } from '../../../firebase/firebase';
import { logEvent } from '../../../services/analytics';
import { ROOT } from '../../../constants/routes';
import SignUpBanner from '../../../components/SignUpBanner';
import EmailLoginForm from '../../../components/EmailLoginForm';
import { EDITPROFILE } from '../../../constants/routes'

import { Media } from '../../../utils/AppMediaBreakPoints'
import demo from '../../../images/demo-landing.jpg'
import HomePageSignInComponent from '../HomePageSignInComponent'

const HomepageHeading = (({ mobile, history, isInstalled }, ref) => {

  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth);
  const { user: userContext, updateUser: updateUserContext, theme } = useContext(AppContext);

  const userContextUsername = (user && user.username) || (userContext && userContext.username)

  const profileNotCompleted = !userContextUsername
  // console.log("HomePageSignInComponent user:", user)
  // console.log("HomePageSignInComponent userContext:", userContext)
  // console.log("HomePageSignInComponent get user with ID:", user && user.uid)

  const renderCompleteProfile = () => {
    return (
      <Container style={{
        paddingBottom: '2em',
      }}>
        <center>
          <Header
            as='h1'
            inverted
            style={{
              fontSize: mobile ? '2em' : '3em',
              fontWeight: 'normal',
              // marginBottom: 0,
              marginTop: mobile ? '1.5em' : '1em',
            }}
          >Complete your profile</Header>
          <Header
            as='h2'
            content='Complete your profile to get started.'
            inverted
            style={{
              fontSize: mobile ? '1.5em' : '1.7em',
              fontWeight: 'normal',
              marginTop: mobile ? '0.5em' : '0.5em',
            }}
          />
          <Link to={EDITPROFILE}>
            <Button
              primary
              size='huge'
              icon labelPosition='right'
              // style={{ height: '40px' }}
              onClick={() => logEvent('HomepageHeadingCompleteProfileClicked')}
            >
              Continue
              <Icon name='right arrow' />
            </Button>
          </Link>

          <HomePageSignInComponent history={history} isInstalled={isInstalled} />
        </center>
      </Container>
    )
  }

  const mobileStyle = {
    marginRight: '0px !important',
    marginLeft: '0px !important',
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  }

  return (
    <Container className='ui home-hero-mobile' style={{ mobileStyle }}>
      {user && profileNotCompleted && renderCompleteProfile()}
      <HomePageSignInComponent history={history} isInstalled={isInstalled} mobile={mobile} />
    </Container>
  );
});

export default HomepageHeading;



  // <div as={Media} greaterThanOrEqual='computer'>
  //   {/* <Responsive {...Responsive.onlyComputer}> */}

  //   <Container className='ui home-hero'>
  //     <Header
  //       as='h1'
  //       content='Make the most out of your Instagram!'
  //       style={{
  //         fontSize: mobile ? '1.8em' : '2.5em',
  //         fontWeight: 'bold',
  //         marginBottom: 0,
  //         marginTop: mobile ? '1em' : '1em'
  //       }}
  //     />
  //     <Header
  //       as='h3'
  //       style={{
  //         fontSize: mobile ? '0.9em' : '1.em',
  //         fontWeight: 'normal'
  //       }}
  //     >
  //       <p>
  //         Use <b>seemyfit.at/@yourUsername</b> as bio link to tag
  //         <br /><b>products</b> on <b>Instagram posts</b> and turn your feed
  //         <br />into a <b>shoppable</b>, <b>optimized</b> <b>landing page</b>!
  //       </p>
  //     </Header>
  //     <Header
  //       as='h4'
  //       style={{
  //         fontSize: mobile ? '1.5em' : '1.7em',
  //         fontWeight: 'normal'
  //       }}
  //     >Check this out! <span role='img' aria-label='bellow'>👇</span>
  //     </Header>

  //     <Grid centered>
  //       <Image src={demo} style={{ height: '450px' }} />
  //     </Grid>
  //   </Container>
  // </div>
// )
