import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'

import HomePage from './HomePage'

export default connect(
  state => ({
    // currentUser: state.login.currentUser,
  }),
  dispatch => ({
  })
)(HomePage)
