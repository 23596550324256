import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import userDetailActions from '../../../store/redux/users/UserDetailRedux'
import relatedPostsActions from '../../../store/redux/products/ProductRelatedPostsRedux'

import UserPage from './UserPage'

export default connect(
  state => ({
    // currentUser: state.login.currentUser,
    user: state.userDetailState.user,
    allPosts: state.userDetailState.allItems,
    isLoadingProfile: state.userDetailState.fetching,
    statusText: state.userDetailState.statusText,
    isLoadingInstagram: state.instagramUserDetailState.fetching,
    instagramStatusText: state.instagramUserDetailState.statusText
  }),
  dispatch => ({
    getUserAction: bindActionCreators(userDetailActions.request, dispatch),
    filterUserPostsAction: bindActionCreators(userDetailActions.filter, dispatch),
    getRelatedPostsAction: bindActionCreators(relatedPostsActions.request, dispatch)
  })
)(UserPage)
