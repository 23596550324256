import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

import { logEvent } from '../services/analytics'

const shareTextMessage = (name, brandName, url) => `Check out ${brandName} ${name} looks 👉 ${url} #${brandName} #seemyfit`
const title = (name) => `${name} looks on seemyfit.at`

export const share = (url, name, brandName, analyticsEvent) => {
  logEvent(analyticsEvent)
  if (navigator.share) {
    navigator.share({
      title: title(name),
      text: shareTextMessage(name, brandName, url),
      url: url
    }).then(() => {
      logEvent('NativeShareButtonSucceeded')
    })
      .catch(() => {
        logEvent('NativeShareButtonError')
      })
  } else {
    logEvent('NativeShareButtoNotSupported')
  }
}

const NativeShareButtonIcon = ({ url, name, brandName }) => (
  (navigator.share) ? (
    <Button
      onClick={() => share(url, name, brandName, 'NativeShareButtonIconClicked')}
      icon
      color='black'
      style={{ position: 'absolute', top: '19px', left: '19px', borderRadius: '20px' }}
    >
      <Icon name='paper plane' />
    </Button>
  ) : <></>
)

export default NativeShareButtonIcon
