import { getAuth } from 'firebase/auth'
import React, { useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  Container,
  Header,
  Segment,
  Divider,
  Icon,
  Message,
  Button
} from 'semantic-ui-react'
import { Media } from '../../utils/AppMediaBreakPoints'
import { EDITPROFILE } from '../../constants/routes'
import { linkIGInFirebaseAndWebUser } from '../../firebase/firebase'
import getFirebaseUser from '../../firebase/users/getFirebaseUser'
import { AppContext } from '../../shared/context'

import { getIgUser } from '../../shared/services/instagramBasicDisplayApi'
import { getIgProfilePicByUsername } from '../../shared/services/instagramRapidApi'

const IgAuthPage = (({ location, history }, ref) => {

  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState();
  const [isSuccess, setIsSuccess] = useState();
  const [statusText, setStatusText] = useState();
  const { user: userContext, updateUser: updateUserContext } = useContext(AppContext);

  const historyLocation = location || (history && history.location)
  const code = historyLocation && new URLSearchParams(historyLocation.search).get("code")

  useEffect(() => {
    console.log("IgAuthPage useEffect:");
    async function getAndLoadUser() {
      try {
        const uid = user && user.uid
        console.log("IgAuthPage useEffect uid:", uid);
        if (uid) {
          const fbuser = await getFirebaseUser(uid);
          console.log("IgAuthPage fbuser:", fbuser)
          console.log("IgAuthPage userContext:", userContext)
          load(code)
          updateUserContext({ uid, ...fbuser })
        }
      } catch (e) {
        console.error(e);
      }
    };
    getAndLoadUser();
  }, [user])

  const load = async (code) => {
    console.log("load - called code:", code)

    if (!code) {
      setIsSuccess(false)
      setStatusText('Ops! an error occured. Please try again later')
      return
    }
    // IG connect: 1st call user update with "code" to get the access_token (shortLived or longlived one)
    console.log("load - userContext:", userContext)
    if (userContext) {
      setIsLoading(true)
      setStatusText('Loading...')
      linkIGInFirebaseAndWebUser({
        auth,
        userContext,
        updateUserContext,
        code,
        getInstagramUserByToken,
        getInstagramAvatarByUsername,
        onSuccess,
        onFailure
      })
    }
  }

  const onSuccess = async () => {
    setIsLoading(false)
    setIsSuccess(true)
    setStatusText('Done!')
    history && history.push({ pathname: EDITPROFILE })
  }

  const onFailure = async (e) => {
    setIsLoading(false)
    setIsSuccess(false)
    setStatusText('Ops! an error occured. Please try again')
  }

  const getInstagramUserByToken = async (token) => {
    setIsLoading(true)
    setStatusText('Getting username...')
    try {
      var response = await getIgUser(token)
      setStatusText('username saved')
      if (response.error_type) {
        setIsLoading(false)
        setIsSuccess(false)
        statusText(response.error_message || 'Ops! an error occured. Please try again')
        return null
      }
      setIsLoading(false)
      setIsSuccess(true)
      return response
    }
    catch (e) {
      setIsLoading(false)
      setIsSuccess(false)
      console.log('getInstagramUserByToken error', e)
      setStatusText('Ops! an error occured. Please try again')
    }
    return null
  }

  const getInstagramAvatarByUsername = async (username) => {
    setIsLoading(true)
    setStatusText(`Getting profile picture for @${username}...`)
    try {
      const response = await getIgProfilePicByUsername(username)
      const image = response && response.data
      setStatusText('profile picture saved')
      if (!image) {
        setIsLoading(false)
        setIsSuccess(false)
        statusText('Ops! an error occured. Please try again')
        return null
      }
      setIsLoading(false)
      setIsSuccess(true)
      return image
    }
    catch (e) {
      setIsLoading(false)
      setIsSuccess(false)
      setStatusText('Ops! an error occured. Please try again')
    }
    return null
  }

  // NOT USED
  // const updateUsernameAndToken = async (userIdResponse, accessToken) => {
  //   console.log("updateToken called accessToken", accessToken)
  //   console.log("updateToken called userIdResponse", userIdResponse)
  //   setIsLoading(true)
  //   setStatusText('Saving...')
  //   try {
  //     const data = new FormData()
  //     data.append('username', userIdResponse.username)
  //     data.append('uid', userIdResponse.id)
  //     if (longTokenResponse) {
  //       data.append('at', longTokenResponse.access_token)
  //     }
  //     const payload = { username: userIdResponse.username }
  //     const webRequest = {
  //       id: userContext.webUser.id,
  //       ...payload
  //     }
  //     webResponse = await updateWebUser(webRequest)
  //     setIsLoading(false)
  //     setIsSuccess(true)
  //     return response
  //   }
  //   catch (e) {
  //     setIsLoading(false)
  //     setIsSuccess(false)
  //     setStatusText(response.message || 'Ops! an error occured. Please try again')
  //   }
  //   return null
  // }

  // NOT USED
  // const getShortToken = async (code) => {
  //   console.log("getShortToken called code:", code)
  //   setIsLoading(true)
  //   setStatusText('Connecting to Instagram')
  //   try {
  //     console.log("getShortAccessToken called")
  //     //TODO1 update user to get code 
  //     var response = {} //await getShortAccessToken(code)
  //     console.log("getShortAccessToken called response:", response)
  //     if (response.error_type) {
  //       setIsLoading(false)
  //       setIsSuccess(false)
  //       setStatusText(response.error_message || 'Ops! an error occured. Please try again')
  //       return null
  //     }
  //     else {
  //       setIsLoading(false)
  //       setIsSuccess(true)
  //       return response
  //     }

  //   }
  //   catch (e) {
  //     setIsLoading(false)
  //     setIsSuccess(false)
  //     setStatusText(response && response.error_message || 'Ops! an error occured. Please try again')
  //   }
  //   return null
  // }
  // const getLongToken = async (code) => {
  //     console.log("getLongToken called code:", code)
  //     setIsLoading(true)
  //     setStatusText('Connecting to Instagram...')
  //     setCode(code)

  //     try {
  //       // var response = await getLongToken(code)
  //       if (response.error_type) {
  //         setIsLoading(false)
  //         setIsSuccess(false)
  //         setStatusText(response.error_message || 'Ops! an error occured. Please try again')
  //         return null
  //       }
  //       setIsLoading(false)
  //       setIsSuccess(true)
  //       return response
  //     }
  //     catch (e) {
  //       setIsLoading(false)
  //       setIsSuccess(false)
  //       setStatusText(response.message || 'Ops! an error occured. Please try again')
  //     }
  //     return null
  //   }

  return (
    <Container>
      <Divider hidden />
      <center>
        <Segment loading={isLoading} inverted>
          {/* <div>code: {this.state.code}</div> */}
          <Header><Icon name='instagram' />Linking your Instagram...</Header>
          <Message compact error={!isSuccess} success={isSuccess}>{statusText}</Message>
          <Container as={Media} greaterThanOrEqual='computer'>
            <Button
              as='a'
              href={EDITPROFILE}
              compact
              size='tiny'
              style={{ marginLeft: '5px' }}
            >Continue</Button>
          </Container>
          <Container as={Media} at='mobile'>
            <Button
              as='a'
              href={`seemyfit://code=${code}`}
              compact
              size='tiny'
              style={{ marginLeft: '5px' }}
            >Continue</Button>
          </Container>
        </Segment>
      </center>
      <Divider hidden />
    </Container>
  )
})

export default IgAuthPage
