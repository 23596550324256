import React, { Component } from 'react'

import {
  Grid,
  Image,
  Button
} from 'semantic-ui-react'

import asoslogo from '../../../../images/asoslogo.png'
import boohoologo from '../../../../images/boohoologo.png'
import vanslogo from '../../../../images/vanslogo.png'
import zaralogo from '../../../../images/zaralogo.png'
import sheinlogo from '../../../../images/sheinlogo.png'
import guccilogo from '../../../../images/guccilogo.png'
import diorlogo from '../../../../images/diorlogo.png'
import hermeslogo from '../../../../images/hermeslogo.png'
// import chanellogo from '../../../../images/chanellogo.png'
import aritzialogo from '../../../../images/aritzialogo.png'

export const FILTERS = [
  { name: 'gucci', image: guccilogo },
  { name: 'dior', image: diorlogo },
  { name: 'hermes', image: hermeslogo },
  //   { name: 'chanel', image: chanellogo },
  { name: 'aritzia', image: aritzialogo },
  { name: 'asos', image: asoslogo },
  { name: 'boohoo', image: boohoologo },
  { name: 'zara', image: zaralogo },
  { name: 'shein', image: sheinlogo },
  { name: 'vans', image: vanslogo }
  // { name: 'dc', image: dclogo },
]

class ProductFilters extends Component {
  setSitenameFilter (newSitename) {
    this.props.onProductFilterChange && this.props.onProductFilterChange(newSitename)
  }

  renderFilter (filter, index) {
    const { siteName } = this.props
    return (
      <Grid.Column mobile={6} tablet={2} computer={2} key={index}>
        <Button
          onClick={() => this.setSitenameFilter(filter.name)}
          active={siteName ? siteName.includes(filter.name) : false}
          fluid
        >
          <Image src={filter.image} size='tiny' rounded />
        </Button>
      </Grid.Column>
    )
  }

  render () {
    return (
    // <Menu compact inverted style={{ background: 'white' }}>
      <Grid centered columns='equal' className='filters'>
        {FILTERS.map((f, index) => this.renderFilter(f, index))}

        {/* <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('dior')}
                        active={siteName === 'dior'}
                        fluid
                    >
                        <Image src={diorlogo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('hermes')}
                        active={siteName === 'hermes'}
                        fluid
                    >
                        <Image src={hermeslogo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('chanel')}
                        active={siteName === 'chanel'}
                        fluid
                    >
                        <Image src={chanellogo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('asos')}
                        active={siteName === 'asos'}
                        fluid
                    >
                        <Image src={asoslogo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('boohoo')}
                        active={siteName === 'boohoo'}
                        fluid
                    >
                        <Image src={boohoologo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('vans')}
                        active={siteName === 'vans'}
                        fluid
                    >
                        <Image src={vanslogo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('zara')}
                        active={siteName === 'zara'}
                        fluid
                    >
                        <Image src={zaralogo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('shein')}
                        active={siteName === 'shein'}
                        fluid
                    >
                        <Image src={sheinlogo} size='tiny' rounded />
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        onClick={() => this.setSitenameFilter('dc')}
                        active={siteName === 'dc'}
                        fluid
                    >
                        <Image src={dclogo} size='tiny' rounded />
                    </Button>
                </Grid.Column> */}
      </Grid>
    )
  }
}
export default ProductFilters
