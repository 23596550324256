import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
// import { FBPixel } from '../../services/facebookPixel'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  request: ['request'],
  reset: null
}, { prefix: 'SESSION_' })

export const SessionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isCookiesAccepted: null
})

/* ------------- Reducers ------------- */

export const request = (state) => {
  // FBPixel.grantConsent()
  return state.merge({ isCookiesAccepted: true })
}
export const reset = () => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.RESET]: reset
})
