import React, { Component } from 'react'
import {
    Button,
    Container,
    Grid,
    Header,
    Segment,
    Form, Message
} from 'semantic-ui-react'

import ResponsiveContainer from '../../../layouts/components/Responsive/ResponsiveContainer'


class AddImagePage extends Component {
    state = {
        isLoading: false,
        statusText: null,
        isSuccess: null,
        url: '',
        file: '',
        color: '',
        size: '',
        instagram_username: '',
        image_url: '',
        name: '',
        price: '',
        currency: '',
        url_original_post: '',
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    onFileSelectedHandler = (e) => this.setState({ file: e.target.files[0] })

    handleSubmit = async () => {
        // TODO copy the same logic from sending a post with the new logic here, when needed
    }

    renderForm() {
        const {
            url, color, size, image_url,
            name, instagram_username, price,
            url_original_post, currency
        } = this.state
        return (
            <>
                <Segment padded textAlign='left'>
                    <Header
                    >{'Product Detail'}</Header>
                    <Form.Input
                        label='Product Url'
                        placeholder='Url of the product eg. https://ca.boohoo.com/tall-teddy-faux-fur-aviator/TZZ96440-109-20.html'
                        name='url'
                        value={url}
                        onChange={this.handleChange}
                        required
                    />
                    <Form.Input
                        label='Product Name'
                        placeholder='eg. Leopard Panel Basic Flat Sneakers'
                        name='name'
                        value={name}
                        onChange={this.handleChange}
                    />
                    <Form.Input
                        label='Product Image Url'
                        placeholder='Url of the product eg. https://i1.adis.ws/i/boohooamplience/fzz77610_white_xl/white-leopard-panel-basic-flat-sneakers'
                        name='image_url'
                        value={image_url}
                        onChange={this.handleChange}
                    />
                    <Form.Input
                        label='Product Color'
                        placeholder='eg. RED'
                        name='color'
                        value={color}
                        onChange={this.handleChange}
                    />
                    <Form.Input
                        label='Product Size'
                        placeholder='the size of the product your want to share eg. CAD 8'
                        name='size'
                        value={size}
                        onChange={this.handleChange}
                    />
                    <Form.Group widths='equal'>
                        <Form.Input
                            label='Product Price'
                            placeholder='eg. 100$'
                            name='price'
                            value={price}
                            onChange={this.handleChange}
                        />

                        <Form.Input
                            label='Currency'
                            placeholder='eg. CAD or EUR'
                            name='currency'
                            value={currency}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                </Segment>

                <Segment padded textAlign='left'>
                    <Header
                    >{'Your Fit Detail'}</Header>
                    <Form.Field>
                        <label>Your Picture</label>
                        <input
                            type="file"
                            name="file"
                            onChange={this.onFileSelectedHandler}
                            ref={(ref) => this.fileUploadRef = ref}
                        />
                    </Form.Field>
                    <Form.Input
                        label='Your Instagram'
                        placeholder='eg. @seemyfit'
                        name='instagram_username'
                        value={instagram_username}
                        onChange={this.handleChange}
                    />
                    <Form.Input
                        label='Instagram Post'
                        placeholder='eg. https://www.instagram.com/p/B8wmbvbBxMM/'
                        name='url_original_post'
                        value={url_original_post}
                        onChange={this.handleChange}
                    />
                </Segment>
                <Button type='submit' size='huge'>Share</Button>
            </>
        )
    }

    renderMessage() {
        const { statusText, isSuccess } = this.state
        return (
            <Message success={isSuccess} error={isSuccess === false}>
                {statusText}
            </Message>
        )
    }

    render() {
        const { statusText, isSuccess } = this.state

        return (
            <ResponsiveContainer history={this.props.history} location={this.props.location}>
                <Container>
                    <Segment padded color='yellow'>
                        <center>
                            <Header as='h3' style={{ fontSize: '2em' }} centered>
                                Add a new product and share your fit!
                            </Header>
                        </center>
                        <br />
                        <Grid centered>
                            <Form
                                success={isSuccess} error={isSuccess === false}
                                onSubmit={this.handleSubmit}
                                loading={this.state.isLoading}
                                style={{ paddingTop: 15 }}>
                                {statusText !== null && this.renderMessage()}
                                {this.renderForm()}
                            </Form>
                        </Grid>
                    </Segment>
                </Container>

            </ResponsiveContainer>
        )
    }
}

export default AddImagePage